/* eslint-disable react-hooks/exhaustive-deps */

import { useHistory, useParams } from 'react-router-dom';
import { StepCreateWithModal } from '../../components/Modals';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  MiniMap,
  useReactFlow
} from 'reactflow';
import 'reactflow/dist/style.css';

import { Switch } from '../../components/Core';
import { BackArrowIcon } from '../../components/Icons';
import HoverEdge from '../../components/ReactFlow/edges/HoverEdge';
import NavigationEdgeRules from '../../components/NavigationRules/NavigationEdgeRules';
import StepNode from '../../components/ReactFlow/nodes/StepNode';
import { recurseSteps } from '../../components/ReactFlow/graph';
import useFeatheryRedux from '../../redux';
import { DEFAULT_FLOW_POSITION } from '../../redux/panels';
import { deepEquals } from '../../utils/core';
import styles from './styles.module.scss';
import { useAppSelector } from '../../hooks';
import { useFormBuilderChangeStep } from '../../hooks/useFormBuilderChangeStep';
import AnimatedConnectionLine from '../../components/ReactFlow/AnimatedConnectionLine';
import AnimatedEdge from '../../components/ReactFlow/edges/AnimatedEdge';
import ElementSelectorModal from '../../components/Modals/ElementSelectorWithModal/ElementSelectorModal';
import validateStepConnections from '../../components/NavigationRules/components/utils';
import NavigationNodeRules from '../../components/NavigationRules/NavigationNodeRules';
import debounce from 'lodash.debounce';

const nodeTypes = { step: StepNode };
const edgeTypes = { hover: HoverEdge, animated: AnimatedEdge };

export default function FormFlowPage() {
  const history = useHistory();
  const { formId, stepId } = useParams<{ formId: string; stepId: string }>();
  const {
    editAccountPanelData,
    formBuilder: { setAccountData, updateNavRulesByPair },
    panels: { setFlowPosition, setFlowStepPositions },
    toasts: { addErrorToast }
  } = useFeatheryRedux();

  const [nodes, setNodes] = useState<any[]>([]);
  const [edges, setEdges] = useState<any[]>([]);
  const [hoverStepId, setHoverStepId] = useState('');
  const [pendingEdge, setPendingEdge] = useState<null | {
    source: string;
    target?: string;
  }>(null);
  const [pendingConnection, setPendingConnection] = useState(false);
  const clearPendingState = () => {
    setPendingConnection(false);
    setPendingEdge(null);
  };

  const reactFlowInstance = useReactFlow();

  const flowPosition = useAppSelector(
    (state) => state.panels.flowPositions[formId] ?? DEFAULT_FLOW_POSITION,
    deepEquals
  );
  const allStepPositions = useAppSelector(
    (state) => state.panels.flowStepPositions,
    deepEquals
  );
  const stepPositions = allStepPositions[formId] ?? {};

  const debouncedSaveStepPositions = useCallback(
    debounce(
      (newStepPositions) =>
        editAccountPanelData({
          panelId: formId,
          step_positions: { ...allStepPositions, [formId]: newStepPositions }
        }),
      2000,
      { leading: true, trailing: true }
    ),
    []
  );
  const updateStepPositions = (
    newStepPositions: Record<string, { x: number; y: number }>
  ) => {
    setFlowStepPositions({
      id: formId,
      positions: newStepPositions
    });
    debouncedSaveStepPositions(newStepPositions);
  };

  const panelsData = useAppSelector((state) => state.panels.panels);
  const showConnections = useAppSelector(
    (state) => state.formBuilder.accountData.showConnections
  );
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );

  const changeStep = useFormBuilderChangeStep();

  const [selection, setSelection] = useState<{
    source?: string;
    target?: string;
    node?: string;
  }>({});

  const panel = panelsData[formId];

  useEffect(() => {
    changeStep(stepId, true);
  }, [stepId, changeStep]);

  useEffect(() => clearPendingState(), [stepId]);

  const formatDisplayEdge = (
    edge: any,
    enableHover = true,
    selected = false,
    pending = false
  ) => ({
    id: `${edge.source}-${edge.target}${pending ? '-pending' : ''}`,
    source: edge.source,
    target: edge.target,
    type: pending ? 'animated' : 'hover',
    zIndex: selected || pending ? 1 : 0,
    data: { selected, enableHover, bidirectional: !!edge.bidirectional },
    style: {
      visibility:
        showConnections || [edge.source, edge.target].includes(hoverStepId)
          ? 'visible'
          : 'hidden'
    }
  });

  // @ts-expect-error TS(7006) FIXME: Parameter 'step' implicitly has an 'any' type.
  const formatDisplayNode = (step, position, key, selected) => ({
    id: step.id,
    type: 'step',
    key,
    data: {
      label: step.key,
      origin: step.origin,
      disabled: !!pendingEdge,
      selected:
        selected ||
        [selection.source, selection.target, selection.node].includes(step.id),
      onClick: () => setSelection({ node: step.id }),
      onDoubleClick: () => {
        setSelection({ node: step.id });
        changeStep(step.id);
        history.push(`/forms/${formId}/${step.id}/`);
      }
    },
    position
  });

  const [orderedSteps, recurseEdges, floatingSteps] = useMemo(
    () => recurseSteps(workingSteps),
    [workingSteps]
  );

  useEffect(() => {
    const pe = pendingEdge ?? ({} as any);
    const displayNodes: any = [];

    // Make sure nodes don't end up on top of one another
    const occupiedPositions: { [key: number]: { [key: number]: string } } = {};
    Object.entries(stepPositions).forEach(([stepId, position]) => {
      if (!occupiedPositions[position.y]) occupiedPositions[position.y] = {};
      occupiedPositions[position.y][position.x] = stepId;
    });

    const newPositions = JSON.parse(JSON.stringify(stepPositions));

    orderedSteps.forEach((steps: any, depth: any) => {
      steps.forEach((step: any, index: any) => {
        let position;
        if (step.id in newPositions) position = newPositions[step.id];
        else {
          const y = 100 + depth * 100;
          let x = 500 + index * 200;
          while (occupiedPositions[y] && occupiedPositions[y][x]) x = x + 200;
          position = { y, x };
          if (!occupiedPositions[y]) occupiedPositions[y] = {};
          occupiedPositions[y][x] = step.id;
          newPositions[step.id] = position;
        }
        displayNodes.push(
          formatDisplayNode(
            step,
            position,
            `${depth}-${index}`,
            [pe.source, pe.target].includes(step.id)
          )
        );
      });
    });
    const displayFloatingNodes = floatingSteps.map((step: any, index: any) => {
      let position;
      if (step.id in newPositions) position = newPositions[step.id];
      else {
        position = { y: 100 + index * 100, x: 300 };
        newPositions[step.id] = position;
      }
      return formatDisplayNode(
        step,
        position,
        index,
        [pe.source, pe.target].includes(step.id)
      );
    });
    let edgeSelected = false;
    const s = selection;
    const displayEdges = recurseEdges.map((edge: any) => {
      const selected =
        (s.source === edge.source && s.target === edge.target) ||
        (s.source === edge.target && s.target === edge.source) ||
        edge.source === s.node ||
        (edge.target === s.node && edge.bidirectional);
      edgeSelected = edgeSelected || selected;
      return formatDisplayEdge(edge, !pendingEdge, selected);
    });
    if (!edgeSelected && s.target) {
      // Selected but no rules yet
      displayEdges.push(
        formatDisplayEdge(selection, !pendingEdge, false, true)
      );
    }
    if (pe.target && (s.target !== pe.target || s.source !== pe.source)) {
      // Connection line
      displayEdges.push(
        formatDisplayEdge(pendingEdge, !pendingEdge, false, true)
      );
    }

    updateStepPositions(newPositions);
    setNodes([...displayNodes, ...displayFloatingNodes]);
    setEdges(displayEdges);
  }, [
    orderedSteps,
    recurseEdges,
    floatingSteps,
    setNodes,
    setEdges,
    showConnections,
    hoverStepId,
    selection,
    pendingEdge?.target
  ]);

  const onNodesChange = useCallback(
    (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const ss = selection.source ?? '';
  const st = selection.target ?? '';
  const sn = selection.node ?? '';
  return (
    <>
      <div id={'debug-layer'} />
      <div className={styles.flowContainer}>
        <div
          className={styles.editStepsButton}
          onClick={() => history.push(`/forms/${formId}/${activeStepId}/`)}
        >
          <BackArrowIcon />
          <span>Designer</span>
        </div>
        <StepCreateWithModal
          createStep={(newStep: any) => {
            let y = 100;
            while (
              nodes.find(
                (node) => node.position.x === 200 && node.position.y === y
              )
            )
              y += 100;
            updateStepPositions({
              ...stepPositions,
              [newStep.id]: { x: 200, y }
            });
            reactFlowInstance.setViewport({
              x: 400,
              y: y + 200,
              zoom: flowPosition.zoom
            });
            setSelection({ node: newStep.id });
          }}
          buttonStyle={{
            right: selection.source || selection.node ? 530 : 30
          }}
          buttonClassName={styles.addStepButton}
        />
        {pendingEdge?.source && (
          <ElementSelectorModal
            show={pendingConnection}
            hide={() => clearPendingState()}
            step={workingSteps[pendingEdge.source]}
            onSelect={(elementType: string, elementId: string) => {
              setSelection({});
              if (!pendingEdge) return;

              // Handle new rule creation
              const curStep = workingSteps[pendingEdge.source];
              const newData = [
                ...curStep.next_conditions.filter(
                  (cond: any) => cond.next_step === pendingEdge.target
                ),
                {
                  previous_step: pendingEdge.source,
                  next_step: pendingEdge.target,
                  element_id: elementId,
                  element_type: elementType,
                  created_at: new Date().toISOString(),
                  metadata: {},
                  rules: []
                }
              ];
              const errors = validateStepConnections(
                newData,
                curStep,
                workingSteps[pendingEdge.target ?? '']
              );
              const errVals = Object.values(errors);
              if (errVals.length === 0) {
                updateNavRulesByPair({
                  prevStepId: pendingEdge.source,
                  nextStepId: pendingEdge.target,
                  newData,
                  formId
                });
                setSelection({ ...pendingEdge });
                clearPendingState();
              } else addErrorToast({ title: errVals[0] });
            }}
          />
        )}
        <label className={styles.showConnections}>
          <Switch
            id='show-connections'
            checked={showConnections}
            onCheckedChange={(newShowConnections) => {
              setAccountData({ showConnections: newShowConnections });
              editAccountPanelData({
                panelId: panel.id,
                show_all_connections: newShowConnections
              });
            }}
          />
          Show All Connections
        </label>
        <ReactFlow
          style={{
            gridColumnEnd: selection.source || selection.node ? 2 : 3
          }}
          connectionLineComponent={(props) => (
            <AnimatedConnectionLine {...props} hide={!!pendingEdge?.target} />
          )}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onConnectStart={(event, params) => {
            if (params.nodeId) setPendingEdge({ source: params.nodeId });
          }}
          onConnectEnd={() => {
            if (pendingEdge?.target) {
              setPendingConnection(true);
            } else setPendingEdge(null);
          }}
          onNodeMouseEnter={(event, node) => {
            if (pendingConnection) return;
            if (pendingEdge?.source && pendingEdge.source !== node.id)
              setPendingEdge({ ...pendingEdge, target: node.id });
            else if (!pendingEdge) setHoverStepId(node.id);
          }}
          onNodeMouseLeave={() => {
            if (pendingConnection) return;
            if (pendingEdge?.source)
              setPendingEdge({ ...pendingEdge, target: '' });
          }}
          onEdgeClick={(event, edge) => {
            setSelection({
              source: edge.source,
              target: edge.target
            });
          }}
          // @ts-expect-error TS(2322) FIXME: Type '"loose"' is not assignable to type 'Connecti... Remove this comment to see the full error message
          connectionMode='loose'
          onNodeDragStop={(event, node) =>
            updateStepPositions({
              ...stepPositions,
              [node.id]: node.position
            })
          }
          onMoveEnd={(event, newPosition) =>
            setFlowPosition({ id: formId, position: newPosition })
          }
          onPaneClick={() => setSelection({})}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          panOnScroll
          panOnScrollSpeed={0.7}
          defaultViewport={flowPosition}
          /* Boundaries must be symmetrical, otherwise defaultPosition in one direction gets limited by boundary in the opposite direction (React Flow bug) */
          translateExtent={[
            [-20000, -20000],
            [20000, 20000]
          ]}
          proOptions={{ hideAttribution: true }}
        >
          <Background
            color='#EAEBED'
            style={{ backgroundColor: 'white' }}
            gap={20}
            size={3}
          />
          <MiniMap
            nodeStrokeWidth={3}
            nodeColor='black'
            className={styles.flowMinimap}
          />
        </ReactFlow>
        {ss in workingSteps && st in workingSteps && (
          <NavigationEdgeRules
            key={`${ss}-${st}`}
            previousStep={workingSteps[ss]}
            nextStep={workingSteps[st]}
          />
        )}
        {sn in workingSteps && (
          <NavigationNodeRules key={sn} step={workingSteps[sn]} />
        )}
      </div>
    </>
  );
}
