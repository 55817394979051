import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useGig } from '../../context/Gig';
import useGridMode from '../../hooks/useGridMode';
import useViewport from '../../hooks/useViewport';
import { getOS } from '../../utils/browserUtils';
import { Switch } from '../Core';
import Breadcrumbs from '../FormFieldEditors/Breadcrumbs';
import { ExpandedIcon, LaptopIcon, MobileIcon } from '../Icons';
import { Viewport } from '../RenderingEngine/GridInGrid/engine';
import styles from './styles.module.scss';
import testIds from '../../utils/testIds';

const BreadcrumbBar = () => {
  const { node: cellNode, gigPosition } = useGig();
  const { toggleGridMode, gridMode } = useGridMode();
  const { viewport, setViewport } = useViewport();
  const gridModeKeybind = getOS() === 'MacOS' ? '⌘ + G' : 'CTRL + G';

  return (
    <div id='breadcrumbsBar' className={styles.breadcrumbsBar}>
      <div>
        {/* cellNode is the same when nothing is selected & when the step is selected,
        so needed to use gigPosition to determine if we should hide breadcrumbs because
        the user doesn't have the step selected yet */}
        {cellNode && (gigPosition.length || gigPosition === 'root') && (
          <Breadcrumbs cellNode={cellNode} />
        )}
      </div>
      <div
        id='breadcrumbsBarControls'
        className={styles.breadcrumbsBarControls}
      >
        <div className={styles.viewportButtons}>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip>Actual Size</Tooltip>}
          >
            <div
              className={classNames(
                styles.viewportButton,
                viewport === Viewport.Unconstrained && styles.active
              )}
              onClick={() => setViewport(Viewport.Unconstrained)}
            >
              <ExpandedIcon />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip>Responsive Desktop</Tooltip>}
          >
            <div
              data-testid={testIds.desktopViewportButton}
              className={classNames(
                styles.viewportButton,
                viewport === Viewport.Desktop && styles.active
              )}
              onClick={() => setViewport(Viewport.Desktop)}
            >
              <LaptopIcon />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip>Responsive Mobile</Tooltip>}
          >
            <div
              data-testid={testIds.mobileViewportButton}
              className={classNames(
                styles.viewportButton,
                viewport === Viewport.Mobile && styles.activeMobile
              )}
              onClick={() => setViewport(Viewport.Mobile)}
            >
              <MobileIcon viewbox='5 0 32 32' />
            </div>
          </OverlayTrigger>
        </div>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip id='grid-mode-toggle-tooltip'>{gridModeKeybind}</Tooltip>
          }
        >
          <label className={styles.gridSwitch}>
            <Switch
              id='gridMode'
              checked={gridMode}
              onCheckedChange={toggleGridMode}
            />
            Grid Display
          </label>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default BreadcrumbBar;
