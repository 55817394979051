import type { ComponentProps } from 'react';

function Delete({
  children,
  ...rest
}: Omit<ComponentProps<'button'>, 'className'>) {
  return (
    <button {...rest} className='delete-button'>
      {children}
    </button>
  );
}

export default Delete;
