import { memo } from 'react';

import Dialog from '../Dialog';
import { Negative, Neutral } from '../Core/Button';

function DeleteRuleConfirmModal({ show, setShow, rule, deleteRule }: any) {
  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      shouldShowCloseIcon={false}
      title={`Are you sure you want to delete ${rule?.name}?`}
      alignCenter
      style={{ padding: '24px 40px' }}
    >
      <div className='dialog-form-action'>
        <Neutral onClick={() => setShow(false)}>Cancel</Neutral>
        <Negative
          onClick={async (event: any) => {
            event.preventDefault();
            await deleteRule(rule);
            setShow(false);
          }}
        >
          Yes, Delete Rule
        </Negative>
      </div>
    </Dialog>
  );
}

export default memo(DeleteRuleConfirmModal);
