/* eslint-disable react-hooks/exhaustive-deps */

import { useParams } from 'react-router-dom';
import { hasDiffs } from './diff';

import {
  FIELD_TYPES,
  flattenStyleMap,
  TYPE_BUTTON,
  TYPE_FIELD,
  TYPE_IMAGE,
  TYPE_PROGRESS_BAR,
  TYPE_TEXT,
  TYPE_VIDEO
} from './elements';
import {
  FONT_ATTRIBUTES,
  FONT_STYLE_ATTRIBUTES,
  getAsset,
  getFormattedTextStyle,
  getThemeStyle,
  loadFont,
  themeSelectorToElementType
} from './themes';
import { objectApply, objectPick, objectRemove } from './core';
import { useAppSelector } from '../hooks';

import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { SizeUnit, UNDO_TITLES, UNDO_TYPES } from './constants';
import { capitalizeFirstLetter, toCamelCase, uniqifyKey } from './format';
import {
  getContainersFromSteps,
  makeGetNextContainerKey,
  Viewport
} from '../components/RenderingEngine/GridInGrid/engine';
import { ACTION_OPTIONS } from '../components/Panels/utils';
import { ConditionData as Condition } from '../components/NavigationRules/components/types';
import { useMemo } from 'react';
import { defaultFieldValue } from '../components/SelectionPanel/elementEntries';

export interface Element {
  id: string;
  properties: { actions: any[] };
  source_asset?: string;
  servar?: any;
}
export interface Step {
  id: string;
  key: string;
  origin?: boolean;
  next_conditions: Condition[];
  previous_conditions: Condition[];
  texts: Element[];
  buttons: Element[];
}

function applyToStepElements(
  step: any,
  { filter, map }: any,
  applyToSubgrids = false
) {
  filter = filter ?? (() => true);
  map = map ?? ((element: any) => element);

  return produce(step, (draft: any) => {
    // Go through and remove elements that match the shouldRemove predicate
    draft.progress_bars = draft.progress_bars.filter(filter).map(map);
    draft.buttons = draft.buttons.filter(filter).map(map);
    draft.texts = draft.texts.filter(filter).map(map);
    draft.images = draft.images.filter(filter).map(map);
    draft.videos = draft.videos.filter(filter).map(map);
    draft.servar_fields = draft.servar_fields.filter(filter).map(map);
    if (applyToSubgrids)
      draft.subgrids = draft.subgrids.filter(filter).map(map);
  });
}

function reduceStepElements(
  step: any,
  { filter, callback, initialValue }: any,
  includeSubgrids = false
) {
  filter = filter ?? (() => true);
  initialValue = initialValue ?? {};
  callback =
    callback ??
    ((
      accumulator: any,
      currentValue: any,
      currentIndex: number,
      array: any[]
    ) => accumulator);

  const elements = [
    ...step.progress_bars,
    ...step.buttons,
    ...step.texts,
    ...step.images,
    ...step.videos,
    ...step.servar_fields,
    ...(includeSubgrids ? step.subgrids : [])
  ];
  return elements.filter(filter).reduce(callback, initialValue);
}

function someStepElements(
  step: any,
  { filter, test }: any,
  testSubgrids = false
) {
  filter = filter ?? (() => true);
  test = test ?? ((element: any, index: number, arr: any[]) => true);
  return (
    step.progress_bars.filter(filter).some(test) ||
    step.buttons.filter(filter).some(test) ||
    step.texts.filter(filter).some(test) ||
    step.images.filter(filter).some(test) ||
    step.videos.filter(filter).some(test) ||
    step.servar_fields.filter(filter).some(test) ||
    (testSubgrids && step.subgrids.filter(filter).some(test))
  );
}

const NavElementTypes = {
  BUTTON: 'button',
  TEXT: 'text',
  FIELD: 'field',
  PROGRESS_BAR: 'progress_bar',
  VIDEO: 'video',
  IMAGE: 'image'
} as const;
export type NavElementType =
  | 'button'
  | 'text'
  | 'field'
  | 'progress_bar'
  | 'video'
  | 'image';
export type ElementTypeTuple = [Element, NavElementType];

function filterAndMapStepElements(step: any, { filter, map }: any): any[] {
  filter = filter ?? (() => true);
  map = map ?? ((element: any) => element);

  return [
    ...step.buttons.map((e: Element) => [e, NavElementTypes.BUTTON]),
    ...step.texts.map((e: Element) => [e, NavElementTypes.TEXT]),
    ...step.progress_bars.map((e: Element) => [
      e,
      NavElementTypes.PROGRESS_BAR
    ]),
    ...step.images.map((e: Element) => [e, NavElementTypes.IMAGE]),
    ...step.videos.map((e: Element) => [e, NavElementTypes.VIDEO]),
    ...step.servar_fields.map((e: Element) => [e, NavElementTypes.FIELD])
  ]
    .filter(filter)
    .map(map);
}

const ELEMENT_TYPE_TO_PROP = {
  [TYPE_PROGRESS_BAR]: 'progress_bars',
  [TYPE_FIELD]: 'servar_fields',
  [TYPE_BUTTON]: 'buttons',
  [TYPE_IMAGE]: 'images',
  [TYPE_VIDEO]: 'videos',
  [TYPE_TEXT]: 'texts'
};

function getStepPropFromElementType(elementType: any) {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return ELEMENT_TYPE_TO_PROP[elementType] ?? 'servar_fields';
}

function commonElementProps() {
  return {
    id: uuidv4(),
    hide_ifs: [],
    show_logic: true, // If true, changes the meaning of hide_ifs to show_ifs
    styles: {},
    mobile_styles: {}
  };
}

function getGigUndoRedoPayload({
  stepId,
  oldSteps,
  newSteps,
  changedStepKeys, // array
  oldLogicRules,
  newLogicRules,
  changedLogicRuleKeys // array
}: any): any {
  return {
    id: stepId,
    oldValue: objectPick(oldSteps, changedStepKeys),
    newValue: objectPick(newSteps, changedStepKeys),
    title: UNDO_TITLES.FORMAT,
    type: UNDO_TYPES.SOME_STEPS,
    workingSteps: newSteps,
    oldRulesValue: objectPick(oldLogicRules, changedLogicRuleKeys),
    newRulesValue: objectPick(newLogicRules, changedLogicRuleKeys),
    workingLogicRules: newLogicRules
  };
}

function getUndoRedoPayload({
  elementType,
  oldSteps,
  newSteps,
  stepId,
  changedStepKeys, // array
  oldLogicRules,
  newLogicRules,
  changedLogicRuleKeys // array
}: any) {
  const stepElementProp = getStepPropFromElementType(elementType);
  let undoTitle;

  if (elementType === TYPE_TEXT) {
    undoTitle = UNDO_TITLES.TEXT;
  } else if (elementType === TYPE_IMAGE) {
    undoTitle = UNDO_TITLES.IMAGE;
  } else if (elementType === TYPE_VIDEO) {
    undoTitle = UNDO_TITLES.VIDEO;
  } else if (elementType === TYPE_BUTTON) {
    undoTitle = UNDO_TITLES.BUTTON;
  } else if (elementType === TYPE_PROGRESS_BAR) {
    undoTitle = UNDO_TITLES.PROGRESS_BAR;
  } else if (elementType === 'cell') {
    return getGigUndoRedoPayload({
      stepId,
      oldSteps,
      newSteps,
      changedStepKeys,
      oldLogicRules,
      newLogicRules,
      changedLogicRuleKeys
    });
  } else {
    undoTitle = UNDO_TITLES.SERVAR;
  }

  return {
    id: stepId,
    oldValue: { [stepElementProp]: oldSteps[stepId][stepElementProp] },
    newValue: { [stepElementProp]: newSteps[stepId][stepElementProp] },
    title: undoTitle,
    type: UNDO_TYPES.STEP,
    workingSteps: newSteps,
    oldRulesValue: objectPick(oldLogicRules, changedLogicRuleKeys),
    newRulesValue: objectPick(newLogicRules, changedLogicRuleKeys),
    workingLogicRules: newLogicRules
  };
}

function formatSteps(steps: any) {
  const stepIdMap = {};
  steps.forEach((step: any) => {
    // Populate with a default array if no servar_fields.
    // https://sentry.io/organizations/feathery-forms/issues/3650942724/?project=5280968
    step.servar_fields = step.servar_fields ?? [];

    step.servar_fields.forEach((field: any) => {
      const servar = field.servar;
      servar.value = defaultFieldValue(servar.type);
    });
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    stepIdMap[step.id] = step;
  });
  return stepIdMap;
}

/**
 * Determine which servars have been changed on a draft step.
 * @param step
 * @param oldServars Old servars from time of last publish.
 * @param ignoreKeys
 * @returns
 */
function getChangedServarsForStep(
  step: { servar_fields: { servar: { id: string } }[] },
  oldServars: Record<string, any>,
  ignoreKeys: string[] = ['created_at', 'updated_at', 'value', 'has_data'] // ignore transient fields
): Record<string, any> {
  return step.servar_fields.reduce(
    (changedServars: Record<string, any>, servarField) => {
      const { servar } = servarField;
      if (oldServars[servar.id]) {
        if (hasDiffs(oldServars[servar.id], servar, ignoreKeys))
          changedServars[servar.id] = servar;
      }
      return changedServars;
    },
    {}
  );
}

function getFirstStep(workingSteps: { [stepId: string]: Step }) {
  const steps = Object.values(workingSteps);
  return steps.find((step) => step.origin) ?? steps[0];
}

interface NewStepParams {
  key: string;
  width: SizeUnit;
  height: SizeUnit;
  backActionType?: string;
  origin?: boolean;
}

const useNewStep = () => {
  const { formId } = useParams<{ formId: string }>();
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const workingSteps = useAppSelector((s: any) => s.formBuilder.workingSteps);
  const theme = useAppSelector((state) => state.themes.themes[panel.theme]);
  const fields = useAppSelector((state) => [
    ...Object.values(state.formBuilder.servars)
  ]);

  const containers = useMemo(() => {
    return getContainersFromSteps(Object.values(workingSteps));
  }, [workingSteps]);

  return ({
    key,
    width,
    height,
    backActionType = 'back',
    origin = false
  }: NewStepParams): Record<string, any> => {
    const getNextContainerKey = makeGetNextContainerKey(containers);

    const buildElement = (
      elementType: string,
      position: number[],
      propOverrides = {},
      styleOverrides = {},
      assetId?: string,
      defaultStyles = {},
      defaultTextStyles?: Record<string, any>
    ) => {
      const element: any = objectApply(
        newElement({
          elementType,
          defaultProperties: objectApply(
            FeatheryConfig.default_element_properties[elementType],
            propOverrides
          ),
          fields
        }),
        { position }
      );

      element.styles = objectApply(element.styles, styleOverrides);
      if (assetId) element.source_asset = assetId;
      else {
        element.styles = objectApply(element.styles, defaultStyles);
        if (defaultTextStyles) {
          const op = element.properties.text_formatted[0];
          op.attributes = objectApply(op.attributes, defaultTextStyles);
        }
      }

      return element;
    };

    return {
      id: uuidv4(),
      key,
      origin: origin,
      next_conditions: [],
      previous_conditions: [],
      servar_fields: [
        buildElement(
          'text_field',
          [2],
          {
            placeholder: 'Placeholder'
          },
          { width: 400, width_unit: 'px' }
        )
      ],
      images: [],
      videos: [],
      texts: [
        buildElement(
          TYPE_TEXT,
          [0],
          {
            text: 'Your title here',
            text_formatted: [
              {
                insert: 'Your title here',
                attributes: {}
              }
            ]
          },
          { padding_bottom: 30 },
          getAsset(theme, TYPE_TEXT, '', 'Title')?.id,
          { width: '', width_unit: 'fit' },
          { font_size: 24, font_weight: 600 }
        ),
        buildElement(
          TYPE_TEXT,
          [1],
          {
            text: 'Field label',
            text_formatted: [
              {
                insert: 'Field label',
                attributes: {}
              }
            ]
          },
          { width: 400, width_unit: 'px' },
          getAsset(theme, TYPE_TEXT, '', 'Field Label')?.id,
          { horizontal_align: 'flex-start' },
          { font_weight: 500 }
        )
      ],
      buttons: [
        buildElement(
          TYPE_BUTTON,
          [3, 1],
          {
            text: 'Next',
            text_formatted: [{ insert: 'Next', attributes: {} }]
          },
          { width: 160, width_unit: 'px' }
        ),
        buildElement(
          TYPE_BUTTON,
          [3, 0],
          {
            text: 'Back',
            text_formatted: [{ insert: 'Back', attributes: {} }],
            actions: [{ type: backActionType }],
            submit: false
          },
          { width: 160, width_unit: 'px' },
          (
            getAsset(theme, TYPE_BUTTON, '', 'Secondary') ||
            getAsset(theme, TYPE_BUTTON, '', 'Back')
          )?.id
        )
      ],
      progress_bars: [],
      position: [],
      subgrids: [
        {
          position: [],
          mobile_axis: '',
          axis: 'row',
          hide_ifs: [],
          show_logic: true,
          key: getNextContainerKey(),
          properties: {},
          styles: {
            vertical_align: 'flex-start',
            horizontal_align: 'center',
            gap: 10,
            padding_left: 40,
            padding_right: 40,
            padding_top: 80,
            padding_bottom: 80
          },
          mobile_styles: {},
          background_image: null,
          mobile_background_image: null,
          width: 'fill',
          mobile_width: null,
          height: 'fill',
          mobile_height: null,
          repeated: false
        },
        {
          position: [3],
          mobile_axis: '',
          axis: 'column',
          hide_ifs: [],
          show_logic: true,
          key: getNextContainerKey(),
          properties: {},
          styles: { horizontal_align: 'space-between' },
          mobile_styles: {},
          background_image: null,
          mobile_background_image: null,
          width: '400px',
          mobile_width: null,
          height: 'fit',
          mobile_height: null,
          repeated: false
        }
      ],
      width: width,
      height: height
    };
  };
};

function copyStep(
  step: any,
  newKey: string,
  formId: string,
  copyFormId: string,
  servars: any[],
  hiddenFields: any[],
  panels: any[],
  servarUsage: any[],
  steps: any[],
  cleanupAllElementReferences: (
    steps: any,
    element: any,
    changedStepKeys: Set<string>,
    logicRules: any,
    changedLogicRuleKeys: Set<string>,
    replacementElement?: any
  ) => any
): any {
  const containers = getContainersFromSteps(steps);
  const getNextContainerKey = makeGetNextContainerKey(containers);

  let newServars = servars;
  const mappedHiddenFields = hiddenFields.reduce((obj: any, field: any) => {
    obj[field.id] = field;
    return obj;
  }, {});

  // Ensure all container IDs and keys are unique
  const subgrids = step.subgrids.map(({ id, key, ...subgrid }: any) => {
    return {
      id: uuidv4(),
      key: getNextContainerKey(),
      ...subgrid
    };
  });

  // create a map of old servar element to new copied servar so that each reference
  // can be fixed up in rules, properties, etc.
  const servarMap = new Map<any, any>();

  const stepCopy: any = applyToStepElements(step, {
    map(element: any) {
      element.id = uuidv4();
      const oldServar = element.servar;

      // Need a guard here for a sentry error that cannot be replicated
      // https://feathery-forms.sentry.io/issues/4494555013/?alert_rule_id=1124137&alert_type=issue&notification_uuid=8ad48495-47ef-4e7c-9dd5-e9535d2e2d28&project=5280968&referrer=slack
      if (oldServar && (servarUsage[oldServar.id]?.length ?? 0) <= 1) {
        // If servar isn't linked, create a new servar

        // save it for mapping
        const oldElement = { ...element, servar: { ...element.servar } };

        const servar = element.servar;
        servar.id = uuidv4();
        servar.key = uniqueFieldKey(servar.key, {
          ...newServars,
          ...mappedHiddenFields
        });
        newServars = { ...newServars, [servar.id]: servar };
        // map it
        servarMap.set(oldElement, {
          // avoid proxy issues by copying
          ...element,
          servar: { ...element.servar }
        });
      }

      const oldP = panels.find((p) => (p as any).id === formId);
      const newP = panels.find((p) => (p as any).id === copyFormId);
      if ((oldP as any).theme !== (newP as any).theme) {
        element.source_asset = null;
      }

      return element;
    }
  });

  // fix up changed servar references in this new step only
  let fixedUpSteps = { [stepCopy.id]: { ...stepCopy, subgrids } };
  for (const [oldElement, newElement] of servarMap.entries()) {
    const { steps } = cleanupAllElementReferences(
      fixedUpSteps,
      oldElement,
      new Set<string>(),
      // no need to fix up logic rules since this is a new step with the same or new elements/fields
      // and will not affect existing logic rules
      {},
      new Set<string>(),
      newElement
    );
    fixedUpSteps = steps;
  }

  return {
    ...fixedUpSteps[stepCopy.id],
    id: uuidv4(),
    key: newKey,
    next_conditions: [],
    previous_conditions: [],
    origin: false
  };
}

/**
 * Find an element on the source step that is eligible to be used for auto-connection
 * to the target step.  Rules:
 * 1. Its action must be NEXT
 * 2. It must have no associated navigation rules or only have associated navigation rules with conditions.
 * Associated navigation rules are those on the step involving this element.
 * 3. It must not already have a connection from this element to the destination step
 * 4. Buttons are favored over texts when choosing
 * @param sourceStep
 * @param targetStep
 * @param excludedElementIds Element ids on the source step to exclude from eligibility
 * @returns
 */
function findSourceElementForConnection(
  sourceStep: Step,
  targetStep: Step,
  excludedElementIds: string[] = []
): ElementTypeTuple | null {
  return (
    filterAndMapStepElements(sourceStep, {
      filter: (element: ElementTypeTuple) => {
        if (
          !excludedElementIds.includes(element[0].id) &&
          (element[0].properties.actions ?? []).some(
            (action) => action.type === ACTION_OPTIONS.NEXT
          )
        ) {
          const associatedNavRules = sourceStep.next_conditions.filter(
            (condition) => condition.element_id === element[0].id
          );
          if (
            !associatedNavRules.length ||
            associatedNavRules.every(
              (condition) =>
                condition.next_step !== targetStep.id && condition.rules.length
            )
          )
            return true;
        }
        return false;
      }
    })[0] ?? null
  );
}

function uniqueFieldKey(baseKey: any, fields: any, capitalize = false) {
  if (capitalize) baseKey = capitalizeFirstLetter(toCamelCase(baseKey));
  // Given all the fields within the org, find the first ID that is not duplicated
  return uniqifyKey(
    baseKey,
    Object.values(fields).map((field) => (field as any).key)
  );
}

function defaultServarProps({ type, fields, defaultProperties }: any) {
  return {
    ...defaultProperties,
    id: uuidv4(),
    key: uniqueFieldKey(type, fields, true)
  };
}

function newElement({ elementType, asset, defaultProperties, fields }: any) {
  const element: any = commonElementProps();

  element.properties = objectRemove(defaultProperties, [
    'servar',
    ...Object.keys(asset?.properties ?? {})
  ]);
  if (asset) {
    element.source_asset = asset.id;
    if ([TYPE_BUTTON, TYPE_TEXT].includes(elementType)) {
      // For elements with rich text and backed by an asset, copy only mixed styles over to the new element.
      // Constant styles don't need to be copied since they'll be inherited from the asset or
      // underlying theme by default.
      const mixedStyles = getRichTextMixedStyles(
        asset.properties.text_formatted
      );
      element.properties.text_formatted = produce(
        asset.properties.text_formatted,
        (draft: any) =>
          draft.forEach((op: any) => {
            if (op.insert) {
              op.attributes = Object.entries(op.attributes ?? {}).reduce(
                (attrs, [k, v]) => {
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  if (mixedStyles.includes(k)) attrs[k] = v;
                  return attrs;
                },
                {}
              );
            }
            return op;
          })
      );
      element.properties.text = asset.properties.text;
    }
  }
  if (FIELD_TYPES.includes(elementType)) {
    element.servar = objectApply(
      defaultServarProps({
        type: elementType,
        fields,
        defaultProperties: defaultProperties?.servar
      }),
      asset?.servar
    );
  }

  return element;
}

function resetRichTextStyles(richText: any, styles: any, reset = true) {
  return produce(richText, (draft: any) => {
    draft.forEach((op: any) => {
      Object.keys(op.attributes).forEach((style) => {
        const hasStyle = styles.includes(style);
        if (reset ? hasStyle : !hasStyle) delete op.attributes[style];
      });
    });
  });
}

function getRichTextSameStyles(richText: any) {
  let sameStyles = {};
  if (richText && richText.length) {
    sameStyles = richText[0].attributes ?? {};
    richText.forEach((op: any, index: any) => {
      if (index > 0) {
        const newStyleValues = {};
        Object.entries(sameStyles).forEach(([style, val]) => {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (op.attributes[style] === val) newStyleValues[style] = val;
        });
        sameStyles = newStyleValues;
      }
    });
  }
  return sameStyles;
}

function getRichTextMixedStyles(richText: any) {
  const mixedStyles: any = [];
  FONT_ATTRIBUTES.forEach((attr) => {
    if (mixedStyles.includes(attr)) return;

    let val: any;
    let isMixed = false;
    richText
      .filter((op: any) => op.insert)
      .forEach((op: any, index: any) => {
        if (isMixed) return;
        const opVal = op.attributes?.[attr];
        if (index === 0) val = opVal;
        else if (val !== opVal) {
          if (FONT_STYLE_ATTRIBUTES.includes(attr)) {
            mixedStyles.push(...FONT_STYLE_ATTRIBUTES);
          } else mixedStyles.push(attr);
          isMixed = true;
        }
      });
  });
  return mixedStyles;
}

/**
 * Elements include a collection of metadata (like corresponding asset or theme styles that apply).
 * This method collapses all the metadata about an element and returns an element object that is fit to render.
 */
function calculateElementRenderData({
  theme,
  element,
  viewport = Viewport.Desktop,
  style: [l2, l1 = ''],
  loadFonts = true
}: any) {
  const themeMap = flattenStyleMap(theme.elements);

  return produce(element, (draft: any) => {
    const asset = getAsset(
      theme,
      themeSelectorToElementType(l2, l1),
      draft.source_asset
    );

    // Copy over properties from the asset (if there is one)
    if (asset) {
      const assetPropsToIgnore = ['id', 'key', 'text_formatted'];
      const assetProps = objectRemove(asset, assetPropsToIgnore);
      draft = objectApply(assetProps, draft);
    }

    // Apply the styles from the theme hierarchy as well as the corresponding asset (if there is one)
    const styles = objectApply(
      getThemeStyle(themeMap, viewport, l2, l1),
      asset?.styles,
      viewport === Viewport.Mobile ? asset?.mobile_styles : {},
      draft.styles
    );

    // Format text_formatted appropriately
    const assetTF = asset ? asset.properties.text_formatted : null;
    if ('text_formatted' in (draft.properties ?? {}) || assetTF) {
      const sameStyles = getRichTextSameStyles(assetTF);
      const textFormatted = getFormattedTextStyle(
        draft.properties.text_formatted,
        { ...styles, ...sameStyles },
        viewport === Viewport.Mobile ? draft.mobile_styles : null,
        loadFonts
      );

      draft = objectApply(draft, {
        properties: { text_formatted: textFormatted }
      });
    }

    // Finish applying mobile styles if necessary
    const finalStyles = objectApply(
      styles,
      viewport === Viewport.Mobile ? draft.mobile_styles : {}
    );

    if (loadFonts) {
      loadFont(finalStyles);
    }

    return objectApply(draft, { styles: finalStyles });
  });
}

function calculateGridRenderData({ theme, grid, viewport, root }: any) {
  const step_background_color = theme.step_background_color;

  return produce(grid, (draft: any) => {
    // Apply the theme step background color if grid is root
    const styles = objectApply(
      root ? { background_color: step_background_color } : {},
      draft.styles
    );

    // Finish applying mobile styles if necessary
    const finalStyles = objectApply(
      styles,
      viewport === Viewport.Mobile ? draft.mobile_styles : {}
    );

    return objectApply(draft, { styles: finalStyles });
  });
}

const sortStepElements = (a: any, b: any) => {
  if (a.row_index > b.row_index) return 1;
  else if (a.row_index < b.row_index) return -1;
  else if (a.column_index > b.column_index) return 1;
  else if (a.column_index < b.column_index) return -1;
  else return 0;
};

export {
  getGigUndoRedoPayload,
  resetRichTextStyles,
  getRichTextMixedStyles,
  getRichTextSameStyles,
  getUndoRedoPayload,
  applyToStepElements,
  reduceStepElements,
  someStepElements,
  getStepPropFromElementType,
  uniqueFieldKey,
  defaultServarProps,
  formatSteps,
  getChangedServarsForStep,
  newElement,
  getFirstStep,
  useNewStep,
  copyStep,
  findSourceElementForConnection,
  calculateElementRenderData,
  calculateGridRenderData,
  sortStepElements,
  filterAndMapStepElements
};
