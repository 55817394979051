import {
  DropdownField,
  DropdownMultiField,
  InlineTooltip,
  TextField
} from '../../../components/Core';
import Label from '../../../components/Dialog/Label';
import '../../../style/dialog-form.css';
import styles from '../styles.module.scss';

export default function ExtractionProperties({
  formData,
  handleChange,
  accounts,
  fileOptions,
  hiddenFieldOptions,
  usedFields
}: any) {
  return (
    <>
      <Label>Name</Label>
      <TextField
        value={formData.key}
        placeholder='My Extraction'
        onComplete={(newKey: string) => handleChange('key', newKey)}
        className={styles.modalInput}
        required
      />
      <div className={styles.labelContainer}>
        <Label>File Type</Label>
      </div>
      <DropdownField
        selected={formData.file_type}
        options={[
          { value: 'image', display: 'Documents or images' },
          { value: 'csv', display: 'Spreadsheets or CSVs' }
        ]}
        onChange={(event: any) => handleChange('file_type', event.target.value)}
        className={styles.modalInput}
        required
      />
      <div className={styles.labelContainer}>
        <Label>File Sources</Label>
        <InlineTooltip text='The extraction will run on files stored in these fields' />
      </div>
      <DropdownMultiField
        selected={formData.file_sources}
        options={[...fileOptions, ...hiddenFieldOptions].filter(
          (option) =>
            formData.file_sources.includes(option.value) ||
            !usedFields.includes(option.value)
        )}
        onChange={(items: { value: string }[]) =>
          handleChange(
            'file_sources',
            items.map((item) => item.value)
          )
        }
        className={styles.modalInput}
        required
      />
      <div className={styles.labelContainer}>
        <Label>Reviewers</Label>
        <InlineTooltip text='Reviewers will be notified via email to review & approve new extraction runs.' />
      </div>
      <DropdownMultiField
        selected={formData.reviewers}
        options={accounts.map((account: any) => ({
          label: account.email,
          value: account.id
        }))}
        onChange={(items: { value: string }[]) =>
          handleChange(
            'reviewers',
            items.map((item) => item.value)
          )
        }
        className={styles.modalInput}
      />
    </>
  );
}
