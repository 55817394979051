function MoneyGuide({ width = 90, height = 90 }) {
  return (
    <img
      alt='MoneyGuide logo'
      src={require('./MoneyGuide.png')}
      style={{ width, height }}
    />
  );
}

export default MoneyGuide;
