import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const getTheme = createAsyncThunk(
  'themes/getTheme',
  async ({ token, themeId }: { token: string; themeId: string }) => {
    const response = await FeatheryAPI.getTheme(token, { themeId });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch theme');
    }
  }
);

const getPanelThemeAssetUse = createAsyncThunk(
  'themes/getPanelThemeAssetUse',
  async ({ token, cached = true }: { token: string; cached?: boolean }) => {
    const response = await FeatheryAPI.getPanelThemeAssetUse(token, cached);

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch panel asset usage');
    }
  }
);

const createTheme = createAsyncThunk(
  'themes/createTheme',
  async ({
    token,
    themeId,
    copyThemeKey
  }: {
    token: string;
    themeId: string;
    copyThemeKey: string;
  }) => {
    const response = await FeatheryAPI.createTheme(token, {
      themeId,
      copyThemeKey
    });
    if (response.status === 201) {
      return await response.json();
    } else {
      throw new Error('Could not create theme');
    }
  }
);

const editTheme = createAsyncThunk(
  'themes/editTheme',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editTheme(token, data);

    if (response.status === 200) {
      return await response.json();
    } else {
      let err: any;
      try {
        err = await response.json();
      } catch {
        throw new Error('Could not edit theme');
      }
      if (err) throw new Error(err[0].message);
    }
  }
);

const deleteTheme = createAsyncThunk(
  'themes/deleteTheme',
  async ({ token, themeId }: { token: string; themeId: string }) => {
    const response = await FeatheryAPI.deleteTheme(token, { themeId });

    if (response.status !== 204) {
      throw new Error('Could not delete theme');
    }
  }
);

const getThemes = createAsyncThunk(
  'themes/getThemes',
  async ({ token, force = false }: { token: string; force?: boolean }) => {
    const response = await FeatheryAPI.getThemes(token, null, force);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch themes');
    }
  }
);

const getTemplates = createAsyncThunk(
  'themes/getTemplates',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.getTemplates(token);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch templates');
    }
  }
);

const themesSlice = createSlice({
  name: 'themes',
  initialState: {
    themes: {} as Record<string, any>,
    templates: {
      form_templates: [] as any[],
      finserve_templates: [] as any[]
    },
    panelThemeAssetUse: [] as {
      panel_id: string;
      theme_id: string;
      assets_in_use: any[];
    }[]
  },
  reducers: {
    setPanelThemeAssetUse(state, action) {
      state.panelThemeAssetUse = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTheme.fulfilled, (state, action) => {
      const payload = action.payload;
      state.themes[payload.id] = payload;
    });
    builder.addCase(
      getPanelThemeAssetUse.fulfilled,
      (state: any, action: any) => {
        state.panelThemeAssetUse = action.payload;
      }
    );
    builder.addCase(createTheme.fulfilled, (state: any, action: any) => {
      const payload = action.payload;
      state.themes[payload.id] = payload;
    });
    builder.addCase(editTheme.fulfilled, (state: any, action: any) => {
      state.themes[action.payload.id] = action.payload;
    });
    builder.addCase(deleteTheme.fulfilled, (state: any, action: any) => {
      delete state.themes[action.meta.arg.themeId];
    });
    builder.addCase(getThemes.fulfilled, (state: any, action: any) => {
      state.themes = {
        ...state.themes,
        ...action.payload.reduce(
          // @ts-expect-error TS(7006) FIXME: Parameter 'themes' implicitly has an 'any' type.
          (themes, theme) => ({
            ...themes,
            [theme.id]: theme
          }),
          {}
        )
      };
    });
    builder.addCase(getTemplates.fulfilled, (state: any, action: any) => {
      state.templates = action.payload;
    });
  }
});

export default themesSlice.reducer;
const exportFunctions = {
  syncFunctions: themesSlice.actions,
  asyncFunctions: {
    getTheme,
    getPanelThemeAssetUse,
    createTheme,
    editTheme,
    deleteTheme,
    getThemes,
    getTemplates
  }
};
export { exportFunctions };
