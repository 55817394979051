/* eslint-disable react-hooks/exhaustive-deps */

import { Container, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import ThemeNavigation from '../components/NavBar/ThemeNavigation';
import useFeatheryRedux from '../redux';
import { DeleteThemeModal } from '../components/Modals';
import { useParams } from 'react-router-dom';
import { Button, TextField } from '../components/Core';
import SettingsContainer from '../components/SettingsContainer';
import { useAppSelector } from '../hooks';
import { PUBLISH_STATUS } from '../redux/utils';

export default function ThemeSettingsPage() {
  const { themeId } = useParams<{ themeId: string }>();
  const { editTheme } = useFeatheryRedux();
  const theme = useAppSelector((state) => state.themes.themes[themeId]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [formData, setFormData] = useState<Record<string, any>>({ key: '' });
  const [publishStatus, setPublishStatus] = useState(PUBLISH_STATUS.FULFILLED);

  useEffect(() => {
    if (theme) setFormData({ key: theme.key });
  }, [theme]);

  const handleChange = (property: string) => (value: any) => {
    if (value !== formData[property]) {
      setFormData((formData) => ({
        ...formData,
        [property]: value
      }));
      setPublishStatus(PUBLISH_STATUS.ACTIVE);
    }
  };

  const formatData = () => {
    const { key } = formData;
    if (key.trim().length === 0)
      return { error: 'You need to enter a name for your theme' };
    return { data: formData };
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const payload = formatData();
    if (payload.error) return;

    setPublishStatus(PUBLISH_STATUS.LOADING);
    try {
      await editTheme({ themeId: theme.id, ...payload.data });
      setPublishStatus(PUBLISH_STATUS.FULFILLED);
    } catch (error: any) {
      setPublishStatus(PUBLISH_STATUS.ACTIVE);
    }
  };

  return (
    <div className='form-settings-page'>
      <ThemeNavigation
        activeItem='settings'
        status={publishStatus}
        onPublish={handleSubmit}
        saveFlag
      />
      <Container fluid className='settings-page'>
        <h1 className='page-head settings-header'>Settings</h1>
        <SettingsContainer title='Details'>
          <div className='field-group'>
            <Form.Label>Theme Name</Form.Label>
            <TextField
              placeholder='My Design System'
              value={formData.key ?? ''}
              onComplete={handleChange('key')}
            />
          </div>
        </SettingsContainer>
        <SettingsContainer title='Delete'>
          <Button.Delete onClick={() => setShowDeleteDialog(true)}>
            Delete Theme
          </Button.Delete>
        </SettingsContainer>
      </Container>
      <DeleteThemeModal
        show={showDeleteDialog}
        setShow={(show: any) => setShowDeleteDialog(show)}
        themeId={theme?.id}
      />
    </div>
  );
}
