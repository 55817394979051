import { memo, useCallback, useMemo, useState } from 'react';

import { getDefaultPrice, useProductCatalog } from './useProductCatalog';
import {
  Product,
  Price
} from '../../Panels/Sections/ClickActionSection/useStripeActions';
import Table from '../../Table';
import { formatMoney } from '../../../utils/format';
import { Neutral, Positive } from '../../Core/Button';
import { useParams } from 'react-router-dom';
import useFeatheryRedux from '../../../redux';
import { INTEGRATIONS } from '../../FormIntegrations/types';
import useIntegrations from '../../FormIntegrations/useIntegrations';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import useDialogFocus from '../../../utils/useDialogFocus';
import Label from '../../Dialog/Label';
import {
  CheckboxField,
  DropdownField,
  NumberInput,
  TextField
} from '../../Core';
import currencyData from '../../Assets/currencyData';

import Dialog from '../../Dialog';
import classNames from 'classnames';
import styles from './styles.module.scss';

const ProductSelectorModal = ({
  selectedId = '',
  show,
  setShow = () => {},
  onSelect = () => {}
}: any) => {
  function ActiveCheckbox({ active }: any) {
    return <CheckboxField checked={active} disabled />;
  }
  const [selected, setSelected] = useState(selectedId);

  const { productCatalog } = useProductCatalog();

  const recurringLabels: Record<string, string> = {
    day: 'Daily',
    week: 'Weekly',
    month: 'Monthly',
    year: 'Yearly',
    oneTime: 'One-Time'
  };

  // Only showing the active (checkbox) column if there is an inactive product already selected
  const showActiveColumn = useMemo(() => {
    return Object.values(productCatalog).some(
      (productCatalogItem) =>
        productCatalogItem.products.some((product) => !product.active) &&
        productCatalogItem.products.some((product) => product.id === selectedId)
    );
  }, [productCatalog, selectedId]);

  const productCatalogData = useMemo(() => {
    return Object.entries(productCatalog)
      .filter(([name, productCatalogItem]) => {
        // only include an inactive product if it is already selected
        // Normally inactives are not selectable
        // For product pairs only include if all products are inactive
        return (
          !productCatalogItem.products.every((product) => !product.active) ||
          productCatalogItem.products.some(
            (product) => product.id === selectedId
          )
        );
      })
      .reduce((data, [name, productCatalogItem]) => {
        // If this is a live/test pair of products, only list one so that they are treated as one.
        // If this is a set of live or test product with duplicate names, thenn list them individually.
        const products = productCatalogItem.isLiveTestPair
          ? [productCatalogItem.products[0]]
          : productCatalogItem.products;
        products.forEach((product) => {
          const price = getDefaultPrice(product);
          data[product.id] = {
            id: product.id,
            name,
            price: formatMoney(
              (price?.unit_amount ?? 0) / 100,
              price?.currency ?? 'USD'
            ),
            price_unformatted: price?.unit_amount ?? 0,
            active: product.active,
            active_cb: <ActiveCheckbox active={product.active} />,
            subscription_interval: price?.recurring_interval ?? 'one-time',
            subscription_interval_display:
              recurringLabels[price?.recurring_interval ?? 'oneTime'],
            currency: (price?.currency ?? '').toUpperCase(),
            productCatalogItem
          };
        });
        return data;
      }, {} as Record<string, any>);
  }, [productCatalog, selectedId]);

  const columns = useMemo(() => {
    const cols = [
      { key: 'name', name: 'Name' },
      {
        key: 'price',
        name: 'Price',
        sortBy: 'price_unformatted',
        headerCellStyle: { width: '130px' }
      },
      {
        key: 'subscription_interval_display',
        name: 'Subscription Interval',
        headerCellStyle: { width: '240px' }
      },
      {
        key: 'currency',
        name: 'Ccy.',
        headerCellStyle: { width: '100px' }
      }
    ];
    if (showActiveColumn)
      cols.push({
        key: 'active_cb',
        name: 'Active',
        sortBy: 'active',
        headerCellStyle: { width: '100px' }
      });
    return cols;
  }, [showActiveColumn]);

  // Display states are: product selector or create/update product form
  const [displayState, setDisplayState] = useState<
    'selector' | 'create' | 'update'
  >('selector');

  // for updating and creating products
  const focusElement = useDialogFocus(show);
  const { formId } = useParams<{ formId: string }>();
  const { editIntegration } = useFeatheryRedux();
  const integrations = useIntegrations({
    types: [INTEGRATIONS.STRIPE],
    panelId: formId
  });
  const allTestProducts: Record<string, Product> =
    integrations[INTEGRATIONS.STRIPE]?.data.metadata.test
      ?.product_price_cache ?? {};
  const allLiveProducts: Record<string, Product> =
    integrations[INTEGRATIONS.STRIPE]?.data.metadata.live
      ?.product_price_cache ?? {};

  const NEW_PRICE_ID = 'new_price';
  const NEW_PRODUCT_ID = 'new_product';
  const newPrice = (
    type: 'one_time' | 'recurring',
    recurringInterval: '' | 'day' | 'week' | 'month' | 'year',
    unitAmount: number,
    currency: string
  ): Price => ({
    id: NEW_PRICE_ID,
    currency: currency,
    type: type,
    billing_scheme: 'per_unit',
    recurring_usage_type: 'licensed',
    recurring_interval: recurringInterval,
    unit_amount: unitAmount
  });
  const newProduct = (): Product => {
    const price = newPrice('one_time', '', 0, 'usd');
    return {
      id: NEW_PRODUCT_ID, // if we ever support adding multiple products at same time, need to change this
      name: '',
      description: '',
      active: true,
      default_price: NEW_PRICE_ID,
      prices: [price]
    };
  };

  const [changedProducts, setChangedProducts] = useState<Product[]>([]);
  // product change/create targeted for live, test or both
  const [targetMode, setTargetMode] = useState<'live' | 'test' | 'both'>(
    'both'
  );

  const [error, setError] = useState('');
  const submitProductChange = useCallback(async () => {
    // Separate product updates into live and test.
    // First put existing products into the correct bucket.
    const liveProducts: Record<string, Product> = changedProducts
      .filter((changedProduct) => allLiveProducts[changedProduct.id])
      .reduce((productsObj, changedProduct) => {
        productsObj[changedProduct.id] = changedProduct;
        return productsObj;
      }, {} as Record<string, Product>);
    const testProducts: Record<string, Product> = changedProducts
      .filter((changedProduct) => allTestProducts[changedProduct.id])
      .reduce((productsObj, changedProduct) => {
        productsObj[changedProduct.id] = changedProduct;
        return productsObj;
      }, {} as Record<string, Product>);

    // now add the new products to the correct bucket
    changedProducts
      .filter((changedProduct) => changedProduct.id.startsWith(NEW_PRODUCT_ID))
      .forEach((product) => {
        if (targetMode === 'live' || targetMode === 'both')
          liveProducts[product.id] = product;
        if (targetMode === 'test' || targetMode === 'both')
          testProducts[product.id] = product;
      });

    const payload = {
      type: 'stripe',
      active: integrations[INTEGRATIONS.STRIPE]?.data.active,
      panelId: formId,
      api_key: '',
      metadata: {
        ...integrations[INTEGRATIONS.STRIPE]?.data.metadata,
        live: { product_price_cache: liveProducts },
        test: { product_price_cache: testProducts }
      },
      secret_metadata: integrations[INTEGRATIONS.STRIPE]?.data.secret_metadata
    };
    await editIntegration(payload)
      .then(() => {
        setDisplayState('selector');
      })
      .catch((e: any) => {
        setError(`Product updates failed: ${e.message}`);
      });
  }, [
    changedProducts,
    targetMode,
    integrations,
    formId,
    allLiveProducts,
    allTestProducts,
    setDisplayState
  ]);

  const { lockOutFlag, lockoutFunction } =
    useModalSubmissionLockout(submitProductChange);

  const currencyOptions = useMemo(() => {
    // only one of each
    const currencyMap = currencyData.reduce((map, currency) => {
      if (!currency.code) return map;
      const code = currency.code.toLowerCase();
      map[code] = {
        value: code,
        display: `${currency.currencyName} - ${currency.code}`
      };
      return map;
    }, {} as Record<string, any>);
    const opts = Object.values(currencyMap).sort((a, b) =>
      a.display.localeCompare(b.display)
    );
    // put USD, EUR, GBP at the top because so common
    const usdIdx = opts.findIndex((opt) => opt.value === 'usd');
    const usd = opts.splice(usdIdx, 1);
    const eurIdx = opts.findIndex((opt) => opt.value === 'eur');
    const eur = opts.splice(eurIdx, 1);
    const gbpIdx = opts.findIndex((opt) => opt.value === 'gbp');
    const gbp = opts.splice(gbpIdx, 1);
    return [...usd, ...eur, ...gbp, ...opts];
  }, [currencyData]);

  const modeOptions: any[] = [];
  if (integrations[INTEGRATIONS.STRIPE]?.data.metadata.prod_client_key)
    modeOptions.push({ value: 'live', display: 'Live Only' });
  if (integrations[INTEGRATIONS.STRIPE]?.data.metadata.test_client_key)
    modeOptions.push({ value: 'test', display: 'Test Only' });
  if (modeOptions.length > 1)
    modeOptions.unshift({ value: 'both', display: 'Both' });

  /**
   * This form component is used to create a new Stripe product or update an existing
   * Stripe product(s).  If updating existing products, then it will be either
   * a single live or test product.  If it is updating more than one product, then
   * it will be a pair of matched live and test products with the same name.  In this case
   * they are sort of treated as one in that updates will be made to both.
   */
  const CreateOrUpdateProduct = ({
    changedProducts,
    setChangedProducts,
    targetMode,
    setTargetMode,
    lockOutFlag,
    lockoutFunction,
    saveButtonLabel
  }: any) => {
    // Any price change must always create a new default price obj in stripe
    const getNewPrice = (changedProduct: Product) => ({
      ...changedProduct.prices[0],
      id: NEW_PRICE_ID
    });

    let subscriptionInterval = 'oneTime';
    if (
      changedProducts.length &&
      changedProducts[0].prices[0].type !== 'one_time'
    )
      subscriptionInterval = changedProducts[0].prices[0].recurring_interval;

    // stripe needs lower case ISO 4217 codes
    const currency = changedProducts[0]?.prices[0].currency ?? '';

    return (
      <div className={styles.productEditContainer}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            return lockoutFunction();
          }}
          className={styles.productEditForm}
        >
          <Label>Name</Label>
          <TextField
            ref={focusElement}
            value={changedProducts[0]?.name ?? ''}
            placeholder='Enter a product name'
            required
            onChange={(name: string) => {
              // update the name on all changed products
              setChangedProducts(
                [...changedProducts].map((changedProduct) => ({
                  ...changedProduct,
                  name: name
                }))
              );
            }}
            className={styles.input}
          />
          <Label>Price</Label>
          <NumberInput
            placeholder='Enter a price for this product'
            required
            value={(changedProducts[0]?.prices[0].unit_amount ?? 0) / 100}
            onChange={(amt: any) => {
              const newChangedProducts = [...changedProducts].map(
                (changedProduct) => {
                  const price = getNewPrice(changedProduct);
                  price.unit_amount = (amt ?? 0) * 100;
                  return {
                    ...changedProduct,
                    default_price: NEW_PRICE_ID,
                    prices: [price]
                  };
                }
              );
              setChangedProducts(newChangedProducts);
            }}
            classNames={{ root: styles.input }}
          />
          <Label>Subscription Interval</Label>
          <DropdownField
            className={classNames('dialog-form-input', styles.input)}
            selected={subscriptionInterval}
            required
            onChange={(event: any) => {
              const newChangedProducts = [...changedProducts].map(
                (changedProduct) => {
                  const price = getNewPrice(changedProduct);
                  const subsInt = event.target.value;
                  price.type = subsInt === 'oneTime' ? 'one_time' : 'recurring';
                  price.recurring_interval =
                    subsInt === 'oneTime' ? '' : subsInt;
                  return {
                    ...changedProduct,
                    default_price: NEW_PRICE_ID,
                    prices: [price]
                  };
                }
              );
              setChangedProducts(newChangedProducts);
            }}
            options={Object.entries(recurringLabels).map(([key, value]) => ({
              value: key,
              display: value
            }))}
          />
          <Label>Live / Test</Label>
          <DropdownField
            className={classNames('dialog-form-input', styles.input)}
            required
            selected={targetMode}
            disabled={
              /* If existing product, can't change mode (live/test) */
              changedProducts.length > 0 &&
              changedProducts[0].id !== NEW_PRODUCT_ID
            }
            onChange={(event: any) => {
              setTargetMode(event.target.value);
            }}
            options={modeOptions}
          />
          <Label>Currency</Label>
          <DropdownField
            className={classNames('dialog-form-input', styles.input)}
            selected={currency}
            placeholder='Select a currency for the price'
            required
            onChange={(event: any) => {
              const newChangedProducts = [...changedProducts].map(
                (changedProduct) => {
                  const price = getNewPrice(changedProduct);
                  price.currency = event.target.value;
                  return {
                    ...changedProduct,
                    default_price: NEW_PRICE_ID,
                    prices: [price]
                  };
                }
              );
              setChangedProducts(newChangedProducts);
            }}
            options={[{ value: '', display: '' }, ...currencyOptions]}
          />

          {error && <div className={styles.error}>{error}</div>}

          <div
            className={classNames(
              'dialog-form-action text-center',
              styles.buttonContainer,
              styles.centered
            )}
          >
            <Neutral onClick={() => setDisplayState('selector')}>
              Cancel
            </Neutral>
            <Positive lockoutOverride={lockOutFlag}>{saveButtonLabel}</Positive>
          </div>
        </form>
      </div>
    );
  };

  const ProductSelector = ({
    setChangedProducts,
    selected,
    setSelected,
    setTargetMode
  }: any) => {
    return (
      <div className={styles.productSelectorContainer}>
        <Table
          name='Stripe Product'
          className={styles.productSelectorTable}
          data={Object.values(productCatalogData)}
          columns={columns}
          defaultSort={{ order: 1, key: 'name' }}
          showSelected
          initSelectId={selected}
          onBeforeSelect={
            (productData: any) =>
              productData.active /* only allowed to select active products*/
          }
          onSelect={(productData: any) => {
            const productId = productData.id !== selected ? productData.id : '';
            // Always can deselect
            if (!productId) setSelected(productId);
            // only allowed to select active products
            else if (productData.active) setSelected(productId);
          }}
          type='modal'
        />

        <div
          className={classNames(
            'dialog-form-action text-center',
            styles.buttonContainer
          )}
        >
          <div>
            <Neutral
              onClick={() => {
                setChangedProducts([newProduct()]);
                setTargetMode(modeOptions[0].value);
                setDisplayState('create');
              }}
            >
              Create Product
            </Neutral>
            {selected && productCatalogData[selected] && (
              <Neutral
                onClick={() => {
                  const productCatalogItem =
                    productCatalogData[selected].productCatalogItem;
                  // If live/test pair, modify both, otherwise just the selected product
                  const selectedProducts = (
                    productCatalogData[selected]?.productCatalogItem.products ??
                    []
                  ).filter(
                    (product: any) =>
                      productCatalogItem.isLiveTestPair ||
                      product.id === selected
                  );
                  setChangedProducts(selectedProducts);
                  if (selectedProducts.length) {
                    if (productCatalogItem.isLiveTestPair)
                      // If modifying a pair of products, then set the target mode to both
                      // Otherwise set it to the first products mode
                      setTargetMode('both');
                    else if (allTestProducts[selectedProducts[0].id])
                      setTargetMode('test');
                    else setTargetMode('live');
                  }
                  setDisplayState('update');
                }}
              >
                Edit
              </Neutral>
            )}
          </div>
          <Positive
            onClick={async () => {
              onSelect(selected);
              setShow(false);
            }}
          >
            Select
          </Positive>
        </div>
      </div>
    );
  };

  // either showing the product selector or create/update product form
  const [title, Content, dialogWidth, saveButton] = useMemo(() => {
    if (displayState === 'create')
      return [
        'Create New Stripe Product',
        CreateOrUpdateProduct,
        'sm',
        'Create'
      ];
    if (displayState === 'update')
      return ['Edit Stripe Product', CreateOrUpdateProduct, 'sm', 'Save'];
    return ['Select Stripe Product', ProductSelector, 'xlg', ''];
  }, [displayState]);

  return (
    <Dialog
      isOpen={show}
      onClose={() => {
        setShow(false);
        setSelected(selectedId); // reset to the original selected id on cancel
        setDisplayState('selector');
      }}
      title={title}
      size={dialogWidth}
    >
      <Content
        changedProducts={changedProducts}
        setChangedProducts={setChangedProducts}
        selected={selected}
        setSelected={setSelected}
        targetMode={targetMode}
        setTargetMode={setTargetMode}
        lockOutFlag={lockOutFlag}
        lockoutFunction={lockoutFunction}
        saveButtonLabel={saveButton}
      />
    </Dialog>
  );
};

export default memo(ProductSelectorModal);
