import { memo } from 'react';

import { CloseIcon } from '../../Icons';
import { Positive, Negative } from '../../Core/Button';
import styles from './styles.module.scss';
import classNames from 'classnames';

const ConfirmationOverlay = ({
  onConfirm,
  show,
  hideIt,
  cancelLabel = 'Cancel',
  confirmLabel = 'Yes',
  message = 'If you close the dialog without saving first, you will lose your changes.'
}: {
  onConfirm: () => void;
  show: boolean;
  hideIt: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  message?: string;
}) => {
  return (
    <>
      {show && (
        <div className={styles.closeConfirm}>
          <div
            className={classNames(styles.closeCancel)}
            tabIndex={0}
            onClick={() => {
              hideIt();
            }}
          >
            <CloseIcon height={16} width={16} />
          </div>
          <div className={styles.body}>
            <div className={styles.title}>Are you sure?</div>
            <div className={styles.message}>{message}</div>
            <div className={styles.buttons}>
              <Negative className={styles.noButton} onClick={hideIt}>
                {cancelLabel}
              </Negative>
              <Positive
                onClick={async () => {
                  hideIt();
                  onConfirm();
                }}
              >
                {confirmLabel}
              </Positive>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(ConfirmationOverlay);
