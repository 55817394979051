function RedoIcon({ className = '' }: any) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      className={className}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3747 9.27527H13.4023C8.19488 9.27527 4 13.432 4 18.5922C4 23.7523 8.19488 27.9091 13.4023 27.9091H28'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.6364 13.3551L21.8523 9.17754L17.6364 4.99994'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default RedoIcon;
