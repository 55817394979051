import { PageProps } from './types';
import styles from './styles.module.scss';

import {
  API_CONNECTOR_TEMPLATE_PAGE,
  CONFIGURE_REQUEST_PAGE,
  SELECT_API_SERVICE_PAGE
} from '../constants';
import { useState } from 'react';
import { NavBar } from '../components/NavBar';
import { useAppSelector } from '../../../../hooks';
import { useParams } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { APPS, TEMPLATES } from '../templates';
import { DropdownField } from '../../../Core';

export const APIConnectorTemplatePage = (props: PageProps) => {
  const { setState, options, setOptions, goto = () => {} } = props;
  const { formId } = useParams<{ formId: string }>();
  const [selectedApp, setSelectedApp] = useState<keyof typeof APPS | ''>('');
  const [selectedAction, setSelectedAction] = useState<string>('');
  const enabledIntegrations = useAppSelector((state) => {
    return Object.entries(state.integrations.integrations[formId] ?? {})
      .filter(([_, value]: any) => value?.data?.active)
      .map(([key, _]) => key);
  });
  const handleSubmit = () => {
    if (selectedApp === 'custom') {
      setState(APPS.custom.actions[0].defaultState);
      goto(CONFIGURE_REQUEST_PAGE);
    } else if (selectedAction && selectedAction in TEMPLATES) {
      setState(
        TEMPLATES[selectedAction as keyof typeof TEMPLATES].defaultState
      );
      goto(CONFIGURE_REQUEST_PAGE);
    } else {
      console.error('Invalid action');
    }
  };

  const buttons = Object.keys(APPS).sort((idA, idB) => {
    const aDisabled = idA !== 'custom' && !enabledIntegrations.includes(idA);
    const bDisabled = idB !== 'custom' && !enabledIntegrations.includes(idB);
    if (aDisabled === bDisabled) {
      return 0;
    }
    if (aDisabled && !bDisabled) {
      return 1;
    }
    return -1;
  });

  const allDisabled = !enabledIntegrations.includes(buttons[1]);

  if (allDisabled) {
    setState(APPS.custom.actions[0].defaultState);
    if (options?.startPage === 1) {
      setOptions({ startPage: 2 });
    }
    goto(CONFIGURE_REQUEST_PAGE);
  }

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <div className='tw-grid lg:tw-grid-cols-2 tw-gap-4'>
          {buttons.map((id) => (
            <TemplateButton
              key={id}
              app={id}
              onClick={() => {
                if (selectedApp !== id) {
                  setSelectedApp(id as any);
                  setSelectedAction(
                    APPS[id as keyof typeof APPS].actions[0].id
                  );
                }
              }}
              selected={selectedApp === id}
              disabled={id !== 'custom' && !enabledIntegrations.includes(id)}
            />
          ))}
        </div>
        {selectedApp && selectedApp !== 'custom' && (
          <div className='tw-mt-8'>
            <div className={styles.label}>Select Action</div>
            <DropdownField
              placeholder='Select Action'
              selected={selectedAction}
              options={APPS[selectedApp].actions.map((action) => ({
                display: action.display,
                value: action.id
              }))}
              onChange={(event: any) => setSelectedAction(event.target.value)}
            />
          </div>
        )}
      </div>
      <NavBar
        next
        back={
          options?.startPage != null &&
          options.startPage < API_CONNECTOR_TEMPLATE_PAGE
        }
        disabled={{
          next: !selectedAction
        }}
        onClick={(btn: string) => {
          if (btn === 'next') handleSubmit();
          if (btn === 'back') goto(SELECT_API_SERVICE_PAGE);
        }}
      />
    </div>
  );
};

function TemplateButton({ disabled, app, onClick, selected }: any) {
  const appConfig = APPS[app as keyof typeof APPS];
  if (!appConfig) return <></>;
  const appDisplay = app.charAt(0).toUpperCase() + app.slice(1);

  if (disabled) {
    return (
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip
            style={{ zIndex: 99999999 }}
          >{`Set up a ${appDisplay} integration to use the ${appDisplay} API.`}</Tooltip>
        }
      >
        <button
          disabled={disabled}
          onClick={onClick}
          className='tw-h-24 tw-block tw-p-6 tw-bg-white !tw-border !tw-border-solid !tw-border-gray-200 tw-rounded-lg enabled:hover:tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-gap-6 tw-cursor-pointer data-[selected]:!tw-border-primary data-[selected]:!tw-border-2 data-[selected]:!tw-bg-primary-light/20 disabled:tw-bg-gray-100/50 disabled:tw-cursor-auto disabled:tw-text-gray-600 disabled:tw-opacity-70'
          data-selected={selected || undefined}
        >
          {appConfig.logo}
          {appConfig.header}
        </button>
      </OverlayTrigger>
    );
  }

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className='tw-h-24 tw-block tw-p-6 tw-bg-white !tw-border !tw-border-solid !tw-border-gray-200 tw-rounded-lg enabled:hover:tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-gap-6 tw-cursor-pointer data-[selected]:!tw-border-primary data-[selected]:!tw-border-2 data-[selected]:!tw-bg-primary-light/20 disabled:tw-bg-gray-100/50 disabled:tw-cursor-auto disabled:tw-text-gray-600 disabled:tw-opacity-70'
      data-selected={selected || undefined}
    >
      {appConfig.logo}
      {appConfig.header}
    </button>
  );
}
