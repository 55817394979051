import { memo, useCallback, useState } from 'react';
import { DropdownMultiField, TextField } from '../../../components/Core';
import Dialog from '../../../components/Dialog';
import { Positive } from '../../../components/Core/Button';
import useFeatheryRedux from '../../../redux';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import { useAppSelector } from '../../../hooks';
import styles from '../../../components/Modals/UserCollaborationModal/styles.module.scss';
import { TrashIcon } from '../../../components/Icons';
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { deepEquals } from '../../../utils/core';

const defaultGroup = () => ({
  id: uuidv4(),
  name: '',
  field_ids: [],
  panel_ids: []
});

function AllUserGroupsModal({ show, close }: any) {
  const {
    editOrganization,
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  const panels = useAppSelector((state) => state.panels.panels);
  const allGroups = useAppSelector(
    (state) => state.accounts.organization?.all_user_groups ?? []
  );
  const servars = useAppSelector(
    (state) => state.fields.servars ?? [],
    deepEquals
  );
  const hfs = useAppSelector(
    (state) => state.fields.hiddenFields ?? [],
    deepEquals
  );
  const [error, setError] = useState('');
  const [curGroups, setCurGroups] = useState<Record<string, any>[]>(allGroups);

  const addGroup = () => setCurGroups([...curGroups, defaultGroup()]);
  const updateGroup = (index: number, key: string, val: any) => {
    const newGroups = produce(curGroups, (draft) => {
      draft[index][key] = val;
    });
    setCurGroups(newGroups);
  };
  const removeGroup = (index: number) => {
    const newGroups = produce(curGroups, (draft) => {
      draft.splice(index, 1);
    });
    setCurGroups(newGroups);
  };

  const submit = useCallback(() => {
    let err = '';
    curGroups.forEach((group) => {
      if (!group.name) err = 'All groups must have names';
    });

    setError(err);
    if (err) return;

    editOrganization({ all_user_groups: curGroups }).then(() => {
      addInfoToast('User groups updated');
      close();
    });
  }, [curGroups]);

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  return (
    <Dialog
      isOpen={show}
      title='Edit Available User Groups'
      size='lg'
      onClose={close}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        {curGroups.map((group, index) => (
          <div key={group.id} className={styles.collaboratorSection}>
            <div className={styles.delete} onClick={() => removeGroup(index)}>
              <TrashIcon width={16} height={16} />
            </div>
            <div className={styles.controlsSection}>
              <div className={styles.controlLabel}>Group Name</div>
              <TextField
                className={styles.controlField}
                onChange={(value: any) => updateGroup(index, 'name', value)}
                value={group.name}
              />
            </div>
            <div className={styles.controlsSection}>
              <div className={styles.controlLabel}>Available Forms</div>
              <DropdownMultiField
                className={styles.controlField}
                options={Object.entries(panels).map(([panelId, panel]) => ({
                  label: panel.key,
                  value: panelId
                }))}
                selected={group.panel_ids}
                placeholder='Select Forms'
                onChange={(items: { value: string }[]) => {
                  updateGroup(
                    index,
                    'panel_ids',
                    items.map((item) => item.value)
                  );
                }}
              />
            </div>
            <div className={styles.controlsSection}>
              <div className={styles.controlLabel}>Hide Fields</div>
              <DropdownMultiField
                className={styles.controlField}
                options={[...servars, ...hfs].map((field) => ({
                  label: field.key,
                  value: field.id
                }))}
                selected={group.field_ids}
                placeholder='Select Fields'
                onChange={(items: { value: string }[]) => {
                  updateGroup(
                    index,
                    'field_ids',
                    items.map((item) => item.value)
                  );
                }}
              />
            </div>
          </div>
        ))}
        <div
          onClick={() => addGroup()}
          className={styles.addCollaboratorButton}
        >
          Add User Group
        </div>
        <div className='dialog-form-action text-center'>
          <Positive lockoutOverride={lockOutFlag}>Save</Positive>
        </div>
        {error}
      </form>
    </Dialog>
  );
}

export default memo(AllUserGroupsModal);
