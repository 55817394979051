import { useMemo } from 'react';
import { useAppSelector } from '../../hooks';
import { INTEGRATIONS } from './types';

export default function useIntegrations({
  panelId = '',
  types = [],
  type = '',
  all = false,
  includeInactive = false,
  extractionId = '',
  entityType = 'form'
}: any) {
  const integrations = useAppSelector((state) => {
    return entityType === 'ai'
      ? state.integrations.aiIntegrations[extractionId]
      : state.integrations.integrations[panelId];
  });

  return useMemo(() => {
    const filteredIntegrations = Object.entries(integrations ?? {})
      .filter(
        ([integType, integ]) =>
          (includeInactive || (integ as any).data.active) &&
          (all || integType === type || types.includes(integType))
      )
      .reduce((allIntegs, [integType, integ]) => {
        allIntegs[integType] = integ;
        return allIntegs;
      }, {} as Record<string, any>);
    if (type) return filteredIntegrations[type];
    else if (types.length > 0 || all) return filteredIntegrations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations]);
}

export function useActiveAuthIntegration(formId: string) {
  const integs = useIntegrations({
    types: [INTEGRATIONS.FIREBASE, INTEGRATIONS.STYTCH],
    panelId: formId
  });
  return integs[INTEGRATIONS.STYTCH] || integs[INTEGRATIONS.FIREBASE];
}
