import { useHistory, useParams } from 'react-router-dom';
import LogicPageComponent from './LogicPageComponent';
import FormLogicRulePage from '../FormLogicRulePage';
import APIConnectorPage from '../APIConnectorPage';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '../../components/Core/Tabs';

export default function FormLogicPage() {
  const { formId, ruleId, tab } =
    useParams<{ ruleId: string; formId: string; tab: string }>();
  const history = useHistory();

  const isConnectorsTab = ruleId === 'connectors' || tab === 'connectors';

  const setTab = (eventKey: string | null) => {
    if (eventKey === 'connectors') {
      if (ruleId) {
        history.push(`/forms/${formId}/logic/${ruleId}/connectors`);
      } else {
        history.push(`/forms/${formId}/logic/connectors`);
      }
    } else {
      if (ruleId && ruleId !== 'connectors') {
        history.push(`/forms/${formId}/logic/${ruleId}`);
      } else {
        history.push(`/forms/${formId}/logic`);
      }
    }
  };

  return (
    <div
      style={{
        overflowY: 'scroll',
        height: 'calc(100% - 55px)',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Tabs
        value={isConnectorsTab ? 'connectors' : 'rules'}
        onValueChange={setTab}
        className='tw-relative tw-flex-1 tw-flex tw-flex-col tw-p-[16px]'
      >
        <TabsList unstyled className='legacyRulesTabsList'>
          <TabsTrigger
            value='rules'
            unstyled
            className='legacyRulesTabsTrigger'
          >
            Rules
          </TabsTrigger>
          <TabsTrigger
            value='connectors'
            unstyled
            className='legacyRulesTabsTrigger'
          >
            API Connectors
          </TabsTrigger>
        </TabsList>
        <TabsContent
          value='rules'
          unstyled
          className='legacyRulesTabsContent tw-flex tw-flex-col'
        >
          {ruleId ? <FormLogicRulePage /> : <LogicPageComponent />}
        </TabsContent>
        <TabsContent
          value='connectors'
          unstyled
          className='legacyRulesTabsContent'
        >
          <APIConnectorPage formId={formId} />
        </TabsContent>
      </Tabs>
    </div>
  );
}
