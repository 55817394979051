import type { ComponentProps } from 'react';
import { RedoIcon } from '../../Icons';

function Redo({
  disabled = false,
  ...rest
}: Omit<ComponentProps<'button'>, 'children' | 'className'>) {
  return (
    <button {...rest} disabled={disabled} className='redo-button'>
      <RedoIcon className={disabled ? '' : 'undo-redo-enabled'} />
    </button>
  );
}

export default Redo;
