// inverts keys and values of a Record<string, string>
function invert(data: Record<string, string>): Record<string, string> {
  const result: Record<string, string> = {};
  Object.keys(data).forEach((oldKey: string) => {
    result[data[oldKey]] = oldKey;
  });
  return result;
}

export function transformFieldMap(
  fieldMap: Record<string, string>,
  formServars: string[]
): Record<string, string> {
  // Remove any field maps that aren't on the current form anymore
  const filteredMapFieldData = Object.keys(fieldMap)
    .filter((key) => formServars.includes(key))
    .reduce((obj, key) => {
      obj[key] = fieldMap[key];
      return obj;
    }, {} as Record<string, any>);

  // invert field_map for BE
  return invert(filteredMapFieldData);
}

export function groupKeysById<T extends { id: string; key: string }>(
  acc: Record<string, string>,
  item: T
) {
  acc[item.id] = item.key;
  return acc;
}

export function groupByForm(
  acc: Record<string, string[]>,
  current: [string, { panel_id: string }[]]
) {
  const [servarId, forms] = current;

  forms.forEach((form) => {
    const panelId = form.panel_id;
    if (!acc[panelId]) {
      acc[panelId] = [];
    }
    acc[panelId].push(servarId);
  });

  return acc;
}

function hasProperty<T extends string>(
  obj: unknown,
  key: T
): obj is Record<T, unknown> {
  return typeof obj === 'object' && obj !== null && key in obj;
}

export function handleErrorMessage(error: unknown) {
  if (hasProperty(error, 'message')) {
    const errorMessage = error.message || '';
    if (
      typeof errorMessage === 'string' &&
      errorMessage.startsWith("Form can't be")
    ) {
      return errorMessage;
    }
  }

  return 'Could not promote form. Please try again.';
}
