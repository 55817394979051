import '../../style/dialog-form.css';

import { Fragment, useState } from 'react';
import IntegrationsSidebar from './IntegrationsSidebar';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { Form } from 'react-bootstrap';
import {
  DropdownField,
  InlineTooltip,
  PropertyLabel,
  TextField,
  YesNoSwitch
} from '../Core';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FieldSelectorWithModal } from '../Modals';
import { PlusIcon, TrashIcon } from '../Icons';
import useIntegrations from './useIntegrations';
import useReceiveOauthMessage from './shared/useReceiveOauthMessage';
import { useParams } from 'react-router-dom';

const oauthRedirect =
  'https://app.hubspot.com/oauth/authorize?client_id=de356149-cd14-4343-9bf4-4e970191df0e&redirect_uri=https://app.feathery.io/oauth-redirect/&scope=crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.schemas.contacts.write';

const HUBSPOT_FIELDS = [
  { label: 'Email', val: 'email' },
  { label: 'Phone', val: 'phone' },
  { label: 'First Name', val: 'firstname' },
  { label: 'Last Name', val: 'lastname' },
  { label: 'Company', val: 'company' },
  { label: 'Website', val: 'website' }
];

const HUBSPOT_FIELD_PROPS = HUBSPOT_FIELDS.map(({ val }) => val);

const DEFAULT_CUSTOM_PROPERTIES = [
  { property: '', field: { id: '', type: '' } }
];
const DEFAULT_UNIQUE_PROPERTY = { type: '', value: '' };

function HubspotSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.HUBSPOT_LEGACY,
    panelId: formId,
    includeInactive: true
  });

  // Local/draft integration settings
  const [error, setError] = useState('');
  const [isPartial, setIsPartial] = useState(false);
  const meta = integration?.data.secret_metadata ?? {};
  const [defaultProperties, setDefaultProperties] = useState(
    meta.default_properties ?? {}
  );
  const cp = meta.custom_properties ?? [];
  const [customProps, setCustomProps] = useState(
    cp.length === 0 ? DEFAULT_CUSTOM_PROPERTIES : cp
  );
  const [uniqueProp, setUniqueProp] = useState(
    meta.unique_property ?? DEFAULT_UNIQUE_PROPERTY
  );
  const [processPropertyChanges, setProcessPropertyChanges] = useState(
    meta.process_property_changes ?? false
  );
  const [trigger, setTrigger] = useState(meta.trigger ?? 'data_received');
  const oauthToken: string | undefined =
    integration?.data.secret_metadata.oauth_token;

  function onSubmitCustom(newIsActive: boolean) {
    setError('');
    // Filter out completely empty custom property rows
    const filtered = customProps.filter(
      (cf: any) => cf.property || cf.field.id
    );

    if (newIsActive) {
      let partial = false;
      filtered.forEach(({ property, field }: any) => {
        if (!property || !field.id) partial = true;
      });
      setIsPartial(partial);
      if (partial) {
        setError('Custom properties must have both name and field specified');
        return;
      }
    }

    const customPropKeys = filtered.map(({ property }: any) => property);
    const allProperties = [
      ...customPropKeys,
      ...HUBSPOT_FIELDS.map(({ val }) => val)
    ];

    if (!oauthToken) {
      setError('You must authorize your Hubspot account');
    } else if (allProperties.length === 0) {
      setError('You must specify at least one Hubspot property');
    } else if (allProperties.length !== new Set(allProperties).size) {
      setError('Each custom property must be unique');
    } else if (
      !uniqueProp.type ||
      (uniqueProp.type === 'default' && !defaultProperties[uniqueProp.value])
    ) {
      setError(
        'The property to identify a unique contact record must be specified'
      );
    } else {
      const secretMetadata = {
        unique_property: uniqueProp,
        default_properties: defaultProperties,
        custom_properties: filtered,
        process_property_changes: processPropertyChanges,
        trigger
      };

      return {
        isUpdate: integration?.data,
        apiKey: '',
        secretMetadata
      };
    }
  }

  const authorizeHubspot = useReceiveOauthMessage(
    INTEGRATIONS.HUBSPOT_LEGACY,
    oauthRedirect,
    !!oauthToken
  );

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.HUBSPOT_LEGACY]}
      authorizeData={{
        authorize: authorizeHubspot,
        oauthToken
      }}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={error}
    >
      <Form>
        <div className={styles.twoColumnContainer}>
          <div className={styles.fieldHeaderText}>Hubspot Property</div>
          <div className={styles.fieldHeaderText}>Field</div>
          {HUBSPOT_FIELDS.map(({ label, val }) => (
            <Fragment key={val}>
              <PropertyLabel label={label} />
              <FieldSelectorWithModal
                selectId={defaultProperties[val]?.id}
                selectType={defaultProperties[val]?.type}
                placeholder='Select'
                onSelect={(data) => {
                  setDefaultProperties({
                    ...defaultProperties,
                    [val]: { id: data.selectId, type: data.selectType }
                  });
                  if (
                    !data.selectId &&
                    uniqueProp.type === 'default' &&
                    uniqueProp.value === val
                  ) {
                    setUniqueProp(DEFAULT_UNIQUE_PROPERTY);
                  }
                }}
                className={styles.marginBottom}
              />
            </Fragment>
          ))}
          {customProps.map(({ property, field }: any, index: any) => (
            <Fragment key={index}>
              <TextField
                placeholder='Hubspot Internal Name'
                className='dialog-form-input'
                value={property}
                onComplete={(newProp: any) => {
                  const newFields = [...customProps];
                  newFields[index] = { property: newProp, field };
                  setCustomProps(newFields);
                }}
                error={isPartial && !property}
              />
              <div className={styles.fieldSelectorContainer}>
                <FieldSelectorWithModal
                  selectId={field.id}
                  selectType={field.type}
                  placeholder='Select'
                  onSelect={(data) => {
                    const newFields = [...customProps];
                    newFields[index] = {
                      property,
                      field: { id: data.selectId, type: data.selectType }
                    };
                    setCustomProps(newFields);
                  }}
                  error={isPartial && !field.id}
                  className={classNames(
                    styles.marginBottom,
                    styles.fieldSelector
                  )}
                />
                <TrashIcon
                  height={16}
                  width={16}
                  className={classNames('tr-icon', styles.customPropertyDelete)}
                  onClick={() => {
                    const newFields = [...customProps];
                    newFields.splice(index, 1);
                    setCustomProps(newFields);
                    if (
                      uniqueProp.type === 'custom' &&
                      uniqueProp.value === index.toString()
                    ) {
                      setUniqueProp(DEFAULT_UNIQUE_PROPERTY);
                    }
                  }}
                />
              </div>
            </Fragment>
          ))}
        </div>
        <PlusIcon
          className={styles.customPropertyAdd}
          onClick={() => {
            const newFields = [...customProps];
            newFields.push({ property: '', field: { id: '', type: '' } });
            setCustomProps(newFields);
          }}
        />
        <div className={styles.fieldHeaderText}>
          Lookup Property{' '}
          <InlineTooltip text='Used to look up and update an existing Hubspot record' />
        </div>
        <DropdownField
          selected={uniqueProp.value}
          onChange={(e: any) => {
            const value = e.target.value;
            const type = HUBSPOT_FIELD_PROPS.includes(value)
              ? 'default'
              : 'custom';
            setUniqueProp({ type, value });
          }}
          options={[
            ...Object.entries(defaultProperties)
              .filter(([, field]) => (field as any).id)
              .map(([prop]) => ({ value: prop, display: prop })),
            ...customProps
              .filter(({ property, field }: any) => property && field.id)
              // @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
              .map(({ property }: any, index) => ({
                value: index.toString(),
                display: property
              }))
          ]}
        />
        <div className={styles.fieldHeaderText}>Update Hubspot Record When</div>
        <DropdownField
          selected={trigger}
          onChange={(event: any) => setTrigger(event.target.value)}
          options={[
            { value: 'form_completion', display: 'the form is completed' },
            {
              value: 'data_received',
              display: 'any user data is received (e.g. step submission)'
            }
          ]}
        />
        <div className={styles.fieldHeaderText}>
          Sync Hubspot Changes to Feathery
        </div>
        <YesNoSwitch
          id='hubspot-data-update'
          checked={processPropertyChanges}
          onCheckedChange={setProcessPropertyChanges}
        />
      </Form>
    </IntegrationsSidebar>
  );
}

export default HubspotSettingsSidebar;
