function Mailgun({ width = 72, height = 72 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 256 256'
      preserveAspectRatio='xMidYMid'
    >
      <path
        d='M127.998 105.848c12.238 0 22.157 9.917 22.157 22.151 0 12.237-9.919 22.156-22.157 22.156-12.234 0-22.153-9.922-22.153-22.156 0-12.234 9.919-22.151 22.153-22.151zm-75.485 22.151c0-41.69 33.796-75.485 75.485-75.485 41.695 0 75.488 33.795 75.488 75.485 0 2.76-.147 5.482-.436 8.16-.573 7.359 4.827 12.976 12.139 12.976 12.409 0 13.731-16 13.731-21.136 0-55.74-45.183-100.922-100.922-100.922S27.077 72.26 27.077 127.999c0 55.736 45.182 100.92 100.92 100.92 29.602 0 56.227-12.737 74.689-33.035l20.747 17.422c-23.435 26.197-57.51 42.698-95.435 42.698C57.306 256.004 0 198.692 0 127.999S57.306 0 127.998 0C198.694 0 256 57.306 256 128c0 28.415-13.53 51.49-40.706 51.49-11.96 0-19.23-5.48-23.2-11.596-13.33 21.368-37.048 35.596-64.096 35.596-41.69 0-75.485-33.801-75.485-75.49zm75.485-48.41c-26.734 0-48.41 21.673-48.41 48.41s21.676 48.412 48.41 48.412c26.736 0 48.413-21.675 48.413-48.412s-21.677-48.41-48.413-48.41z'
        fill='#AF252A'
      />
    </svg>
  );
}

export default Mailgun;
