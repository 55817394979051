import type { ComponentProps } from 'react';
import { BackArrowIcon } from '../../Icons';
import classNames from 'classnames';

function Back({
  className,
  ...rest
}: Omit<ComponentProps<'button'>, 'children'>) {
  return (
    <button {...rest} className={classNames('back-button', className)}>
      <BackArrowIcon />
    </button>
  );
}

export default Back;
