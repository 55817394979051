import { Salesforce, Orion, Redtail } from '../../../Logos';
import { CUSTOM_TEMPLATE } from './custom';
import {
  SALESFORCE_CREATE,
  SALESFORCE_PREFILL,
  SALESFORCE_UPDATE
} from './salesforce';
import { ORION_CREATE, ORION_GET_NET_WORTH } from './orion';
import type { Template, App } from './types';
import { REDTAIL_GET_CONTACT } from './redtail';

export const TEMPLATES: Record<string, Template> = {
  salesforce_prefill: SALESFORCE_PREFILL,
  salesforce_create: SALESFORCE_CREATE,
  salesforce_update: SALESFORCE_UPDATE,
  orion_create: ORION_CREATE,
  orion_get_net_worth: ORION_GET_NET_WORTH,
  redtail_get_contact: REDTAIL_GET_CONTACT
};

export const APPS: Record<string, App> = {
  custom: {
    header: 'Custom Connector',
    logo: null,
    actions: [CUSTOM_TEMPLATE]
  },
  salesforce: {
    header: 'Salesforce',
    logo: <Salesforce />,
    actions: [SALESFORCE_PREFILL, SALESFORCE_CREATE, SALESFORCE_UPDATE]
  },
  orion: {
    header: 'Orion',
    logo: <Orion width={45} height={45} />,
    actions: [ORION_CREATE, ORION_GET_NET_WORTH]
  },
  redtail: {
    header: 'Redtail',
    logo: <Redtail width={45} height={45} />,
    actions: [REDTAIL_GET_CONTACT]
  }
};
