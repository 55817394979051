import { memo, useCallback, useState } from 'react';

import Dialog from '../../Dialog';
import { Positive } from '../../Core/Button';
import {
  CheckboxField,
  DropdownField,
  InlineTooltip,
  TextField
} from '../../Core';
import styles from './styles.module.scss';
import modalStyles from '../styles.module.scss';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import { useParams } from 'react-router-dom';
import useFeatheryRedux from '../../../redux';
import classNames from 'classnames';
import { useAppSelector } from '../../../hooks';
import { deepEquals } from '../../../utils/core';

function DocumentTriggerModal({ show, close }: any) {
  const { documentId } = useParams<{ documentId: string }>();
  const doc = useAppSelector((state) => state.documents.documents[documentId]);
  const isPDF = doc?.type === 'pdf';
  const isDocx = doc?.type === 'docx';
  const servars = useAppSelector(
    (state) => state.fields.servars ?? [],
    deepEquals
  );
  const hfs = useAppSelector(
    (state) => state.fields.hiddenFields ?? [],
    deepEquals
  );
  const panels = useAppSelector((state) => state.panels.panels);

  const [trigger, setTrigger] = useState(doc.trigger);
  const [triggerSigner, setTriggerSigner] = useState(doc.trigger_signer);
  const [routeForSignature, setRouteForSignature] = useState(
    !!doc.trigger_signer
  );
  const [triggerMeta, setTriggerMeta] = useState(doc.trigger_meta);

  const {
    editDocument,
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  const submit = useCallback(async () => {
    try {
      await editDocument({
        documentId,
        trigger,
        trigger_signer: routeForSignature ? triggerSigner : '',
        trigger_meta: triggerMeta
      });
    } catch (e: any) {
      addErrorToast({ title: e.message });
      return;
    }

    addInfoToast('Document fill trigger updated');
    close();
  }, [trigger, triggerSigner, triggerMeta, routeForSignature]);

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  return (
    <Dialog isOpen={show} title='Set Fill Trigger' size='sm' onClose={close}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <div className={modalStyles.inviteTeamEmailRow}>
          <div className={modalStyles.inviteLabelWide}>
            Fill Trigger{' '}
            <InlineTooltip text="When event is triggered, save a document for the associated form submission that's autofilled with submission data" />
          </div>
          <DropdownField
            className={classNames('dialog-form-input', styles.marginBottom)}
            selected={trigger}
            onChange={(event: any) => {
              setTrigger(event.target.value);
              setTriggerMeta({});
            }}
            options={[
              { value: 'none', display: 'No Trigger' },
              { value: 'form_completion', display: 'Form Completion' },
              { value: 'field_value_change', display: 'Changed Field Value' }
            ]}
          />
        </div>
        {trigger !== 'none' && (
          <>
            {trigger === 'form_completion' && (
              <div className={modalStyles.inviteTeamEmailRow}>
                <div className={modalStyles.inviteLabelWide}>Form</div>
                <DropdownField
                  onChange={(event: any) =>
                    setTriggerMeta({
                      ...triggerMeta,
                      panel_id: event.target.value
                    })
                  }
                  selected={triggerMeta.panel_id ?? ''}
                  options={Object.values(panels).map((panel) => ({
                    display: panel.key,
                    value: panel.id
                  }))}
                  required
                />
              </div>
            )}
            {trigger === 'field_value_change' && (
              <>
                <div className={modalStyles.inviteTeamEmailRow}>
                  <div className={modalStyles.inviteLabelWide}>Field</div>
                  <DropdownField
                    onChange={(event: any) =>
                      setTriggerMeta({
                        ...triggerMeta,
                        changed_field_id: event.target.value
                      })
                    }
                    selected={triggerMeta.changed_field_id ?? ''}
                    options={[...servars, ...hfs].map((field) => ({
                      display: field.key,
                      value: field.id
                    }))}
                    required
                  />
                </div>
                <div className={modalStyles.inviteTeamEmailRow}>
                  <div className={modalStyles.inviteLabelWide}>Value</div>
                  <TextField
                    value={triggerMeta.changed_field_value ?? ''}
                    onChange={(newVal: string) =>
                      setTriggerMeta({
                        ...triggerMeta,
                        changed_field_value: newVal
                      })
                    }
                    required
                  />
                </div>
              </>
            )}
            {(isPDF || isDocx) && (
              <>
                <CheckboxField
                  checked={routeForSignature}
                  text='Route document for signature after autofill'
                  onChange={(isChecked) => setRouteForSignature(isChecked)}
                />
                {routeForSignature && (
                  <>
                    <div className={modalStyles.inviteTeamEmailRow}>
                      <div className={modalStyles.inviteLabelWide}>
                        Signer email{' '}
                        <InlineTooltip text='A literal email or the ID of a field containing an email' />
                      </div>
                      <TextField
                        placeholder='user@email.com or {{email_field}}'
                        value={triggerSigner}
                        onChange={setTriggerSigner}
                        required
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        <div className='dialog-form-action text-center'>
          <Positive lockoutOverride={lockOutFlag}>Save</Positive>
        </div>
      </form>
    </Dialog>
  );
}

export default memo(DocumentTriggerModal);
