import { IAPIActionState } from '../../pages/types';
import type { Template } from '../types';

export const ORION_GET_NET_WORTH: Template = {
  id: 'orion_get_net_worth',
  display: 'Get Client Net Worth',
  configuration: {
    name: { type: 'textfield', label: 'Connector Name' },
    clientId: { type: 'textfield', label: 'Client ID' }
  },
  defaultState: {
    name: 'Orion Get Client Net Worth',
    template: 'orion_get_net_worth',
    url: 'https://api.orionadvisor.com/api/v1/Integrations/Advizr/NetWorth',
    method: 'GET',
    headers: {
      Authorization: 'Session {{orion_token}}'
    },
    body: '',
    params: {},
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  },
  loadConfig: (state) => {
    return {
      name: state.name,
      clientId: state.url.endsWith('NetWorth') ? '' : state.url.split('/').pop()
    };
  },
  saveConfig: (state, configValues) => {
    const { clientId, name } = configValues;
    const newValues: Partial<IAPIActionState> = {
      name: name,
      url: `https://api.orionadvisor.com/api/v1/Integrations/Advizr/NetWorth/${clientId}`
    };
    return {
      ...state,
      ...newValues
    };
  }
};
