import { Col, Row } from 'react-bootstrap';
import {
  CheckboxField,
  CollapsibleSection,
  InlineTooltip,
  PropertyLabel,
  TextField
} from '../../../../Core';
import { useAppSelector } from '../../../../../hooks';
import { onFreePlan } from '../../../../PricingPlans/plans';
import useIntegrations from '../../../../FormIntegrations/useIntegrations';
import { INTEGRATIONS } from '../../../../FormIntegrations';
import { useParams } from 'react-router-dom';
import StepSelector from '../../../../Modals/StepSelector';
import { useRepeatedContainerChecks } from '../../../../../hooks/useRepeatedContainerChecks';
import { useState } from 'react';

const ContainerPropertiesSection = ({ cellNode, handlePropChange }: any) => {
  const { formId } = useParams<{ formId: string }>();
  const integrations = useIntegrations({
    types: [INTEGRATIONS.CALENDLY],
    panelId: formId
  });
  const activeStepId = useAppSelector(
    (state) => (state as any).formBuilder.activeStepId
  );

  // If this cellNode in a repeatable grid (ancestor)
  // or is a ancestor of a repeatable grid, then it is not repeatable.
  const { inRepeatContainer } = useRepeatedContainerChecks(cellNode, true);

  const org = useAppSelector((state) => state.accounts.organization);

  const [showIframe, setShowIframe] = useState(cellNode.properties?.iframe_url);
  const [showCustomHTML, setShowCustomHTML] = useState(
    cellNode.properties?.custom_html
  );

  return (
    <CollapsibleSection title='Properties' collapsible>
      {integrations[INTEGRATIONS.CALENDLY] && (
        <>
          <Row>
            <Col>
              <CheckboxField
                checked={!!cellNode.properties?.embed_calendly}
                text='Embed Calendly'
                onChange={(isChecked) =>
                  handlePropChange({
                    properties: { embed_calendly: isChecked }
                  })
                }
              />
            </Col>
          </Row>
          {cellNode.properties?.embed_calendly && (
            <Row>
              <Col sm='6'>
                <PropertyLabel
                  label={
                    <>
                      Success Redirect
                      <InlineTooltip text='(Optional) The step the user will be redirected to if a Calendly event is scheduled successfully' />
                    </>
                  }
                />
              </Col>
              <Col>
                <StepSelector
                  filterStepId={activeStepId}
                  curStepId={cellNode.properties?.calendly_success_step}
                  onSelect={(newStepId: string) =>
                    handlePropChange({
                      properties: { calendly_success_step: newStepId }
                    })
                  }
                />
              </Col>
            </Row>
          )}
        </>
      )}
      {!cellNode.isRoot() && (
        <Row>
          <Col>
            <CheckboxField
              checked={cellNode.repeated}
              text={
                <>
                  Repeatable
                  <InlineTooltip text='This container can dynamically display multiple times' />
                </>
              }
              onChange={(isChecked) =>
                handlePropChange({ repeated: isChecked })
              }
              disabled={inRepeatContainer && !cellNode.repeated}
              disabledMessage="This container can't be repeatable since one of its parent or child containers is already repeatable"
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <CheckboxField
            checked={showIframe}
            text={
              <>
                Embed Iframe
                <InlineTooltip
                  text={`An iframe pointing to this URL will be embedded into this container. ${
                    onFreePlan(org)
                      ? 'You must be on a paid plan to use this feature.'
                      : ''
                  }`}
                />
              </>
            }
            onChange={(isChecked) => {
              if (isChecked) {
                setShowIframe(true);
              } else {
                setShowIframe(false);
                handlePropChange({ properties: { iframe_url: '' } });
              }
            }}
            disabled={onFreePlan(org)}
            disabledMessage='You must be on a paid plan to use this feature.'
          />
        </Col>
      </Row>
      {showIframe && (
        <Row>
          <Col sm='3'>
            <PropertyLabel label='URL' />
          </Col>
          <Col>
            <TextField
              title='Iframe Url'
              type='url'
              placeholder='https://iframe.com'
              value={cellNode.properties?.iframe_url || ''}
              disabled={onFreePlan(org)}
              onComplete={(iFrameUrl: string) => {
                // make it a little tougher to walk around the paywall
                if (!onFreePlan(org))
                  handlePropChange({ properties: { iframe_url: iFrameUrl } });
              }}
              triggerCleanUp
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <CheckboxField
            checked={showCustomHTML}
            text={
              <>
                Embed Custom HTML
                <InlineTooltip
                  text={`HTML will be embedded into this container. ${
                    onFreePlan(org)
                      ? 'You must be on a paid plan to use this feature.'
                      : ''
                  }`}
                />
              </>
            }
            onChange={(isChecked) => {
              if (isChecked) {
                setShowCustomHTML(true);
              } else {
                setShowCustomHTML(false);
                handlePropChange({ properties: { custom_html: '' } });
              }
            }}
            disabled={onFreePlan(org)}
            disabledMessage='You must be on a paid plan to use this feature.'
          />
        </Col>
      </Row>
      {showCustomHTML && (
        <Row>
          <Col>
            <TextField
              title='Custom HTML'
              type='textarea'
              placeholder='<div...'
              value={cellNode.properties?.custom_html || ''}
              disabled={onFreePlan(org)}
              onComplete={(customHtml: string) => {
                // make it a little tougher to walk around the paywall
                if (!onFreePlan(org))
                  handlePropChange({ properties: { custom_html: customHtml } });
              }}
              triggerCleanUp
              rows={5}
            />
          </Col>
        </Row>
      )}
    </CollapsibleSection>
  );
};

export default ContainerPropertiesSection;
