import { memo, useCallback, useRef, useState } from 'react';

import Dialog from '../Dialog';
import useModalSubmissionLockout from '../../utils/useModalSubmissionLockout';
import useFeatheryRedux from '../../redux';
import { useHistory } from 'react-router-dom';
import { Neutral, Positive } from '../Core/Button';
import RadioInput from '../Core/RadioInput';
import { AnnotatedField } from '../../pages/DocumentEditorPage/types';
import { CloseIcon } from '../Icons';

import styles from './styles.module.scss';
import {
  acceptedFileTypesMap,
  createAnnotatedFieldsFromPdf,
  getFileType,
  readFile,
  validatePdf
} from '../../utils/documents';

function CreateDocumentModal({ show, close, folder }: any) {
  const fileInput = useRef<any>();
  const history = useHistory();

  const { createDocument } = useFeatheryRedux();
  const [error, setError] = useState('');
  const [hasPdfFields, setHasPdfFields] = useState(false);
  const [fieldsRequired, setFieldsRequired] = useState<'required' | 'optional'>(
    'required'
  );

  const [fileReadPromise, setFileReadPromise] = useState<Promise<any> | null>(
    null
  );
  const [pdfValidationPromise, setPdfValidationPromise] =
    useState<Promise<any> | null>(null);

  function reset() {
    setError('');
    setHasPdfFields(false);
  }

  const onChooseFile = async (e: any) => {
    reset();
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const readPromise = readFile(file);
    setFileReadPromise(readPromise);
    const type = getFileType(file);
    if (!type) {
      setError('Unsupported file type');
      return;
    }
    if (type === 'pdf') {
      const validationPromise = validatePdf(readPromise);
      setPdfValidationPromise(validationPromise);
      const { error, hasFields } = await validationPromise;
      if (error) {
        setError(error as string);
        return;
      }
      setHasPdfFields(hasFields);
    }
  };

  const submit = useCallback(async () => {
    if (!fileReadPromise) {
      setError('Please choose a file');
      return;
    }
    let annotatedFields: AnnotatedField[] | null = [];
    const file = fileInput.current.files[0];
    const type = getFileType(file);

    if (!type) {
      setError('Unsupported file type');
      return;
    }

    if (type === 'pdf') {
      // ensure pdf validated
      const { error } = await pdfValidationPromise;
      if (error) {
        setError(error as string);
        return;
      }
      // extract annotated fields from pdf based on any fillable fields
      annotatedFields = await createAnnotatedFieldsFromPdf(
        fileReadPromise,
        fieldsRequired
      );
      if (!annotatedFields) {
        setError('Error reading PDF fields');
        return;
      }
    }

    const res = await createDocument({
      file,
      type,
      annotated_fields: annotatedFields,
      folder: folder ?? ''
    });
    type === 'pdf' || res.display_file != null
      ? history.push(`/documents/${res.id}`)
      : history.push(`/documents/${res.id}/envelopes`);
  }, [
    fileInput.current,
    fileReadPromise,
    pdfValidationPromise,
    fieldsRequired
  ]);

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  return (
    <Dialog
      isOpen={show}
      onClose={() => {
        reset();
        close();
      }}
      shouldShowCloseIcon={false}
      title='Create Document'
      showCloseIcon
      alignCenter
      style={{ padding: '24px 40px' }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <div className={styles.createDocumentModal}>
          {fileReadPromise && fileInput.current ? (
            <div className={styles.selectedFile}>
              {fileInput.current.files[0]?.name}
              <div
                className={styles.close}
                tabIndex={0}
                onClick={(e) => {
                  setFileReadPromise(null);
                  fileInput.current.value = '';
                  reset();
                }}
              >
                <CloseIcon width={12} height={12} />
              </div>
            </div>
          ) : (
            <div className={styles.chooseButton}>
              <Neutral onClick={() => fileInput.current.click()}>
                Choose a PDF, Word, or Excel template
              </Neutral>
            </div>
          )}

          <input
            id='choose-document'
            ref={fileInput}
            onChange={onChooseFile}
            type='file'
            accept={Object.values(acceptedFileTypesMap).join(',')}
            style={{
              position: 'relative',
              height: 0,
              width: 0,
              opacity: 0
            }}
          />
          {error && <span className={styles.error}>{error}</span>}

          {hasPdfFields && (
            <>
              <label>Your PDF has fillable fields. Are they required?</label>
              <RadioInput
                selected={fieldsRequired}
                onChange={(val: any) => setFieldsRequired(val)}
                options={[
                  {
                    value: 'required',
                    display: 'Required'
                  },
                  {
                    value: 'optional',
                    display: 'Optional'
                  }
                ]}
              />
            </>
          )}
        </div>
        <div className='dialog-form-action'>
          <Neutral
            onClick={() => {
              reset();
              close();
            }}
          >
            Cancel
          </Neutral>
          <Positive lockoutOverride={lockOutFlag}>Create</Positive>
        </div>
      </form>
    </Dialog>
  );
}

export default memo(CreateDocumentModal);
