/* eslint-disable react-hooks/exhaustive-deps */

import '../../../style/form-field-editor.scss';

import {
  ACTION_FIELDS,
  AUTO_SUBMITTABLE_FIELDS,
  LENGTH_FIELDS,
  MINMAX_LENGTH_FIELDS,
  NUMBER_FIELDS,
  OPTION_FIELDS,
  OTHER_FIELDS
} from '../../SelectionPanel/elementEntries';
import { Col, InputGroup, Row } from 'react-bootstrap';
import {
  CheckboxField,
  CollapsibleSection,
  ContextMenu,
  DropdownField,
  DropdownMultiField,
  FColorPicker,
  InlineTooltip,
  NumberInput,
  PropertyLabel,
  TextField
} from '../../Core';
import { ChangeEvent, Fragment, memo, useRef, useState } from 'react';
import {
  assetUpdateOperations,
  elementOperation,
  getAsset
} from '../../../utils/themes';
import { objectPick } from '../../../utils/core';

import { CustomValidationsSection, HideIfsSection } from './components';
import {
  AddImageIcon,
  ColorPickerIcon,
  EditIcon,
  InfoIcon,
  RightArrowIcon,
  TextIcon,
  TrashIcon
} from '../../Icons';
import { calculateOverrideObjects } from '../utils';
import produce from 'immer';
import useFeatheryRedux from '../../../redux';
import ImageThumbnail from '../../Core/UploadedImageField/ImageThumbnail';
import { isNum } from '../../../utils/validate';
import { BulkAddOptions, ImageUploadModal } from '../../Modals';

import styles from './styles.module.scss';
import classNames from 'classnames';
import MultiselectToggle from './components/MultiselectToggle';
import { useGlobalMouseDownToggle } from '../../Core/util';
import DisplayImage from '../../Core/UploadedImageField/DisplayImage';
import countryData from '../../Assets/countryData';
import statesData from '../../Assets/states';
import timesByHalfHour from '../../Assets/timesByHalfHour';
import { v4 } from 'uuid';
import { useAppSelector } from '../../../hooks';
import htmlAutocompleteOptions from '../../Assets/htmlAutocompleteOptions';
import { useRepeatedContainerChecks } from '../../../hooks/useRepeatedContainerChecks';
import { DATE_MULTISELECT_OPTIONS } from '../../Core/DropdownMultiField/utils';
import { useParams } from 'react-router-dom';
import controlStyles from '../../Modals/UserCollaborationModal/styles.module.scss';
import useQuikFieldConfig from './components/useQuikFieldConfig';

const WORD_FILE_TYPES =
  'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx';
const EXCEL_FILE_TYPES =
  'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,.xlsx';
const POWERPOINT_FILE_TYPES =
  'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,.ppt,.pptx';
const PLAINTEXT_FILE_TYPES = 'text/plain,.txt';
const RICH_FILE_TYPE_OPTIONS = [
  {
    value: [
      WORD_FILE_TYPES,
      EXCEL_FILE_TYPES,
      POWERPOINT_FILE_TYPES,
      PLAINTEXT_FILE_TYPES
    ].join(','),
    display: 'Document'
  },
  { value: 'application/pdf', display: 'PDF' },
  { value: 'image/*', display: 'Image' },
  { value: 'audio/*', display: 'Audio' },
  { value: 'video/*', display: 'Video' }
];

function FieldPropertiesPanel({
  mode,
  viewport,
  theme,
  baseProps,
  overrideProps,
  handleUpdates
}: any) {
  const { formId } = useParams<{ formId: string }>();
  const { result: element, isOverride: isOverrideProp } =
    calculateOverrideObjects(baseProps, overrideProps, true);
  const servar = element.servar ?? {};
  const servarMeta = servar.metadata ?? {};
  const elementType = servar.type;
  const asset = getAsset(theme, elementType, element.source_asset);

  const servarUsage = useAppSelector((state) => state.formBuilder.servarUsage);

  const isEnterprise = useAppSelector((state) => {
    const org = state.accounts.organization;
    return !!(org && org.tier >= 4);
  });

  const isLinked =
    mode === 'builder' ? servarUsage[servar.id].length > 1 : false;

  const editLinkedAllowed =
    useAppSelector((state) => {
      const account = state.accounts.account;
      return (
        account.role === 'admin' || account.permission_edit_linked_properties
      );
    }) || !isLinked;

  const isAutofillPluginEnabled = useAppSelector((state) => {
    return state.panels.panels[formId].plugin_autofill;
  });

  const {
    formBuilder: { addChangedServar }
  } = useFeatheryRedux();

  function handlePropChange(propUpdate: any) {
    handleUpdates([elementOperation({ viewport, propUpdate })]);
  }

  function handleInheritedPropChange(propUpdate: any) {
    handlePropChange({ properties: propUpdate });
  }

  function handleServarChange(servar: any) {
    handlePropChange({ servar });

    // In the form builder, we need to record that we made changes to this servar
    // So we can show the publish confirmation modal
    if (mode === 'builder') addChangedServar(element.servar.id);
  }

  function handleServarMetadataChange(metadata: any) {
    handleServarChange({ metadata });
  }

  const quikConfig = useQuikFieldConfig(servarMeta, handleServarMetadataChange);

  const handleOptionValChange =
    (key: string, index: number) => (val: string | null) => {
      const newOptionVals = produce(servarMeta[key], (draft: any) => {
        draft[index] = val;
      });
      handleServarMetadataChange({ [key]: newOptionVals });
    };

  function addOption() {
    if (!editLinkedAllowed) return;
    const newOptionData = produce(servar, (draft: any) => {
      if (servar.type === 'text_field')
        return {
          metadata: { options: [...(draft.metadata.options ?? []), ''] }
        };
      else
        return {
          option_images: [...(draft.option_images ?? []), ''],
          metadata: {
            options: [...(draft.metadata.options ?? []), ''],
            option_labels: [...(draft.metadata.option_labels ?? []), null],
            option_tooltips: [...(draft.metadata.option_tooltips ?? []), null],
            option_images: [...(draft.metadata.option_images ?? []), '']
          }
        };
    });
    handleServarChange(newOptionData);
  }

  const handleQuestionChange =
    (key: string, index: number) => (val: string | boolean | null) => {
      const newQuestions = produce(servarMeta.questions, (draft: any) => {
        draft[index][key] = val;
      });
      handleServarMetadataChange({ questions: newQuestions });
    };

  function addQuestion() {
    if (!editLinkedAllowed) return;
    handleServarMetadataChange({
      questions: [
        ...servarMeta.questions,
        {
          id: v4(),
          label: '',
          default_value: null,
          tooltip: null,
          read_only: null
        }
      ]
    });
  }

  function deleteQuestion() {
    if (activeQuestionIndex === undefined) return;
    const newQuestions = [...servarMeta.questions];
    newQuestions.splice(activeQuestionIndex, 1);
    handleServarMetadataChange({ questions: newQuestions });
    setActiveQuestionIndex(0);
  }

  function deleteOption() {
    const newOptionData = produce(servar, (draft: any) => {
      draft.metadata.options.splice(activeOptionIndex, 1);
      if (servar.type !== 'text_field') {
        draft.metadata.option_labels.splice(activeOptionIndex, 1);
        draft.metadata.option_tooltips?.splice(activeOptionIndex, 1);
        draft.metadata.option_images.splice(activeOptionIndex, 1);
        if (draft.option_images)
          // https://feathery-forms.sentry.io/issues/5135572730
          draft.option_images.splice(activeOptionIndex, 1);
      }
    });
    handleServarChange(newOptionData);
    setActiveOptionIndex(0);
  }

  const rearrange = (arr: any[], up: boolean, index: number) => {
    const val = arr.splice(index, 1)[0];
    const shift = up ? -1 : 1;
    arr.splice(index + shift, 0, val);
  };

  const moveOption = (up: boolean) => () => {
    const newOptionData = produce(servar, (draft: any) => {
      rearrange(draft.metadata.options, up, activeOptionIndex);
      if (servar.type !== 'text_field') {
        rearrange(draft.metadata.option_labels, up, activeOptionIndex);
        rearrange(draft.metadata.option_tooltips, up, activeOptionIndex);
        rearrange(draft.metadata.option_images, up, activeOptionIndex);
        if (draft.option_images)
          rearrange(draft.option_images, up, activeOptionIndex);
      }
    });
    handleServarChange(newOptionData);
  };

  const moveQuestion = (up: boolean) => () => {
    const newQuestionData = produce(servar, (draft: any) => {
      rearrange(draft.metadata.questions, up, activeQuestionIndex as number);
    });
    handleServarChange(newQuestionData);
  };

  function selectOptionImage(newImage: any, indexOverride = null) {
    const imageIndex = indexOverride ?? activeOptionIndex;
    const newOptionData = produce(servar, (draft: any) => {
      const imageIds = [...draft.option_images];
      imageIds[imageIndex] = newImage?.id ?? '';
      const imageUrls = [...draft.metadata.option_images];
      imageUrls[imageIndex] = newImage?.file ?? '';
      return {
        option_images: imageIds,
        metadata: { option_images: imageUrls }
      };
    });
    handleServarChange(newOptionData);
  }

  const org = useAppSelector((state) => state.accounts.organization);
  const { inRepeatContainer } = useRepeatedContainerChecks(element, true);

  const enterprise = org?.tier >= 4;

  const [activeOptionIndex, setActiveOptionIndex] = useState(0);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0);
  const [optionImageUpload, setOptionImageUpload] = useState(false);
  const [menuPosition, setMenuPosition] = useState<{
    x?: number;
    y?: number;
  }>({});
  const activeImage =
    servar.type === 'button_group'
      ? servar.option_images[activeOptionIndex]
      : null;

  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useGlobalMouseDownToggle([menuRef]);
  const [questionMenuPosition, setQuestionMenuPosition] = useState<{
    x?: number;
    y?: number;
  }>({});
  const questionMenuRef = useRef<HTMLDivElement>(null);
  const [showQuestionMenu, setShowQuestionMenu] = useGlobalMouseDownToggle([
    questionMenuRef
  ]);

  const openQuestionMenu = (index: number) => (e: any) => {
    if (!editLinkedAllowed) return;
    e.preventDefault();
    setActiveQuestionIndex(index);
    setQuestionMenuPosition({ x: e.pageX, y: e.pageY });
    setShowQuestionMenu(true);
  };

  const openMenu = (index: number) => (e: any) => {
    if (!editLinkedAllowed) return;
    e.preventDefault();
    setActiveOptionIndex(index);
    setMenuPosition({ x: e.pageX, y: e.pageY });
    setShowMenu(true);
  };

  function labelData(...propList: string[]) {
    const newProps = objectPick(element.properties, propList);

    return {
      mode,
      highlighted: isOverrideProp({ properties: propList }),
      themeOperations: {
        instance: {
          label: 'Reset to asset value',
          operation: () => {
            handleUpdates([
              elementOperation({ viewport, propReset: propList })
            ]);
          }
        },
        ...(asset && {
          asset: {
            label: asset.key,
            operation: () => {
              handleUpdates(
                assetUpdateOperations({
                  viewport,
                  elementType,
                  asset,
                  newProps
                })
              );
            }
          }
        })
      }
    };
  }

  const countryCodeMap = countryData.reduce(
    (cur, { countryCode, countryName }) => ({
      ...cur,
      [countryCode.toLowerCase()]: countryName
    }),
    {} as Record<string, string>
  );

  return (
    <>
      <CollapsibleSection title='Description'>
        <Row>
          <Col>
            <TextField
              disabled={!editLinkedAllowed}
              type='textarea'
              placeholder='What is your name?'
              value={servar.name}
              onComplete={(name: any) => handleServarChange({ name })}
              triggerCleanUp
              maxLength={1024}
              rows={1}
            />
          </Col>
        </Row>
      </CollapsibleSection>

      {servar.type === 'gmap_line_1' && (
        <CollapsibleSection title='Address Properties'>
          <Row>
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.address_autocomplete}
                text={
                  <>
                    Address Autocomplete
                    <InlineTooltip text='Address autocomplete appears as the user types their address. On select, populates either entirely into the current field or components of nearby address fields' />
                  </>
                }
                onChange={(isActive) =>
                  handleServarMetadataChange({
                    address_autocomplete: isActive
                  })
                }
              />
            </Col>
          </Row>
          {servarMeta.address_autocomplete && (
            <>
              <Row>
                <Col>
                  <Col sm='4'>
                    <PropertyLabel label='Save Address' />
                  </Col>
                  <DropdownField
                    disabled={!editLinkedAllowed}
                    title='On Autocomplete Address Select'
                    selected={servarMeta.save_address ?? ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleServarMetadataChange({
                        save_address: e.target.value
                      })
                    }
                    options={[
                      {
                        value: '',
                        display: 'Into component fields'
                      },
                      {
                        value: 'all_line_1',
                        display: 'Into Line 1 field'
                      }
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm='5'>
                  <PropertyLabel label='Search Country' />
                </Col>
                <Col>
                  <DropdownField
                    disabled={!editLinkedAllowed}
                    selected={servarMeta.autocomplete_country ?? ''}
                    onChange={(e: any) =>
                      handleServarMetadataChange({
                        autocomplete_country: e.target.value
                      })
                    }
                    options={[
                      { value: '', display: 'All' },
                      ...countryData.map(({ countryCode, countryName }) => ({
                        value: countryCode,
                        display: countryName
                      }))
                    ]}
                  />
                </Col>
              </Row>
            </>
          )}
        </CollapsibleSection>
      )}
      {['gmap_state', 'gmap_country'].includes(servar.type) && (
        <CollapsibleSection title='Address Properties'>
          <Row>
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.store_abbreviation}
                text={
                  <>
                    Save {servar.type === 'gmap_state' ? 'state' : 'country'}{' '}
                    abbreviation
                    <InlineTooltip
                      text={`The ${
                        servar.type === 'gmap_state' ? 'state' : 'country'
                      } abbreviation is saved as the field value instead of its full name`}
                    />
                  </>
                }
                onChange={(isActive) =>
                  handleServarMetadataChange({
                    store_abbreviation: isActive
                  })
                }
              />
            </Col>
          </Row>
          {servar.type === 'gmap_country' && (
            <Row>
              <Col>
                <PropertyLabel label='Default Country' />
              </Col>
              <Col>
                <DropdownField
                  disabled={!editLinkedAllowed}
                  selected={servarMeta.default_country ?? ''}
                  onChange={(e: any) =>
                    handleServarMetadataChange({
                      default_country: e.target.value
                    })
                  }
                  options={[
                    { value: '', display: 'None' },
                    ...countryData.map((data) => ({
                      value: data.countryCode,
                      display: data.countryName
                    }))
                  ]}
                />
              </Col>
            </Row>
          )}
          {servar.type === 'gmap_state' && (
            <>
              <Row>
                <Col>
                  <PropertyLabel label='Default Country' />
                </Col>
                <Col>
                  <DropdownField
                    disabled={!editLinkedAllowed}
                    selected={servarMeta.default_country ?? 'us'}
                    onChange={(e: any) =>
                      handleServarMetadataChange({
                        default_country: e.target.value,
                        default_state: ''
                      })
                    }
                    options={Object.keys(statesData).map((countryCode) => ({
                      value: countryCode,
                      display: countryCodeMap[countryCode]
                    }))}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PropertyLabel label='Default State' />
                </Col>
                <Col>
                  <DropdownField
                    disabled={!editLinkedAllowed}
                    selected={servarMeta.default_state ?? ''}
                    onChange={(e: any) =>
                      handleServarMetadataChange({
                        default_state: e.target.value
                      })
                    }
                    options={[
                      { value: '', display: 'None' },
                      ...statesData[servarMeta.default_country ?? 'us'].map(
                        (state: string) => ({
                          value: state,
                          display: state
                        })
                      )
                    ]}
                  />
                </Col>
              </Row>
              {(!servarMeta.default_country ||
                servarMeta.default_country === 'us') && (
                <Row>
                  <Col>
                    <CheckboxField
                      disabled={!editLinkedAllowed}
                      checked={servarMeta.include_territories}
                      text={
                        <>
                          Include US territory options
                          <InlineTooltip text='Includes US territories, armed forces, etc.' />
                        </>
                      }
                      onChange={(isActive) =>
                        handleServarMetadataChange({
                          include_territories: isActive
                        })
                      }
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </CollapsibleSection>
      )}

      {servar.type === 'phone_number' && (
        <CollapsibleSection title='Phone Properties'>
          <Row>
            <Col>
              <PropertyLabel label='Default Country' />
            </Col>
            <Col>
              <DropdownField
                disabled={!editLinkedAllowed}
                selected={servarMeta.default_country || 'US'}
                onChange={(e: any) =>
                  handleServarMetadataChange({
                    default_country: e.target.value
                  })
                }
                options={[
                  { value: 'auto', display: 'Automatic' },
                  ...countryData.map((data) => ({
                    value: data.countryCode,
                    display: data.countryName
                  }))
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.disable_other_countries}
                text='Disable other countries'
                onChange={(isChecked) =>
                  handleServarMetadataChange({
                    disable_other_countries: isChecked
                  })
                }
              />
            </Col>
          </Row>
        </CollapsibleSection>
      )}

      {servar.type === 'date_selector' && (
        <CollapsibleSection title='Date Selector Properties'>
          <Row>
            <Col sm='5'>
              <PropertyLabel label='Display Format' />
            </Col>
            <Col>
              <DropdownField
                disabled={!editLinkedAllowed}
                selected={servarMeta.display_format ?? ''}
                onChange={(e: any) =>
                  handleServarMetadataChange({
                    display_format: e.target.value
                  })
                }
                options={[
                  { value: '', display: 'MM-DD-YYYY' },
                  { value: 'DD-MM-YYYY', display: 'DD-MM-YYYY' }
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='3'>
              <PropertyLabel label='Disable Dates' />
            </Col>
            <Col>
              <DropdownMultiField
                disabled={!editLinkedAllowed}
                selected={servarMeta.disabled_dates ?? []}
                options={DATE_MULTISELECT_OPTIONS}
                onChange={(items: { value: string }[]) =>
                  handleServarMetadataChange({
                    disabled_dates: items.map((item) => item.value)
                  })
                }
                placeholder='Choose Dates'
                className={controlStyles.controlField}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.no_past}
                text='No Past Dates'
                onChange={(checked) =>
                  handleServarMetadataChange({
                    no_past: checked
                  })
                }
              />
            </Col>
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.no_future}
                text='No Future Dates'
                onChange={(checked) =>
                  handleServarMetadataChange({
                    no_future: checked
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.no_weekends}
                text='No Weekends'
                onChange={(checked) =>
                  handleServarMetadataChange({
                    no_weekends: checked
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.choose_time}
                text='Allow choosing a time'
                onChange={(checked) =>
                  handleServarMetadataChange({
                    choose_time: checked
                  })
                }
              />
            </Col>
          </Row>
          {servarMeta.choose_time && (
            <>
              <Row>
                <Col>
                  <PropertyLabel label='Time Format' />
                </Col>
                <Col>
                  <DropdownField
                    disabled={!editLinkedAllowed}
                    selected={servarMeta.time_format ?? '12hr'}
                    onChange={(e: any) =>
                      handleServarMetadataChange({
                        time_format: e.target.value
                      })
                    }
                    options={[
                      { value: '12hr', display: '12 hr (am/pm)' },
                      { value: '24hr', display: '24 hr' }
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PropertyLabel label='Time Interval' />
                </Col>
                <Col>
                  <DropdownField
                    disabled={!editLinkedAllowed}
                    selected={servarMeta.time_interval ?? ''}
                    onChange={(e: any) =>
                      handleServarMetadataChange({
                        time_interval: e.target.value
                      })
                    }
                    options={[
                      { value: '', display: '30 min' },
                      { value: '20', display: '20 min' },
                      { value: '15', display: '15 min' },
                      { value: '10', display: '10 min' },
                      { value: '5', display: '5 min' },
                      { value: '1', display: '1 min' }
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PropertyLabel label='Earliest Time' />
                </Col>
                <Col>
                  <DropdownField
                    disabled={!editLinkedAllowed}
                    selected={servarMeta.min_time ?? ''}
                    onChange={(e: any) =>
                      handleServarMetadataChange({
                        min_time: e.target.value
                      })
                    }
                    options={[
                      { value: '', display: 'None' },
                      ...timesByHalfHour
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PropertyLabel label='Latest Time' />
                </Col>
                <Col>
                  <DropdownField
                    disabled={!editLinkedAllowed}
                    selected={servarMeta.max_time ?? ''}
                    onChange={(e: any) =>
                      handleServarMetadataChange({
                        max_time: e.target.value
                      })
                    }
                    options={[
                      { value: '', display: 'None' },
                      ...timesByHalfHour
                    ]}
                  />
                </Col>
              </Row>
            </>
          )}
        </CollapsibleSection>
      )}

      <CollapsibleSection title='Constraints'>
        <MultiselectToggle
          servar={servar}
          onChange={(checked: boolean) => {
            const toChange: Record<string, any> = {
              servar: { metadata: { multiple: checked } }
            };
            if (checked && element.properties.submit_trigger !== 'none')
              toChange.properties = { submit_trigger: 'none' };
            handlePropChange(toChange);
          }}
          disabled={!editLinkedAllowed}
        />
        {servar.type === 'signature' && (
          <>
            <Row>
              <Col sm='4'>
                <PropertyLabel label='Sign Methods' />
              </Col>
              <Col>
                <DropdownField
                  disabled={!editLinkedAllowed}
                  selected={servarMeta.sign_methods ?? ''}
                  onChange={(e: any) =>
                    handleServarMetadataChange({ sign_methods: e.target.value })
                  }
                  options={[
                    { value: '', display: 'Draw or Type' },
                    { value: 'draw', display: 'Draw' },
                    { value: 'type', display: 'Type' }
                  ]}
                />
              </Col>
            </Row>
          </>
        )}
        {servar.type === 'file_upload' && (
          <>
            <Row>
              <Col sm='4'>
                <PropertyLabel label='File Types' />
              </Col>
              <Col className='col-vertical'>
                {RICH_FILE_TYPE_OPTIONS.map(({ display, value }) => (
                  <CheckboxField
                    disabled={!editLinkedAllowed}
                    key={value}
                    checked={servarMeta.file_types.includes(value)}
                    text={display}
                    onChange={(isChecked) => {
                      const fileTypes = [...servarMeta.file_types];
                      if (isChecked) fileTypes.push(value);
                      else fileTypes.splice(fileTypes.indexOf(value), 1);
                      handleServarMetadataChange({ file_types: fileTypes });
                    }}
                  />
                ))}
                <CheckboxField
                  disabled={!editLinkedAllowed}
                  key='custom'
                  checked={!!servarMeta.custom_file_types}
                  text='Custom'
                  onChange={(isChecked) => {
                    const newVal = isChecked ? [] : undefined;
                    handleServarMetadataChange({ custom_file_types: newVal });
                  }}
                />
                <TextField
                  disabled={!editLinkedAllowed}
                  style={{
                    marginTop: '5px',
                    visibility: servarMeta.custom_file_types
                      ? 'visible'
                      : 'hidden'
                  }}
                  placeholder='zip,csv,png,...'
                  value={servarMeta.custom_file_types?.join(',')}
                  onComplete={(fileTypes: string) =>
                    handleServarMetadataChange({
                      custom_file_types: fileTypes
                        .split(',')
                        .map((type) => type.trim())
                        .filter((type) => type)
                    })
                  }
                  triggerCleanUp
                />
              </Col>
            </Row>
            <Row>
              <Col sm='5'>
                <PropertyLabel label='Max File Size (KB)' />
              </Col>
              <Col>
                <NumberInput
                  disabled={!editLinkedAllowed}
                  placeholder='10,000'
                  min={1}
                  // Max 10MB file size, 500MB for enterprise
                  max={enterprise ? 500000 : 10000}
                  value={servar.max_length}
                  onComplete={({ value }: any) =>
                    handleServarChange({ max_length: value })
                  }
                  triggerCleanUp
                />
              </Col>
            </Row>
          </>
        )}
        {servar.type === 'checkbox' && (
          <Row>
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.must_check}
                text='Must be checked'
                onChange={(isChecked) =>
                  handleServarMetadataChange({ must_check: isChecked })
                }
              />
            </Col>
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.default_checked}
                text='Checked by default'
                onChange={(isChecked) =>
                  handleServarMetadataChange({ default_checked: isChecked })
                }
              />
            </Col>
          </Row>
        )}
        <Row>
          {servar.type === 'checkbox' ? (
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.always_checked}
                text='Always checked'
                onChange={(isChecked) =>
                  handleServarMetadataChange({ always_checked: isChecked })
                }
              />
            </Col>
          ) : (
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={!servar.required}
                text={
                  <>
                    Optional
                    <InlineTooltip text='The field is not required to be completed by your user.' />
                  </>
                }
                onChange={(isChecked) =>
                  handleServarChange({ required: !isChecked })
                }
              />
            </Col>
          )}
          {servar.type !== 'payment_method' && (
            <Col>
              <CheckboxField
                checked={servar.repeated}
                text={
                  <>
                    Repeatable
                    <InlineTooltip text='This field can display multiple times. Repeatable fields must be in a repeatable container.' />
                  </>
                }
                onChange={(isChecked) =>
                  handleServarChange({ repeated: isChecked })
                }
                disabled={
                  !editLinkedAllowed ||
                  (!inRepeatContainer && !servar.repeated) ||
                  (servar.type === 'file_upload' && servarMeta.multiple) ||
                  (servar.has_data && servar.repeated)
                }
                disabledMessage={
                  !inRepeatContainer && !servar.repeated
                    ? 'Must be in a repeating container to be set as repeatable'
                    : servar.has_data && servar.repeated
                    ? 'This field has repeating data saved and cannot be unrepeatable. Please replace the field instead.'
                    : 'Multi-file uploads cannot be repeated'
                }
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <CheckboxField
              checked={element.properties.disabled ?? false}
              onChange={(isChecked) => {
                const props: Record<string, any> = {
                  properties: { disabled: isChecked }
                };
                if (isChecked) props.servar = { required: false };
                handlePropChange(props);
              }}
              text='Read Only'
            />
          </Col>
          {servar.type === 'gmap_zip' && (
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.allowed_characters === 'digits'}
                text='Only Digits'
                onChange={(isChecked) =>
                  handleServarMetadataChange({
                    allowed_characters: isChecked ? 'digits' : ''
                  })
                }
              />
            </Col>
          )}
        </Row>
        {servar.repeated && (
          <>
            <Row>
              <Col sm='9'>
                <PropertyLabel label='Add new repeat after field is set' />
              </Col>
              <Col>
                <DropdownField
                  disabled={!editLinkedAllowed}
                  selected={servar.repeat_trigger}
                  onChange={(e: any) =>
                    handleServarChange({ repeat_trigger: e.target.value })
                  }
                  options={[
                    { value: '', display: 'No' },
                    { value: 'set_value', display: 'Yes' }
                  ]}
                />
              </Col>
            </Row>
            {servar.type === 'dropdown' && (
              <Row>
                <Col>
                  <CheckboxField
                    disabled={!editLinkedAllowed}
                    checked={servar.metadata.unique_repeat_options}
                    text={
                      <>
                        Unique repeat option selections{' '}
                        <InlineTooltip
                          text='Each option may only be selected one across all repeating fields'
                          inline
                        />
                      </>
                    }
                    onChange={(isChecked) =>
                      handleServarMetadataChange({
                        unique_repeat_options: isChecked
                      })
                    }
                  />
                </Col>
              </Row>
            )}
          </>
        )}
        {LENGTH_FIELDS.includes(servar.type) && (
          <Row>
            <Col sm='4'>
              <PropertyLabel label='Length' />
            </Col>
            <Col>
              <NumberInput
                disabled={!editLinkedAllowed}
                min={1}
                value={servar.max_length}
                onComplete={({ value }: any) =>
                  handleServarChange({ max_length: value })
                }
                triggerCleanUp
              />
            </Col>
          </Row>
        )}
        {servar.type in MINMAX_LENGTH_FIELDS && (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label={
                  NUMBER_FIELDS.includes(servar.type)
                    ? 'Min Value'
                    : 'Min Length'
                }
              />
            </Col>
            <Col>
              <NumberInput
                disabled={!editLinkedAllowed}
                max={
                  MINMAX_LENGTH_FIELDS[
                    servar.type as keyof typeof MINMAX_LENGTH_FIELDS
                  ]
                }
                min={servar.type === 'slider' ? undefined : 1}
                value={servar.min_length}
                onComplete={({ value }: any) =>
                  handleServarChange({
                    min_length: isNum(value) ? value : null
                  })
                }
                placeholder={servar.type === 'slider' ? '0' : ''}
                triggerCleanUp
              />
            </Col>
            <Col sm='3'>
              <PropertyLabel
                label={
                  NUMBER_FIELDS.includes(servar.type)
                    ? 'Max Value'
                    : 'Max Length'
                }
                indented
              />
            </Col>
            <Col>
              <NumberInput
                disabled={!editLinkedAllowed}
                max={
                  MINMAX_LENGTH_FIELDS[
                    servar.type as keyof typeof MINMAX_LENGTH_FIELDS
                  ]
                }
                min={servar.type === 'slider' ? undefined : 1}
                value={servar.max_length}
                onComplete={({ value }: any) =>
                  handleServarChange({
                    max_length: isNum(value) ? value : null
                  })
                }
                placeholder={servar.type === 'slider' ? '100' : ''}
                triggerCleanUp
              />
            </Col>
          </Row>
        )}
        {servar.type === 'password' && (
          <>
            <Row>
              <Col>
                <CheckboxField
                  disabled={!editLinkedAllowed}
                  checked={servarMeta.letter_required}
                  text='At least one letter required'
                  onChange={(isChecked) =>
                    handleServarMetadataChange({
                      letter_required: isChecked
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CheckboxField
                  disabled={!editLinkedAllowed}
                  checked={servarMeta.uppercase_letter_required}
                  text='At least one uppercase letter required'
                  onChange={(isChecked) =>
                    handleServarMetadataChange({
                      uppercase_letter_required: isChecked
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CheckboxField
                  disabled={!editLinkedAllowed}
                  checked={servarMeta.lowercase_letter_required}
                  text='At least one lowercase letter required'
                  onChange={(isChecked) =>
                    handleServarMetadataChange({
                      lowercase_letter_required: isChecked
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CheckboxField
                  disabled={!editLinkedAllowed}
                  checked={servarMeta.number_required}
                  text='At least one number required'
                  onChange={(isChecked) =>
                    handleServarMetadataChange({
                      number_required: isChecked
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CheckboxField
                  disabled={!editLinkedAllowed}
                  checked={servarMeta.symbol_required}
                  text='At least one symbol required'
                  onChange={(isChecked) =>
                    handleServarMetadataChange({
                      symbol_required: isChecked
                    })
                  }
                />
              </Col>
            </Row>
          </>
        )}
        {['multiselect', 'dropdown_multi'].includes(servar.type) && (
          <Row>
            <Col sm='7'>
              <PropertyLabel label='Max Selectable Options' />
            </Col>
            <Col>
              <NumberInput
                disabled={!editLinkedAllowed}
                value={servar.max_length}
                onComplete={({ value }: any) =>
                  handleServarChange({
                    max_length: isNum(value) ? value : null
                  })
                }
                placeholder='Unlimited'
                triggerCleanUp
              />
            </Col>
          </Row>
        )}
        {servar.type === 'rating' && (
          <Row>
            <Col sm='3'>
              <PropertyLabel label='Rating Scale' indented />
            </Col>
            <Col>
              <NumberInput
                disabled={!editLinkedAllowed}
                min={1}
                value={servar.max_length}
                onComplete={({ value }: any) =>
                  handleServarChange({
                    max_length: isNum(value) ? value : null
                  })
                }
                triggerCleanUp
              />
            </Col>
          </Row>
        )}
        {servar.type === 'slider' && (
          <>
            <Row>
              <Col sm='3'>
                <PropertyLabel label='Min Label' />
              </Col>
              <Col>
                <TextField
                  disabled={!editLinkedAllowed}
                  value={servarMeta.min_val_label ?? ''}
                  onComplete={(label: any) =>
                    handleServarMetadataChange({ min_val_label: label })
                  }
                  triggerCleanUp
                />
              </Col>
              <Col sm='3'>
                <PropertyLabel label='Max Label' indented />
              </Col>
              <Col>
                <TextField
                  disabled={!editLinkedAllowed}
                  value={servarMeta.max_val_label ?? ''}
                  onComplete={(label: any) =>
                    handleServarMetadataChange({ max_val_label: label })
                  }
                  triggerCleanUp
                />
              </Col>
            </Row>
            <Row>
              <Col sm='3'>
                <PropertyLabel label='Step Size' />
              </Col>
              <Col>
                <NumberInput
                  disabled={!editLinkedAllowed}
                  min={1}
                  value={servarMeta.step_size ?? 1}
                  onComplete={({ value }: any) =>
                    handleServarMetadataChange({ step_size: value })
                  }
                  triggerCleanUp
                />
              </Col>
            </Row>
          </>
        )}
        {servar.type === 'text_field' && (
          <>
            <Row>
              <Col sm='6'>
                <PropertyLabel label='Allowed Characters' />
              </Col>
              <Col>
                <DropdownField
                  disabled={!editLinkedAllowed}
                  selected={servarMeta.allowed_characters || ''}
                  onChange={(e: any) =>
                    handleServarMetadataChange({
                      allowed_characters: e.target.value
                    })
                  }
                  options={[
                    { value: '', display: 'All' },
                    { value: 'alphanumeric', display: 'Alphanumeric' },
                    { value: 'digits', display: 'Digits' },
                    { value: 'letters', display: 'Letters' }
                  ]}
                />
              </Col>
            </Row>
            {['', 'alphanumeric'].includes(servarMeta.allowed_characters) && (
              <Row>
                <Col>
                  <CheckboxField
                    disabled={!editLinkedAllowed}
                    checked={servarMeta.number_keypad}
                    text='Use Numeric Keypad on Mobile'
                    onChange={(isChecked) =>
                      handleServarMetadataChange({
                        number_keypad: isChecked
                      })
                    }
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col sm='3'>
                <PropertyLabel
                  label={
                    <>
                      Mask
                      <InlineTooltip
                        text='Specify a custom, visible constraint for the input text. This will override any other constraints defined. Special characters include 0 (any digit), a (any letter), b (alphanumeric), and * (any character)'
                        inline
                      />
                    </>
                  }
                />
              </Col>
              <Col>
                <TextField
                  disabled={!editLinkedAllowed}
                  value={servarMeta.mask ?? ''}
                  onComplete={(mask: any) =>
                    handleServarMetadataChange({ mask })
                  }
                  triggerCleanUp
                />
              </Col>
              <Col sm='1' />
              <Col sm='3'>
                <CheckboxField
                  disabled={!editLinkedAllowed}
                  checked={servarMeta.save_mask}
                  text={
                    <>
                      Save
                      <InlineTooltip text='Save non-placeholder mask characters into the field value' />
                    </>
                  }
                  onChange={(isChecked) =>
                    handleServarMetadataChange({ save_mask: isChecked })
                  }
                />
              </Col>
            </Row>
          </>
        )}
      </CollapsibleSection>

      {[
        'text_field',
        'text_area',
        'dropdown',
        'select',
        'email',
        'url',
        'multiselect',
        'dropdown_multi'
      ].includes(servar.type) && (
        <CollapsibleSection title='Default Value'>
          <Row>
            <Col>
              <TextField
                disabled={!editLinkedAllowed}
                value={servarMeta.default_value ?? ''}
                onComplete={(val: any) =>
                  handleServarMetadataChange({ default_value: val })
                }
                triggerCleanUp
                type={servar.type === 'text_area' ? 'textarea' : 'text'}
                placeholder={
                  ['multiselect', 'dropdown_multi'].includes(servar.type)
                    ? 'Option 1, Option 2'
                    : ''
                }
              />
            </Col>
          </Row>
        </CollapsibleSection>
      )}

      {NUMBER_FIELDS.includes(servar.type) && (
        <CollapsibleSection title='Default Value'>
          <Row>
            <Col>
              <NumberInput
                disabled={!editLinkedAllowed}
                min={0}
                value={servarMeta.default_value ?? ''}
                onComplete={({ value }: any) =>
                  handleServarMetadataChange({ default_value: value })
                }
                triggerCleanUp
              />
            </Col>
          </Row>
        </CollapsibleSection>
      )}

      {servar.type === 'date_selector' && (
        <CollapsibleSection title='Default Value'>
          <Row>
            <Col>
              <CheckboxField
                disabled={!editLinkedAllowed}
                checked={servarMeta.default_date_today}
                text='Today'
                onChange={(checked) =>
                  handleServarMetadataChange({
                    default_date_today: checked
                  })
                }
              />
            </Col>
          </Row>
        </CollapsibleSection>
      )}

      {['integer_field', 'text_field'].includes(servar.type) && (
        <CollapsibleSection
          title={
            <>
              Format{' '}
              {servar.type === 'text_field' && (
                <InlineTooltip text="Guiding text surrounding the field's input value" />
              )}
            </>
          }
        >
          {servar.type === 'integer_field' && (
            <Row>
              <Col>
                <DropdownField
                  disabled={!editLinkedAllowed}
                  selected={servar.format}
                  onChange={(e: any) =>
                    handleServarChange({ format: e.target.value })
                  }
                  options={[
                    { value: '', display: 'None' },
                    { value: 'currency', display: 'Currency' }
                  ]}
                />
              </Col>
            </Row>
          )}
          {servar.type === 'text_field' && (
            <>
              <Row>
                <Col sm='2'>
                  <PropertyLabel label='Prefix' />
                </Col>
                <Col>
                  <TextField
                    disabled={!editLinkedAllowed}
                    value={servarMeta.prefix ?? ''}
                    onComplete={(prefix: any) =>
                      handleServarMetadataChange({ prefix })
                    }
                    triggerCleanUp
                  />
                </Col>
                <Col sm='2'>
                  <PropertyLabel label='Suffix' indented />
                </Col>
                <Col>
                  <TextField
                    disabled={!editLinkedAllowed}
                    value={servarMeta.suffix ?? ''}
                    onComplete={(suffix: any) =>
                      handleServarMetadataChange({ suffix })
                    }
                    triggerCleanUp
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PropertyLabel label='Browser Autocomplete' />
                </Col>
                <Col>
                  <DropdownField
                    disabled={!editLinkedAllowed}
                    selected={servarMeta.custom_autocomplete ?? ''}
                    onChange={(e: any) =>
                      handleServarMetadataChange({
                        custom_autocomplete: e.target.value
                      })
                    }
                    options={[
                      { value: '', display: 'None' },
                      ...htmlAutocompleteOptions
                    ]}
                  />
                </Col>
              </Row>
            </>
          )}
        </CollapsibleSection>
      )}

      {ACTION_FIELDS.includes(servar.type) && (
        <CollapsibleSection title='Actions'>
          {AUTO_SUBMITTABLE_FIELDS.includes(servar.type) && (
            <Row>
              <Col>
                <CheckboxField
                  text={
                    <>
                      <PropertyLabel
                        label='Autosubmit'
                        {...labelData('submit_trigger')}
                      />
                      <InlineTooltip text='Automatically submit when field is filled' />
                    </>
                  }
                  disabled={servarMeta.multiple}
                  checked={element.properties.submit_trigger === 'auto'}
                  onChange={(isChecked) =>
                    handleInheritedPropChange({
                      submit_trigger: isChecked ? 'auto' : 'none'
                    })
                  }
                />
              </Col>
            </Row>
          )}
        </CollapsibleSection>
      )}

      <CollapsibleSection title='Accessibility' expanded={false}>
        <Row>
          <Col sm='4'>
            <PropertyLabel label='Aria Label' {...labelData('aria_label')} />
          </Col>
          <Col>
            <TextField
              value={element.properties.aria_label ?? ''}
              onComplete={(label: any) =>
                handleInheritedPropChange({
                  aria_label: label
                })
              }
              triggerCleanUp
            />
          </Col>
        </Row>
      </CollapsibleSection>

      {isEnterprise && (
        <CollapsibleSection
          title={
            <>
              Semantic Meaning
              <InlineTooltip text='Associate this field with meaning to tie user data together across systems' />
            </>
          }
          expanded={!!quikConfig}
        >
          {quikConfig}
          <Row>
            <Col sm='4'>
              <PropertyLabel
                label={
                  <>
                    Entity{' '}
                    <InlineTooltip text='The object that this field describes' />
                  </>
                }
              />
            </Col>
            <Col>
              <DropdownField
                disabled={!editLinkedAllowed}
                selected={servar.semantic_entity}
                onChange={(e: any) =>
                  handleServarChange({
                    semantic_entity: e.target.value
                  })
                }
                options={[
                  { value: '', display: 'None' },
                  { value: 'applicant', display: 'Form Applicant' }
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='4'>
              <PropertyLabel
                label={
                  <>
                    Attribute{' '}
                    <InlineTooltip text='The attribute that this field describes' />
                  </>
                }
              />
            </Col>
            <Col>
              <DropdownField
                disabled={!editLinkedAllowed}
                selected={servar.semantic_attribute}
                onChange={(e: any) =>
                  handleServarChange({
                    semantic_attribute: e.target.value
                  })
                }
                options={[
                  { value: '', display: 'None' },
                  { value: 'first_name', display: 'First Name' },
                  { value: 'last_name', display: 'Last Name' },
                  { value: 'email', display: 'Email' },
                  { value: 'phone', display: 'Phone' }
                ]}
              />
            </Col>
          </Row>
          {isAutofillPluginEnabled && (
            <>
              <Row>
                <Col sm='4'>
                  <PropertyLabel
                    label={
                      <>
                        Description{' '}
                        <InlineTooltip text='The semantic meaning of this field. Required when using the Feathery form autofill plugin.' />
                      </>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    type='textarea'
                    rows={4}
                    value={servar.semantic_description ?? ''}
                    onComplete={(val: string) => {
                      handleServarChange({ semantic_description: val });
                    }}
                    triggerCleanUp
                  />
                </Col>
              </Row>
            </>
          )}
        </CollapsibleSection>
      )}
      {mode === 'builder' && (
        <>
          <CustomValidationsSection
            element={element}
            customValidations={(element as any).validations}
            handleUpdates={handleUpdates}
          />
          <HideIfsSection
            element={element}
            hideIfs={(element as any).hide_ifs}
            showLogic={element.show_logic}
            handleUpdates={handleUpdates}
          />
        </>
      )}

      {(OPTION_FIELDS.includes(servar.type) ||
        servar.type === 'text_field') && (
        <CollapsibleSection
          title={
            servar.type === 'text_field'
              ? 'Custom Autocomplete Options'
              : servar.type === 'matrix'
              ? 'Options (Columns)'
              : 'Options'
          }
        >
          {OTHER_FIELDS.includes(servar.type) && (
            <>
              <Row>
                <Col>
                  <CheckboxField
                    disabled={!editLinkedAllowed}
                    checked={servarMeta.other}
                    text={
                      <>
                        Enable &quot;Other&quot; Option
                        <InlineTooltip text='Adds an "Other" option for custom values not included in the existing options' />
                      </>
                    }
                    onChange={(isChecked) =>
                      handleServarMetadataChange({ other: isChecked })
                    }
                  />
                </Col>
              </Row>
              {servarMeta.other && (
                <Row
                  className='other-tooltip'
                  style={{
                    paddingBottom: '6px'
                  }}
                >
                  <Col sm='3'>
                    <PropertyLabel
                      label='Tooltip'
                      style={{ fontWeight: 200, paddingLeft: '10px' }}
                    />
                  </Col>
                  <Col>
                    <TextField
                      disabled={!editLinkedAllowed}
                      placeholder='Tooltip'
                      className='field-options-input'
                      defaultValue={servarMeta.other_tooltip ?? ''}
                      onComplete={(val: string) =>
                        handleServarMetadataChange({ other_tooltip: val })
                      }
                      triggerCleanUp
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
          {servar.type === 'dropdown_multi' && (
            <Row>
              <Col>
                <CheckboxField
                  disabled={!editLinkedAllowed}
                  checked={servarMeta.creatable_options}
                  text={
                    <>
                      Allow New Options
                      <InlineTooltip text='User can add options not specified here' />
                    </>
                  }
                  onChange={(isChecked) =>
                    handleServarMetadataChange({ creatable_options: isChecked })
                  }
                />
              </Col>
            </Row>
          )}
          {servarMeta.questions && (
            <ContextMenu
              ref={questionMenuRef}
              show={showQuestionMenu}
              close={() => setQuestionMenuPosition({})}
              position={questionMenuPosition as any}
              actions={[
                {
                  onMouseDown: () => {
                    const defaultVal =
                      servarMeta.questions[activeQuestionIndex].default_value;
                    handleQuestionChange(
                      'default_value',
                      activeQuestionIndex
                    )(defaultVal === null ? '' : null);
                  },
                  title:
                    servarMeta.questions[activeQuestionIndex].default_value ===
                    null
                      ? 'Set Default Value'
                      : 'Clear Default Value',
                  Icon: ({ color }: any) => (
                    <InfoIcon width={16} height={16} color={color} />
                  )
                },
                {
                  onMouseDown: () => {
                    const tooltip =
                      servarMeta.questions[activeQuestionIndex].tooltip;
                    handleQuestionChange(
                      'tooltip',
                      activeQuestionIndex
                    )(tooltip === null ? '' : null);
                  },
                  title:
                    servarMeta.questions[activeQuestionIndex].tooltip === null
                      ? 'Add Tooltip'
                      : 'Remove Tooltip',
                  Icon: ({ color }: any) => (
                    <InfoIcon width={16} height={16} color={color} />
                  )
                },
                {
                  onMouseDown: () => {
                    const highlight =
                      servarMeta.questions[activeQuestionIndex].highlight_color;
                    handleQuestionChange(
                      'highlight_color',
                      activeQuestionIndex
                    )(highlight === null ? 'FFFF00FF' : null);
                  },
                  title: !servarMeta.questions[activeQuestionIndex]
                    .highlight_color
                    ? 'Set Highlight'
                    : 'Clear Highlight',
                  Icon: ({ color }: any) => (
                    <ColorPickerIcon width={16} height={16} color={color} />
                  )
                },
                {
                  onMouseDown: () => {
                    const readOnly =
                      servarMeta.questions[activeQuestionIndex].read_only;
                    handleQuestionChange(
                      'read_only',
                      activeQuestionIndex
                    )(readOnly === null ? true : null);
                  },
                  title:
                    servarMeta.questions[activeQuestionIndex].read_only === null
                      ? 'Set Read Only'
                      : 'Clear Read Only',
                  Icon: ({ color }: any) => (
                    <InfoIcon width={16} height={16} color={color} />
                  )
                },
                activeQuestionIndex !== 0 && {
                  onMouseDown: moveQuestion(true),
                  title: 'Move Up',
                  Icon: ({ color }: any) => (
                    <RightArrowIcon width={16} height={16} color={color} />
                  )
                },
                activeQuestionIndex < servarMeta.questions.length - 1 && {
                  onMouseDown: moveQuestion(false),
                  title: 'Move Down',
                  Icon: ({ color }: any) => (
                    <RightArrowIcon width={16} height={16} color={color} />
                  )
                },
                {
                  onMouseDown: deleteQuestion,
                  title: 'Delete',
                  Icon: ({ color }: any) => (
                    <TrashIcon width={16} height={16} color={color} />
                  )
                }
              ]}
            />
          )}
          <ContextMenu
            ref={menuRef}
            show={showMenu}
            close={() => setMenuPosition({})}
            position={menuPosition as any}
            actions={[
              servar.type === 'button_group' && {
                onMouseDown: () =>
                  activeImage
                    ? selectOptionImage('')
                    : setOptionImageUpload(true),
                title: activeImage ? 'Remove Image' : 'Add Image',
                Icon: ({ color }: any) => (
                  <AddImageIcon width={16} height={16} color={color} />
                )
              },
              !['text_field', 'matrix'].includes(servar.type) && {
                onMouseDown: () => {
                  const label = servarMeta.option_labels[activeOptionIndex];
                  handleOptionValChange(
                    'option_labels',
                    activeOptionIndex
                  )(label === null ? '' : null);
                },
                title:
                  servarMeta.option_labels[activeOptionIndex] === null
                    ? 'Add Display Label'
                    : 'Remove Display Label',
                Icon: ({ color }: any) => (
                  <TextIcon width={16} height={16} color={color} />
                )
              },
              OPTION_FIELDS.includes(servar.type) && {
                onMouseDown: () => {
                  const label = servarMeta.option_tooltips[activeOptionIndex];
                  handleOptionValChange(
                    'option_tooltips',
                    activeOptionIndex
                  )(label === null ? '' : null);
                },
                title:
                  servarMeta.option_tooltips[activeOptionIndex] === null
                    ? 'Add Tooltip'
                    : 'Remove Tooltip',
                Icon: ({ color }: any) => (
                  <InfoIcon width={16} height={16} color={color} />
                )
              },
              activeOptionIndex !== 0 && {
                onMouseDown: moveOption(true),
                title: 'Move Up',
                Icon: ({ color }: any) => (
                  <RightArrowIcon width={16} height={16} color={color} />
                )
              },
              activeOptionIndex < servarMeta.options.length - 1 && {
                onMouseDown: moveOption(false),
                title: 'Move Down',
                Icon: ({ color }: any) => (
                  <RightArrowIcon width={16} height={16} color={color} />
                )
              },
              {
                onMouseDown: deleteOption,
                title: 'Delete',
                Icon: ({ color }: any) => (
                  <TrashIcon width={16} height={16} color={color} />
                )
              }
            ]}
          />
          {servar.type === 'button_group' && (
            <ImageUploadModal
              selectedImageId={activeImage}
              show={optionImageUpload}
              close={() => setOptionImageUpload(false)}
              onSelect={selectOptionImage}
              fileTypes={['image/*']}
            />
          )}
          {(servarMeta.options ?? []).map((option: any, index: any) => (
            <Fragment key={`option-${index}-${option}`}>
              <Row>
                <Col sm='3'>
                  <PropertyLabel label={`Option ${index + 1}`} />
                </Col>
                <Col>
                  <InputGroup>
                    <TextField
                      disabled={!editLinkedAllowed}
                      placeholder='Option'
                      className='field-options-input'
                      defaultValue={option}
                      onComplete={handleOptionValChange('options', index)}
                      triggerCleanUp
                    />
                    <button
                      className='field-icon-toggle field-options-end'
                      onClick={openMenu(index)}
                    >
                      <EditIcon width={18} height={18} />
                    </button>
                  </InputGroup>
                </Col>
              </Row>
              {servar.type !== 'text_field' &&
                typeof servarMeta.option_labels[index] === 'string' && (
                  <Row
                    className='field-options-display-input'
                    style={{
                      paddingBottom:
                        servarMeta.option_tooltips[index] ||
                        servarMeta.option_images[index]
                          ? 0
                          : '6px'
                    }}
                  >
                    <Col sm='3'>
                      <PropertyLabel
                        label='Display'
                        style={{ fontWeight: 200, paddingLeft: '10px' }}
                      />
                    </Col>
                    <Col>
                      <TextField
                        disabled={!editLinkedAllowed}
                        placeholder='Label'
                        className='field-options-input'
                        defaultValue={servarMeta.option_labels[index]}
                        onComplete={handleOptionValChange(
                          'option_labels',
                          index
                        )}
                        triggerCleanUp
                      />
                    </Col>
                  </Row>
                )}
              {typeof (servarMeta.option_tooltips ?? [])[index] ===
                'string' && (
                <Row
                  className='field-options-display-input'
                  style={{
                    paddingBottom: servarMeta.option_images[index] ? 0 : '6px'
                  }}
                >
                  <Col sm='3'>
                    <PropertyLabel
                      label='Tooltip'
                      style={{ fontWeight: 200, paddingLeft: '10px' }}
                    />
                  </Col>
                  <Col>
                    <TextField
                      disabled={!editLinkedAllowed}
                      placeholder='Tooltip'
                      className='field-options-input'
                      defaultValue={servarMeta.option_tooltips[index]}
                      onComplete={handleOptionValChange(
                        'option_tooltips',
                        index
                      )}
                      triggerCleanUp
                    />
                  </Col>
                </Row>
              )}
              {servar.type === 'button_group' &&
                servarMeta.option_images[index] && (
                  <Row
                    key={`option-image-${index}`}
                    style={{ justifyContent: 'end', paddingBottom: '6px' }}
                  >
                    <Col sm='3'>
                      <PropertyLabel
                        centered={false}
                        label='Image'
                        style={{ fontWeight: 200, paddingLeft: '10px' }}
                      />
                    </Col>
                    <Col>
                      <DisplayImage
                        imageId={servar.option_images[index]}
                        onDelete={() => selectOptionImage('', index)}
                      />
                      <ImageThumbnail
                        fileUrl={servarMeta.option_images[index]}
                        fileType='image/*'
                      />
                    </Col>
                  </Row>
                )}
            </Fragment>
          ))}
          <div className={styles.buttonGroupAddContainer}>
            {servar.type !== 'matrix' && (
              <BulkAddOptions
                disabled={!editLinkedAllowed}
                initOptions={servar.metadata.options}
                allowLabelValues={servar.type !== 'text_field'}
                setOptions={(
                  options: string[],
                  optionLabels: (string | null)[]
                ) => {
                  const imageMap: Record<string, string> = {},
                    tooltipMap: Record<string, string | null> = {};
                  const meta = servar.metadata;
                  (meta.options as string[]).forEach((option, i) => {
                    imageMap[option] = meta.option_images[i] || '';
                    tooltipMap[option] = meta.option_tooltips[i] || null;
                  });
                  const newOptionData =
                    servar.type === 'text_field'
                      ? { metadata: { options } }
                      : {
                          option_images: Array(options.length).fill(''),
                          metadata: {
                            options,
                            option_labels: optionLabels,
                            option_tooltips: options.map(
                              (opt) => tooltipMap[opt] || null
                            ),
                            option_images: options.map(
                              (opt) => imageMap[opt] || ''
                            )
                          }
                        };
                  handleServarChange(newOptionData);
                }}
              />
            )}
            <button
              onClick={addOption}
              className={classNames(
                'form-field-editor-btn-secondary',
                styles.addOption
              )}
            >
              Add option
            </button>
          </div>
        </CollapsibleSection>
      )}
      {servar.type === 'matrix' && (
        <CollapsibleSection title='Questions (Rows)'>
          {(servarMeta.questions ?? []).map((question: any, index: any) => {
            return (
              <Fragment key={`question-${index}-${question.label}`}>
                <Row>
                  <Col sm='2'>
                    <PropertyLabel label={`Q${index + 1}`} />
                  </Col>
                  <Col>
                    <InputGroup>
                      <TextField
                        disabled={!editLinkedAllowed}
                        placeholder='Question'
                        className='field-options-input'
                        defaultValue={question.label}
                        onComplete={handleQuestionChange('label', index)}
                        triggerCleanUp
                      />
                      <button
                        className='field-icon-toggle field-options-end'
                        onClick={openQuestionMenu(index)}
                      >
                        <EditIcon width={18} height={18} />
                      </button>
                    </InputGroup>
                  </Col>
                </Row>
                {typeof question.default_value === 'string' && (
                  <Row className='field-options-display-input'>
                    <Col sm='3'>
                      <PropertyLabel
                        label='Default Value'
                        style={{ fontWeight: 200, paddingLeft: '10px' }}
                      />
                    </Col>
                    <Col>
                      <TextField
                        disabled={!editLinkedAllowed}
                        placeholder={
                          servarMeta.multiple ? 'Option1, Option2' : 'Option1'
                        }
                        className='field-options-input'
                        defaultValue={question.default_value}
                        onComplete={handleQuestionChange(
                          'default_value',
                          index
                        )}
                        triggerCleanUp
                      />
                    </Col>
                  </Row>
                )}
                {typeof question.tooltip === 'string' && (
                  <Row className='field-options-display-input'>
                    <Col sm='3'>
                      <PropertyLabel
                        label='Tooltip'
                        style={{ fontWeight: 200, paddingLeft: '10px' }}
                      />
                    </Col>
                    <Col>
                      <TextField
                        disabled={!editLinkedAllowed}
                        placeholder='Tooltip'
                        className='field-options-input'
                        defaultValue={question.tooltip}
                        onComplete={handleQuestionChange('tooltip', index)}
                        triggerCleanUp
                      />
                    </Col>
                  </Row>
                )}
                {question.highlight_color && (
                  <Row className='field-options-display-input'>
                    <Col sm='3'>
                      <PropertyLabel
                        label='Highlight Color'
                        style={{ fontWeight: 200, paddingLeft: '10px' }}
                      />
                    </Col>
                    <Col>
                      <FColorPicker
                        value={question.highlight_color}
                        onChange={handleQuestionChange(
                          'highlight_color',
                          index
                        )}
                      />
                    </Col>
                  </Row>
                )}
                {typeof question.read_only === 'boolean' && (
                  <Row className='field-options-display-input'>
                    <Col sm='3' />
                    <Col>
                      <CheckboxField
                        disabled={!editLinkedAllowed}
                        checked={question.read_only}
                        text='Read Only'
                        onChange={handleQuestionChange('read_only', index)}
                      />
                    </Col>
                  </Row>
                )}
              </Fragment>
            );
          })}
          <button
            onClick={addQuestion}
            className={classNames(
              'form-field-editor-btn-secondary',
              styles.addOption
            )}
          >
            Add question
          </button>
        </CollapsibleSection>
      )}
    </>
  );
}

export default memo(FieldPropertiesPanel);
