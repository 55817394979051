import { useHistory } from 'react-router-dom';
import { Neutral, Positive } from '../../../components/Core/Button';
import Dialog from '../../../components/Dialog';
import { ErrorMarkerIcon } from '../../../components/Icons';
import '../../../style/dialog-form.css';
import ExtractionProperties from '../components/ExtractionProperties';
import QuestionGroup from '../components/QuestionGroup';
import styles from '../styles.module.scss';
import { useExtractionForm } from './useExtractionForm';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';

export default function ExtractionCreateEditModal({
  extraction,
  isOpen,
  close
}: any) {
  const history = useHistory();
  const onSubmit = () => {
    close();
  };
  const onDelete = () => {
    history.replace(`/ai`);
    close();
  };
  const {
    formData,
    accounts,
    fileOptions,
    usedFields,
    handleChange,
    handleSubmit,
    handleDelete,
    removeQuestionGroup,
    addQuestionGroup,
    updateQuestionGroup,
    moveQuestionGroupUp,
    moveQuestionGroupDown,
    error,
    loading,
    fieldProperties,
    hiddenFieldProperties,
    hiddenFieldOptions
  } = useExtractionForm(extraction, onSubmit, onDelete);

  const { lockOutFlag, lockoutFunction } =
    useModalSubmissionLockout(handleSubmit);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      title={extraction ? 'Edit Extraction' : 'Create Extraction'}
      size='lg'
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <ExtractionProperties
          formData={formData}
          handleChange={handleChange}
          accounts={accounts}
          fileOptions={fileOptions}
          hiddenFieldOptions={hiddenFieldOptions}
          usedFields={usedFields}
        />
        {formData.question_groups.map((group, index) => {
          return (
            <QuestionGroup
              key={group.id}
              index={index}
              group={group}
              formData={formData}
              usedFields={usedFields}
              fieldProperties={fieldProperties}
              hiddenFieldProperties={hiddenFieldProperties}
              onRemove={() => removeQuestionGroup(index)}
              onMoveUp={() => moveQuestionGroupUp(index)}
              onMoveDown={() => moveQuestionGroupDown(index)}
              onUpdate={(key: string, val: any) =>
                updateQuestionGroup(index, key, val)
              }
            />
          );
        })}
        <div
          onClick={() => addQuestionGroup()}
          className={styles.addGroupButton}
        >
          Add Query
        </div>
        {error && (
          <div className={styles.error}>
            <div className={styles.icon}>
              <ErrorMarkerIcon height={30} width={30} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>{error}</div>
              <div className={styles.message}>
                Please try again or contact support if the problem persists
              </div>
            </div>
          </div>
        )}
        <div className='dialog-form-action text-center'>
          {extraction && <Neutral onClick={handleDelete}>Delete</Neutral>}
          <Positive lockoutOverride={lockOutFlag} loading={loading}>
            {extraction ? 'Save' : 'Create'}
          </Positive>
        </div>
      </form>
    </Dialog>
  );
}
