import type { ComponentProps } from 'react';
import LockoutButton from './LockoutButton';

function Positive({
  children,
  ...rest
}: Omit<ComponentProps<typeof LockoutButton>, 'type'>) {
  // In a form, the positive button should be triggered on `Enter` being pressed
  return (
    <LockoutButton {...rest} type='submit'>
      {children}
    </LockoutButton>
  );
}

export default Positive;
