import { memo } from 'react';

import { CollapsibleSection } from '../../Core';
import { ElementSection } from '..';
import { Elements, StyledContainer } from '@feathery/react';
import assetStyles from './styles.module.scss';
import { calculateElementRenderData } from '../../../utils/step';
import elementStyles from '../ElementSection/styles.module.scss';
import classNames from 'classnames';
import { isDark } from '../../../utils/color';

type Props = {
  title?: string;
  label?: string;
  highlighted?: boolean;
  category: string;
  selectedItem?: {
    theme: string[];
    assetId?: string;
  };
  appliedStyle?: string[];
  mouseDownTheme?: () => void;
  elementData?: any;
  viewport?: any;
  component?: string;
  assets?: any[];
  children?: React.ReactNode;
  overflow?: boolean;
  background: string;
  isElement?: boolean;
  style?: React.CSSProperties;
  theme?: any;
  onMouseDownAsset?: (assetId: string) => void;
  onMouseDown?: () => void;
  getTextCallbacks?: (asset: any) => any;
};

/**
 * Renders a section of assets, optionally with an element.
 */
export function ElementAssetSection({
  title,
  label,
  highlighted,
  category,
  selectedItem,
  appliedStyle,
  mouseDownTheme = () => {},
  elementData = {},
  viewport,
  component = '',
  assets = [],
  children,
  overflow = true,
  background = 'ffffff',
  isElement = false,
  style = {},
  theme,
  onMouseDownAsset,
  onMouseDown,
  getTextCallbacks = () => {}
}: Props): React.ReactElement {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const ElementComponent = Elements[component];

  if (isElement) {
    const elementRenderData = {
      ...calculateElementRenderData({
        theme,
        viewport,
        element: elementData,
        style: [category]
      }),
      isElement: true
    };

    return (
      <div className={assetStyles.elementAssetSectionContainer}>
        <div className={assetStyles.borderOnly} style={{ marginTop: '8px' }}>
          <ElementSection
            label={title}
            showLabel
            category={category}
            onMouseDown={mouseDownTheme}
            className={`${appliedStyle?.[0] === category && assetStyles.addBg}`}
            highlighted={
              selectedItem?.theme && selectedItem?.theme[0] === category
            }
            background={background}
          >
            <StyledContainer
              node={elementRenderData}
              viewport={viewport}
              viewportOnly={true}
            >
              <ElementComponent
                element={elementRenderData}
                editMode='editable'
              />
            </StyledContainer>
          </ElementSection>
          {assets.length > 0 && (
            <CollapsibleSection
              title='Assets'
              hasAssets
              collapsible={assets.length > 0}
              resetHeaderStyle
              isAssetSelected={appliedStyle?.[0] === category}
              mouseDownTheme={() => {
                onMouseDownAsset && onMouseDownAsset(assets[0].id);
              }}
              customClasses={
                isDark(background)
                  ? { header: elementStyles.darkSectionHeader }
                  : {}
              }
            >
              <div className={assetStyles.assetContainer}>
                {assets.map((asset) => {
                  const focused = selectedItem?.assetId === asset.id;
                  const assetRenderData = {
                    ...calculateElementRenderData({
                      theme,
                      viewport,
                      element: asset,
                      style: [category]
                    }),
                    isElement: true
                  };

                  return (
                    <div
                      key={asset.key}
                      id={`asset-section-${asset.key}`}
                      className={classNames(
                        elementStyles.elementSection,
                        focused && elementStyles.highlighted,
                        onMouseDownAsset && elementStyles.highlightable,
                        overflow && elementStyles.overflow
                      )}
                      style={style}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                        onMouseDownAsset && onMouseDownAsset(asset.id);
                      }}
                    >
                      <StyledContainer
                        node={assetRenderData}
                        viewport={viewport}
                        viewportOnly={true}
                      >
                        <ElementComponent
                          element={assetRenderData}
                          textCallbacks={getTextCallbacks(asset)}
                          editMode='editable'
                          focused={focused}
                        />
                      </StyledContainer>
                    </div>
                  );
                })}
              </div>
            </CollapsibleSection>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      id={`asset-section-${category}`}
      className={classNames(
        elementStyles.elementSection,
        highlighted && elementStyles.highlighted,
        onMouseDown && elementStyles.highlightable,
        overflow && elementStyles.overflow
      )}
      style={style}
      onMouseDown={(event) => {
        event.stopPropagation();
        onMouseDown && onMouseDown();
      }}
    >
      {label && <div className={elementStyles.header}>{label}</div>}
      {children}
    </div>
  );
}

export default memo(ElementAssetSection);
