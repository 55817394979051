import type { Custom } from './types';

export const CUSTOM_TEMPLATE: Custom = {
  id: 'custom',
  display: 'Custom Connector',
  defaultState: {
    name: 'Custom Connector',
    template: '',
    url: '',
    method: 'GET',
    headers: {},
    body: '',
    params: {},
    tokens: {},
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  }
} as const;
