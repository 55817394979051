import { Neutral, Positive } from '../Core/Button';
import { memo } from 'react';

import Dialog from '../Dialog';

const PublishThemeModal = ({
  show,
  setShow,
  themeId,
  onPublish,
  panels
}: any) => {
  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      shouldShowCloseIcon={false}
      title='Changes you made will be applied to these forms'
    >
      <div>
        <ul style={{ paddingLeft: 20 }}>
          {Object.values(panels)?.map((panel) => {
            if ((panel as any).theme === themeId) {
              return <li key={(panel as any).id}>{(panel as any).key}</li>;
            }
            return null;
          })}
        </ul>
      </div>
      <div className='dialog-form-action text-center'>
        <Neutral onClick={() => setShow(false)}>Cancel</Neutral>
        <Positive
          onClick={async () => {
            await onPublish();
            setShow(false);
          }}
        >
          Publish
        </Positive>
      </div>
    </Dialog>
  );
};

export default memo(PublishThemeModal);
