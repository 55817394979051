import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SettingsContainer from '../../components/SettingsContainer';
import AINavigation from '../../components/NavBar/AINavigation';
import SideNavigation from '../../components/SideNavigation';
import { useAppSelector } from '../../hooks';
import { PUBLISH_STATUS } from '../../redux/utils';
import { useExtractionForm } from '../AIExtractionListPage/ExtractionCreateEditModal/useExtractionForm';
import styles from './styles.module.scss';
import { PublishButton } from '../../components/Core/Button';

import ExtractionProperties from '../AIExtractionListPage/components/ExtractionProperties';
import QuestionGroup from '../AIExtractionListPage/components/QuestionGroup';
import useFeatheryRedux from '../../redux';

export type FormData = {
  key: string;
  file_sources: string[];
  question_groups: any[];
  file_type: string;
  reviewers: string[];
};

function AIExtractionSettingsPage() {
  const {
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();
  const [publishStatus, setPublishStatus] = useState(PUBLISH_STATUS.FULFILLED);
  const { extractionId } = useParams<{ extractionId: string }>();
  const extraction = useAppSelector((state) =>
    state.ai.extractions.find((extraction) => extraction.id === extractionId)
  );

  const onSubmit = () => {
    setPublishStatus(PUBLISH_STATUS.FULFILLED);
    addInfoToast('Your settings have been saved.');
  };

  const onChange = () => {
    setPublishStatus(PUBLISH_STATUS.ACTIVE);
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    error,
    accounts,
    usedFields,
    fileOptions,
    fieldProperties,
    hiddenFieldProperties,
    hiddenFieldOptions,
    addQuestionGroup,
    removeQuestionGroup,
    moveQuestionGroupUp,
    moveQuestionGroupDown,
    updateQuestionGroup
  } = useExtractionForm(extraction, onSubmit, undefined, onChange);

  useEffect(() => {
    if (error) {
      setPublishStatus(PUBLISH_STATUS.ACTIVE);
      addErrorToast({
        title: 'Please try again or contact support if the problem persists'
      });
    }
  }, [error]);

  return (
    <>
      <AINavigation
        activeItem='settings'
        submitButton={
          <PublishButton
            status={publishStatus}
            onPublish={handleSubmit}
            saveFlag
          />
        }
      />
      <div className={styles.settingsPage}>
        <SideNavigation title='Settings'>
          <SettingsContainer title='Configuration'>
            <ExtractionProperties
              formData={formData}
              handleChange={handleChange}
              accounts={accounts}
              fileOptions={fileOptions}
              hiddenFieldOptions={hiddenFieldOptions}
              usedFields={usedFields}
            />
            {formData.question_groups.map((group, index) => {
              return (
                <QuestionGroup
                  key={group.id}
                  index={index}
                  group={group}
                  usedFields={usedFields}
                  formData={formData}
                  fieldProperties={fieldProperties}
                  hiddenFieldProperties={hiddenFieldProperties}
                  onRemove={() => removeQuestionGroup(index)}
                  onMoveUp={() => moveQuestionGroupUp(index)}
                  onMoveDown={() => moveQuestionGroupDown(index)}
                  onUpdate={(key: string, val: any) =>
                    updateQuestionGroup(index, key, val)
                  }
                />
              );
            })}
            <div
              onClick={() => addQuestionGroup()}
              className={styles.addGroupButton}
            >
              Add Query
            </div>
          </SettingsContainer>
        </SideNavigation>
      </div>
    </>
  );
}

export default memo(AIExtractionSettingsPage);
