function Apollo({ width = 72, height = 72 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 150 150'
    >
      <path fill='none' d='M0,0H150V150H0V0Z' />
      <path d='M125.76,36.74h-20.67l10.71,17.77,9.95-17.77Z' />
      <path d='M79.37,12.07L2.37,139.79c63.25,.69,57.86,4.98,89.59-50.17l-11.71-19.42-25.45,42.43c-3.46,5.94-10.61,8.33-17.23,7.74L79.39,51.02l53.53,88.77h15.18v-14.14S89.42,28.67,79.37,12.07Z' />
    </svg>
  );
}

export default Apollo;
