import * as Icons from '../../Icons';
import {
  BORDER_PROPS,
  cellOperation,
  HOVER_BORDER_PROPS,
  SELECTED_BORDER_PROPS
} from '../../../utils/themes';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import {
  BorderStyleInput,
  CheckboxField,
  CollapsibleSection,
  CornerStyleInput,
  DropdownField,
  FColorPicker,
  NumberInput,
  PropertyLabel,
  RadioButtonGroup
} from '../../Core';
import BackgroundImageSection from '../Sections/BackgroundImageSection';
import {
  Viewport,
  ViewportType
} from '../../RenderingEngine/GridInGrid/engine/viewports';
import { ShadowStyleSection } from './components';
import { AXIS, SIZE_UNITS } from '../../../utils/constants';
import { useEffect, useMemo } from 'react';
import {
  AxisSize,
  Cell,
  parseUnit,
  parseValue
} from '../../RenderingEngine/GridInGrid/engine';
import { isOverride as isOverrideStyle } from '../utils';
import { Model } from '../../RenderingEngine/GridInGrid/engine/models';
import useAxisResize from '../../RenderingEngine/utils/useAxisResize';
import BoxSpacingInput from '../../Core/BoxSpacingInput';
import { TYPE_CONTAINER } from '../../../utils/elements';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../Core/Tabs';

const isOverride = (
  cell: Cell,
  props: string[],
  viewport: ViewportType,
  theme: any
) => {
  if (props[0] === 'width') {
    const viewportWidth = (cell.model as Model).getKeyOnAllViewports('width');

    return (
      viewportWidth[Viewport.Mobile] &&
      viewportWidth[Viewport.Mobile] !== viewportWidth[Viewport.Desktop]
    );
  } else if (props[0] === 'height') {
    const viewportHeight = (cell.model as Model).getKeyOnAllViewports('height');

    return (
      viewportHeight[Viewport.Mobile] &&
      viewportHeight[Viewport.Mobile] !== viewportHeight[Viewport.Desktop]
    );
  } else if (props[0] === 'spacing') {
    const spacingValues = ['space-between', 'space-around', 'space-evenly'];
    const viewportStyles = cell.getStyle(true);
    const desktopStyles = viewportStyles[Viewport.Desktop];
    const mobileStyles = viewportStyles[Viewport.Mobile];

    const desktopSpacing =
      cell.axis === AXIS.ROW
        ? desktopStyles.vertical_align
        : desktopStyles.horizontal_align;

    const mobileSpacing =
      cell.axis === AXIS.ROW
        ? mobileStyles.vertical_align
        : mobileStyles.horizontal_align;

    const desktopHasSpacing = spacingValues.includes(desktopSpacing);
    const mobileHasSpacing = spacingValues.includes(mobileSpacing);

    if (!mobileHasSpacing && !desktopHasSpacing) return false;
    if (desktopHasSpacing && !mobileHasSpacing) return true;
    if (!desktopHasSpacing && mobileHasSpacing) return true;
    if (desktopHasSpacing && mobileHasSpacing)
      return desktopSpacing !== mobileSpacing;
  } else if (
    cell.isRoot() &&
    props[0] === 'background_color' &&
    viewport === Viewport.Desktop
  ) {
    const viewportStyles = cell.getStyle(false);
    const { background_color } = viewportStyles;
    const { step_background_color } = theme;
    return background_color && step_background_color !== background_color;
  } else {
    const viewportStyles = cell.getStyle(true);

    return isOverrideStyle(
      viewportStyles[Viewport.Desktop],
      viewportStyles[Viewport.Mobile],
      props,
      true
    );
  }
};

export type ContainerStylePanelProps = {
  theme: any;
  viewport: ViewportType;
  handleUpdates: any;
  cellNode: any;
};

const ContainerStylePanel = ({
  theme,
  viewport,
  handleUpdates,
  cellNode
}: ContainerStylePanelProps) => {
  const colAxisResize = useAxisResize(cellNode, AXIS.COL);
  const rowAxisResize = useAxisResize(cellNode, AXIS.ROW);

  const isRoot = cellNode?.isRoot();

  const [width, widthUnit, height, heightUnit] = useMemo(() => {
    const width = cellNode.width ?? '';
    const height = cellNode.height ?? '';
    return [
      parseValue(width),
      parseUnit(width),
      parseValue(height),
      parseUnit(height)
    ];
  }, [cellNode]);

  const containerStyles = isRoot
    ? {
        ...FeatheryConfig.default_container_styles,
        background_color: theme.step_background_color,
        ...cellNode.getStyle()
      }
    : {
        ...FeatheryConfig.default_container_styles,
        ...cellNode.getStyle()
      };

  const canBeFixed = useMemo(() => {
    const FIXED_UNITS = [SIZE_UNITS.PX, SIZE_UNITS.FILL];

    const isHeightAllowed = FIXED_UNITS.includes(heightUnit);
    const isWidthAllowed = FIXED_UNITS.includes(widthUnit);

    return isHeightAllowed && isWidthAllowed;
  }, [cellNode, heightUnit, widthUnit]);

  const handleStyleChange = (styleUpdate: any) => {
    handleUpdates([cellOperation({ viewport, styleUpdate })]);
  };

  useEffect(() => {
    if (!canBeFixed && containerStyles.position) {
      handleStyleChange({ position: '' });
    }
  }, [canBeFixed, containerStyles.position]);

  const labelData = (...props: string[]) => {
    const key = viewport === Viewport.Mobile ? 'Desktop' : 'Theme';
    return {
      highlighted: isOverride(cellNode, props, viewport, theme),
      themeOperations: {
        instance: {
          label: `Reset to ${key}`,
          operation: () => {
            if (props[0] === 'width') colAxisResize();
            else if (props[0] === 'height') rowAxisResize();
            else if (props[0] === 'spacing') {
              handleUpdates([
                cellOperation({
                  viewport,
                  styleReset: ['vertical_align', 'horizontal_align']
                })
              ]);
            } else {
              handleUpdates([cellOperation({ viewport, styleReset: props })]);
            }
          }
        }
      }
    };
  };

  let fixedComponent = (
    <DropdownField
      selected={canBeFixed ? containerStyles.position ?? '' : ''}
      onChange={(event: any) => {
        handleStyleChange({
          position: event.target.value
        });
      }}
      options={[
        { value: '', display: 'None' },
        { value: 'fixed', display: 'Fixed' }
      ]}
      disabled={!canBeFixed}
    />
  );
  if (!canBeFixed)
    fixedComponent = (
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip id='position-tooltip'>
            The position can&apos;t be changed if height or width is <b>fit</b>
          </Tooltip>
        }
      >
        <div style={{ width: '100%' }}>{fixedComponent}</div>
      </OverlayTrigger>
    );

  return (
    <>
      <CollapsibleSection title='Layout' collapsible>
        <Row>
          <Col sm='3'>
            <PropertyLabel label='Width' {...labelData('width')} />
          </Col>
          <Col>
            <NumberInput
              required
              min={0}
              value={width}
              unit={widthUnit}
              units={[SIZE_UNITS.PX, SIZE_UNITS.FILL, SIZE_UNITS.FIT]}
              onComplete={(axisSize: AxisSize) =>
                colAxisResize(axisSize, widthUnit)
              }
              triggerCleanUp
              dimension='width'
            />
          </Col>
          <Col sm='3'>
            <PropertyLabel label='Height' indented {...labelData('height')} />
          </Col>
          <Col>
            <NumberInput
              required
              min={0}
              value={height}
              unit={heightUnit}
              units={[SIZE_UNITS.PX, SIZE_UNITS.FILL, SIZE_UNITS.FIT]}
              onComplete={(axisSize: AxisSize) =>
                rowAxisResize(axisSize, heightUnit)
              }
              triggerCleanUp
              dimension='height'
            />
          </Col>
        </Row>
        <BoxSpacingInput
          elementStyle={containerStyles}
          handleStyleChange={handleStyleChange}
          labelData={labelData}
          elementType={TYPE_CONTAINER}
        />
        {!isRoot && (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Align Self'
                {...labelData('layout', 'vertical_layout')}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={containerStyles.layout}
                name='button-editor-horizontal-layout'
                onChange={(value: any) => handleStyleChange({ layout: value })}
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.COL}
                allowUnselect
                hideValueIfDisabled
                options={[
                  {
                    value: 'flex-start',
                    display: <Icons.HorizontalLeftIcon />
                  },
                  {
                    value: 'center',
                    display: <Icons.HorizontalCenterIcon />
                  },
                  {
                    value: 'flex-end',
                    display: <Icons.HorizontalRightIcon />
                  }
                ]}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={containerStyles.vertical_layout}
                name='button-editor-vertical-layout'
                allowUnselect
                onChange={(value: any) =>
                  handleStyleChange({ vertical_layout: value })
                }
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.ROW}
                hideValueIfDisabled
                options={[
                  { value: 'flex-start', display: <Icons.VerticalTopIcon /> },
                  { value: 'center', display: <Icons.VerticalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.VerticalBottomIcon /> }
                ]}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm='3'>
            <PropertyLabel
              label='Align Content'
              {...labelData('vertical_align', 'horizontal_align')}
            />
          </Col>
          <Col>
            <RadioButtonGroup
              selected={containerStyles.horizontal_align}
              name='button-editor-horizontal-alignment'
              onChange={(value: any) =>
                handleStyleChange({ horizontal_align: value })
              }
              options={[
                {
                  value: 'flex-start',
                  display: <Icons.HorizontalLeftIcon />
                },
                {
                  value: 'center',
                  display: <Icons.HorizontalCenterIcon />
                },
                {
                  value: 'flex-end',
                  display: <Icons.HorizontalRightIcon />
                }
              ]}
            />
          </Col>
          <Col>
            <RadioButtonGroup
              selected={containerStyles.vertical_align}
              name='button-editor-vertical-alignment'
              onChange={(value: any) =>
                handleStyleChange({ vertical_align: value })
              }
              options={[
                { value: 'flex-start', display: <Icons.VerticalTopIcon /> },
                { value: 'center', display: <Icons.VerticalCenterIcon /> },
                { value: 'flex-end', display: <Icons.VerticalBottomIcon /> }
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <PropertyLabel label='Spacing' {...labelData('spacing')} />
          </Col>
          <Col sm='6'>
            <DropdownField
              key={
                cellNode.axis === AXIS.ROW
                  ? containerStyles.vertical_align
                  : containerStyles.horizontal_align
              }
              selected={
                cellNode.axis === AXIS.ROW
                  ? containerStyles.vertical_align
                  : containerStyles.horizontal_align
              }
              placeholder='None'
              allowNoSelection
              onChange={(event: any) => {
                const alignProperty =
                  cellNode.axis === AXIS.ROW
                    ? 'vertical_align'
                    : 'horizontal_align';

                handleStyleChange({
                  [alignProperty]: event.target.value
                });
              }}
              options={[
                { value: undefined, display: 'Default' },
                { value: 'space-between', display: 'Space between' },
                { value: 'space-around', display: 'Space around' },
                { value: 'space-evenly', display: 'Space evenly' }
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <PropertyLabel label='Overflow' />
          </Col>
          <Col sm='4'>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id='overflow-tooltip'>
                  The overflow style only appears on the actual form, not the
                  editor
                </Tooltip>
              }
            >
              <DropdownField
                selected={containerStyles.overflow ?? ''}
                placeholder='Default'
                allowNoSelection
                onChange={(event: any) => {
                  handleStyleChange({
                    overflow: event.target.value
                  });
                }}
                options={[
                  { value: '', display: 'Default' },
                  { value: 'scroll', display: 'Scroll' },
                  { value: 'hidden', display: 'Hide' }
                ]}
                style={{ width: '85%' }}
              />
            </OverlayTrigger>
          </Col>
          <Col sm='2'>
            <PropertyLabel label='Gap' {...labelData('gap')} />
          </Col>
          <Col sm='3'>
            <NumberInput
              placeholder='0'
              value={containerStyles.gap}
              min={0}
              units={['px']}
              onComplete={({ value }: any) => handleStyleChange({ gap: value })}
            />
          </Col>
        </Row>
        {!isRoot && (
          <Row>
            <Col sm='3'>
              <PropertyLabel label='Position' {...labelData('position')} />
            </Col>
            <Col sm='6'>{fixedComponent}</Col>
          </Row>
        )}
        <Row>
          <Col sm='3'>
            <PropertyLabel label='Visibility' />
          </Col>
          <Col>
            <RadioButtonGroup
              selected={containerStyles.visibility}
              name='container-editor-visibility'
              onChange={(value: any) =>
                handleStyleChange({ visibility: value })
              }
              options={[
                {
                  value: 'visible',
                  display: <Icons.ShowIcon width={18} height={18} />
                },
                {
                  value: 'hidden',
                  display: <Icons.HideIcon width={18} height={18} />
                }
              ]}
            />
          </Col>
        </Row>
      </CollapsibleSection>

      <CollapsibleSection title='Background' collapsible>
        <BackgroundImageSection
          imageId={containerStyles.background_image_id}
          styles={containerStyles || {}}
          onStyleChange={handleStyleChange}
          labelData={labelData}
        />
      </CollapsibleSection>

      <CollapsibleSection title='Corners' collapsible>
        <Row>
          <Col>
            <CornerStyleInput
              labelData={labelData(
                'corner_top_left_radius',
                'corner_top_right_radius',
                'corner_bottom_left_radius',
                'corner_bottom_right_radius'
              )}
              topLeft={containerStyles.corner_top_left_radius}
              topRight={containerStyles.corner_top_right_radius}
              bottomLeft={containerStyles.corner_bottom_left_radius}
              bottomRight={containerStyles.corner_bottom_right_radius}
              onComplete={(newCorners: any) => {
                handleStyleChange({
                  corner_top_left_radius: newCorners.topLeft,
                  corner_top_right_radius: newCorners.topRight,
                  corner_bottom_left_radius: newCorners.bottomLeft,
                  corner_bottom_right_radius: newCorners.bottomRight
                });
              }}
            />
          </Col>
        </Row>
      </CollapsibleSection>

      <ShadowStyleSection
        labelData={labelData}
        styles={containerStyles}
        handleStyleChange={handleStyleChange}
      />

      <Tabs defaultValue='DefaultStyles'>
        <TabsList unstyled className='legacyTabsList'>
          <TabsTrigger
            unstyled
            className='legacyTabsTriggerFlex'
            value='DefaultStyles'
          >
            Default
          </TabsTrigger>
          <TabsTrigger
            unstyled
            className='legacyTabsTriggerFlex'
            value='HoverStyles'
          >
            Hover
          </TabsTrigger>
          {isRoot && (
            <TabsTrigger
              unstyled
              className='legacyTabsTriggerFlex'
              value='SelectedStyles'
            >
              Selected
            </TabsTrigger>
          )}
        </TabsList>

        <TabsContent
          unstyled
          className='legacyTabsContent'
          value='DefaultStyles'
          style={{ padding: '0 1em' }}
        >
          <Row>
            <Col sm='5'>
              <PropertyLabel
                label='Background'
                {...labelData('background_color')}
              />
            </Col>
            <Col>
              <FColorPicker
                value={
                  containerStyles.background_color ??
                  theme.step_background_color
                }
                colorPickerStyle={{ left: '-30px' }}
                onChange={(color: any) => {
                  handleStyleChange({ background_color: color });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='5'>
              <CheckboxField
                style={{ marginLeft: '5px' }}
                checked={!!containerStyles.gradient_color}
                onChange={(value) =>
                  handleStyleChange({
                    gradient_color: value ? '000000FF' : ''
                  })
                }
                text={
                  <PropertyLabel
                    label='Gradient'
                    {...labelData('gradient_color')}
                  />
                }
              />
            </Col>
            {containerStyles.gradient_color && (
              <Col>
                <FColorPicker
                  value={containerStyles.gradient_color}
                  onChange={(color: any) =>
                    handleStyleChange({ gradient_color: color })
                  }
                  colorPickerStyle={{ left: '-30px' }}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <PropertyLabel label='Border' {...labelData(...BORDER_PROPS)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <BorderStyleInput
                elementStyles={containerStyles ?? {}}
                onComplete={handleStyleChange}
              />
            </Col>
          </Row>
        </TabsContent>
        <TabsContent
          unstyled
          className='legacyTabsContent'
          value='HoverStyles'
          style={{ padding: '0 1em' }}
        >
          <Row>
            <Col sm='5'>
              <CheckboxField
                checked={!!containerStyles.hover_background_color}
                onChange={(value) =>
                  handleStyleChange({
                    hover_background_color: value ? '000000FF' : ''
                  })
                }
                text={
                  <PropertyLabel
                    label='Background'
                    {...labelData('hover_background_color')}
                  />
                }
              />
            </Col>
            {containerStyles.hover_background_color && (
              <Col>
                <FColorPicker
                  value={containerStyles.hover_background_color}
                  onChange={(color: any) =>
                    handleStyleChange({ hover_background_color: color })
                  }
                  colorPickerStyle={{ left: '-30px' }}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col sm='5'>
              <CheckboxField
                checked={!!containerStyles.hover_border_top_color}
                onChange={(value) =>
                  handleStyleChange({
                    hover_border_top_color: value ? '000000FF' : '',
                    hover_border_right_color: value ? '000000FF' : '',
                    hover_border_bottom_color: value ? '000000FF' : '',
                    hover_border_left_color: value ? '000000FF' : '',
                    hover_border_top_width: 0,
                    hover_border_right_width: 0,
                    hover_border_bottom_width: 0,
                    hover_border_left_width: 0,
                    hover_border_top_pattern: value ? 'solid' : '',
                    hover_border_right_pattern: value ? 'solid' : '',
                    hover_border_bottom_pattern: value ? 'solid' : '',
                    hover_border_left_pattern: value ? 'solid' : ''
                  })
                }
                text={
                  <PropertyLabel
                    label='Border'
                    {...labelData(...HOVER_BORDER_PROPS)}
                  />
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {!!containerStyles.hover_border_top_color && (
                <BorderStyleInput
                  elementStyles={containerStyles ?? {}}
                  onComplete={handleStyleChange}
                  prefix='hover_'
                />
              )}
            </Col>
          </Row>
        </TabsContent>
        {isRoot && (
          <TabsContent
            unstyled
            className='legacyTabsContent'
            value='SelectedStyles'
            style={{ padding: '0 1em' }}
          >
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!containerStyles.selected_background_color}
                  onChange={(value) =>
                    handleStyleChange({
                      selected_background_color: value ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Background'
                      {...labelData('selected_background_color')}
                    />
                  }
                />
              </Col>
              {containerStyles.selected_background_color && (
                <Col>
                  <FColorPicker
                    value={containerStyles.selected_background_color}
                    onChange={(color: any) =>
                      handleStyleChange({ selected_background_color: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!containerStyles.selected_border_top_color}
                  onChange={(value) =>
                    handleStyleChange({
                      selected_border_top_color: value ? '000000FF' : '',
                      selected_border_right_color: value ? '000000FF' : '',
                      selected_border_bottom_color: value ? '000000FF' : '',
                      selected_border_left_color: value ? '000000FF' : '',
                      selected_border_top_width: 0,
                      selected_border_right_width: 0,
                      selected_border_bottom_width: 0,
                      selected_border_left_width: 0,
                      selected_border_top_pattern: value ? 'solid' : '',
                      selected_border_right_pattern: value ? 'solid' : '',
                      selected_border_bottom_pattern: value ? 'solid' : '',
                      selected_border_left_pattern: value ? 'solid' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Border'
                      {...labelData(...SELECTED_BORDER_PROPS)}
                    />
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {!!containerStyles.selected_border_top_color && (
                  <BorderStyleInput
                    elementStyles={containerStyles ?? {}}
                    onComplete={handleStyleChange}
                    prefix='selected_'
                  />
                )}
              </Col>
            </Row>
          </TabsContent>
        )}
      </Tabs>
    </>
  );
};

export default ContainerStylePanel;
