import { ErrorMarkerIcon } from '../Icons';
import styles from './styles.module.scss';
import { cn } from '../../utils/cn';

type Props = {
  error?: string;
  className?: string;
};

const ErrorCard = ({ error, className }: Props) => {
  return (
    <div className={cn(styles.infoCard, styles.white, styles.flex, className)}>
      <div
        className={cn(
          styles.icon,
          styles.errorIcon,
          styles.error,
          styles.white
        )}
      >
        <ErrorMarkerIcon width={30} height={30} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>Error</div>
        <div className={styles.text}>
          {error || 'Something went wrong. Please contact support.'}
        </div>
      </div>
    </div>
  );
};

export default ErrorCard;
