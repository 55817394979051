function Addepar({ width = 560, height = 400 }) {
  return (
    <svg
      clipRule='evenodd'
      fillRule='evenodd'
      strokeLinejoin='round'
      strokeMiterlimit='2'
      viewBox='0 0 560 400'
      width={width}
      height={height}
    >
      <g
        fillRule='nonzero'
        transform='matrix(1.55979 0 0 1.55979 6.33449 123.492)'
      >
        <path d='m131 42.2-3.5 8.6h7.1zm8.1 19.7-2.4-6h-11.2l-2.4 6h-6l11.4-26.7h5.4l11.4 26.7z' />
        <path d='m170.3 48.7c0-4.7-3.2-8-8-8h-4.5v16h4.5c4.7 0 8-3.3 8-8zm-8 13.2h-10.3v-26.5h10.3c8.3 0 14.1 5.7 14.1 13.2v.1c0 7.4-5.8 13.2-14.1 13.2' />
        <path d='m203 48.7c0-4.7-3.2-8-8-8h-4.5v16h4.5c4.8 0 8-3.3 8-8zm-8 13.2h-10.3v-26.5h10.3c8.3 0 14.1 5.7 14.1 13.2v.1c0 7.4-5.8 13.2-14.1 13.2' />
        <path d='m237.8 61.9h-20.2v-26.5h20.1v5.2h-14.3v5.4h12.5v5.2h-12.5v5.5h14.4z' />
        <path d='m262.7 44.7c0-2.6-1.8-4-4.7-4h-4.5v8.1h4.6c2.9 0 4.6-1.8 4.6-4.1zm-4.8 9.3h-4.4v8h-5.8v-26.6h10.8c6.3 0 10.2 3.8 10.2 9.2v.1c-.1 6.1-4.8 9.3-10.8 9.3' />
        <path d='m285.9 42.2-3.5 8.6h7.1zm8 19.7-2.4-6h-11.2l-2.4 6h-6l11.4-26.7h5.4l11.3 26.7z' />
        <path d='m323.3 44.5c0-2.5-1.7-3.8-4.6-3.8h-5.7v7.6h5.9c2.8 0 4.4-1.5 4.4-3.8zm-.1 17.4-5.7-8.5h-4.5v8.5h-5.8v-26.5h12.1c6.3 0 10 3.3 10 8.8v.1c0 4.3-2.3 7-5.7 8.2l6.5 9.5h-6.9z' />
        <path d='m83.4 20.2-36.5 57.7h-26.1l36.6-57.7z' fill='#88817f' />
        <path d='m64 50.8 17.1 27.1h26l-17-27.1z' />
      </g>
    </svg>
  );
}

export default Addepar;
