function Workiz({ width = 230, height = 72 }) {
  return (
    <img
      alt='Workiz logo'
      src={require('./Workiz.jpg')}
      style={{ width, height }}
    />
  );
}

export default Workiz;
