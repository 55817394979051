import { memo } from 'react';

import useFeatheryRedux from '../../redux';
import Dialog from '../Dialog';
import { Negative, Neutral } from '../Core/Button';

function DeleteEnvelopeModal({ show, close, envelopeId, signer }: any) {
  const { deleteEnvelope } = useFeatheryRedux();

  return (
    <Dialog
      isOpen={show}
      onClose={() => close()}
      shouldShowCloseIcon={false}
      title={`Are you sure you want to delete the envelope sent to ${signer}?`}
      alignCenter
      style={{ padding: '24px 40px' }}
    >
      <div className='dialog-form-action'>
        <Neutral onClick={() => close()}>Cancel</Neutral>
        <Negative
          onClick={async (event: any) => {
            event.preventDefault();
            await deleteEnvelope({ envelopeId });
            close();
          }}
        >
          Yes, Delete Envelope
        </Negative>
      </div>
    </Dialog>
  );
}

export default memo(DeleteEnvelopeModal);
