function Productboard({ width = 84, height = 84 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 -44 256 256'
      preserveAspectRatio='xMidYMid'
    >
      <g>
        <polygon
          fill='#FF2638'
          points='85.326959 83.9967251 170.653918 167.99345 0 167.99345'
        ></polygon>
        <polygon
          fill='#FFC600'
          points='0 0 85.326959 83.9967251 170.653918 0'
        ></polygon>
        <polygon
          fill='#0079F2'
          points='85.3406727 83.9967251 170.667632 167.99345 255.994591 83.9967251 170.667632 0'
        ></polygon>
      </g>
    </svg>
  );
}

export default Productboard;
