function Freshmarketer({ width = 1028, height = 160 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='-153 -46 652 652'
      enableBackground='new -153 -46 652 652'
      xmlSpace='preserve'
      width={width}
      height={height}
    >
      <g>
        <path
          fill='#4D4D4D'
          d='M-14.4,229.2c-12.1,0-16.4,8.4-16.4,16.3v4.8h-2.5c-1.9,0-3.3,1.5-3.3,3.3c0,1.9,1.5,3.3,3.3,3.3h2.5v33.3   c0,2,1.5,3.5,3.5,3.5s3.5-1.5,3.5-3.5v-33.3h7.8c1.9,0,3.3-1.5,3.3-3.3c0-1.9-1.5-3.3-3.3-3.3h-7.8v-4.8c0-4.4,1.6-9.7,9.4-9.7   c1.8,0,3.3-1.4,3.3-3.3c0,0,0,0,0-0.1C-11,230.7-12.5,229.2-14.4,229.2C-14.3,229.2-14.3,229.2-14.4,229.2 M13,249.2   c-5.2,0-9.7,2.1-12.1,5.7v-1.8c0-2-1.5-3.5-3.5-3.5c-2,0-3.5,1.5-3.5,3.5v37.1c0,2,1.5,3.5,3.5,3.5c2,0,3.5-1.5,3.5-3.5v-20.5   c0-8.6,4.5-13.7,12.1-13.7c1.9,0,3.4-1.5,3.4-3.4C16.4,250.7,14.9,249.2,13,249.2 M37.9,255.5c7.2,0,11,4.1,11.1,12.1H25.6   C26.3,259.8,30.7,255.5,37.9,255.5 M55.9,267.4c0-2-0.3-7.2-3.4-11.6c-3.1-4.5-8.1-6.8-14.7-6.8c-12.3,0-19.4,8.2-19.4,22.5   c0,14.8,7,22.6,20.3,22.6c8.9,0,13.8-4.4,14.7-5.3c0.6-0.6,1.2-1.3,1.2-2.5c0-1.8-1.4-3.2-3.2-3.2c-0.9,0-1.4,0.3-2,0.7   c-2.6,1.9-5.5,3.8-10.6,3.8c-8,0-13-5.3-13.2-13.8h26.1C55.9,273.9,55.9,270,55.9,267.4 M79.6,268c-5.2-1.8-9.8-3.4-9.8-7.3   c0-4.3,4.7-4.9,7.6-4.9c5.1,0,7.6,1.8,9.6,3.7c0.7,0.7,1.4,1,2.3,1c1.8,0,3.2-1.4,3.2-3.2c0-1-0.4-1.6-0.8-2.1   c-3-3.9-8.1-6.1-14.4-6.1c-9,0-14.6,4.5-14.6,11.6c0,8.8,7.7,11.4,14.5,13.7c5.1,1.7,9.6,3.2,9.6,7c0,5.2-5.7,5.9-9.1,5.9   c-4.6,0-8.3-1.4-11.2-4.3c-0.9-0.9-1.8-1.1-2.4-1.1c-1.8,0-3.2,1.4-3.2,3.2c0,0.7,0.2,1.3,0.8,2.1c2.7,3.4,8.7,6.8,16.1,6.8   c10.1,0,16.1-4.7,16.1-12.7C93.9,272.9,86.3,270.3,79.6,268'
        />
        <g transform='translate(97)'>
          <defs>
            <filter
              id='Adobe_OpacityMaskFilter'
              filterUnits='userSpaceOnUse'
              x='5.6'
              y='226.7'
              width='36'
              height='67'
            >
              <feColorMatrix
                type='matrix'
                values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0'
              />
            </filter>
          </defs>
          <mask
            maskUnits='userSpaceOnUse'
            x='5.6'
            y='226.7'
            width='36'
            height='67'
            id='b_1_'
          >
            <g filter='url(#Adobe_OpacityMaskFilter)'>
              <path
                id='a_1_'
                fill='#FFFFFF'
                d='M5.6,226.7h36v67h-36L5.6,226.7L5.6,226.7z'
              />
            </g>
          </mask>
          <path
            mask='url(#b_1_)'
            fill='#4D4D4D'
            d='M24.2,249c-4.8,0-9.2,1.9-11.6,5v-23.8c0-2-1.5-3.5-3.5-3.5c-2,0-3.5,1.5-3.5,3.5v60c0,2,1.5,3.5,3.5,3.5    s3.5-1.5,3.5-3.5v-22.6c0-8.1,3.9-12.2,11.6-12.2c7.4,0,10.4,3.4,10.4,11.6v23.2c0,2,1.5,3.5,3.5,3.5c2,0,3.5-1.5,3.5-3.5v-24.4    C41.6,254.7,35.8,249,24.2,249'
          />
        </g>
        <path
          fill='#4D4D4D'
          d='M194.7,249c-6,0-10.5,2-13.4,6c-2.7-3.8-8-6-14.6-6c-5.3,0-9.2,2.3-11.3,5v-0.9c0-2-1.5-3.5-3.5-3.5   c-2,0-3.5,1.5-3.5,3.5v37.2c0,2,1.5,3.5,3.5,3.5c2,0,3.5-1.5,3.5-3.5v-22.6c0-8.3,3.4-12.2,10.7-12.2c7.6,0,10.7,3.4,10.7,11.6   v23.2c0,2,1.5,3.5,3.5,3.5c2,0,3.5-1.5,3.5-3.5v-24.4c0-6.8,3.8-10.4,10.9-10.4c7.5,0,10.9,3.6,10.9,11.6v23.2c0,2,1.5,3.5,3.5,3.5   c2,0,3.5-1.5,3.5-3.5v-24.4C212.6,258.1,209.5,249,194.7,249 M236.5,287.7c-4.5,0-9.9-1.3-9.9-7.2c0-6.3,5.9-7.2,9.5-7.2h12.3v3.3   C248.5,284.2,242.3,287.7,236.5,287.7 M238.3,249c-9.5,0-13.9,4.7-15.7,7.5c-0.4,0.6-0.5,1.2-0.5,1.8c0,1.8,1.4,3.2,3.2,3.2   c1.1,0,1.8-0.4,2.8-1.5l0,0c2.8-3.2,5.7-4.5,10.2-4.5c6.4,0,10.2,3.6,10.2,9.5v1.9h-12.3c-10.2,0-16.6,5.2-16.6,13.5   c0,8.5,6.1,13.7,16.4,13.7c5.3,0,9.9-2,12.5-5.3v1.4c0.1,1.9,1.6,3.4,3.5,3.3c1.8,0,3.3-1.5,3.3-3.3v-25.2   C255.3,255.2,248.8,249,238.3,249 M284.3,249.2c-5.2,0-9.7,2.1-12.1,5.7v-1.8c0-2-1.5-3.5-3.5-3.5c-2,0-3.5,1.5-3.5,3.5v37.1   c0,2,1.5,3.5,3.5,3.5c2,0,3.5-1.5,3.5-3.5v-20.5c0-8.6,4.5-13.7,12.1-13.7c1.9,0,3.4-1.5,3.4-3.4   C287.7,250.7,286.2,249.2,284.3,249.2'
        />
        <g transform='translate(176)'>
          <defs>
            <filter
              id='Adobe_OpacityMaskFilter_1_'
              filterUnits='userSpaceOnUse'
              x='117.4'
              y='226.7'
              width='34.3'
              height='67'
            >
              <feColorMatrix
                type='matrix'
                values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0'
              />
            </filter>
          </defs>
          <mask
            maskUnits='userSpaceOnUse'
            x='117.4'
            y='226.7'
            width='34.3'
            height='67'
            id='d_1_'
          >
            <g filter='url(#Adobe_OpacityMaskFilter_1_)'>
              <path
                id='c_1_'
                className='st2'
                d='M151.7,226.7h-34.3v67h34.3V226.7z'
              />
            </g>
          </mask>
          <path
            mask='url(#d_1_)'
            fill='#4D4D4D'
            d='M135,267.5l14.6-11.6c0.9-0.7,1.4-1.7,1.4-2.8c0-2-1.5-3.5-3.5-3.5c-0.6,0-1.5,0.1-2.4,0.9l-20.7,16.9v-37.1    c0-2-1.5-3.5-3.5-3.5s-3.5,1.5-3.5,3.5v60c0,2,1.5,3.5,3.5,3.5s3.5-1.5,3.5-3.5v-14.5l4.9-3.9l15.8,20.3l0,0    c0.9,1.1,1.8,1.6,3,1.6c2,0,3.5-1.5,3.5-3.5c0-0.6-0.1-1.4-0.8-2.2C150.9,288,135,267.5,135,267.5z'
          />
        </g>
        <path
          fill='#4D4D4D'
          d='M351.5,255.5c7.2,0,11,4.1,11.1,12.1h-23.4C340,259.8,344.3,255.5,351.5,255.5 M369.6,267.4   c0-2-0.3-7.2-3.3-11.6c-3.1-4.5-8.1-6.8-14.7-6.8c-12.3,0-19.4,8.2-19.4,22.5c0,14.8,7,22.6,20.3,22.6c8.9,0,13.8-4.4,14.7-5.3   c0.6-0.6,1.2-1.3,1.2-2.5c0-1.8-1.4-3.2-3.2-3.2c-0.9,0-1.4,0.3-2,0.7c-2.6,1.9-5.5,3.8-10.6,3.8c-8,0-13-5.3-13.2-13.8h26.1   C369.6,273.9,369.6,270,369.6,267.4 M392.2,256.9c1.8,0,3.3-1.4,3.3-3.3c0,0,0,0,0-0.1c0-1.8-1.4-3.3-3.3-3.3c0,0,0,0-0.1,0h-5.4   v-9c0-1.9-1.6-3.5-3.5-3.5c-1.9,0-3.5,1.6-3.5,3.5v9h-3c-1.9,0-3.3,1.5-3.3,3.3c0,1.9,1.5,3.3,3.3,3.3h3v24.7   c0,7.4,4.6,12.1,11.7,12.1c1.9,0,3.4-1.5,3.4-3.3c0-1.9-1.5-3.3-3.4-3.3c-3,0-4.7-2-4.7-5.5v-24.7H392.2L392.2,256.9z M419.7,255.5   c7.2,0,11,4.1,11.1,12.1h-23.4C408.1,259.8,412.5,255.5,419.7,255.5 M437.8,267.4c0-2-0.3-7.2-3.3-11.6c-3.1-4.5-8.1-6.8-14.7-6.8   c-12.3,0-19.4,8.2-19.4,22.5c0,14.8,7,22.6,20.3,22.6c8.9,0,13.8-4.4,14.7-5.3c0.6-0.6,1.2-1.3,1.2-2.5c0-1.8-1.4-3.2-3.2-3.2   c-0.9,0-1.4,0.3-2,0.7c-2.6,1.9-5.5,3.8-10.6,3.8c-8,0-13-5.3-13.2-13.8h26.1C437.8,273.9,437.8,270,437.8,267.4 M464.8,249.2   c-5.2,0-9.7,2.1-12.1,5.7v-1.8c0-2-1.5-3.5-3.5-3.5c-2,0-3.5,1.5-3.5,3.5v37.1c0,2,1.5,3.5,3.5,3.5c2,0,3.5-1.5,3.5-3.5v-20.5   c0-8.6,4.5-13.7,12.1-13.7c1.9,0,3.4-1.5,3.4-3.4C468.2,250.7,466.7,249.2,464.8,249.2'
        />
        <g transform='translate(0 5)'>
          <defs>
            <filter
              id='Adobe_OpacityMaskFilter_2_'
              filterUnits='userSpaceOnUse'
              x='-131.8'
              y='235.9'
              width='82.3'
              height='82.4'
            >
              <feColorMatrix
                type='matrix'
                values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0'
              />
            </filter>
          </defs>
          <mask
            maskUnits='userSpaceOnUse'
            x='-131.8'
            y='235.9'
            width='82.3'
            height='82.4'
            id='f_1_'
          >
            <g filter='url(#Adobe_OpacityMaskFilter_2_)'>
              <path
                id='e_1_'
                fill='#FFFFFF'
                d='M-49.4,318.3h-82.3v-82.4h82.3V318.3z'
              />
            </g>
          </mask>
          <path
            mask='url(#f_1_)'
            fill='#EE5A24'
            d='M-90.6,318.3c-22.7,0-41.2-18.4-41.2-41.2s18.4-41.2,41.2-41.2h30.9c5.7,0,10.3,4.6,10.3,10.3v30.9    C-49.4,299.9-67.9,318.3-90.6,318.3'
          />
        </g>
        <path
          fill='#FFFFFF'
          d='M-70.7,280.1c0,3.5-1,6.3-2.3,6.3c-1.3,0-2.3-2.8-2.3-6.3c0-3.5,1-6.4,2.3-6.4   C-71.8,273.8-70.7,276.6-70.7,280.1 M-79.6,280.1c0-6.9,0.9-12.8,2.3-15.9c0.1-0.3,0-0.7-0.3-0.8c-0.2-0.1-0.4-0.1-0.5,0l-15.5,7.6   c-0.8,0.4-1.7,0.6-2.6,0.7l-4.8,0.5c-4.3,0.5-7.5,4.4-7,8.8c0.4,3.7,3.3,6.5,7,7l0.3,0l1.6,8.8c0.4,1.6,2,2.7,3.6,2.3   c1.5-0.3,2.6-1.8,2.4-3.4l-1.2-6.8c0.2,0.1,0.5,0.2,0.7,0.3l15.5,7.6c0.3,0.1,0.7,0,0.8-0.3c0.1-0.2,0.1-0.4,0-0.5   C-78.7,293-79.6,287-79.6,280.1'
        />
      </g>
    </svg>
  );
}

export default Freshmarketer;
