/* eslint-disable react-hooks/exhaustive-deps */

import '../../style/dialog-form.css';

import FormFieldEditor from './FormFieldEditor';
import { CollapsibleSection, SectionHeader } from '../Core';
import FontStyleSection from '../Panels/StylePanel/components/FontStyleSection';
import { elementOperation } from '../../utils/themes';
import { calculateOverrideObjects } from '../Panels/utils';

function ThemeGlobalEditor({
  mode,
  viewport,
  theme,
  baseProps,
  baseStyle,
  overrideProps,
  overrideStyle,
  handleUpdates
}: any) {
  const { result: elementStyle } = calculateOverrideObjects(
    baseStyle,
    overrideStyle
  );

  function handleStyleChange(styleUpdate: any) {
    handleUpdates([elementOperation({ viewport, styleUpdate })]);
  }

  return (
    <FormFieldEditor mode='theme'>
      <SectionHeader title='Global Styles' />
      <CollapsibleSection title='Typography' collapsible>
        <FontStyleSection
          mode={mode}
          viewport={viewport}
          baseProps={baseProps}
          overrideProps={overrideProps}
          baseStyle={baseStyle}
          overrideStyle={overrideStyle}
          handleUpdates={handleUpdates}
          theme={theme}
          handleCustomStyleChange={handleStyleChange}
          customValues={{
            line_height: elementStyle.line_height,
            text_align: elementStyle.text_align
          }}
        />
      </CollapsibleSection>
    </FormFieldEditor>
  );
}

export default ThemeGlobalEditor;
