import classNames from 'classnames';
import styles from '../styles.module.scss';
import { Card, Tag } from '../../Core';
import { CheckIcon } from '../../Icons';
import { INTEGRATIONS } from '../types';
import useIntegrations from '../useIntegrations';
import { useParams } from 'react-router-dom';

function IntegrationCard({
  className,
  integrationType,
  onClick,
  logo,
  title,
  description,
  tags,
  isSelected
}: any) {
  const { formId, extractionId } =
    useParams<{ formId: string; extractionId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: integrationType,
    panelId: formId,
    extractionId,
    entityType: formId ? 'form' : 'ai'
  });

  return (
    <Card
      className={classNames(
        className,
        styles.integrationCard,
        integrationType && styles.hover,
        isSelected && styles.integrationCardActive
      )}
      onClick={onClick}
    >
      <div className={styles.integrationCardBody}>
        <div className={styles.integrationLogoColumn}>
          <div className={styles.logoContainer}>{logo}</div>
        </div>
        <div className={styles.integrationInfoColumn}>
          <span className={styles.integrationTitleText}>{title}</span>
          <span className={styles.integrationDescriptionText}>
            {description}
          </span>
          <div className={styles.tagsContainer}>
            {tags.map((tag: any) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </div>
        </div>
        {INTEGRATIONS.ZAPIER !== integrationType && (
          <div className={styles.integrationStatusColumn}>
            {integration ? (
              <div className={styles.connectedStatus}>
                <CheckIcon height={24} width={24} /> Connected
              </div>
            ) : (
              <div className={styles.disconnectedStatus}>Not Connected</div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

export default IntegrationCard;
