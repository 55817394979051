import '../../../../style/dialog-form.css';
import { Neutral, Positive } from '../../../Core/Button';
import { DropdownField, DropdownMultiField, TextField } from '../../../Core';
import Label from '../../../Dialog/Label';
import dialogStyles from '../../../Dialog/styles.module.scss';
import { useAppSelector } from '../../../../hooks';
import testIds from '../../../../utils/testIds';

export default function CreateFeatheryForm({
  isFinServTemplate,
  changeStep,
  formData,
  handleChange,
  error,
  lockOutFlag,
  lockoutFunction
}: any) {
  const themes = useAppSelector((s) => s.themes.themes);
  const finserveTemplates = useAppSelector(
    (s) => s.themes.templates.finserve_templates
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        return lockoutFunction();
      }}
    >
      {isFinServTemplate && (
        <>
          <Label>Forms</Label>
          <DropdownMultiField
            selected={formData.finserve_templates}
            placeholder='Select Templates'
            onChange={(items: { value: string }[]) =>
              handleChange({
                finserve_templates: items.map((item) => item.value)
              })
            }
            options={finserveTemplates.map((template) => ({
              label: `${template.name} (${template.quik_form_id})`,
              value: template.name
            }))}
          />
        </>
      )}
      <Label>Name</Label>
      <TextField
        autoFocus
        value={formData.key}
        placeholder='My Form'
        onComplete={(key: any) => handleChange({ key })}
        className={dialogStyles.input}
        style={isFinServTemplate ? { marginBottom: '100px' } : {}}
      />
      {!isFinServTemplate && (
        <>
          <Label>Theme</Label>
          <DropdownField
            disabled={Boolean(formData.template)}
            className={dialogStyles.input}
            selected={formData.theme}
            onChange={(event: any) =>
              handleChange({ theme: event.target.value })
            }
            options={[
              {
                value: '',
                display: formData.template
                  ? `${formData.template} Theme`
                  : '+ Create New Theme'
              },
              ...Object.values(themes).map(({ id, key }) => ({
                value: id,
                display: key
              }))
            ]}
          />
        </>
      )}
      <div className='dialog-form-action text-center'>
        <Neutral onClick={() => changeStep(1)}>Back</Neutral>
        <Positive
          data-testid={testIds.createFormDialogSubmit}
          lockoutOverride={lockOutFlag}
        >
          Create
        </Positive>
      </div>
      {error && <div>{error}</div>}
    </form>
  );
}
