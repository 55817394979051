import { memo, useCallback, useMemo, useState } from 'react';
import useFeatheryRedux from '../../redux';

import '../../style/dialog-form.css';
import { Positive } from '../Core/Button';
import Dialog from '../Dialog';
import Label from '../Dialog/Label';
import { TextField } from '../Core';
import styles from '../Dialog/styles.module.scss';
import useDialogFocus from '../../utils/useDialogFocus';
import useModalSubmissionLockout from '../../utils/useModalSubmissionLockout';

function CreateThemeModal({ show, setShow, themes }: any) {
  const { createTheme } = useFeatheryRedux();
  const focusElement = useDialogFocus(show);

  const [themeKey, setThemeKey] = useState('');
  const [error, setError] = useState('');

  const themeKeys = useMemo(
    () => Object.values(themes).map((t) => (t as any).key),
    [themes]
  );

  const submit = useCallback(() => {
    if (themeKey === '' || themeKeys.includes(themeKey)) {
      setError('You need to provide a unique theme name');
      return;
    }

    return createTheme({ themeId: themeKey })
      .then(() => {
        setThemeKey('');
        setError('');
        setShow(false);
      })
      .catch((e: any) => setError(e.message));
  }, [themeKeys, themeKey]);

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  return (
    <Dialog
      isOpen={show}
      title='Create a Theme'
      size='xsm'
      onClose={() => {
        setShow(false);
        setThemeKey('');
        setError('');
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <Label>Name</Label>
        <TextField
          ref={focusElement}
          value={themeKey}
          placeholder='My Form'
          onComplete={setThemeKey}
          className={styles.input}
        />
        <div className='dialog-form-action'>
          <Positive lockoutOverride={lockOutFlag}>Create</Positive>
        </div>
      </form>
      {error && <div>{error}</div>}
    </Dialog>
  );
}

export default memo(CreateThemeModal);
