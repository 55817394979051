/* eslint-disable react-hooks/exhaustive-deps */

import '../../style/dialog-form.css';

import { useEffect, useRef } from 'react';
import useFeatheryRedux from '../../redux';
import { FieldPropertiesPanel, FieldStylePanel } from '../Panels';
import { EDITOR_TABS } from '../../types/formBuilder';

import FormFieldEditor from './FormFieldEditor';

import { cellOperation, elementOperation, getAsset } from '../../utils/themes';
import { calculateOverrideObjects } from '../Panels/utils';
import FieldIdToggle from './FieldIdToggle';
import { useAppSelector } from '../../hooks';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../Core/Tabs';

function ServarFieldEditor({
  mode,
  viewport,
  theme,
  baseProps,
  baseStyle,
  overrideProps,
  overrideStyle,
  cellNode,
  handleUpdates,
  onClose,
  isAsset
}: any) {
  const activeTab = useAppSelector((state) => state.formBuilder.activeTab);
  const editorBodyReference = useRef(null);

  const { result: element } = calculateOverrideObjects(
    baseProps,
    overrideProps
  );
  const servar = element.servar ?? {};
  const fieldType = servar.type;
  const {
    formBuilder: { selectTab }
  } = useFeatheryRedux();
  const asset = getAsset(theme, fieldType, element.source_asset);

  useEffect(() => {
    const options = servar.metadata?.options;
    // scroll to end of the editor only when adding an option
    if (options) {
      if (editorBodyReference.current && options[options.length - 1] === '') {
        (editorBodyReference.current as any).scrollTop = (
          editorBodyReference.current as any
        )?.scrollHeight;
      }
    }
  }, [servar.metadata?.options]);

  const onDelete = () => {
    onClose();
    handleUpdates([
      mode === 'builder'
        ? cellOperation({ viewport, remove: true })
        : elementOperation({ viewport, remove: true, asset })
    ]);
  };

  const commonProps = {
    fieldType,
    mode,
    viewport,
    theme,
    baseProps,
    baseStyle,
    overrideProps,
    overrideStyle,
    handleUpdates,
    cellNode
  };

  const servarProps = {
    element,
    handleUpdates,
    viewport,
    mode,
    asset
  };

  return (
    <FormFieldEditor
      mode={mode}
      onDelete={onDelete}
      ref={editorBodyReference}
      element={element}
      elementType={servar.type}
      assetName={asset?.key}
      fieldIdToggle={<FieldIdToggle key='field-id-toggle' {...servarProps} />}
      cellNode={cellNode}
    >
      {(mode === 'builder' || isAsset) && (
        <Tabs value={activeTab} onValueChange={(tab) => selectTab(tab)}>
          <TabsList className='legacyTabsList' unstyled>
            <TabsTrigger
              className='legacyTabsTriggerFlex'
              value={EDITOR_TABS.PROPERTIES}
              unstyled
            >
              Properties
            </TabsTrigger>
            <TabsTrigger
              className='legacyTabsTriggerFlex'
              value={EDITOR_TABS.STYLES}
              unstyled
            >
              Style
            </TabsTrigger>
          </TabsList>
          <TabsContent
            unstyled
            className='legacyTabsContent'
            value={EDITOR_TABS.PROPERTIES}
          >
            <FieldPropertiesPanel {...commonProps} />
          </TabsContent>
          <TabsContent
            unstyled
            className='legacyTabsContent'
            value={EDITOR_TABS.STYLES}
          >
            <FieldStylePanel {...commonProps} isAsset={isAsset} />
          </TabsContent>
        </Tabs>
      )}
      {mode === 'theme' && !isAsset && <FieldStylePanel {...commonProps} />}
    </FormFieldEditor>
  );
}

export default ServarFieldEditor;
