import { useMemo, useRef, useState } from 'react';

import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import { DropdownField, DropdownMenu, PropertyLabel, TextField } from '../Core';
import { objectApply } from '../../utils/core';
import { defaultServarProps } from '../../utils/step';
import { elementOperation } from '../../utils/themes';
import useFeatheryRedux from '../../redux';

import styles from './styles.module.scss';
import formFieldStyles from './formFieldEditor.module.scss';
import classNames from 'classnames';
import { ChevronDownIcon, LinkIcon, UnlinkIcon } from '../Icons';
import { fieldTypeNameMap } from '../../utils/elements';
import { FieldLinkWarningModal } from '../Modals';
import { useAppSelector } from '../../hooks';

const FieldIdToggle = ({
  element,
  handleUpdates,
  viewport,
  mode,
  asset
}: any) => {
  const servar = element.servar ?? {};
  const fieldType = servar.type;

  const {
    formBuilder: { addChangedServar },
    toasts: { addInfoToast }
  } = useFeatheryRedux();
  const allServars = useAppSelector((state) => state.formBuilder.servars);
  const servarUsage = useAppSelector((state) => state.formBuilder.servarUsage);
  const editLinkedAllowed = useAppSelector((state) => {
    const account = state.accounts.account;
    return (
      account.role === 'admin' || account.permission_edit_linked_properties
    );
  });
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const [showLinkModal, setShowLinkModal] = useState(false);
  const activeStep = workingSteps[activeStepId];

  const stepFields = activeStep.servar_fields;
  const servarSwapOptions: any[] = useMemo(() => {
    if (mode !== 'builder') return [];

    const excludedServars = new Set(
      stepFields.map((field: any) => field.servar.id)
    );
    return Object.values(allServars)
      .filter(
        (s) =>
          (s as any).type === fieldType && !excludedServars.has((s as any).id)
      )
      .sort((a: any, b: any) => (a.key > b.key ? 1 : -1));
  }, [allServars, fieldType, mode, stepFields]);

  const allowedTypeChanges =
    FeatheryConfig.allowed_field_type_changes[fieldType] ?? [];

  const dropdownRef = useRef(null);
  const [show, setShow] = useState(false);
  const [editDisabled, setEditDisabled] = useState(true);

  function handlePropChange(propUpdate: any) {
    handleUpdates([elementOperation({ viewport, propUpdate })]);
  }

  function linkToServar(servar: any) {
    // reset and set servar to linked servar
    handleUpdates([
      elementOperation({
        viewport,
        propUpdate: { servar },
        propReset: ['servar']
      })
    ]);
  }

  function handleServarChange(servar: any) {
    handlePropChange({ servar });

    // In the form builder, we need to record that we made changes to this servar
    // So we can show the publish confirmation modal
    if (mode === 'builder') addChangedServar(element.servar.id);
  }

  function swapToNewServar(repeated = false) {
    handleUpdates([
      elementOperation({
        viewport,
        propUpdate: {
          servar: objectApply(
            defaultServarProps({
              type: fieldType,
              fields: Object.values(allServars),
              defaultProperties: {
                ...FeatheryConfig.default_element_properties[fieldType].servar,
                repeated
              }
            }),
            asset?.servar
          )
        },
        propReset: ['servar']
      })
    ]);
  }

  // If we're going to use an existing servar, we need to remove the temporary servar we just made
  // This is to avoid servar.key conflicts
  function swapToExistingServar() {
    const option = servarSwapOptions.find(
      (servarOption) => servarOption.repeated === servar.repeated
    );
    if (option) {
      handleUpdates([
        elementOperation({
          viewport,
          propUpdate: { servar: option },
          propReset: ['servar']
        })
      ]);
      setEditDisabled(true);
    } else {
      addInfoToast('No available field to link to');
    }
  }

  // servarUsage doesn't exist if in theme builder
  const isLinked =
    mode === 'builder' && servarUsage[servar.id]
      ? servarUsage[servar.id].length > 1
      : false;
  let textField = (
    <TextField
      className={classNames(isLinked && styles.fieldIdInputLinked)}
      placeholder='FieldId'
      value={servar.key || ''}
      onComplete={(val: any) => {
        val = val.trim();
        if (val !== servar.key) handleServarChange({ key: val });
        if (isLinked) setEditDisabled(true);
      }}
      disabled={isLinked && (editDisabled || !editLinkedAllowed)}
      triggerCleanUp
      maxLength={128}
    />
  );
  if (isLinked && editDisabled && editLinkedAllowed)
    textField = (
      <OverlayTrigger
        placement='bottom'
        overlay={<Tooltip id='inline-tooltip'>Double click to edit</Tooltip>}
      >
        <div style={{ position: 'relative' }}>
          {textField}
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              top: 0
            }}
            onDoubleClick={() => setEditDisabled(false)}
          />
        </div>
      </OverlayTrigger>
    );

  const elementLabel = fieldTypeNameMap[fieldType];
  return (
    <>
      <Row>
        <FieldLinkWarningModal
          show={showLinkModal}
          setShow={setShowLinkModal}
          onConfirm={() => swapToExistingServar()}
        />
        <Col sm='3'>
          <PropertyLabel label='ID' />
        </Col>
        <Col className={styles.fieldIdColumn}>
          <div className={styles.fieldIdInputContainer}>
            {textField}
            {isLinked && (
              <>
                <div
                  className={styles.fieldIdInputChevron}
                  ref={dropdownRef}
                  onClick={() => setShow((show) => !show)}
                >
                  <ChevronDownIcon width={18} height={18} />
                </div>
                <DropdownMenu
                  targetRef={dropdownRef}
                  show={show}
                  setShow={setShow}
                  options={servarSwapOptions.map((option) => ({
                    display: option.key,
                    value: option.id
                  }))}
                  onSelect={(optionId) => {
                    const option = servarSwapOptions.find(
                      (option) => option.id === optionId
                    );
                    linkToServar(option);
                    setEditDisabled(true);
                  }}
                />
              </>
            )}
          </div>
          <OverlayTrigger
            // @ts-expect-error TS(2322) FIXME: Type 'boolean' is not assignable to type 'Key | nu... Remove this comment to see the full error message
            key={isLinked}
            placement='bottom'
            overlay={
              <Tooltip id='inline-tooltip'>
                {isLinked ? (
                  <b>Unlink field</b>
                ) : servarSwapOptions.length ? (
                  <>
                    <b>Link to an existing field.</b>
                    <br /> Linked fields share the same properties and user
                    data. Styling may vary.
                  </>
                ) : (
                  <span>
                    No {fieldTypeNameMap[servar.type]} fields to link to
                  </span>
                )}
              </Tooltip>
            }
          >
            <div
              className={classNames(
                styles.fieldIdToggle,
                isLinked && styles.linked,
                !isLinked && !servarSwapOptions.length && styles.disabled
              )}
              onClick={() => {
                if (!isLinked && !servarSwapOptions.length) return;
                if (isLinked) swapToNewServar(servar.repeated);
                else if (servar.has_data) setShowLinkModal(true);
                else swapToExistingServar();
              }}
            >
              {isLinked ? (
                <LinkIcon width={18} height={18} />
              ) : (
                <UnlinkIcon width={18} height={18} />
              )}
            </div>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row>
        <Col sm='3'>
          <PropertyLabel label='Type' />
        </Col>
        <Col>
          {allowedTypeChanges.length === 0 || asset ? (
            <div className={formFieldStyles.labelType}>{elementLabel}</div>
          ) : (
            <DropdownField
              disabled={!editLinkedAllowed && isLinked}
              selected={fieldType}
              options={[fieldType, ...allowedTypeChanges].map((type) => ({
                value: type,
                display: fieldTypeNameMap[type]
              }))}
              onChange={(event: any) => {
                const selected = event.target.value;
                if (fieldType !== selected)
                  handleServarChange({
                    type: selected,
                    ...FeatheryConfig.default_element_properties[selected]
                      .servar
                  });
              }}
              style={{ width: 'auto' }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default FieldIdToggle;
