import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useFeatheryRedux from '../../../redux';

import Dialog from '../../Dialog';
import { useParams } from 'react-router-dom';
import { Neutral, Positive } from '../../Core/Button';
import { UsersIcon } from '../../Icons';
import {
  CheckboxField,
  DropdownField,
  DropdownMultiField,
  InlineTooltip,
  TextField
} from '../../Core';
import styles from './styles.module.scss';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import { useAppSelector } from '../../../hooks';
import { isValidEmail } from '../../../utils/validate';
import { openTab } from '../../../utils/domOperations';
import CollaboratorConfig, { defaultCollaborator } from './CollaboratorConfig';
import ConfirmationOverlay from '../components/ConfirmationOverlay';
import { useHostedURL } from '../../Core/Button/ShareButton/ShareFormOverlay';

const emptyEmails = (collaborators: any[]) =>
  Array(collaborators.length).fill([]);

function UserCollaborationModal({
  step,
  setStep,
  initialSessionMethod = 'email'
}: any) {
  const { formId } = useParams<{ formId: string }>();
  const {
    editPanel,
    inviteCollaborators,
    startCollaboratorSession,
    toasts: { addToast }
  } = useFeatheryRedux();

  const fileInput = useRef<any>(null);
  const [bulkFile, setBulkFile] = useState<any>(null);
  const [fileParsing, setFileParsing] = useState(false);

  const panel = useAppSelector((state) => state.panels.panels[formId]);

  const org = useAppSelector((state) => state.accounts.organization);
  const account = useAppSelector((state) => state.accounts.account);
  const allowEditTemplate =
    account.role === 'admin' || account.permission_edit_collaborator_template;
  const getHostedUrl = useHostedURL(org, panel);

  const allGroups = useAppSelector(
    (state) => state.accounts.organization?.all_user_groups ?? {}
  );

  const [collaborationEnabled, setCollaborationEnabled] = useState(false);
  const [anonymousCollaboration, setAnonymousCollaboration] = useState(false);
  const [nonCollaborationDisabled, setNonCollaborationDisabled] =
    useState(false);
  const [ordered, setOrdered] = useState(false);
  const [cTemplate, setCTemplate] = useState<any[]>([defaultCollaborator()]);
  const [dataChanged, setDataChanged] = useState(false);
  const [inviteMessage, setInviteMessage] = useState('');
  const [inviteSubject, setInviteSubject] = useState('');
  const [inviteTimestamp, setInviteTimestamp] = useState(false);
  const [updateDefault, setUpdateDefault] = useState(false);

  const [invites, setInvites] = useState(emptyEmails(cTemplate));
  const [bulkInvites, setBulkInvites] = useState<null | any[]>(null);
  const [err, setErr] = useState('');
  const [sessionMethod, setSessionMethod] = useState(initialSessionMethod);

  const [showCloseConfirmOverlay, setShowCloseConfirmOverlay] = useState(false);

  const handleCollabTemplateChanges =
    (stateUdate: (val: any) => void) => (val: any) => {
      stateUdate(val);
      setDataChanged(true);
    };

  useEffect(() => {
    // viewers start directly at step 3
    if (step === 1 || (step === 3 && account.role === 'viewer')) {
      // make sure everything re-initializes when re-starting dialog wizard
      setCollaborationEnabled(panel?.collaboration_enabled);
      setAnonymousCollaboration(panel?.anonymous_collaboration);
      setNonCollaborationDisabled(panel?.non_collaboration_disabled);
      let formTemplate = panel?.collaborator_template;
      if (!formTemplate || formTemplate.length === 0)
        formTemplate = [defaultCollaborator()];
      setCTemplate(formTemplate);
      setInviteMessage(panel?.collaborator_invite_note ?? '');
      setInviteSubject(panel?.collaborator_invite_subject ?? '');
      setInviteTimestamp(panel?.collaborator_subject_timestamp ?? false);
      setOrdered(panel?.collaborator_ordered);
      setSessionMethod(initialSessionMethod);
    }
  }, [panel, step]);
  useEffect(() => {
    setInvites(emptyEmails(cTemplate));
  }, [cTemplate]);

  const closeModal = () => {
    setInvites(emptyEmails(cTemplate));
    setBulkFile(null);
    setBulkInvites(null);
    setErr('');
    setStep(0);
    setDataChanged(false);
  };

  const submit = useCallback(
    async (advanceStep = true) => {
      setErr('');
      if (step === 1) {
        const stepAdvance = () => {
          if (advanceStep) setStep(2);
        };
        if (dataChanged)
          editPanel({
            panelId: formId,
            collaboration_enabled: collaborationEnabled,
            anonymous_collaboration: anonymousCollaboration,
            non_collaboration_disabled: nonCollaborationDisabled,
            collaborator_template: cTemplate,
            collaborator_ordered: ordered
          }).then(() => {
            setDataChanged(false);
            addToast({
              icon: <UsersIcon />,
              text: `Collaborators saved`
            });
            stepAdvance();
          });
        else stepAdvance();
      } else if (step === 2) {
        if (advanceStep) setStep(3);
      } else if (step === 3) {
        if (fileParsing) return;

        const inviteMeta = {
          collaborator_invite_note: inviteMessage,
          collaborator_invite_subject: inviteSubject,
          collaborator_subject_timestamp: inviteTimestamp
        };

        if (updateDefault) {
          await editPanel({ panelId: formId, ...inviteMeta });
        }

        if (sessionMethod === 'direct') {
          const res = await startCollaboratorSession({
            panelId: formId,
            ...inviteMeta
          });
          const url = getHostedUrl('live', {
            _cid: res.collaborator_id,
            _id: res.fuser_key
          });
          openTab(url);
        } else if (sessionMethod === 'email') {
          const curInvites = bulkInvites ?? [
            { field_data: {}, collaborators: invites }
          ];
          inviteCollaborators({
            panelId: formId,
            collaborators: curInvites,
            ...inviteMeta
          });

          const numEmails =
            curInvites.length * curInvites[0].collaborators.length;
          const term = numEmails > 1 ? 'users' : 'user';
          addToast({
            icon: <UsersIcon />,
            text: `${numEmails} ${term} invited`
          });
        }

        closeModal();
      }
    },
    [
      invites,
      bulkInvites,
      step,
      collaborationEnabled,
      anonymousCollaboration,
      nonCollaborationDisabled,
      cTemplate,
      inviteMessage,
      sessionMethod,
      ordered,
      updateDefault
    ]
  );

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  const onFileChange = async (event: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      setFileParsing(false);
      const payload = (event.target?.result ?? '').toString();
      const submissions = payload
        .split('\n')
        .filter((s) => s)
        .map((submission) => submission.split(',').map((item) => item.trim()));
      if (submissions.length <= 1) {
        setErr('This file has no users to invite');
        return;
      }

      const numC = cTemplate.length;
      const fieldIds = submissions[0].slice(numC);
      const newInvites: any[] = [];
      let curErr;

      submissions.slice(1).forEach((submission) => {
        if (!submission.length) return;

        const cleanedInvitees = submission.slice(0, numC).map((invitees) => {
          let _invitees: string[] = [];
          invitees = invitees.trim();
          if (!invitees)
            curErr = `Each row should contain emails or groups to invite in the first ${numC} columns`;
          else {
            _invitees = invitees
              .split('|')
              .map((i) => i.trim())
              .filter((i) => i);
            curErr = validateInvitees(_invitees);
          }
          return _invitees;
        });
        const fieldData: Record<string, any> = {};
        submission.slice(numC).forEach((val, index) => {
          fieldData[fieldIds[index]] = val;
        });
        newInvites.push({
          field_data: fieldData,
          collaborators: cleanedInvitees
        });
      });

      if (curErr) setErr(curErr);
      else {
        setBulkFile(file);
        setBulkInvites(newInvites);
      }
    });

    setErr('');
    const file = event.target.files[0];
    if (file) {
      setFileParsing(true);
      reader.readAsText(file);
    } else {
      setBulkFile(null);
      setBulkInvites(null);
    }
  };

  let title;
  if (step === 1) title = 'Set Form Collaborators';
  else if (step === 2) title = 'Start New Submission';
  else
    title =
      sessionMethod === 'direct' ? 'Start Directly' : 'User & Group Invite';

  const userGroupMap = useMemo(
    () =>
      allGroups.reduce((acc: any, group: any) => {
        acc[group.name] = group;
        return acc;
      }, {}),
    [allGroups]
  );
  const validateInvitees = (invitees: string[]) => {
    for (let i = 0; i < invitees.length; i++) {
      const invalidEmail = !isValidEmail(invitees[i]);
      const invalidGroup = !userGroupMap[invitees[i]];
      if (invalidEmail && invalidGroup)
        return `${invitees[i]} is not a valid email address or user group`;
    }
    return '';
  };

  // get all user groups and all known emails as selectable invitee options
  let invitees = allGroups.map((group: any) => group.name);
  // scrounge up all the emails that are registered with the org
  if (org) {
    invitees.push(
      ...org.team_accounts
        .filter((account: any) => account.email)
        .map((account: any) => account.email)
    );
  }
  // add the current account email as well
  invitees.push(account.email);
  // add all added invitees so that they show up
  invitees.push(...invites.flat());
  // eliminate duplicates and make into options
  invitees = Array.from(new Set(invitees)).sort();
  const inviteeOptions = Array.from(new Set(invitees))
    .sort()
    .map((invitee: any) => ({
      value: invitee,
      label: invitee
    }));

  return (
    <Dialog
      isOpen={step > 0}
      title={title}
      size='sm'
      onClose={() => {
        if (dataChanged) setShowCloseConfirmOverlay(true);
        else closeModal();
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        {step === 1 && (
          <CollaboratorConfig
            inFormBuilder={account.role !== 'viewer'}
            collaborationEnabled={collaborationEnabled}
            setCollaborationEnabled={handleCollabTemplateChanges(
              setCollaborationEnabled
            )}
            anonymousCollaboration={anonymousCollaboration}
            setAnonymousCollaboration={setAnonymousCollaboration}
            nonCollaborationDisabled={nonCollaborationDisabled}
            setNonCollaborationDisabled={setNonCollaborationDisabled}
            collaboratorOrdered={ordered}
            setCollaboratorOrdered={handleCollabTemplateChanges(setOrdered)}
            collaboratorTemplate={cTemplate}
            setCollaboratorTemplate={handleCollabTemplateChanges(setCTemplate)}
          />
        )}
        {step === 2 && (
          <div className={styles.inviteMessageSection}>
            <div className={styles.inviteLabel}>
              How do you want to collaborate?
            </div>
            <DropdownField
              className={styles.controlField}
              onChange={(event: any) => {
                setDataChanged(true);
                setSessionMethod(event.target.value);
              }}
              selected={sessionMethod}
              options={[
                {
                  value: 'direct',
                  display: 'Start directly as first collaborator'
                },
                { value: 'email', display: 'Email invite' }
              ]}
            />
          </div>
        )}
        {step === 3 && (
          <>
            {sessionMethod === 'email' && (
              <>
                {!bulkFile &&
                  invites.map((invitees, index) => (
                    <div key={index} className={styles.inviteEmailSection}>
                      <div className={styles.inviteLabel}>
                        {cTemplate[index].label}
                      </div>
                      <DropdownMultiField
                        selected={invitees}
                        options={inviteeOptions}
                        onChange={(items: { value: string }[]) => {
                          setErr('');
                          const inviteesForThisCollab = items.map(
                            (item) => item.value
                          );
                          const _err = validateInvitees(inviteesForThisCollab);
                          if (_err) {
                            setErr(_err);
                            return;
                          }
                          const newInvitees = [...invites];
                          newInvitees[index] = inviteesForThisCollab;
                          setInvites(newInvitees);
                          setDataChanged(true);
                        }}
                        required
                        creatable
                        formatCreateLabel={(inputValue: string) =>
                          `Add "${inputValue}"`
                        }
                        placeholder='Enter email(s) or user group(s)'
                      />
                    </div>
                  ))}
                {bulkInvites &&
                  `Number of form submissions: ${bulkInvites.length}`}
                <div
                  onClick={() => fileInput.current.click()}
                  className={styles.addCollaboratorButton}
                >
                  {bulkFile ? bulkFile.name : 'Bulk CSV Invite'}
                </div>
                <input
                  ref={fileInput}
                  type='file'
                  onChange={onFileChange}
                  accept='.csv'
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    opacity: 0,
                    zIndex: -1
                  }}
                />
              </>
            )}
            <div className={styles.inviteMessageSection}>
              <div className={styles.inviteLabel}>
                Email Invite Subject (Optional)
              </div>
              <TextField
                placeholder={`${org?.name} sent you a form to complete: ${panel.key}`}
                value={inviteSubject}
                onChange={(val: any) => {
                  setInviteSubject(val);
                  setDataChanged(true);
                }}
              />
              <CheckboxField
                checked={inviteTimestamp}
                text={
                  <>
                    Add current time to end of subject line{' '}
                    <InlineTooltip
                      text="Creates unique subject lines that don't group together in inbox"
                      inline
                    />
                  </>
                }
                onChange={(flag) => {
                  setInviteTimestamp(flag);
                  setDataChanged(true);
                }}
                style={{
                  marginTop: '12px',
                  marginBottom: '5px'
                }}
              />
            </div>
            <div className={styles.inviteMessageSection}>
              <div className={styles.inviteLabel}>Email Invite Message</div>
              <textarea
                required={false}
                rows={10}
                className={styles.inviteMessageInput}
                placeholder="I'm inviting you to fill out this form"
                value={inviteMessage}
                onChange={(e) => {
                  setInviteMessage(e.target.value);
                  setDataChanged(true);
                }}
              />
            </div>
            <div className={styles.inviteMessageSection}>
              <CheckboxField
                disabled={!allowEditTemplate}
                checked={updateDefault}
                text='Save subject and message as default'
                onChange={(flag) => {
                  setUpdateDefault(flag);
                  setDataChanged(true);
                }}
              />
            </div>
          </>
        )}
        <div className='dialog-form-action text-center'>
          {step > 1 && account.role !== 'viewer' && (
            <Neutral
              onClick={() => {
                setErr('');
                setStep(step - 1);
              }}
            >
              Back
            </Neutral>
          )}
          {step === 1 && (
            <Neutral
              disabled={!dataChanged}
              lockoutOverride={lockOutFlag}
              onClick={(e: any) => {
                e.preventDefault();
                lockoutFunction(false); // stay on the same step
              }}
            >
              Save
            </Neutral>
          )}
          <Positive
            disabled={!collaborationEnabled}
            lockoutOverride={lockOutFlag}
          >
            {step < 3
              ? dataChanged && step === 1
                ? 'Save & Next'
                : 'Next'
              : sessionMethod === 'email'
              ? 'Send Invites'
              : 'Start'}
          </Positive>
        </div>
        <div className={styles.error}>{err && <div>{err}</div>}</div>
        <ConfirmationOverlay
          show={showCloseConfirmOverlay}
          hideIt={() => setShowCloseConfirmOverlay(false)}
          onConfirm={() => {
            closeModal();
          }}
        />
      </form>
    </Dialog>
  );
}

export default memo(UserCollaborationModal);
