import '../../style/dialog-form.css';
import '../../style/form-field-editor.scss';

import { TextPropertiesPanel, TextStylePanel } from '../Panels';

import FormFieldEditor from './FormFieldEditor';

import { cellOperation, elementOperation, getAsset } from '../../utils/themes';
import useFeatheryRedux from '../../redux';
import { EDITOR_TABS } from '../../types/formBuilder';
import { calculateOverrideObjects } from '../Panels/utils';
import { TYPE_TEXT } from '../../utils/elements';
import { useAppSelector } from '../../hooks';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../Core/Tabs';

function TextEditor({
  mode,
  viewport,
  theme,
  baseProps,
  baseStyle,
  overrideProps,
  overrideStyle,
  cellNode,
  handleUpdates,
  onClose,
  isAsset
}: any) {
  const activeTab = useAppSelector((state) => state.formBuilder.activeTab);

  const { result: element } = calculateOverrideObjects(
    baseProps,
    overrideProps
  );

  const {
    formBuilder: { selectTab }
  } = useFeatheryRedux();
  const asset = getAsset(theme, TYPE_TEXT, element.source_asset);

  const onDelete = () => {
    onClose();
    handleUpdates([
      mode === 'builder'
        ? cellOperation({ viewport, remove: true })
        : elementOperation({ viewport, remove: true, asset })
    ]);
  };

  const commonProps = {
    mode,
    viewport,
    theme,
    baseProps,
    baseStyle,
    overrideProps,
    overrideStyle,
    handleUpdates,
    cellNode
  };

  return (
    <FormFieldEditor
      mode={mode}
      onDelete={onDelete}
      element={element}
      elementType={TYPE_TEXT}
      assetName={asset?.key}
      cellNode={cellNode}
    >
      {(mode === 'builder' || isAsset) && (
        <Tabs value={activeTab} onValueChange={(tab) => selectTab(tab)}>
          <TabsList className='legacyTabsList' unstyled>
            <TabsTrigger
              className='legacyTabsTriggerFlex'
              value={EDITOR_TABS.PROPERTIES}
              unstyled
            >
              Properties
            </TabsTrigger>
            <TabsTrigger
              className='legacyTabsTriggerFlex'
              value={EDITOR_TABS.STYLES}
              unstyled
            >
              Style
            </TabsTrigger>
          </TabsList>
          <TabsContent
            unstyled
            className='legacyTabsContent'
            value={EDITOR_TABS.PROPERTIES}
          >
            <TextPropertiesPanel {...commonProps} />
          </TabsContent>
          <TabsContent
            unstyled
            className='legacyTabsContent'
            value={EDITOR_TABS.STYLES}
          >
            <TextStylePanel {...commonProps} isAsset={isAsset} />
          </TabsContent>
        </Tabs>
      )}
      {mode === 'theme' && !isAsset && <TextStylePanel {...commonProps} />}
    </FormFieldEditor>
  );
}

export default TextEditor;
