import { useEffect, useRef, useState } from 'react';
import { AIExtractionEntry } from '../types';
import { getValueId } from '../utils';
import { useExtractionState, useExtractionUpdater } from '../context';
import {
  AIStarIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  TrashIcon
} from '../../../components/Icons';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  entry: AIExtractionEntry;
  index: number;
  onEdit?: (newValue: string) => void;
  onDelete?: () => void;
};

const AIEntryFieldValue = ({
  entry,
  index,
  onEdit = () => {},
  onDelete = () => {}
}: Props) => {
  const [editedText, setEditedText] = useState('');
  const valueRef = useRef<HTMLDivElement>(null);
  const valueId = getValueId(entry, index);

  let entryValue = entry.value[index];
  if (typeof entryValue === 'boolean') entryValue = entryValue ? 'Yes' : 'No';
  if (Array.isArray(entryValue)) entryValue = entryValue.join(', ');

  const { activeComponent, editingValueId, selectedValueId, hoveredValueId } =
    useExtractionState();
  const { setEditingValueId, setSelectedValueId, setHoveredValueId } =
    useExtractionUpdater();

  useEffect(() => {
    if (editingValueId === getValueId(entry, index)) {
      valueRef.current?.focus();
    } else {
      setEditedText('');
    }
  }, [editingValueId]);

  // Helper for starting the edit of the value
  const handleStartEdit = () => {
    if (entry.xCoordinates[index] >= 0) {
      setSelectedValueId(valueId);
    }

    setEditingValueId(valueId);
  };

  // Handler for cancelling editing the value
  const handleCancelEdit = () => {
    if (valueRef.current) {
      if (entryValue) {
        valueRef.current.textContent = entryValue;
      } else {
        valueRef.current.textContent = 'NO DATA';
      }
    }

    setEditingValueId(null);
  };

  // Handler for finalizing the edit made to the value being edited
  const handleEditValue = (newValue?: string) => {
    onEdit(newValue ? newValue : editedText);
    setEditingValueId(null);
  };

  const isEditing = !!editingValueId;
  const isAIGenerated =
    entry.xCoordinates[index] !== undefined &&
    entry.yCoordinates[index] !== undefined;
  const isDisabled = !entryValue || !isAIGenerated;

  return (
    <div
      key={`${entry.id}-${index}`}
      data-id={`${entry.id}-${index}`}
      className={classNames(styles.value, {
        [styles.selected]: !isDisabled && selectedValueId === valueId,
        [styles.hovered]: !isDisabled && hoveredValueId === valueId,
        [styles.editing]: editingValueId === valueId
      })}
      onDoubleClick={() => handleStartEdit()}
      onMouseEnter={() => !isEditing && setHoveredValueId(valueId, 'panel')}
      onMouseLeave={() => !isEditing && setHoveredValueId(null)}
      onClick={() => {
        if (!isDisabled && !isEditing) {
          setSelectedValueId(selectedValueId === valueId ? null : valueId);
        }
      }}
    >
      {isAIGenerated && (
        <div
          className={classNames(styles.icon, {
            [styles.red]: !isDisabled,
            [styles.grey]: isDisabled
          })}
        >
          <AIStarIcon height={14} />
        </div>
      )}
      <div
        ref={valueRef}
        className={classNames(styles.text, {
          [styles.tag]: !entryValue && editingValueId !== valueId
        })}
        contentEditable={editingValueId === valueId}
        suppressContentEditableWarning
        onInput={(e) => setEditedText(e.currentTarget.textContent ?? '')}
        onBlur={(e) => {
          if (editingValueId === valueId) {
            handleEditValue(e.currentTarget.textContent ?? '');
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            handleEditValue(e.currentTarget.textContent ?? '');
          }
        }}
      >
        {entryValue ? entryValue : editingValueId === valueId ? '' : 'NO DATA'}
      </div>
      {((activeComponent === 'panel' && hoveredValueId === valueId) ||
        editingValueId === valueId) && (
        <div className={styles.actions}>
          {editingValueId === valueId ? (
            <>
              <div
                onMouseDown={(e) => {
                  // onMouseDown over onClick due to the onBlur on the `valueRef` div.
                  // This needs to fire first for proper behaviour.
                  e.preventDefault();
                  e.stopPropagation();
                  handleCancelEdit();
                }}
              >
                <CloseIcon
                  className={styles.fill}
                  height={18}
                  width={18}
                  color='var(--grey-70)'
                  style={{ marginTop: '-1px' }}
                />
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleEditValue();
                }}
              >
                <CheckIcon
                  className={styles.innerStroke}
                  height={18}
                  width={18}
                  color='var(--grey-70)'
                  stroke='1'
                  style={{ marginTop: '1px' }}
                />
              </div>
            </>
          ) : (
            <>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleStartEdit();
                }}
              >
                <EditIcon
                  height={18}
                  width={18}
                  color='var(--grey-70)'
                  style={{ marginTop: '-2px' }}
                />
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDelete();
                }}
              >
                <TrashIcon
                  height={18}
                  width={18}
                  color='var(--grey-70)'
                  style={{ marginTop: '-2px' }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AIEntryFieldValue;
