import Dialog from '../../components/Dialog';
import { Positive } from '../../components/Core/Button';
import { Spinner } from 'react-bootstrap';
import Neutral from '../../components/Core/Button/Neutral';
import { APIService } from './types';

const DeleteConfirmationAPIModal = ({
  isLoading,
  apiService,
  onDelete,
  onClose
}: {
  isLoading: boolean;
  apiService: APIService | null;
  onDelete: (apiService: APIService) => void;
  onClose: () => void;
}) => {
  if (!apiService) return null;

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      title={`Delete Confirmation`}
      shouldShowCloseIcon={!isLoading}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            textAlign: 'center',
            fontSize: 18,
            padding: 36
          }}
        >
          Are you sure you want to delete <b>{apiService.base_url}</b>?
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Neutral onClick={onClose}>Cancel</Neutral>
              <Positive
                onClick={() => {
                  onDelete(apiService);
                }}
              >
                Yes, Delete API Connector
              </Positive>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteConfirmationAPIModal;
