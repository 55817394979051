import classNames from 'classnames';
import Switch from '..';
import styles from './styles.module.scss';
import { ComponentProps } from 'react';
import { cn } from '../../../../utils/cn';

const OnOffSwitch = ({
  checked,
  className,
  ...props
}: ComponentProps<typeof Switch>) => {
  return (
    <label className={styles.switchContainer}>
      <div
        className={classNames(styles.onOverlay, styles.onOffLabel, {
          [styles.onOverlayActive]: checked
        })}
      >
        ON
      </div>
      <Switch
        checked={checked}
        className={cn(styles.switch, className)}
        {...props}
      />
      <div
        className={classNames(styles.offOverlay, styles.onOffLabel, {
          [styles.offOverlayActive]: !checked
        })}
      >
        OFF
      </div>
    </label>
  );
};

export default OnOffSwitch;
