import * as Logos from '../Logos';
import { mapArrayToObject } from '../../utils/core';

const AI_INTEGRATION_TYPES: { [key: string]: string } = {
  EMAIL_INBOX: 'email-inbox'
};

const AI_INTEGRATION_TAGS = {
  INBOX: 'Inbox'
};

const DOCS_BASE = 'https://feathery.io/integrations/ai/';

const AI_INTEGRATIONS = [
  {
    key: AI_INTEGRATION_TYPES.EMAIL_INBOX,
    title: 'Email Inbox',
    description: (
      <>
        Process documents pulled automatically from your email inbox.{' '}
        <a href={`${DOCS_BASE}email-inbox`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [AI_INTEGRATION_TAGS.INBOX],
    logo: <Logos.SimpleEmailService />
  }
];

const AI_INTEGRATION_ORDER = AI_INTEGRATIONS.reduce(
  (orderMap, integ, index) => {
    orderMap[integ.key] = index;
    return orderMap;
  },
  {} as Record<string, any>
);

const AI_INTEGRATIONS_MAP = mapArrayToObject(AI_INTEGRATIONS, 'key');

export {
  AI_INTEGRATION_TAGS,
  AI_INTEGRATION_TYPES,
  AI_INTEGRATIONS,
  AI_INTEGRATION_ORDER,
  AI_INTEGRATIONS_MAP
};
