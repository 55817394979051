import { useEffect, useState } from 'react';

export const useDocumentFile = (docUrl = '') => {
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const baseUrl = docUrl?.split('?')[0];

  useEffect(
    () => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      async function fetchDocument() {
        setLoading(true);
        setError('');

        try {
          const response = await fetch(docUrl, { signal });
          const arrayBuffer = await response.arrayBuffer();
          const file = new File([arrayBuffer], 'document.pdf', {
            type: 'application/pdf'
          });
          setFile(file);
        } catch (err: any) {
          setFile(undefined);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
      if (docUrl) {
        fetchDocument();
      }
      return () => abortController.abort();
    },
    // the file url frequently changes query params that do not effect contents
    // we only refetch when the it actually changes to avoid unnecessarily loading
    [baseUrl]
  );

  return { file, loading, error };
};
