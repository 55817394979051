import {
  CONTENT_TYPE,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  URL
} from './utils';

export default {
  getIntegrations: (token: string, entityType = '') => {
    const params = entityType ? { entity_type: entityType } : {};
    return httpGet({
      token,
      url: URL.INTEGRATIONS,
      params
    });
  },
  createIntegration: (token: string, data: any) => {
    const {
      staticIp,
      panelId,
      entityType,
      extractionId,
      ...newIntegrationData
    } = data;
    const entityId = entityType === 'ai' ? extractionId : panelId;
    let url = staticIp ? URL.INTEGRATIONS_STATIC_IP : URL.INTEGRATIONS;
    url = `${url}${entityId}/`;
    if (entityType) url += `?entity_type=${entityType}`;
    return httpPost({
      token,
      url,
      body: newIntegrationData,
      invalid: [URL.INTEGRATIONS]
    });
  },
  editIntegration: (token: string, data: any) => {
    const {
      staticIp,
      type,
      panelId,
      entityType,
      extractionId,
      ...newIntegrationData
    } = data;
    const entityId = entityType === 'ai' ? extractionId : panelId;
    let url = staticIp ? URL.INTEGRATIONS_STATIC_IP : URL.INTEGRATIONS;
    url = `${url}${entityId}/detail/${type}/`;
    if (entityType) url += `?entity_type=${entityType}`;
    return httpPatch({ token, baseUrl: url, body: newIntegrationData });
  },
  switchIntegrationProperty: (token: string, data: any) => {
    const {
      panelId,
      integrationType,
      propertyType,
      propertyValue,
      automation
    } = data;
    return httpPost({
      token,
      url: `${URL.INTEGRATIONS}${panelId}/rollout/${integrationType}/switch/`,
      body: {
        type: propertyType,
        new_property: propertyValue,
        automation_id: automation
      }
    });
  },
  uploadFont: (token: string, data: any) => {
    return httpPost({
      token,
      url: `${URL.INTEGRATIONS}font/`,
      body: data,
      contentType: CONTENT_TYPE.MULTIPART_FORM
    });
  },
  getDynamicFonts: (token: string) => {
    return httpGet({ token, url: `${URL.INTEGRATIONS}font/` });
  },
  exchangeOAuthToken: (
    token: string,
    { code, type, egnyte_domain, panelId = null }: any
  ) => {
    return httpPost({
      token,
      url: `${URL.INTEGRATIONS}oauth/`,
      body: { type, code, egnyte_domain, panel_id: panelId },
      invalid: [URL.INTEGRATIONS]
    });
  },
  revokeOAuthToken: (token: string, { type, egnyte_domain, panelId }: any) => {
    return httpDelete({
      token,
      baseUrl: URL.INTEGRATIONS,
      path: 'oauth/',
      body: { type, egnyte_domain, panel_id: panelId },
      invalid: [URL.INTEGRATIONS]
    });
  },
  getTranslations: (token: string, panelId: string) => {
    return httpGet({
      token,
      url: `${URL.INTEGRATIONS}${panelId}/translation/`
    });
  },
  editTranslations: (token: string, panelId: string, translations: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.INTEGRATIONS}${panelId}/translation/`,
      body: translations
    });
  },
  fetchRolloutToken: (token: string) => {
    return httpGet({
      token,
      url: `${URL.INTEGRATIONS}rollout/token/`
    });
  },
  fetchQuikFormFields: (token: string, panelId: string, quikFormId: string) => {
    return httpPost({
      token,
      url: `${URL.INTEGRATIONS}quik/fields/`,
      body: {
        panel_id: panelId,
        quik_form_id: quikFormId
      }
    });
  },
  fetchSalesforceObjects: (
    token: string,
    salesforce_instance_url: string,
    salesforce_access_token: string
  ) => {
    const params = new URLSearchParams({
      salesforce_instance_url,
      salesforce_access_token
    });

    return httpGet({
      token,
      url: `${URL.INTEGRATIONS}salesforce/object/?${params.toString()}`
    });
  },
  fetchSalesforceObjectFields: (
    token: string,
    salesforce_instance_url: string,
    salesforce_access_token: string,
    object_name: string
  ) => {
    const params = new URLSearchParams({
      salesforce_instance_url,
      salesforce_access_token,
      object_name
    });

    return httpGet({
      token,
      url: `${URL.INTEGRATIONS}salesforce/object/detail/?${params.toString()}`
    });
  },
  getCustomAPIs: (token: string, data: any) => {
    const { panelId } = data;

    return httpGet({
      token,
      url: `${URL.INTEGRATIONS}service/${panelId}/`,
      forceLoad: true
    });
  },
  createCustomAPI: (token: string, data: any) => {
    const { panelId, customAPI } = data;

    return httpPost({
      token,
      url: `${URL.INTEGRATIONS}service/${panelId}/`,
      body: customAPI
    });
  },
  editCustomAPI: (token: string, data: any) => {
    const { customAPI, remove } = data;

    if (remove) {
      return httpDelete({
        token,
        baseUrl: `${URL.INTEGRATIONS}service/detail/${customAPI.id}/`,
        invalid: [URL.INTEGRATIONS]
      });
    }

    return httpPatch({
      token,
      baseUrl: `${URL.INTEGRATIONS}service/detail/${customAPI.id}/`,
      body: customAPI
    });
  },
  testCustomAPI: (token: string, payload: any) => {
    return httpPost({
      token,
      url: `${URL.INTEGRATIONS}test_request/`,
      body: payload
    });
  }
};
