import '../../style/dialog-form.css';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import {
  CheckboxField,
  DropdownField,
  InlineTooltip,
  PropertyLabel,
  TextField
} from '../Core';
import IntegrationsSidebar from './IntegrationsSidebar';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import Combobox from '../Core/Combobox';
import Label from '../Dialog/Label';
import styles from './styles.module.scss';

const DEFAULT_SETTINGS = {
  username: '',
  password: '',
  form_ids: [],
  esign_send_type: '',
  esign_id_check: '',
  esign_hide_id_check: false,
  esign_read_only: false
};

function QuikSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.QUIK,
    panelId: formId,
    includeInactive: true
  });

  const [err, setErr] = useState('');

  const [quikConfig, setQuikConfig] = useState(
    integration?.data.secret_metadata ?? DEFAULT_SETTINGS
  );

  const setField = (key: string, value: any) =>
    setQuikConfig((quikConfig: any) => ({
      ...quikConfig,
      [key]: value
    }));

  function onSubmitCustom(newIsActive: boolean) {
    if (quikConfig.form_ids.length === 0) {
      setErr('At least one Quik! Form ID is required');
      return;
    }
    setErr('');

    return {
      isUpdate: integration?.data,
      secretMetadata: {
        form_ids: quikConfig.form_ids,
        username: quikConfig.username,
        password: quikConfig.password,
        esign_send_type: quikConfig.esign_send_type,
        esign_id_check: quikConfig.esign_id_check,
        esign_hide_id_check: quikConfig.esign_hide_id_check,
        esign_read_only: quikConfig.esign_read_only
      }
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.QUIK]}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <Form className='integration-modal-form'>
        <Label>Authentication</Label>
        <Form.Group controlId='quik-username'>
          <PropertyLabel label='Username' />
          <TextField
            value={quikConfig.username}
            onChange={(value: string) => setField('username', value)}
            className={styles.marginBottom}
          />
        </Form.Group>
        <Form.Group controlId='quik-password'>
          <PropertyLabel label='Password' />
          <TextField
            value={quikConfig.password}
            onChange={(value: string) => setField('password', value)}
            type='password'
            className={styles.marginBottom}
          />
        </Form.Group>
        <Label style={{ display: 'flex' }}>
          Quik! Form IDs{' '}
          <InlineTooltip text='A list of form IDs from Quik corresponding to documents to fill. If the form ID is appended with `:<FieldKey>`, it will only be included in the generated form bundle if the submision has a value for <FieldKey>' />
        </Label>
        <Form.Group controlId='quik-form-ids'>
          <Combobox
            value={quikConfig.form_ids || []}
            onChange={(items: string[]) => {
              setField('form_ids', items);
            }}
            placeholder='Add your Quik! Form IDs...'
            className={styles.marginBottom}
          />
        </Form.Group>
        <Label>{'E-Sign Pop-Up Configuration (Optional)'}</Label>
        <Form.Group controlId='quik-esign-send-type'>
          <PropertyLabel
            label={
              <>
                Send Type
                <InlineTooltip
                  text='Set Quik! E-Sign Pop-Up Send Type'
                  inline
                />
              </>
            }
          />
          <DropdownField
            onChange={(e: any) => {
              setField('esign_send_type', e.target.value);
            }}
            selected={quikConfig.esign_send_type}
            options={[
              { value: '', display: 'Unset' },
              { value: 'EmailToSign', display: 'Email' },
              { value: 'SendCopy', display: 'Send Copy' },
              { value: 'InPersonSigner', display: 'In Person Signer' },
              { value: 'CertifiedDeliveries', display: 'Certified Deliveries' },
              { value: 'Editor', display: 'Editor' }
            ]}
            className={styles.marginBottom}
          />
        </Form.Group>
        <Form.Group controlId='quik-esign-id-check'>
          <PropertyLabel
            label={
              <>
                Identity Check Type
                <InlineTooltip
                  text='Set Quik! E-Sign Pop-Up Identity Check'
                  inline
                />
              </>
            }
          />
          <DropdownField
            onChange={(e: any) => {
              setField('esign_id_check', e.target.value);
            }}
            selected={quikConfig.esign_id_check}
            options={[
              { value: '', display: 'Unset' },
              { value: 'SMS', display: 'SMS' },
              { value: 'Phone', display: 'Phone' },
              { value: 'LiveID', display: 'Live ID' },
              { value: 'Facebook', display: 'Facebook' },
              { value: 'LinkedIn', display: 'LinkedIn' },
              { value: 'Google', display: 'Google' },
              { value: 'Salesforce', display: 'Salesforce' },
              { value: 'Twitter', display: 'Twitter' },
              { value: 'Yahoo', display: 'Yahoo' },
              { value: 'OpenID', display: 'OpenID' },
              { value: 'IDCheck', display: 'ID Check' },
              { value: 'NoIdentityCheck', display: 'No Identity Check' }
            ]}
            className={styles.marginBottom}
          />
        </Form.Group>
        <Form.Group controlId='quik-esign-hide-id-check'>
          <CheckboxField
            text={
              <>
                Hide Identity Check Column
                <InlineTooltip
                  text='Hide the Identity Check column within the Quik! E-Sign Pop-Up'
                  inline
                />
              </>
            }
            checked={quikConfig.esign_hide_id_check}
            onChange={(isChecked) => setField('esign_hide_id_check', isChecked)}
            className={styles.marginBottom}
          />
        </Form.Group>
        <Form.Group controlId='quik-esign-read-only'>
          <CheckboxField
            text={
              <>
                Set E-Sign Fields As Read Only
                <InlineTooltip
                  text='Set fields as read only within the Quik! E-Sign Pop-Up'
                  inline
                />
              </>
            }
            checked={quikConfig.esign_read_only}
            onChange={(isChecked) => setField('esign_read_only', isChecked)}
          />
        </Form.Group>
      </Form>
    </IntegrationsSidebar>
  );
}

export default QuikSettingsSidebar;
