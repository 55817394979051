function Klaviyo({ width = 84, height = 84 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 581 172'
      width={width}
      height={height}
      xmlSpace='preserve'
    >
      <g>
        <path
          d='M329.9,34.4c3.1,0,6.1-1.2,8.3-3.3c2.2-2.1,3.5-5.1,3.6-8.1c0-3.1-1.3-6.1-3.5-8.3c-2.2-2.2-5.2-3.5-8.3-3.5
		c-3.1,0.1-6,1.3-8.2,3.6c-2.2,2.2-3.4,5.2-3.3,8.2c0,3,1.2,5.9,3.4,8.1C324,33.1,326.9,34.3,329.9,34.4L329.9,34.4z M410.5,45.4
		h28.6v2.3c-1.6,0.3-3.1,0.8-4.5,1.6c-2.6,1.2-7.8,7.1-11.9,17c-6.8,17.1-13.9,37.4-21.4,60.6l-2.8,8.8c-1.2,4-2.3,6.6-2.8,8.1
		c-0.5,1.6-1.2,4-2.4,6.9c-0.7,2.2-1.6,4.4-2.7,6.4c-1.4,2.6-4,7.9-6.1,9.5c-3.3,2.8-8.2,5.9-14.3,5.4c-11.9,0-20.8-8.8-20.9-19.2
		c0-7.1,4.5-11.8,11.3-11.8c4.9,0,9.2,2.6,9.2,8.1c0,4-4,8.1-4,10.2c0,5.4,3.1,7.9,9.2,7.9c4.9,0,8.9-3.1,11.9-9.3
		c4-7.1,4.4-14.9,1-23.6l-25.1-66c-5.8-15.2-10.1-20.2-15.5-20.8v-2.3h39.6v2.3c-4.7,0.5-7.1,3.3-7.1,8.3c0,3.6,1.4,9.2,4,16.3
		l4.7,12.8c5.4,13.9,9.8,26,12,33.6c5.1-15.8,9.8-29.6,14.3-41.7c3.3-9,4.9-15.4,4.9-19.2c0-6.8-3.7-9.9-9.2-9.9L410.5,45.4
		L410.5,45.4z M135.2,129.7c-5.1-0.9-9.4-5.4-9.4-14.9V0L97,6.2v2.4c4.9-0.5,9.8,3.8,9.8,13v93.2c0,9-4.9,14.2-9.8,14.9
		c-0.5,0.1-0.9,0.1-1.4,0.2c-2.5,0.2-5-0.3-7.3-1.3c-3.9-1.7-7.1-4.7-9.8-9.1L65.2,98.4c-2.8-4.5-7-7.9-11.9-9.8
		c-4.9-1.9-10.3-2.2-15.4-0.8l15-16.5c11.3-12.5,21.8-20.4,31.7-23.7v-2.3h-33v2.3c8.5,3.3,8,10.6-1.8,22L28.8,94V0L0,6.2v2.4
		c4.9,0,9.8,4.8,9.8,13.3v92.8C9.8,125,5,129,0,129.7v2.3h38.2v-2.3c-6.3-0.9-9.4-5.7-9.4-14.9V97.7l8.2-9l19.8,32.4
		c4.7,7.8,9.1,10.9,16,10.9h66.4v-1.8C139.3,130.2,137.4,130.1,135.2,129.7L135.2,129.7z M214.4,118.8V80
		c-0.4-25.3-11.1-36.9-35.6-36.9c-7.8-0.1-15.4,2.5-21.6,7.3c-6.5,4.9-9.6,10.4-9.6,16.8c0,6.2,3.5,10.9,9.2,10.9
		c6.1,0,10.5-3.5,10.5-8.3c0-3.6-2.4-8.7-2.4-12.1c0-6.2,4.7-11.6,12.9-11.6c10.5,0,18,7.8,18,25.1v10.4l-8.7,2.1
		c-4.5,0.9-8.4,1.8-11.3,2.6c-3,0.9-6.8,2.1-11.3,3.8c-9.1,3.5-13.9,6.8-18.1,13c-2.1,3-3.1,6.6-3.1,10.2c0,14.4,10.1,21,24.2,21
		c11.2,0,23-5.9,28.4-17c0.1,3.5,0.9,6.9,2.5,10.1c5.9,11.9,25.5,4.9,25.5,4.9V130C215.1,131.3,214.4,121.2,214.4,118.8L214.4,118.8
		z M195.8,106.8c0,5.7-2.1,10.4-6.3,13.7c-4,3.3-8.2,5-12.6,5c-8.5,0-14.1-5.5-14.1-15.8c0-4.8,2.7-9.3,4.9-11.9
		c1.8-1.8,3.8-3.3,6.1-4.3c3-1.6,4.4-2.4,6.5-3.3l8-2.9c4-1.6,6.4-2.4,7.5-2.9L195.8,106.8L195.8,106.8z M581,45.4h-67.8V0H581
		l-14.2,22.7L581,45.4L581,45.4z M443.1,120.9c-8.4-8.6-13.1-20.2-12.9-32.3c-0.1-5.9,1-11.8,3.2-17.3c2.2-5.5,5.5-10.5,9.7-14.8
		c8.6-9,19-13.5,31.3-13.5c12,0,22.5,4.5,31.1,13.5c4.2,4.2,7.6,9.2,9.8,14.7c2.3,5.5,3.4,11.4,3.3,17.4c0.1,6-1,11.9-3.3,17.4
		c-2.3,5.5-5.6,10.6-9.8,14.8c-8.6,8.8-19,13.4-31.1,13.4C462.2,134.3,451.7,129.8,443.1,120.9L443.1,120.9z M490.1,58
		c-3.4-6.7-8-10.6-13.3-11.7c-10.8-2.2-20.3,8.9-23.9,26.4c-1.5,7.9-1.9,15.9-1.1,23.8c0.8,8,3,15.7,6.6,22.9
		c3.5,6.7,8,10.6,13.3,11.7c10.8,2.2,20.6-9.3,24.2-27C498.9,89.3,497.4,71.3,490.1,58L490.1,58L490.1,58z'
        ></path>
        <path
          d='M340,114.8V45.4h-61.4v2.1c8.2,1.2,12.1,7.4,8.4,17.3c-19.2,51.8-18,49.5-19.2,53.6c-1.2-4-4-13.8-8.5-26.1
		c-4.5-12.3-7.5-20.4-8.7-24.1c-4.7-14.4-3.1-19.7,4.5-20.6v-2.3h-39.8v2.3c5.9,1.2,11.2,8,15.5,20.1l6.1,15.8
		c6.7,17,14.6,40.5,17.2,48.5h13.2c4.3-12.3,21.3-61.5,23.6-66.5c2.5-5.7,5.3-10,8.4-13c1.5-1.6,3.3-2.9,5.4-3.7
		c2-0.8,4.2-1.3,6.4-1.2c0,0,9.6,0,9.6,9.2v58.1c0,9.7-4.7,14.2-9.6,14.9v2.3h38v-2.3C344.2,129,340,124.5,340,114.8L340,114.8z'
        ></path>
      </g>
    </svg>
  );
}

export default Klaviyo;
