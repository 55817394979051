import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import {
  groupByForm,
  groupKeysById,
  handleErrorMessage,
  transformFieldMap
} from './utils';
import useFeatheryRedux from '../../../redux';

interface Result {
  promoteForm: () => void;
  isRollback: boolean;
  promote_id: string;
  promote_key: string;
  servarKeysById: Record<string, string>;
  thisFormServarIds: string[];
  promoteToServarIds: string[];
  mapFieldData: Record<string, string>;
  setMapFieldData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

export function usePromoteForm(formId: string): Result {
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const promote_id = panel.promote_to || panel.promote_from;
  const isRollback = !panel.promote_to && panel.promote_from;
  const promote_key = useAppSelector(
    (state) => state.panels.panels[promote_id]?.key ?? ''
  );

  const [mapFieldData, setMapFieldData] = useState<Record<string, string>>({});

  const {
    promotePanel,
    getPanelPromoteLinkFields,
    toasts: { addErrorToast }
  } = useFeatheryRedux();

  const servarKeysById = useAppSelector((state) =>
    (state.fields.servars ?? []).reduce<Record<string, string>>(
      groupKeysById,
      {}
    )
  );

  const servarsByForm = useAppSelector((state) =>
    Object.entries<{ panel_id: string }[]>(state.fields.usage ?? {}).reduce<
      Record<string, string[]>
    >(groupByForm, {})
  );

  const thisFormServarIds = servarsByForm[formId];
  const promoteToServarIds = servarsByForm[promote_id];

  useEffect(() => {
    if (panel.promote_to && formId) {
      (async () => {
        const result = await getPanelPromoteLinkFields({ panelId: formId });
        setMapFieldData(result);
      })();
    }
  }, [panel.promote_to]);

  const promoteForm = useCallback(async () => {
    try {
      await promotePanel({
        panelId: formId,
        fieldMap: transformFieldMap(mapFieldData, thisFormServarIds)
      });
      window.location.assign('/forms');
    } catch (error) {
      addErrorToast({
        title: handleErrorMessage(error)
      });
    }
  }, [formId, mapFieldData, thisFormServarIds]);

  return {
    promoteForm,
    isRollback,
    promote_id,
    promote_key,
    servarKeysById,
    thisFormServarIds,
    promoteToServarIds,
    mapFieldData,
    setMapFieldData
  };
}
