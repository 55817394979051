import { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks';
import { useGlobalMouseDownToggle } from '../../../../../../components/Core/util';
import {
  ACTION_DETAILS,
  AVAILABLE_ACTIONS,
  CALENDLY_ACTION,
  SMS_CODE_ACTION,
  EMAIL_CODE_ACTION,
  TELESIGN_VOICE_ACTION,
  EMAIL_ACTION,
  TELESIGN_SMS_ACTION,
  AI_EXTRACTION_ACTION,
  RUN_INTEGRATION_ACTION
} from '../RuleAction/constants';
import { OpenOverflowIcon } from '../../../../../../components/Icons';
import { ContextMenu } from '../../../../../../components/Core';
import { INTEGRATIONS } from '../../../../../../components/FormIntegrations';
import classNames from 'classnames';
import useIntegrations from '../../../../../../components/FormIntegrations/useIntegrations';
import styles from './styles.module.scss';

type RuleActionSelectorProps = {
  action?: string;
  readonly?: boolean;
  onChange: (action: string) => void;
};

export const RuleActionSelector = ({
  action,
  onChange = () => {},
  readonly = false
}: RuleActionSelectorProps) => {
  const { formId } = useParams<{ formId: string }>();
  const actionSelectorRef = useRef<HTMLDivElement>(null);
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({});
  const [showContextMenu, setShowContextMenu] = useGlobalMouseDownToggle([
    contextMenuRef
  ]);

  const org = useAppSelector((state) => state.accounts.organization);
  const orgTier = org?.tier;

  const {
    calendly,
    'sms-otp': sms_otp,
    'email-otp': email_otp,
    telesign,
    firebase,
    stych,
    email
  } = useIntegrations({
    types: [
      INTEGRATIONS.CALENDLY,
      INTEGRATIONS.TELESIGN,
      INTEGRATIONS.SMS_OTP,
      INTEGRATIONS.EMAIL_OTP,
      INTEGRATIONS.FIREBASE,
      INTEGRATIONS.STYTCH,
      INTEGRATIONS.EMAIL
    ],
    panelId: formId
  });

  const ACTIONS = useMemo(() => {
    const filteredActions = [...AVAILABLE_ACTIONS];
    if (calendly) {
      filteredActions.push(CALENDLY_ACTION);
    }
    if (sms_otp || firebase || stych) {
      filteredActions.push(SMS_CODE_ACTION);
    }
    if (email_otp) {
      filteredActions.push(EMAIL_CODE_ACTION);
    }
    if (telesign) {
      filteredActions.push(TELESIGN_VOICE_ACTION, TELESIGN_SMS_ACTION);
    }
    if (email) {
      filteredActions.push(EMAIL_ACTION);
    }
    if (org?.enterprise_features.ai_documents) {
      filteredActions.push(AI_EXTRACTION_ACTION);
    }
    if (orgTier === 4) {
      filteredActions.push(RUN_INTEGRATION_ACTION);
    }

    return filteredActions;
  }, [
    orgTier,
    calendly,
    sms_otp,
    email_otp,
    telesign,
    firebase,
    stych,
    email,
    formId
  ]);

  const revealContextMenu = (e: any) => {
    e?.preventDefault();
    setPosition({
      x: e.clientX,
      y: e.clientY
    });
    setShowContextMenu(true);
  };

  return (
    <div
      ref={actionSelectorRef}
      className={classNames(styles.ruleActionSelector, {
        [styles.readOnly]: readonly
      })}
    >
      {action ? (ACTION_DETAILS as any)[action]?.name : 'Select Action'}
      {!readonly && (
        <OpenOverflowIcon onClick={(e: MouseEvent) => revealContextMenu(e)} />
      )}
      {showContextMenu && (
        <ContextMenu
          ref={contextMenuRef}
          position={position as { x: number; y: number }}
          show={showContextMenu}
          close={() => setShowContextMenu(false)}
          actions={ACTIONS.map((action) => {
            const actionDetail = (ACTION_DETAILS as any)[action ?? ''] ?? {};
            return {
              onMouseDown: () => onChange(action),
              title: actionDetail.menuName || actionDetail.name
            };
          })}
        />
      )}
    </div>
  );
};
