import { ContainerPropertiesPanel, ContainerStylePanel } from '../../Panels';
import FormFieldEditor from '../FormFieldEditor';
import { cellOperation } from '../../../utils/themes';
import useFeatheryRedux from '../../../redux';
import { EDITOR_TABS } from '../../../types/formBuilder';
import { useAppSelector } from '../../../hooks';
import KeyField from './KeyField';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Core/Tabs';

const ContainerEditor = ({
  viewport,
  handleUpdates,
  onClose,
  theme,
  cellNode
}: any) => {
  const activeContainerTab = useAppSelector(
    (state) => state.formBuilder.activeContainerTab
  );

  const {
    formBuilder: { selectContainerTab }
  } = useFeatheryRedux();

  const customHeaderRows = [
    <KeyField key={`${cellNode?.uuid}-key-field`} cellNode={cellNode} />
  ];

  return (
    <FormFieldEditor
      mode='builder'
      cellNode={cellNode}
      customHeaderRows={customHeaderRows}
      onDelete={() => {
        onClose();
        handleUpdates([cellOperation({ viewport, remove: true })]);
      }}
    >
      <Tabs
        value={activeContainerTab}
        onValueChange={(tab) => selectContainerTab(tab)}
      >
        <TabsList className='legacyTabsList' unstyled>
          <TabsTrigger
            className='legacyTabsTriggerFlex'
            value={EDITOR_TABS.PROPERTIES}
            unstyled
          >
            Properties
          </TabsTrigger>
          <TabsTrigger
            className='legacyTabsTriggerFlex'
            value={EDITOR_TABS.STYLES}
            unstyled
          >
            Style
          </TabsTrigger>
        </TabsList>
        <TabsContent
          unstyled
          className='legacyTabsContent'
          value={EDITOR_TABS.PROPERTIES}
        >
          <ContainerPropertiesPanel
            viewport={viewport}
            handleUpdates={handleUpdates}
            cellNode={cellNode}
          />
        </TabsContent>
        <TabsContent
          unstyled
          className='legacyTabsContent'
          value={EDITOR_TABS.STYLES}
        >
          <ContainerStylePanel
            viewport={viewport}
            handleUpdates={handleUpdates}
            cellNode={cellNode}
            theme={theme}
          />
        </TabsContent>
      </Tabs>
    </FormFieldEditor>
  );
};

export default ContainerEditor;
