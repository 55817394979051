import { memo, useCallback, useState } from 'react';

import Dialog from '../../Dialog';
import { useParams } from 'react-router-dom';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import ConfirmationOverlay from '../components/ConfirmationOverlay';
import { Neutral, Positive } from '../../Core/Button';
import { usePromoteForm } from './usePromoteForm';
import { ConfirmStep } from './steps/ConfirmStep';
import { MapFieldsStep } from './steps/MapFieldsStep';

const CONFIRM_STEP = 'confirm';
const MAP_FIELDS_STEP = 'map_fields';
type Step = typeof CONFIRM_STEP | typeof MAP_FIELDS_STEP;

interface FormPromotionProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function FormPromotionModal({ open, setOpen }: FormPromotionProps) {
  const { formId } = useParams<{ formId: string }>();
  const {
    promoteForm,
    isRollback,
    promote_id,
    promote_key,
    servarKeysById,
    thisFormServarIds,
    promoteToServarIds,
    mapFieldData,
    setMapFieldData
  } = usePromoteForm(formId);

  const [step, setStep] = useState<Step>(CONFIRM_STEP);
  const [showCloseConfirmOverlay, setShowCloseConfirmOverlay] = useState(false);

  const closeModal = useCallback(() => {
    setStep(CONFIRM_STEP);
    setOpen(false);
  }, []);

  const submit = async (currentStep: Step) => {
    if (currentStep === CONFIRM_STEP) {
      setStep(MAP_FIELDS_STEP);
    } else if (currentStep === MAP_FIELDS_STEP) {
      await promoteForm();
    }
  };
  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  const title =
    step === MAP_FIELDS_STEP
      ? 'Map Fields'
      : isRollback
      ? 'Rollback Form'
      : 'Promote Form';

  return (
    <Dialog
      isOpen={open}
      title={title}
      size='sm'
      onClose={() => {
        if (step === MAP_FIELDS_STEP) setShowCloseConfirmOverlay(true);
        else closeModal();
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction(step);
        }}
      >
        {step === CONFIRM_STEP && (
          <ConfirmStep
            isRollback={isRollback}
            formId={promote_id}
            formKey={promote_key}
          />
        )}
        {step === MAP_FIELDS_STEP && (
          <MapFieldsStep
            thisFormServarIds={thisFormServarIds}
            promoteToServarIds={promoteToServarIds}
            servarKeysById={servarKeysById}
            setMapFieldData={setMapFieldData}
            mapFieldData={mapFieldData}
            promoteKey={promote_key}
          />
        )}
        <div className='dialog-form-action text-center'>
          <Neutral
            onClick={() => {
              if (step === MAP_FIELDS_STEP) setShowCloseConfirmOverlay(true);
              else closeModal();
            }}
          >
            Cancel
          </Neutral>
          {step === CONFIRM_STEP && <Positive>Continue</Positive>}
          {step === MAP_FIELDS_STEP && (
            <Positive lockoutOverride={lockOutFlag}>
              {isRollback ? 'Rollback Form' : 'Promote Form'}
            </Positive>
          )}
        </div>
        <ConfirmationOverlay
          show={showCloseConfirmOverlay}
          hideIt={() => setShowCloseConfirmOverlay(false)}
          onConfirm={closeModal}
        />
      </form>
    </Dialog>
  );
}

export default memo(FormPromotionModal);
