/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useEffect, useMemo, useState } from 'react';

import HeaderFilterResultsTable from '../../components/HeaderFilterResultsTable';
import styles from './styles.module.scss';
import pageStyles from '../styles.module.scss';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { capitalizeFirstLetter, formatDate } from '../../utils/format';
import useFeatheryRedux from '../../redux';
import DocumentNavigation from '../../components/NavBar/DocumentNavigation';
import { DeleteEnvelopeModal } from '../../components/Modals';
import { renderFile } from '../FormResultsDetailPage/utils';
import useGetJwt from '../../utils/useGetJwt';
import { parseAndThrowError } from '../../redux/utils';
import { downloadFile } from '../../utils/browserUtils';
import DocumentAutofillButton from './DocumentAutofillButton';
import { getSignUrl } from '../../utils/documents';

function DocumentResultsPage() {
  const { documentId } = useParams<{ documentId: string }>();
  const doc = useAppSelector((state) => state.documents.documents[documentId]);
  const docIsPDF = doc?.type === 'pdf';
  const docHasDisplayFile = doc.display_file != null;

  const { getEnvelopes } = useFeatheryRedux();

  useEffect(() => {
    getEnvelopes({ documentId });
  }, [documentId]);

  const [envelopeToDelete, setEnvelopeToDelete] = useState<any>(null);
  const envelopes = useAppSelector((state) => state.documents.envelopes);
  const dataToRender = useMemo(() => {
    const data = Object.values(envelopes);
    return data.map((d: any) => {
      const envelopeIsPDF = d.type === 'pdf';
      let status = 'Sent';
      if (d.signed) {
        status = 'Completed';
      } else if (d.expired) {
        status = 'Expired';
      } else if (d.viewed) {
        status = 'Viewed';
      }

      const isSignable = status === 'Sent' || status === 'Viewed';

      return {
        ...d,
        updated_at_pretty: formatDate(d.updated_at),
        invite_method: capitalizeFirstLetter(d.invite_method),
        status,
        document: d.file ? (
          renderFile(
            [d.signed ? 'Completed Document' : 'Pending Document'],
            [d.file]
          )
        ) : envelopeIsPDF && isSignable ? (
          <a href={getSignUrl(d)} target='_blank' rel='noreferrer'>
            Sign Here
          </a>
        ) : (
          // if the document is signed but file is null,
          // there must have been a backend error somewhere
          <span className={styles.missingDocument}>No document</span>
        )
      };
    });
  }, [envelopes]);

  const getJwt = useGetJwt();
  const {
    toasts: { addErrorToast }
  } = useFeatheryRedux();
  const downloadLog = async (envelopeId: string) => {
    const token = getJwt();
    await FeatheryAPI.downloadEnvelopeLog(token, envelopeId)
      .then(async (res: any) => {
        if (res.status === 200) {
          const blob = await res.blob();
          downloadFile('signature_log.pdf', blob);
        } else {
          const result = await res.json();
          parseAndThrowError(result, 'Your download could not be started');
        }
      })
      .catch((e: any) => {
        addErrorToast({ text: e.toString() });
      });
  };

  return (
    <>
      <DocumentNavigation
        activeItem='envelopes'
        submitButton={
          !docIsPDF && !docHasDisplayFile ? (
            <DocumentAutofillButton />
          ) : undefined
        }
      />
      <div className={styles.documentResultsPage}>
        <div className={pageStyles.pageHeadRow}>
          <h1 className='page-head'>Envelopes</h1>
        </div>
        <HeaderFilterResultsTable
          data={dataToRender}
          columns={[
            { key: 'signer', name: 'Signer' },
            { key: 'status', name: 'Status' },
            { key: 'document', name: 'Document' },
            {
              key: 'updated_at_pretty',
              name: 'Last Updated',
              sortBy: 'updated_at'
            }
          ]}
          defaultSort={{ order: -1, key: 'updated_at' }}
          useSearch
          name='Envelope'
          onDownload={(doc: any) => downloadLog(doc.id)}
          downloadName='Audit Log'
          onDelete={(doc: any) => setEnvelopeToDelete(doc)}
        />
      </div>
      <DeleteEnvelopeModal
        close={() => setEnvelopeToDelete(null)}
        show={Boolean(envelopeToDelete)}
        envelopeId={envelopeToDelete?.id}
        signer={envelopeToDelete?.signer}
      />
    </>
  );
}

export default memo(DocumentResultsPage);
