import { useState } from 'react';
import classNames from 'classnames';
import { DocumentTriggerModal } from '../../components/Modals';

export default function DocumentAutofillButton({
  className
}: {
  className?: string;
}) {
  const [showTriggerModal, setShowTriggerModal] = useState(false);

  return (
    <>
      <button
        className={classNames('navbar-button', className)}
        onClick={() => setShowTriggerModal(true)}
      >
        Set Autofill Trigger
      </button>
      <DocumentTriggerModal
        show={showTriggerModal}
        close={() => setShowTriggerModal(false)}
      />
    </>
  );
}
