import { useRuleBuilderUpdater } from '../../../context';
import { useAppSelector } from '../../../../../../../hooks';
import { DropdownField, Switch } from '../../../../../../../components/Core';
import { RuleOperand } from '../../../context/RuleDSL';
import { RuleOperand as Operand } from '../../RuleOperand';
import classNames from 'classnames';
import ruleStyles from '../../../../../../../components/NavigationRules/styles.module.scss';
import styles from '../styles.module.scss';

const useExtractions = () => {
  const extractions = useAppSelector((state) => {
    return state.ai.extractions;
  });

  return extractions.map((extraction) => ({
    value: extraction.id,
    display: extraction.key
  }));
};

type AIExtractionActionProps = {
  action: IRuleAction;
  mode?: RuleBuilderMode;
};

const EditMode = ({ action }: AIExtractionActionProps) => {
  const { updateAction, updateOperand } = useRuleBuilderUpdater((s) => ({
    updateAction: s.updateAction,
    updateOperand: s.updateOperand
  }));

  const extractionOptions: any[] = useExtractions();
  if (extractionOptions.length === 0)
    extractionOptions.push({
      value: '',
      display: 'No extraction',
      disabled: true
    });

  const extraction = action.parameters[0] as RuleOperand;
  const runAsync = action.parameters[1] as RuleOperand;

  const handleExtractionChange = (newValue: string) => {
    if (extraction) {
      updateOperand(extraction.id, {
        type: 'value',
        value: newValue
      });
    } else {
      const operand = new RuleOperand();

      operand.type = 'value';
      operand.value = newValue;

      updateAction(action.id, {
        parameters: [operand.toJSON()]
      });
    }
  };

  const handleRunAsyncChange = (newValue: boolean) => {
    if (runAsync) {
      updateOperand(runAsync.id, {
        type: 'value',
        value: JSON.stringify(newValue)
      });
    } else {
      const operand = new RuleOperand();

      operand.type = 'value';
      operand.value = JSON.stringify(newValue);

      updateAction(action.id, {
        parameters: [extraction.toJSON(), operand.toJSON()]
      });
    }
  };

  return (
    <>
      <DropdownField
        className={classNames(
          ruleStyles.ruleTextField,
          ruleStyles.valueInput,
          styles.ruleBuilderInput
        )}
        selected={extraction?.value ?? ''}
        options={extractionOptions}
        onChange={(event: any) => handleExtractionChange(event.target.value)}
      />
      <div className={styles.inputGroup}>
        <label>
          Wait for completion
          <Switch
            checked={runAsync?.value === 'true'}
            onCheckedChange={handleRunAsyncChange}
          />
        </label>
      </div>
    </>
  );
};

const ViewMode = ({ action }: AIExtractionActionProps) => {
  const extractions = useExtractions();

  const extraction = action.parameters[0] as RuleOperand;
  const runAsync = action.parameters[1] as RuleOperand;

  const getExtractionDisplay = (_: IRuleOperand) => {
    return extractions.find((e) => e.value === extraction.value)?.display;
  };

  const getRunAsyncDisplay = (_: IRuleOperand) => {
    return runAsync?.value === 'true' ? 'Wait for completion' : null;
  };

  return (
    <>
      <Operand operand={extraction} getDisplay={getExtractionDisplay} bold />
      {runAsync?.value === 'true' && (
        <>
          <span>with options</span>
          <Operand operand={runAsync} getDisplay={getRunAsyncDisplay} bold />
        </>
      )}
    </>
  );
};

export const AIExtractionAction = ({
  action,
  mode = 'view'
}: AIExtractionActionProps) => {
  return (
    <div className={styles.actionContent}>
      {mode === 'view' ? (
        <ViewMode action={action} />
      ) : (
        <EditMode action={action} />
      )}
    </div>
  );
};
