const PLAN_KEYS = {
  FREE: 'free',
  STARTER: 'starter',
  PLUS: 'plus',
  GROWTH: 'growth',
  BUSINESS: 'business'
};

export interface PlanType {
  key: string;
  active: boolean;
  tier: number;
  title: string;
  cost: number | null;
  monthlySubmissions: number | null;
  forms: number;
  monthlyDocuments: number; // actually envelopes/month (docs that are signed or filled)
  keyPoints: string[];
  additions?: string[];
  more: boolean;
}

const PLANS: PlanType[] = [
  // Starter and Plus are no longer offered as selectable/active plans. However,
  // we have customers still on the plan so we need to support it and show
  // it in the UI for them only.
  {
    key: PLAN_KEYS.STARTER,
    active: false,
    tier: 1,
    title: 'Starter',
    cost: 49,
    monthlySubmissions: 500,
    forms: 10,
    monthlyDocuments: 5,
    keyPoints: ['10 live forms', 'Everything in Free'],
    additions: [
      'Remove Feathery branding',
      'Custom HTML & JS embeds',
      'No email restrictions'
    ],
    more: false
  },
  {
    key: PLAN_KEYS.PLUS,
    active: false,
    tier: 2,
    title: 'Plus',
    cost: 99,
    monthlySubmissions: 5000,
    forms: 20,
    monthlyDocuments: 5,
    keyPoints: [
      '5,000 submissions / month',
      '$50 / 2,500 additional',
      '20 live forms',
      'Everything in Basic'
    ],
    additions: ['Advanced logic & API connectors'],
    more: true
  },
  {
    key: PLAN_KEYS.BUSINESS,
    active: true,
    tier: 4,
    title: 'Growth & Business',
    cost: null, // custom
    monthlySubmissions: null, // custom
    forms: 9999999, // unlimited
    monthlyDocuments: 5, // must have documents ent feature to be unlimited
    keyPoints: ['Custom volume'],
    additions: [
      'Advanced logic',
      'Premium integrations',
      'Workflows, documents, & AI',
      'Analytics & A/B testing',
      'White-glove support',
      'HIPAA & GDPR compliance',
      'SSO & permissions'
    ],
    more: true
  },
  {
    key: PLAN_KEYS.FREE,
    active: true,
    tier: 0,
    title: 'Free',
    cost: 0,
    monthlySubmissions: 1500,
    forms: 5,
    monthlyDocuments: 5,
    keyPoints: [
      '1,500 submissions / month',
      '5 live forms',
      'Standard integrations',
      'Unlimited styles & components',
      'Unlimited questions',
      'Unlimited seats'
    ],
    more: true
  }
];

function chosenPlan(org: any): Record<string, any> {
  const tier = (global as any).Feathery.pricingTierOverride ?? org?.tier;
  const curPlan = PLANS.find((plan) => plan.tier === tier);
  if (org.tier === 4 && curPlan) {
    const features = org.enterprise_features;
    if (features.live_forms) curPlan.forms = features.live_forms;
    if (features.submissions) curPlan.monthlySubmissions = features.submissions;
  }
  return curPlan ?? {};
}

function onFreePlan(org: any) {
  const _tier = (global as any).Feathery.pricingTierOverride ?? org.tier;
  return _tier === 0;
}

const ctaDisplayedThisPeriod = (
  ctaTimestamp: string,
  billStart: Date | null
): boolean => {
  // If the timestamp is null, it means the user has never seen the CTA
  if (!ctaTimestamp) return false;
  // if the timestamp is not null, it means the user has seen the CTA
  // and we need to check if it was displayed during the current billing cycle
  const ctaTimestampDate = new Date(ctaTimestamp);
  return billStart !== null && ctaTimestampDate > billStart;
};

function getBillingCycleDates(subscriptionStart: Date, date = new Date()) {
  const utcDate = ({
    month = date.getUTCMonth(),
    day = subscriptionStart.getUTCDate()
  }) =>
    new Date(
      Date.UTC(
        date.getUTCFullYear(),
        month,
        day,
        subscriptionStart.getUTCHours(),
        subscriptionStart.getUTCMinutes(),
        subscriptionStart.getUTCSeconds()
      )
    );
  // get the subscription start for this month
  const subscriptionStartThisMonth = utcDate({});

  if (date < subscriptionStartThisMonth)
    return [
      utcDate({ month: date.getUTCMonth() - 1 }),
      utcDate({ day: subscriptionStart.getUTCDate() - 1 })
    ];
  return [
    utcDate({}),
    utcDate({
      month: date.getUTCMonth() + 1,
      day: subscriptionStart.getUTCDate() - 1
    })
  ];
}

export {
  PLANS,
  PLAN_KEYS,
  chosenPlan,
  ctaDisplayedThisPeriod,
  getBillingCycleDates,
  onFreePlan
};
