function RightCapital({ width = 110, height = 110 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 110 110'
      fill='none'
    >
      <path
        d='M87.2504 46.7486L78.0803 25L56.5925 36.3946L59.8587 42.553L70.0951 37.1235L65.4947 50.6824C65.4814 50.7212 64.1586 54.607 62.2867 58.6738C61.063 61.3348 60.0356 63.1412 59.2239 64.3619C57.6066 66.4331 57.0157 64.504 57.0157 64.504L57.0137 64.5081C56.9237 64.2249 56.8317 63.9121 56.7387 63.5665C55.0447 57.2671 52.079 48.8494 45.9094 48.4762C38.8963 48.054 34.2837 57.996 30.1618 68.217C29.7487 69.2424 29.3582 70.2105 29.1599 70.6184C26.1502 76.7952 23.6313 78.5852 23.6057 78.6016C23.6057 78.6016 20.7381 80.6574 22.6672 83.4105C24.5963 86.1646 27.3116 84.5034 27.3116 84.5034C27.9842 84.0944 31.5368 81.6511 35.4246 73.67C35.7303 73.0443 36.1054 72.113 36.6258 70.8239C37.7105 68.1352 39.3492 64.0715 41.2507 60.6775C42.1248 59.1164 42.8762 58.0195 43.4967 57.2476C43.7257 56.9737 45.4534 55.0763 47.1832 57.632C47.9693 59.0817 48.9569 61.4667 50.0088 65.377C50.5547 67.4073 52.217 73.5882 57.2478 73.7487C57.3122 73.7507 57.3756 73.7518 57.44 73.7518C61.1509 73.7518 64.4622 70.2974 68.1445 62.5974C70.4263 57.8273 72.0293 53.1155 72.0958 52.9192L76.6399 39.5239L80.8283 49.4556L87.2504 46.7486Z'
        fill='#5070E6'
      />
    </svg>
  );
}

export default RightCapital;
