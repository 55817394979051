/* eslint-disable react-hooks/exhaustive-deps */

import { useHistory } from 'react-router-dom';

import useFeatheryRedux from '../../redux';

import '../../style/dialog-form.css';
import { Neutral, Positive } from '../Core/Button';
import Dialog from '../Dialog';
import { uniqifyKey } from '../../utils/format';
import Label from '../Dialog/Label';
import { DropdownField, InlineTooltip, TextField } from '../Core';
import styles from '../Dialog/styles.module.scss';
import { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { onFreePlan } from '../PricingPlans/plans';

export default function PanelCopyModal({ close, panel, panelsData }: any) {
  const history = useHistory();
  const {
    createPanel,
    getPanelThemeAssetUse,
    getThemes,
    getIntegrations,
    toasts: { addToast }
  } = useFeatheryRedux();

  const org = useAppSelector((state) => state.accounts.organization);

  const [linkFields, setLinkFields] = useState(false);
  const [promotable, setPromotable] = useState(false);
  const [customSuffix, setCustomSuffix] = useState('');

  const handleClose = () => {
    setLinkFields(false);
    setPromotable(false);
    setCustomSuffix('');
    close();
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const oldKey = panel.key;
    const allKeys = Object.values(panelsData).map(
      (panel) => (panel as any).key
    );
    const newKey = uniqifyKey(oldKey, allKeys);
    const response = await createPanel({
      key: newKey,
      copy_panel_key: oldKey,
      link_fields: linkFields,
      copy_servar_suffix: customSuffix,
      promotable
    });
    await Promise.all([
      getPanelThemeAssetUse({ cached: false }),
      getThemes(),
      getIntegrations()
    ]);
    handleClose();
    addToast({
      text: (
        <>
          <strong>{newKey}</strong> was created.
        </>
      )
    });
    history.push(`/forms/${response.id}`);
  };

  return (
    <Dialog isOpen={panel} onClose={handleClose} title='Copy Form' size='xsm'>
      <Label style={{ display: 'flex' }}>
        Link Form Fields{' '}
        <InlineTooltip
          text='If copied form fields are linked to the current form, they will share the same properties and user data.'
          inline
        />
      </Label>
      <DropdownField
        className={styles.input}
        onChange={(event: any) => setLinkFields(event.target.value === 'true')}
        selected={linkFields.toString()}
        options={[
          { value: 'true', display: 'Yes' },
          { value: 'false', display: 'No' }
        ]}
      />
      {!linkFields && (
        <>
          <Label style={{ display: 'flex' }}>
            New Field Suffix{' '}
            <InlineTooltip
              text='The copied field IDs will include this custom suffix'
              inline
            />
          </Label>
          <TextField
            value={customSuffix}
            onComplete={(suffix: any) => setCustomSuffix(suffix)}
            className={styles.input}
          />
        </>
      )}
      {panel && !panel.promote_from && (
        <>
          <Label style={{ display: 'flex' }}>
            Make the copy promotable?
            <InlineTooltip
              text={`Make your changes in a promotable form copy (i.e. staging) and then promote the copy back to the original form (i.e. production). Multiple copies can be set up in a promotion chain (e.g. dev -> staging -> prod).${
                onFreePlan(org)
                  ? ' You must be on a paid plan to use this feature.\n\n\n'
                  : ''
              }`}
              inline
            />
          </Label>
          <DropdownField
            disabled={onFreePlan(org)}
            className={styles.input}
            onChange={(event: any) =>
              setPromotable(event.target.value === 'true')
            }
            selected={promotable.toString()}
            options={[
              { value: 'true', display: 'Yes' },
              { value: 'false', display: 'No' }
            ]}
          />
        </>
      )}
      <div className='dialog-form-action text-center'>
        <Neutral onClick={handleClose}>Cancel</Neutral>
        <Positive onClick={handleSubmit}>Copy</Positive>
      </div>
    </Dialog>
  );
}
