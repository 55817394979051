import { IAPIActionState } from '../../pages/types';
import type { Template } from '../types';

export const REDTAIL_GET_CONTACT: Template = {
  id: 'redtail_get_contact',
  display: 'Get Contact',
  configuration: {
    name: { type: 'textfield', label: 'Connector Name' },
    email: { type: 'textfield', label: 'Contact Email' }
  },
  defaultState: {
    name: 'Redtail Get Contact',
    template: 'redtail_get_contact',
    url: 'http://dev.otw.redtailtechnology.com/api/public/v1/contacts/search',
    method: 'GET',
    headers: {
      Authorization: 'UserkeyAuth {{redtail_token}}',
      include: 'phones, emails, addresses'
    },
    body: '',
    params: { email: '' },
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  },
  loadConfig: (state) => {
    return {
      name: state.name,
      email: state.params.email
    };
  },
  saveConfig: (state, configValues) => {
    const { name, email } = configValues;
    const newValues: Partial<IAPIActionState> = {
      name,
      params: { email }
    };

    return {
      ...state,
      ...newValues
    };
  }
};
