import {
  DropdownField,
  InlineTooltip,
  TextField
} from '../../../components/Core';
import { PlusIcon, TrashIcon, UpArrowIcon } from '../../../components/Icons';
import { getDefaultEntity } from '../utils';
import type { FieldProperties } from '../ExtractionCreateEditModal/types';
import Entity from './Entity';
import Label from '../../../components/Dialog/Label';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FieldSelectorWithModal } from '../../../components/Modals';
import useFeatheryRedux from '../../../redux';
import { useState } from 'react';
import useFieldKey from '../../../utils/useFieldKey';

type Label = {
  label: string;
  value: string;
};

type Props = {
  index: number;
  group: any;
  formData: any;
  usedFields: string[];
  fieldProperties: Record<string, FieldProperties>;
  hiddenFieldProperties: Record<string, Partial<FieldProperties>>;
  onUpdate: (key: string, value: any) => void;
  onRemove: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
};

const QuestionGroup = (props: Props) => {
  const {
    index,
    group,
    formData,
    usedFields,
    fieldProperties,
    hiddenFieldProperties,
    onUpdate,
    onRemove,
    onMoveUp,
    onMoveDown
  } = props;

  const [open, setOpen] = useState(false);
  const fieldKey = useFieldKey(false)(group?.entities?.[0]?.field_id || '');
  const {
    toasts: { addErrorToast }
  } = useFeatheryRedux();
  let saveToTooltip;
  if (group.file_type === 'csv') {
    saveToTooltip =
      'The repeating form or hidden field that the data will be saved to.';
  } else if (group.question_type === 'one_value') {
    saveToTooltip =
      'The form or hidden field that the data will be saved to. Form fields cannot be repeating.';
  } else {
    saveToTooltip =
      'The repeating form or hidden field that the data will be saved to.';
  }

  const label = group.entities.map((entity: any) => entity.name).join(', ');
  return (
    <div
      key={`question-group-${index + 1}`}
      className={styles.questionGroupSection}
    >
      <div className={styles.header}>
        <div className={styles.labelContainer}>
          <Label className={styles.label}>
            {label || `Query ${index + 1}`}
          </Label>
          <InlineTooltip text='The query will be used by AI to find answer(s) from each document, and the answer(s) will be saved into the field(s)' />
        </div>
        <div className={styles.actionContainer}>
          {index !== 0 && (
            <div
              className={classNames(styles.actionButton, styles.action)}
              onClick={onMoveUp}
            >
              <UpArrowIcon width={16} height={16} className={styles.fill} />
            </div>
          )}
          {index !== formData.question_groups.length - 1 && (
            <div
              className={classNames(styles.actionButton, styles.action)}
              onClick={onMoveDown}
            >
              <UpArrowIcon
                className={classNames(styles.flip, styles.fill)}
                width={16}
                height={16}
              />
            </div>
          )}
          <div
            className={classNames(styles.actionButton, styles.action)}
            onClick={onRemove}
          >
            <TrashIcon width={16} height={16} />
          </div>
        </div>
      </div>
      {formData.file_type === 'image' ? (
        <div className={classNames(styles.keyValuePairs, styles.moreGap)}>
          <div className={styles.keyValuePair}>
            <div className={styles.key} style={{ width: '75px' }}>
              Type{' '}
              <InlineTooltip text='What type of extraction do you want to run?' />
            </div>
            <div className={styles.value}>
              <DropdownField
                className={styles.controlField}
                selected={group.question_type}
                onChange={(event: any) =>
                  onUpdate('question_type', event.target.value)
                }
                options={[
                  { value: 'one_value', display: 'Single Value' },
                  {
                    value: 'multiple_value',
                    display: 'Multiple Values'
                  },
                  {
                    value: 'page_number',
                    display: 'Page Numbers'
                  }
                ]}
                required
              />
            </div>
          </div>
          {!['one_value', 'page_number'].includes(group.question_type) && (
            <div className={styles.keyValuePair}>
              <div className={styles.key} style={{ width: '150px' }}>
                Query Details{' '}
                <InlineTooltip text='(Optional) Description & constraints for the overall query' />
              </div>
              <div className={styles.value}>
                <TextField
                  placeholder='Only return active account assets'
                  className={styles.controlField}
                  onComplete={(newVal: string) => {
                    onUpdate('criteria', newVal);
                  }}
                  value={group.criteria}
                />
              </div>
            </div>
          )}
          <div className={styles.keyValuePair}>
            <div className={styles.entitiesContainer}>
              {group.entities?.map((entity: any, entityIndex: number) => (
                <Entity
                  key={`group-${index}-entity-${entityIndex + 1}`}
                  type={group.question_type}
                  labels={{
                    name:
                      group.question_type === 'page_number' ? 'Name' : 'Entity'
                  }}
                  helpers={{
                    name:
                      group.question_type === 'page_number'
                        ? 'The name of the page number query'
                        : 'The name of the variable you want to extract',
                    details:
                      group.question_type === 'page_number'
                        ? 'Details that the page must match to return the page number'
                        : '(Optional) Additional entity details to tune the extraction'
                  }}
                  entity={entity}
                  usedFields={usedFields}
                  fieldProperties={fieldProperties}
                  hiddenFieldProperties={hiddenFieldProperties}
                  onUpdate={(key: string | object, value?: any) => {
                    const updatedEntities = [...group.entities];
                    updatedEntities[entityIndex] =
                      typeof key !== 'string'
                        ? {
                            ...entity,
                            ...key
                          }
                        : {
                            ...entity,
                            [key]: value
                          };
                    onUpdate('entities', updatedEntities);
                  }}
                  onRemove={() => {
                    const updatedEntities = [...group.entities];
                    updatedEntities.splice(entityIndex, 1);
                    if (updatedEntities.length === 0) {
                      updatedEntities.push(getDefaultEntity());
                    }
                    onUpdate('entities', updatedEntities);
                  }}
                />
              ))}
            </div>
          </div>
          {group.question_type === 'multiple_value' && (
            <div className={styles.keyValuePair}>
              <button
                className={styles.dashedButton}
                onClick={(e) => {
                  e.preventDefault();
                  onUpdate('entities', [
                    ...(group.entities || []),
                    getDefaultEntity()
                  ]);
                }}
              >
                <PlusIcon className={styles.icon} width={12} />
                <span>Add Entity</span>
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className={classNames(styles.keyValuePairs, styles.moreGap)}>
          <div className={styles.keyValuePair}>
            <div className={styles.key}>
              Entity <InlineTooltip text='The spreadsheet column to pull' />
            </div>
            <div className={styles.value}>
              <TextField
                placeholder='Stock Price'
                className={styles.controlField}
                onComplete={(newVal: string) =>
                  onUpdate('entities', [{ ...group.entities[0], name: newVal }])
                }
                value={group.entities[0].name}
                required
              />
            </div>
          </div>
          <div className={styles.keyValuePair}>
            <div className={styles.key}>
              Save To <InlineTooltip text={saveToTooltip} />
            </div>
            <div className={styles.value}>
              <DropdownField
                value={group.entities[0].field_id || 'new'}
                options={[
                  ...(group.entities[0].field_id
                    ? [{ value: group.entities[0].field_id, display: fieldKey }]
                    : []),
                  { value: 'new', display: 'New Hidden Field' },
                  { value: 'existing', display: 'Existing Field' }
                ]}
                onChange={(event: any) => {
                  const { value } = event.target;
                  if (value === 'new') {
                    onUpdate('entities', [
                      {
                        ...group.entities[0],
                        field_id: null
                      }
                    ]);
                  } else {
                    setOpen(true);
                  }
                }}
              />
              <FieldSelectorWithModal
                open={open}
                onOpenChange={setOpen}
                onSelect={({ selectId }) => {
                  if (usedFields.includes(selectId)) {
                    addErrorToast({ title: 'Field is already used!' });
                    return;
                  }
                  onUpdate('entities', [
                    {
                      ...group.entities[0],
                      field_id: selectId || null
                    }
                  ]);
                }}
                className='tw-m-0 tw-flex-0 tw-w-min'
                hideTrigger
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionGroup;
