import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FieldSelectorWithModal } from '../..';

export function FieldReference() {
  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip id='inline-tooltip' style={{ zIndex: 99999999 }}>
          {'Use values from fields by typing {{<FieldId>}}'}
        </Tooltip>
      }
    >
      <div>
        <FieldSelectorWithModal
          placeholder='Field Reference'
          disableSelect
          copyFieldVariable
          inFormBuilder
        />
      </div>
    </OverlayTrigger>
  );
}
