/* eslint-disable no-dupe-class-members, no-use-before-define */

import { v4 as uuidv4 } from 'uuid';
import { Element, Model, Subgrid } from './models';
import { DIRECTION, MIN_LAYOUT_SIZE } from './constants';
import { fill, fit, getPxValue, isPx } from './utils';
import { Grid } from './Grid';
import { Viewport, ViewportType } from './viewports';
import { Size } from './types';
import { AXIS, AxisDirection, SIZE_UNITS } from '../../../../utils/constants';
import { isEmptyStyles } from './utils/styles';
import { objectPick } from '../../../../utils/core';
import { Gig } from '.';
import { getElementStyleType } from '../components/CellElement/utils';

import {
  calculateElementRenderData,
  calculateGridRenderData,
  getStepPropFromElementType,
  uniqueFieldKey
} from '../../../../utils/step';

import {
  BaseNode,
  CopyNodeOptions,
  NodeAddOptions,
  NodeOptions,
  NodeRemoveOptions,
  NodeUnlinkOptions
} from './abstracts';
import { normalizeDirection } from './utils/utilities';

type CellAddOptions = NodeAddOptions & {
  inheritDimension?: string;
};

type ClearableProps = 'hideIfs' | 'repeated';
type CellConsumeOptions = {
  element?: boolean;
  merge?: boolean;
  unique?: boolean;
  servarFields?: any[];
  withCellStyles?: boolean;
  clearProps?: ClearableProps[]; // prop names to clear
};

type CellRemoveOptions = NodeRemoveOptions & {
  parentOnly?: boolean;
};

type RenderDataOptions = {
  loadFonts?: boolean;
};

export class Cell extends BaseNode<Grid, Cell> {
  #element: Element | null; // If a cell is an element, it will have an additional Model set to this key for the element's details
  #meta: any; // Holds details about the Cell when the cell is unlinked (ex. theme and viewport)
  #hash: any;

  constructor(
    model: Model = new Subgrid(),
    cellOptions: NodeOptions<Grid, Cell> = {}
  ) {
    super(model, cellOptions);
    this.#element = null;
    this.#meta = {};
    this.#hash = null;
  }

  protected create(model: Model, options: NodeOptions<Grid, Cell> = {}) {
    return new Cell(model, options);
  }

  /**
   * Retrieve the Gig instance that this node is connected to.
   * @returns {Gig}
   */
  public getGig() {
    if (this.tree) {
      return (this.tree as Grid).gig;
    }

    return null;
  }

  public link(parent: Grid | Cell) {
    this.#meta = {}; // Clear the meta data of the node when linking as it's no longer needed

    super.link(parent);
  }

  public unlink(options: NodeUnlinkOptions) {
    // Set meta data when unlinking the node so that actions such as pasting the node can work properly
    this.#meta.theme = (this.getGig() as Gig).theme;
    this.#meta.viewport = (this.getGig() as Gig).viewportName;

    super.unlink(options);
  }

  public add(node?: Cell, options: CellAddOptions = {}): Cell | null {
    let _node = node;

    // If no node is provided, a new node will be created
    if (!_node) {
      const targetWidth =
        isPx(this.width) && getPxValue(this.width) < MIN_LAYOUT_SIZE
          ? this.width
          : SIZE_UNITS.FIT;

      const targetHeight =
        isPx(this.height) && getPxValue(this.height) < MIN_LAYOUT_SIZE
          ? this.height
          : SIZE_UNITS.FIT;

      _node = new Cell(
        new Subgrid({
          key: !this.isUnlinked()
            ? (this.tree as Grid).getNextContainerKey()
            : undefined,
          width: targetWidth,
          height: targetHeight
        })
      );

      // If this node has no children previously, set the axis and vertical/horizontal alignment
      if (!this.hasChildren()) {
        this.axis = AXIS.ROW;
        this.setStyle({
          vertical_align: 'center',
          horizontal_align: 'center'
        });
      }
    }

    return super.add(_node, options as NodeAddOptions);
  }

  public addSibling(
    node?: Cell,
    before?: boolean,
    options?: CellAddOptions
  ): Cell | null;

  public addSibling(
    element?: Model,
    before?: boolean,
    options?: CellAddOptions
  ): Cell | null;

  public addSibling(
    node?: unknown,
    before = false,
    options: CellAddOptions = {}
  ): Cell | null {
    if (this.isRoot()) return null;
    if (node instanceof Cell) return super.addSibling(node, before, options);

    // Create a new cell as the sibling
    const sibling = new Cell(
      new Subgrid({ key: (this.tree as Grid).getNextContainerKey() })
    );

    // If the cell is passed and not a Cell, it is an element model and will be set on the new cell
    if (node) {
      sibling.setElement(node as Model);
    }

    // If `inheritDimension` is passed, the new cell will have the specified dimension set to Fill
    if (options.inheritDimension && !sibling.isElement) {
      sibling[options.inheritDimension] = SIZE_UNITS.FILL;
    }

    return super.addSibling(sibling, before, options);
  }

  /**
   * Add a new cell in the specified direction relative to this cell. If an element is passed, it will set the
   * element on the new cell. If the direction is opposing the parent of this cell's axis, it will add an additional
   * cell to encapsulate this cell and the new cell's opposing direction.
   * @param dir - Direction of this cell to add the new cell in
   * @param element - Optional. An element model to set on the new cell
   * @returns {Cell | null}
   */
  public addDirection(dir: string, element?: Model) {
    const isColDir = [DIRECTION.Left, DIRECTION.Right].includes(dir);
    const axis = isColDir ? AXIS.COL : AXIS.ROW;
    const addBefore = dir === DIRECTION.Left || dir === DIRECTION.Top;
    const inheritedDimension = isColDir ? 'height' : 'width';
    const parent = this.parent || this;

    if (!this.isRoot()) {
      if (parent.hasSingleChild() && parent.axis !== axis) {
        // If the parent only has one child, the axis should change to the accomodate the direction of which the new cell is being added
        parent.axis = axis;
      }

      if (parent.axis === axis) {
        // If the axis is of the direction specified, simply add a sibling to this cell
        return this.addSibling(element, addBefore, {
          inheritDimension: inheritedDimension
        });
      }
    }

    // If this cell is the root cell or has more than 1 child and the axis does not equal the direction specified,
    // then we must add a new parent to allow the opposing axis of this cell and the new cell.
    return this.addNestedGrid(axis, element, addBefore, inheritedDimension);
  }

  /**
   * Replace this cell with a new cell (with the specified axis) that contains a copy of this cell and a new cell as it's sibling.
   * @param axis - New axis of the parent
   * @param element - Optional. An element that is to be added to the new child cell
   * @param addBefore - Optional. Whether to add the new child cell before or after it's sibling
   * @param inheritDimension - Optional. A dimension (width / height) that is to be set to Fill on the new child cell
   * @param parentRetainStyles - Optional. Whether this cell should retain the previously set styles or pass it down to the existing sibling node
   * @returns {Cell | null}
   */
  public addNestedGrid(
    axis: AxisDirection,
    element?: Model,
    addBefore = false,
    inheritDimension?: string,
    parentRetainStyles = false
  ): Cell | null {
    const copy = this.copy({ unique: true });

    // never copy over repeated into the new children
    copy.setRepeated(false);

    this.removeChildren({ force: true });

    if (!this.isRoot()) {
      // If the cell is not root, set a new subgrid
      this.setModel(
        new Subgrid({
          ...(this.model as Model).getRaw(),
          id: uuidv4(), // Generate a new unique ID
          key: (this.tree as Grid).getNextContainerKey(), // Get the next new unique key
          axis
        })
      );
    } else {
      // If the cell is root, update the ID and key to be unique and rotate if needed
      this.id = uuidv4();
      this.key = (this.tree as Grid).getNextContainerKey();

      if (this.axis !== axis) {
        this.rotateAxis();
      }
    }

    // Clear the styles of either the copy or this cell depending on which should retain the styles
    if (!parentRetainStyles) {
      this.clearStyle(true);
    } else {
      const styleCopy = copy.copy({ unique: true });
      copy.clearStyle(true);
      copy.inheritLayout(styleCopy); // Copy the layout styles back onto the copy
    }

    // If this is an element, remove the element
    if (this.#element) {
      this.removeElement();
      this.width = SIZE_UNITS.FILL;

      // If there is a parent, copy alignment styles into the new subgrid
      if (this.parent) {
        this.inheritLayout(this.parent, false);
      }
    }

    // Add the copy of this cell to this cell and add/return a new sibling to that cell
    return (this.add(copy) as Cell).addSibling(element, addBefore, {
      inheritDimension: inheritDimension
    });
  }

  /**
   * Add a new cell to the top of this cell
   * @param element - Element that is to be set on the new cell
   * @returns {Cell | null}
   */
  public addTop(element?: Model) {
    return this.addDirection(DIRECTION.Top, element);
  }

  /**
   * Add a new cell to the right of this cell
   * @param element - Element that is to be set on the new cell
   * @returns {Cell | null}
   */
  public addRight(element?: Model) {
    return this.addDirection(DIRECTION.Right, element);
  }

  /**
   * Add a new cell to the bottom of this cell
   * @param element - Element that is to be set on the new cell
   * @returns {Cell | null}
   */
  public addBottom(element?: Model) {
    return this.addDirection(DIRECTION.Bottom, element);
  }

  /**
   * Add a new cell to the left of this cell
   * @param element - Element that is to be set on the new cell
   * @returns {Cell | null}
   */
  public addLeft(element?: Model) {
    return this.addDirection(DIRECTION.Left, element);
  }

  /**
   * Returns whether the children of this cell can be released from itself. This means
   * that the children can properly be added to this cell's parents and this cell can be
   * removed.
   * @returns {boolean}
   */
  public canReleaseChildren() {
    return !this.isRoot() && this.hasChildren();
  }

  /**
   * Release the children of this cell meaning the children will replace this cell's position in this cell's parent. This cell
   * will then be removed.
   * @returns {boolean} Whether the children were released or not
   */
  public releaseChildren(): boolean {
    if (!this.canReleaseChildren()) {
      return false;
    }

    // If the parent only has one child, copy alignment and the axis
    if (this.parent && this.parent.hasSingleChild()) {
      this.parent.inheritLayout(this);
      this.parent.axis = this.axis; // Set the axis of the parent to this axis
    }

    // Remove just this cell
    this.remove({ parentOnly: true });

    return true;
  }

  /**
   * Split this cell into rows and return the new sibling cell that was created
   * @param element - Element that should be set on the new cell
   * @returns {Cell | null} The new sibling of this cell
   */
  public splitRows(element?: Model) {
    // To split this cell into rows, a nested grid must be created to replace this cell as the parent
    const newSibling = this.addNestedGrid(
      AXIS.ROW,
      element,
      false,
      undefined,
      true
    ) as Cell;

    // Set the dimensions of each child to Fill/Fill after splitting
    (newSibling.parent as Cell).children.forEach((child: Cell) => {
      child.width = SIZE_UNITS.FILL;
      child.height = SIZE_UNITS.FILL;
    });

    return newSibling;
  }

  /**
   * Split this cell into columns and return the new sibling cell that was created
   * @param element - Element that should be set on the new cell
   * @returns {Cell | null} The new sibling of this cell
   */
  public splitCols(element?: Model) {
    // To split this cell into columns, a nested grid must be created to replace this cell as the parent
    const newSibling = this.addNestedGrid(
      AXIS.COL,
      element,
      false,
      undefined,
      true
    ) as Cell;

    // Set the dimensions of each child to Fill/Fill after splitting
    (newSibling.parent as Cell).children.forEach((child: Cell) => {
      child.width = SIZE_UNITS.FILL;
      child.height = SIZE_UNITS.FILL;
    });

    return newSibling;
  }

  /**
   * Remove this cell
   * @param options.parentOnly - Specify whether only this cell should be removed and not it's children. If true, the children of this cell will replace this cell in it's parent's cell's children.
   * @returns
   */
  public remove({ parentOnly = false }: CellRemoveOptions = {}) {
    if (this.isRoot()) return false; // Prevent removing the root cell

    // Remove the parent and children
    if (!parentOnly) {
      const parent = this.parent as Cell;
      super.remove();

      // Root should assume the axis of it's child if only one child is remaining
      if (parent && parent.isRoot() && parent.hasSingleChild()) {
        const remainingNode = parent.getFirstChild() as Cell;
        if (remainingNode.hasChildren() && remainingNode.axis !== parent.axis) {
          parent.rotateAxis();
        }
      }
    }

    // Remove only the parent and reconnect the children to their grandparent in the same index of this cell
    if (parentOnly) {
      const grandParent = this.parent as Cell;
      const children = this.children.map((child: Cell) => child.copy());
      const parentIndex = grandParent.indexOf(this);

      super.remove();

      children.forEach((child: Cell, i) => {
        grandParent.add(child, {
          insert: parentIndex + i
        });
      });
    }

    return true;
  }

  /**
   * Consume the provided copied cell. This method will copy various details of the provided cell onto this cell. The provided
   * cell must be a copied cell.
   * @param node - Cell that is to be consumed by this cell
   * @param options.element - Whether to copy the element from the target cell to this cell
   * @param options.merge - Whether to merge the styles of this cell and the target cell
   * @param options.unique - Whether all keys, servar IDs/keys, and element IDs should be recreated
   * @param options.withCellStyles - Whether to clear the styles of the cells that are being consumed
   * @param options.clearProps - Whether to clear details such as hideIfs on the cells being consumed
   * @param options.servarFields - A list of existing servar fields that will be used to create unique servar fields if `unique` is specified
   */
  public consume(node: Cell, options: CellConsumeOptions = {}) {
    if (!node.isCopy()) return {};

    const {
      element = false,
      merge = true,
      unique = false,
      withCellStyles = true,
      clearProps = [],
      servarFields = []
    } = options;

    const originalStyles = this.getStyle(true);
    const _servarFields = [...servarFields];

    super.consume(node);

    // Retain styles if the node being consumed has none
    if (merge && !node.hasStyles(true)) {
      this.setStyle({ ...originalStyles[Viewport.Desktop] }, Viewport.Desktop);
      this.setStyle({ ...originalStyles[Viewport.Mobile] }, Viewport.Mobile);
    }

    // Remove the current element if there is one set
    if (this.isElement) {
      this.removeElement();
    }

    const copiedIdMap: Record<string, string> = {};
    // If the target node is an element and `options.element` is present, copy the element
    if (element && node.isElement) {
      this.setElement((node.element as Model).copy());
    }

    // Restructure after super.consume() to reposition/remap the new children copied from the cell
    this.restructure();

    // Iterate through descendant cells
    this.getDescendantCells().forEach((cell: Cell) => {
      if (!withCellStyles && cell.hasStyles(true)) {
        cell.clearStyle(true);
      }

      // If unique is specified, ensure each container and element have unique ids and keys
      if (unique) {
        if (!this.isUnlinked()) {
          cell.key = (this.tree as Grid).getNextContainerKey(); // Ensure cell's have a unique key
        }

        if (cell.isContainer && !!cell.model) {
          const newId = uuidv4();
          copiedIdMap[cell.id] = newId;
          cell.model.id = newId; // Set a new unique ID on elements
        }

        if (cell.isElement && !!cell.element) {
          const newId = uuidv4();
          copiedIdMap[cell.element.id] = newId;
          cell.element.id = newId; // Set a new unique ID on elements

          // If a servar is present on the element, set a new unique id and key
          if (cell.element.servar) {
            cell.element.servar.id = uuidv4();
            cell.element.servar.key = uniqueFieldKey(
              cell.element.servar.key,
              _servarFields
            );

            // Push the new servar to the servar fields array to avoid collisions while continuing through the descendant cells
            _servarFields.push(cell.element.servar);
          }
        }
      }

      // Clear certain props that typically are not copied
      if (clearProps.includes('hideIfs')) {
        // Clear hide ifs on containers
        if (cell.isContainer) {
          cell.setHideIfs([]);
        }
      }
      if (clearProps.includes('repeated')) {
        // Clear repeated on containers
        if (cell.isContainer) {
          cell.setRepeated(false);
        }
      }
    });

    return copiedIdMap;
  }

  /**
   * Resize the target axis
   * @param axis - Axis that is to be resized
   * @param size - New size that is to be set
   */
  public resize(axis: AxisDirection, size: Size): void {
    const dimension = this.getAxisSizeName(axis);

    (this.model as Model)[dimension] = `${size.value}${size.unit}`;
  }

  /**
   * Rotate the axis of this cell
   */
  public rotateAxis() {
    if (!this.axis) return;

    const LAYOUT_STYLES = ['vertical_align', 'horizontal_align'];
    const styles = this.getStyle(true);
    const layout: any = objectPick(styles[Viewport.Desktop], LAYOUT_STYLES);

    // Swap alignment styles when rotating the axis of a container
    this.setStyle(
      {
        vertical_align: layout.horizontal_align,
        horizontal_align: layout.vertical_align
      },
      Viewport.Desktop
    );

    // Swap alignment styles of mobile if the axis has not been overrided
    const mobileAxis = (this.model as Model).getKey('axis', Viewport.Mobile);
    if (styles[Viewport.Mobile] && !mobileAxis) {
      const mobileStyles: any = objectPick(
        styles[Viewport.Mobile],
        LAYOUT_STYLES
      );

      // Only modify mobile styles if they already exist to avoid forcing an override
      if (mobileStyles.vertical_align || mobileStyles.horizontal_align) {
        this.setStyle(
          {
            vertical_align: mobileStyles.horizontal_align,
            horizontal_align: mobileStyles.vertical_align
          },
          Viewport.Mobile
        );
      }
    }

    this.axis = this.axis === AXIS.COL ? AXIS.ROW : AXIS.COL;

    if (this.parent && this.parent.isRoot() && this.parent.hasSingleChild()) {
      this.parent.axis = this.axis; // Root should assume the axis of the single child
    }
  }

  /**
   * Set an element model on this cell.
   * @param element - Element that is to be set on this cell
   * @returns {boolean} Whether the element was set or not
   */
  public setElement(element?: Model): boolean {
    if (!this.isEmpty && element) {
      return false; // Prevent setting an element if one is already set
    }

    if (element && element.type !== Element.Type) {
      return false; // Prevent setting a model that is not an element
    }

    // Handle removing an element
    if (!element) {
      this.removeElement();
    } else {
      this.#element = element;

      // Spread the properties of the element onto the instance of this cell so they can be accessed directly
      for (const key in this.#element.state) {
        Object.defineProperty(this, key, {
          configurable: true,
          get: () => (this.#element as Model)[key],
          set: (value) => {
            (this.#element as Model)[key] = value;
          }
        });
      }

      // If this cell has a reference to the tree, ensure we register the element with the tree
      if (!this.isUnlinked()) {
        (this.tree as Grid).registerElement(this);
      }

      // Elements are forced to be within a Fit/Fit container
      this.width = SIZE_UNITS.FIT;
      this.model?.clearKey('width', Viewport.Mobile);

      this.height = SIZE_UNITS.FIT;
      this.model?.clearKey('height', Viewport.Mobile);
    }

    return true;
  }

  /**
   * Remove the element from this cell.
   */
  public removeElement() {
    if (this.isEmpty || !this.#element) {
      return; // Nothing to remove
    }

    // If a reference to the tree is set, ensure to unregister the element with the tree
    if (!this.isUnlinked()) {
      (this.tree as Grid).unregisterElement(this);
    }

    // Unset the properties of the element from the instance of this cell
    for (const key in this.#element.state) {
      Object.defineProperty(this, key, {
        get: undefined,
        set: undefined
      });

      delete this[key as keyof typeof this];
    }

    this.init(); // Re-define any overwritten model fields
    this.#element = null;
  }

  protected _copy(options: CopyNodeOptions = {}, parent?: Cell) {
    const node = super._copy(options, parent);

    if (this.#element) {
      node.setElement(this.#element.copy());
    }

    return node;
  }

  /**
   * Helper function that returns the properties of the cell model assigned to this cell
   * @returns {any} The properties of this cell
   */
  public getProperties() {
    return this.properties || {};
  }

  /**
   * Set new properties on the cell data of this cell
   * @param properties - Properties to be set on the cell data
   */
  public setProperties(properties: any) {
    this.properties = properties || {};
  }

  /**
   * Set new hide-ifs on the cell data of this cell
   * @param hideIfs - Hide-ifs that are to be set on the cell data
   */
  public setHideIfs(hideIfs: any) {
    this.hide_ifs = hideIfs || [];
  }

  /**
   * Set new show_logic (for hide_ifs) on the cell data of this cell
   * @param showLogic - show_logic prop that is to be set on the cell data
   */
  public setShowLogic(showLogic: boolean) {
    this.show_logic = showLogic ?? true;
  }

  /**
   * Set whether this cell should be repeated or not
   * @param repeated - Whether this cell should be repeated or not
   */
  public setRepeated(repeated: boolean) {
    this.repeated = repeated;
  }

  /**
   * Determines whether this cell is descended from a repeat or not
   * @returns {boolean} Whether this cell is descended from a repeat or not
   */
  public hasRepeatingAncestor(): boolean {
    if (!this.parent) return false;

    return this.parent.repeated || this.parent.hasRepeatingAncestor();
  }

  /**
   * Inherit the layout styles of another cell (ex. vertical/horizontal alignment)
   * @param cell - Cell to inherit the layout styles from
   * @param inheritAltLayouts - Whether to inherit alternative layout styles such as 'gap'
   */
  public inheritLayout(cell: Cell, inheritAltLayouts = true) {
    const LAYOUT = ['vertical_align', 'horizontal_align'];
    const ALT_LAYOUT = ['gap'];

    const cellStyles = cell.getStyle(true);
    const styles = (this.model as Model).getCombinedStyles();
    const hasLayout = Object.keys(objectPick(styles, LAYOUT)).length > 0;
    const hasAltLayout = Object.keys(objectPick(styles, ALT_LAYOUT)).length > 0;

    // If this cell does not have layout styles set, copy the layout styles from the target cell
    if (!hasLayout) {
      this.setStyle(objectPick(cellStyles[Viewport.Desktop], LAYOUT));

      // If mobile cell styles are set, set the mobile layout styles on this cell as well
      if (cellStyles[Viewport.Mobile]) {
        this.setStyle(
          objectPick(cellStyles[Viewport.Mobile], LAYOUT),
          Viewport.Mobile
        );
      }
    }

    // If this cell does not have the alternative layout styles set, copy the alternative layout styles from the target cell
    if (inheritAltLayouts && !hasAltLayout) {
      this.setStyle(objectPick(cellStyles[Viewport.Desktop], ALT_LAYOUT));

      // If the mobile styles are set on the target cell, set the mobile styles from target cell as well
      if (cellStyles[Viewport.Mobile]) {
        this.setStyle(
          objectPick(cellStyles[Viewport.Mobile], ALT_LAYOUT),
          Viewport.Mobile
        );
      }
    }
  }

  /**
   * Returns the styles of this cell or a hashmap of viewports with the values being the styles of each viewport (if allViewports is true).
   * @param allViewports - Whether to include the styles of all viewports
   * @returns {object}
   */
  public getStyle(allViewports = false) {
    if (allViewports) {
      const styles = this.model?.getKeyOnAllViewports('styles') || {};

      if (!styles[Viewport.Mobile]) {
        styles[Viewport.Mobile] = {};
      }

      return styles;
    }

    return this.model?.styles;
  }

  /**
   * Set styles on this cell. Optionally, styles can be set on a target viewport.
   * @param styles - Styles that are to be set
   * @param viewport - Optional. The viewport to set the styles on
   */
  public setStyle(styles: any, viewport?: ViewportType) {
    if (!this.model) return;

    if (viewport) {
      const newStyle = {
        ...this.model.getKey('styles', viewport),
        ...styles
      };

      this.model.setKey('styles', newStyle, viewport);
    } else {
      this.model.styles = {
        ...(this.model.styles || {}),
        ...styles
      };
    }
  }

  /**
   * Returns whether this cell has styles set
   * @param allViewports - Whether to check all viewports for styles set
   * @returns {boolean}
   */
  public hasStyles(allViewports = false) {
    return allViewports
      ? Object.keys(this.model?.getCombinedStyles() || {}).length > 0
      : Object.keys(this.model?.styles || {}).length > 0;
  }

  /**
   * Clear the styles on this cell.
   * @param allViewports - Whether to clear the styles on all viewports
   */
  public clearStyle(allViewports = false) {
    if (!this.model) return;

    if (allViewports) {
      this.model.setKeyOnAllViewports('styles', {});
    } else {
      this.model.styles = {};
    }
  }

  /**
   * Set the background image ID in the styles of this cell on the current viewport.
   * @param backgroundImageId - ID of the background image that is to be set
   */
  public setBackgroundImage(backgroundImageId: string) {
    this.setStyle({
      background_image_id: backgroundImageId
    });
  }

  /**
   * Returns all descendant cells of this cell. A descendant cell does not mean the cell is an immediate child of this cell.
   * @param cellDescendants - Internal use only.
   * @param child - Internal use only.
   * @returns {Array} An array of all descendant cells of this cell
   */
  public getDescendantCells(cellDescendants: any = {}, child = false) {
    if (!cellDescendants[this.uuid]) {
      cellDescendants[this.uuid] = this;
    }

    // If this cell has children, recursively call this method on the children
    if (this.hasChildren()) {
      this.children.forEach((child: Cell) =>
        child.getDescendantCells(cellDescendants, true)
      );
    }

    return child ? cellDescendants : Object.values(cellDescendants);
  }

  /**
   * Returns all descendant elements of this cell. A descendant element does not mean the element is an immediate child of this cell.
   * @param elementDescendants - Internal use only.
   * @returns {Array} An array of all descendant elements of this cell
   */
  public getDescendantElements(elementDescendants: any = {}) {
    if (this.isElement) {
      const elementType = getStepPropFromElementType(
        (this.element as Model)._type
      );

      if (!elementDescendants[elementType]) {
        elementDescendants[elementType] = [];
      }

      elementDescendants[elementType].push((this.element as Model).getRaw());
    }

    if (this.hasChildren()) {
      this.children.forEach((child) =>
        (child as Cell).getDescendantElements(elementDescendants)
      );
    }

    return elementDescendants;
  }

  /**
   * Returns the corresponding dimension name of the axis provided.
   * @param axis - Axis to retrieve the name of
   * @returns {string} 'width' or 'height'
   */
  public getAxisSizeName(axis = this.axis) {
    return axis === AXIS.COL ? 'width' : 'height';
  }

  /**
   * Returns the render data of the model whether it's an element or subgrid. Render data is used to
   * render this cell.
   * @param options.loadFonts - Whether to load the fonts found in the render data
   * @returns {object} Render data
   */
  public getRenderData({ loadFonts = false }: RenderDataOptions = {}) {
    let data: any = {};
    // If this cell is unlinked, retrieve the theme from the meta data; otherwise, from Gig
    data.theme = this.isUnlinked()
      ? this.#meta.theme
      : (this.getGig() as Gig).theme;

    // If this cell is unlinked, retrieve the viewport from the meta data; otherwise, from Gig
    data.viewport = this.isUnlinked()
      ? this.#meta.viewport
      : (this.getGig() as Gig).viewportName;

    if (!this.isElement && this.model) {
      data = {
        ...data,
        grid: this.model.getRaw(),
        root: this.isRoot()
      };
      return calculateGridRenderData(data);
    } else if (this.element) {
      const element = this.element.getRaw();
      const { style } = getElementStyleType(element);

      data = {
        ...data,
        loadFonts,
        element,
        style
      };

      return calculateElementRenderData(data);
    }

    return {};
  }

  /**
   * Returns a hash made up of the data of this cell
   * @returns {string}
   */
  public toHash() {
    return `${this.id}-${this.viewport}`;
  }

  /**
   * Returns a generic hash of this cell which excludes any unique fields such as ID, keys, etc
   * This is typically used for equality checks between identical containers.
   * @returns {string | object}
   */
  public toGenericHash(isChild = false) {
    const hash: any = {
      styles: this.getStyle(true)
    };

    if (this.isElement) {
      hash.type = 'element';
      hash.element = this._type;
    }

    if (this.isContainer) {
      hash.type = 'container';
      hash.children = this.children.map((child: Cell) => {
        return child.toGenericHash(true);
      });
    }

    if (!isChild) {
      return JSON.stringify(hash);
    }

    return hash;
  }

  /**
   * Returns a hash of the cell that is made up of its defining properties.
   * It excludes its children because if a child updates, the renderer will re-render the parent.
   * Used for comparing cells to see if they are the same to optimize rendering.
   * @returns {string}
   */
  public toStateHash() {
    const grid = this.tree;
    const cache = grid?.hashCache[this.id];
    if (cache) {
      return cache;
    }

    // compute cache and return
    const hashes: any = {
      // remove id if we are an element to not include the changing id in the hash
      model: this.model?.toHash(this.isElement),
      element: this.element?.toHash(),
      numChildren: this.children.length
    };

    this.#hash = `${this.positionId}:::${JSON.stringify(hashes)}`;
    if (grid) {
      grid.hashCache[this.id] = this.#hash;
    }
    return this.#hash;
  }

  /**
   * Returns whether removing this cell would have any effect on the visual appearance of the form.
   * @returns {boolean}
   */
  public isRedundant(): boolean {
    const model = this.model as Model;
    const IGNORE_STYLES: string[] = [];
    let redundant = true;

    if (this.isElement) {
      return false;
    }

    const hasEmptyProperties = Object.keys(model.properties || {}).length === 0;
    const hasEmptyStyles = isEmptyStyles(
      model.getCombinedStyles(),
      false,
      IGNORE_STYLES
    );

    redundant = redundant && hasEmptyStyles && hasEmptyProperties;

    // If there is only one child, check if the child is identical to this cell
    if (this.isRoot() || (this.isContainer && this.hasSingleChild())) {
      const child = this.getFirstChild();
      const hasSingleChild = this.hasSingleChild();
      const hasSameDimensions =
        (child?.width === this.width && child?.height === this.height) ||
        (this.width === fit && this.height === fit);

      redundant = redundant && hasSingleChild && hasSameDimensions;
    }

    // If there is more than one child, check if this cell is identical to the parent
    if (
      !this.isRoot() &&
      this.isContainer &&
      this.parent &&
      !this.hasSingleChild()
    ) {
      const parent = this.parent as Cell;
      const hasSingleChild = parent.hasSingleChild();
      const hasSameDimensions =
        (parent.width === this.width && parent.height === this.height) ||
        (parent.width === fit && parent.height === fit) ||
        (this.width === fill && this.height === fill);

      redundant = redundant && hasSingleChild && hasSameDimensions;
    }

    return redundant;
  }

  /**
   * Returns whether this cell is an immediate sibling of the provided node
   * @param node - Node to check whether is the sibling of the current node
   * @param dir - Optionally specify which direction of the current node to look for the sibling
   * @param explicitDir - Optionally specify if the direction provided should be treated explicitly corresponding to the parent's axis
   */
  public isImmediateSibling(
    node: Cell,
    dir?: string,
    explicitDir?: boolean
  ): boolean {
    const parent = this.parent as Cell;

    if (!parent) {
      return false; // Not possible to have a sibling if there is no parent
    }

    const index = parent.indexOf(this);
    const previousSibling = parent.get(index - 1);
    const nextSibling = parent.get(index + 1);
    const _dir = normalizeDirection(dir);

    if (_dir) {
      const isColDir = [DIRECTION.Left, DIRECTION.Right].includes(_dir);
      const isMatchingDir = isColDir
        ? parent.axis === AXIS.COL
        : parent.axis === AXIS.ROW;

      if (explicitDir && !isMatchingDir) {
        return false; // The specified direction does not match the parent's axis directions
      }

      // Return whether the previous or next sibling is the node depending on the specified direction
      return _dir === DIRECTION.Left || _dir === DIRECTION.Top
        ? previousSibling?.id === node.id
        : nextSibling?.id === node.id;
    }

    // Return whether the previous or next sibling is the node regardless of before or after
    return previousSibling?.id === node.id || nextSibling?.id === node.id;
  }

  get type() {
    if (this.#element) return this.#element.type;
    else if (this.model) return this.model.type;
    else return null;
  }

  get raw() {
    if (this.element) return this.element.getRaw();
    else if (this.model) return this.model.getRaw();
    else return {};
  }

  get renderData() {
    return this.getRenderData();
  }

  get height(): string {
    const model = this.model as Model;

    if (!model.height && this.parent) {
      return this.parent.height;
    }

    return model.height;
  }

  set height(value) {
    (this.model as Model).height = value;
  }

  get width(): string {
    const model = this.model as Model;

    if (!model.width && this.parent) {
      return this.parent.width;
    }

    return model.width;
  }

  set width(value) {
    (this.model as Model).width = value;
  }

  get element(): any {
    return this.#element;
  }

  get isEmpty(): boolean {
    return !this.isElement && !this.hasChildren();
  }

  get isElement(): boolean {
    return !!this.#element && this.#element.type === Element.Type;
  }

  get isContainer(): boolean {
    return !this.isElement;
  }

  get viewport(): ViewportType {
    return this.tree ? this.tree.viewportName : this.#meta?.viewport || null;
  }

  get hash() {
    return this.toHash();
  }
}
