import { IAPIActionState } from '../../pages/types';
import type { Template } from '../types';

export const SALESFORCE_CREATE: Template = {
  id: 'salesforce_create',
  display: 'Create Record',
  defaultState: {
    name: 'Salesforce Create',
    template: 'salesforce_create',
    url: '',
    method: 'POST',
    headers: {
      Authorization: 'Bearer {{salesforce_token}}'
    },
    body: '{}',
    params: {},
    tokens: { salesforce: '' },
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  },
  configuration: {},
  loadConfig: (state) => {
    return {
      name: state.name,
      object: state.url.split('/').filter(Boolean).at(-1),
      field_values: JSON.parse(state.body)
    };
  },
  saveConfig: (state, configValues) => {
    const { name, credential, object, field_values } = configValues;
    const newValues: Partial<IAPIActionState> = {
      name,
      body: JSON.stringify(field_values, null, 2),
      url: `${credential.data.userCustomDomainUrl}/services/data/v57.0/sobjects/${object}`,
      tokens: { salesforce: credential.credentialKey }
    };

    return {
      ...state,
      ...newValues
    };
  }
};
