import { MouseEvent, useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import styles from './styles.module.scss';
import { CheckIcon } from '../Icons';
import Tag from '../Core/Tag';
import classNames from 'classnames';
import AuthorizeOauthButton from './shared/AuthorizeOauthButton';
import SaveButton from './shared/SaveButton';
import useFeatheryRedux from '../../redux';
import { PUBLISH_STATUS } from '../../redux/utils';
import useIntegrations from './useIntegrations';
import { INTEGRATIONS } from './types';
import { useParams } from 'react-router-dom';

const IntegrationsSidebar = ({
  children,
  className,
  integrationInfo,
  hideSaveButton = false,
  hideConnectButtons = false,
  showConnectedStatus = true,
  entityType = 'form',
  authorizeData,
  isPartial,
  onSubmitCustom,
  customError
}: {
  children?: JSX.Element | JSX.Element[] | string;
  className?: string;
  integrationInfo: any;
  hideSaveButton?: boolean;
  hideConnectButtons?: boolean;
  showConnectedStatus?: boolean;
  entityType?: 'form' | 'ai';
  authorizeData?: {
    authorize: () => void;
    oauthToken: string | undefined;
    domain?: string;
  };
  isPartial?: boolean;
  onSubmitCustom?: (active: boolean) =>
    | void
    | undefined
    | Record<string, any>
    | {
        isUpdate: boolean;
        apiKey?: string;
        metadata?: any;
        secretMetadata?: any;
      };
  customError?: string;
}) => {
  const { formId, extractionId } =
    useParams<{ formId: string; extractionId: string }>();

  const { createIntegration, editIntegration } = useFeatheryRedux();
  const integration = useIntegrations({
    type: integrationInfo.key,
    panelId: formId,
    extractionId,
    entityType,
    includeInactive: true
  });
  const [isActive, setIsActive] = useState<boolean>(
    Boolean(integration?.data.active)
  );
  const [error, setError] = useState('');
  const errorMessage = customError || error;

  const [submittingStatus, setSubmittingStatus] = useState(
    PUBLISH_STATUS.ACTIVE
  );

  const onSubmit = async (event: React.FormEvent, newIsActive: boolean) => {
    event.preventDefault();
    if (!onSubmitCustom) return;

    const customResult = onSubmitCustom(newIsActive);
    if (!customResult) return;

    setError('');
    setSubmittingStatus(PUBLISH_STATUS.ACTIVE);

    const {
      isUpdate = false,
      apiKey = '',
      metadata,
      secretMetadata
    } = customResult;

    let payload: Record<string, any> = {
      type: integrationInfo.key,
      active: newIsActive,
      entityType,
      metadata
    };
    if (entityType === 'form') {
      payload = {
        ...payload,
        panelId: formId,
        api_key: apiKey,
        secret_metadata: secretMetadata,
        staticIp:
          integrationInfo.key === INTEGRATIONS.POSTGRES ? true : undefined
      };
    } else {
      payload = { ...payload, extractionId };
    }

    await (isUpdate ? editIntegration(payload) : createIntegration(payload))
      .then(() => {
        setIsActive(newIsActive);
        setSubmittingStatus(PUBLISH_STATUS.FULFILLED);
      })
      .catch((e: any) => setError(e.message));
  };

  let status;
  if (errorMessage) {
    status = <Alert variant='danger'>{errorMessage}</Alert>;
  } else {
    if (submittingStatus === PUBLISH_STATUS.FULFILLED) {
      if (isPartial) {
        status = (
          <Alert variant='danger'>
            We could not turn on your integration because a required field is
            not filled out.
          </Alert>
        );
      } else {
        status = (
          <Alert variant='success'>
            Success! Your settings have been updated!
          </Alert>
        );
      }
    }
  }

  return (
    <Card className={styles.sidebarContainer}>
      <div className={styles.sidebarSection}>
        <h4>{integrationInfo.title}</h4>
        <p className={styles.integrationDescription}>
          {integrationInfo.description}
        </p>
        {showConnectedStatus && renderConnectedStatus(isActive)}
        <span className={styles.tagsLabel}>Tags</span>
        <div className={styles.tagsContainer}>
          {integrationInfo.tags &&
            integrationInfo.tags.map((tag: any) => <Tag key={tag}>{tag}</Tag>)}
        </div>
      </div>
      <div className={styles.horizontalDivider} />
      <div className={classNames(styles.sidebarSection, className)}>
        {authorizeData && (
          <AuthorizeOauthButton
            authorize={authorizeData.authorize}
            hasToken={!!authorizeData.oauthToken}
            integrationEnabled={isActive}
            domain={authorizeData.domain}
          />
        )}
        {children}
        {onSubmitCustom && (
          <div className={styles.connectButtonContainer}>
            {!hideSaveButton && (
              <SaveButton
                submittingStatus={submittingStatus}
                onClick={(event: MouseEvent) => onSubmit(event, isActive)}
              />
            )}
            {!hideConnectButtons && !isActive && (
              <button
                className={classNames('btn-custom', styles.connectButton)}
                onClick={(event: MouseEvent) => onSubmit(event, true)}
              >
                Connect
              </button>
            )}
            {!hideConnectButtons && isActive && (
              <button
                className={classNames('btn-custom', styles.connectButton)}
                onClick={(event: MouseEvent) => onSubmit(event, false)}
              >
                Disconnect
              </button>
            )}
          </div>
        )}
        {status}
      </div>
    </Card>
  );
};

const renderConnectedStatus = (isConnected: boolean) => {
  if (isConnected) {
    return (
      <span>
        Status: <CheckIcon height={18} width={18} />{' '}
        <span className={styles.connectedStatusText}>Connected</span>
      </span>
    );
  } else {
    return (
      <span>
        Status:{' '}
        <span className={styles.disconnectedStatusText}>Not Connected</span>
      </span>
    );
  }
};

export default IntegrationsSidebar;
