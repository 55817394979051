import { ComponentProps, useMemo, useState } from 'react';
import useMounted from '../../../utils/useMounted';

import { Spinner } from '../Spinner';
import { cn } from '../../../utils/cn';

interface LockoutButtonProps extends Omit<ComponentProps<'button'>, 'title'> {
  icon?: React.ReactNode;

  lockoutOverride?: boolean;
  setLockoutOverride?: (lockout: boolean) => void;

  loading?: boolean;
  enableLockout?: boolean;
}

/**
 * A button component with lockout functionality and loading state.
 *
 * @component
 * @param {React.ReactNode} [props.icon] - Optional icon to display in the button. Replaced by spinner when loading or locked out.
 * @param {boolean} [props.lockoutOverride=false] - External control for the lockout state.
 * @param {function} [props.setLockoutOverride] - Function to set the external lockout state.
 * @param {boolean} [props.loading=false] - Indicates if the button is in a loading state.
 * @param {boolean} [props.enableLockout=true] - Enables or disables the lockout functionality.
 * @returns {React.Component} The LockoutButton component.
 */

function LockoutButton({
  icon,
  children,
  disabled,
  className,
  onClick = () => {},
  lockoutOverride = false,
  setLockoutOverride,
  loading = false,
  enableLockout = true,
  ...rest
}: LockoutButtonProps) {
  const [lockedOut, setLockedOut] = useState(false);
  const mounted = useMounted();
  const [thisButtonClicked, setThisButtonClicked] = useState(false);

  const handleClick = useMemo(
    () => async (e: any) => {
      setThisButtonClicked(true);
      if (enableLockout) {
        setLockedOut(true);
        setLockoutOverride?.(true);
      }
      await onClick(e);
      // Only reset if still mounted
      if (mounted.current) {
        setLockedOut(false);
        setLockoutOverride?.(false);
      }
    },
    [enableLockout, setLockedOut, mounted, onClick]
  );

  const isLockedOut = lockoutOverride || lockedOut;

  return (
    <button
      onClick={handleClick}
      disabled={disabled || loading || isLockedOut}
      className={cn('btn', 'btn-custom', className)}
      {...rest}
    >
      {loading || (isLockedOut && thisButtonClicked) ? (
        <Spinner
          style={{ width: '16px', height: '16px', marginRight: '10px' }}
        />
      ) : (
        !!icon && icon
      )}
      {/* Must wrap text in span so Google Translate doesn't replace spinner during translation. That breaks the app */}
      <span>{children}</span>
    </button>
  );
}

export default LockoutButton;
