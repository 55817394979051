import { memo } from 'react';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { isDark } from '../../../utils/color';

type Props = {
  label?: string;
  highlighted?: boolean;
  category: string;
  showLabel?: boolean;
  children: React.ReactNode;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement>) => void;
  overflow?: boolean;
  background?: string;
  className?: string;
  style?: React.CSSProperties;
};

export function ElementSection({
  label,
  highlighted,
  category,
  showLabel,
  children,
  onMouseDown,
  overflow = true,
  background = 'ffffff',
  className,
  style = {}
}: Props): JSX.Element {
  return (
    <div
      id={`element-section-${category}`}
      className={classNames(
        className,
        styles.elementSection,
        styles[`element-section-${category}`],
        highlighted && styles.highlighted,
        onMouseDown && styles.highlightable,
        overflow && styles.overflow,
        isDark(background) && styles.dark
      )}
      style={{ ...style, background: `#${background}` }}
      onMouseDown={(event) => {
        if (onMouseDown) {
          event.stopPropagation();
          onMouseDown(event);
        }
      }}
    >
      <div className={showLabel ? styles.headingWrapper : ''}>
        {label && (
          <div className={showLabel ? styles.title : styles.header}>
            {label}
          </div>
        )}
        {showLabel && <div className={styles.label}>PRIMARY</div>}
      </div>
      {children}
    </div>
  );
}

export default memo(ElementSection);
