import { PageProps } from './types';
import { DynamicPage } from './configurations/DynamicPage';
import { CustomPage } from './configurations/CustomPage';
import { TEMPLATES } from '../templates';
import { useEffect } from 'react';
import { SalesforceCreatePage } from './configurations/Salesforce/SalesforceCreatePage';
import { SalesforceUpdatePage } from './configurations/Salesforce/SalesforceUpdatePage';

export const ConfigureRequestPage = (props: PageProps) => {
  const { state, setState } = props;

  useEffect(() => {
    // legacy convert 'salesforce' template into 'salesforce_prefill'
    if (state.template && state.template === 'salesforce') {
      setState({
        ...state,
        template: 'salesforce_prefill'
      });
    }
  }, [state]);

  if (state.template === 'salesforce_create') {
    return <SalesforceCreatePage {...props} />;
  }
  if (state.template === 'salesforce_update') {
    return <SalesforceUpdatePage {...props} />;
  }
  if (state.template && state.template in TEMPLATES) {
    return <DynamicPage {...props} />;
  }

  return <CustomPage {...props} />;
};
