import { useEffect, useState } from 'react';
import { TextField } from '../../../components/Core';
import { Neutral, Positive } from '../../../components/Core/Button';
import { AIExtractionEntry } from '../types';
import useField from '../../../utils/useField';
import Dialog from '../../../components/Dialog';
import Label from '../../../components/Dialog/Label';
import classNames from 'classnames';
import styles from './styles.module.scss';

export type AddValuePayload = {
  entryId: string;
  value: string;
}[];

type Props = {
  entries: AIExtractionEntry[];
  show?: boolean;
  onClose?: () => void;
  onComplete?: (val: AddValuePayload) => void;
};

const AIAddValueModal = ({
  entries,
  show = false,
  onClose = () => {},
  onComplete = () => {}
}: Props) => {
  const getField = useField();

  const [inputValues, setInputValues] = useState<string[]>(
    new Array(entries.length).fill('')
  );

  const resetValues = () => {
    setInputValues(new Array(entries.length).fill(''));
  };

  useEffect(() => {
    resetValues();
  }, [show, entries]);

  const handleClose = () => {
    onClose();
    resetValues();
  };

  const handleComplete = () => {
    const response = inputValues.map((val, index) => {
      return {
        entryId: entries[index].id,
        value: val
      };
    });

    onComplete(response);
    handleClose();
  };

  return (
    <Dialog isOpen={show} onClose={handleClose} title='Add Value' size='sm'>
      {show && (
        <div className={styles.addValueModal}>
          <div className={styles.content}>
            {entries.map((entry, index) => (
              <div className={styles.row} key={entry.id}>
                <Label>{getField(entry.fieldId).key}</Label>
                <TextField
                  placeholder='Enter a value'
                  value={inputValues[index]}
                  onComplete={(val: string) => {
                    setInputValues((prev) => {
                      const newValues = [...prev];
                      newValues[index] = val;
                      return newValues;
                    });
                  }}
                />
              </div>
            ))}
          </div>
          <div className={classNames(styles.footer, 'dialog-form-action')}>
            <Neutral onClick={() => handleClose()}>Cancel</Neutral>
            <Positive onClick={() => handleComplete()}>Create</Positive>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default AIAddValueModal;
