import { Positive } from '../../../../../../components/Core/Button';
import Dialog from '../../../../../../components/Dialog';
import styles from '../../../ConfirmationModal/styles.module.scss';

type InvalidDSLModalProps = {
  title?: string;
  message?: string;
  show?: boolean;
  setShow?: (value: boolean) => void;
};

export const InvalidDSLModal = (props: InvalidDSLModalProps) => {
  const {
    show = false,
    setShow = () => {},
    title = 'Your rule is invalid',
    message = 'Please fix your rule before saving.'
  } = props;

  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      title={title}
      size='sm'
      shouldShowCloseIcon
    >
      <div className={styles.message}>{message}</div>
      <div className={styles.actions}>
        <Positive onClick={() => setShow(false)}>Okay</Positive>
      </div>
    </Dialog>
  );
};
