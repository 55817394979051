import { IAPIActionState } from '../../pages/types';
import type { Template } from '../types';

export const ORION_CREATE: Template = {
  id: 'orion_create',
  display: 'Create Portfolio',
  configuration: {
    name: { type: 'textfield', label: 'Connector Name' },
    firstname: { type: 'textfield', label: 'First Name' },
    lastname: { type: 'textfield', label: 'Last Name' }
  },
  defaultState: {
    name: 'Orion Create Portfolio',
    template: 'orion_create',
    url: 'https://api.orionadvisor.com/api/v1/Portfolio/Accounts/NewPortfolio',
    method: 'POST',
    headers: {
      Authorization: 'Session {{orion_token}}'
    },
    body: JSON.stringify({
      name: '',
      billing: { statusType: 'Pending Review' },
      client: { portfolio: { firstName: '', lastName: '' } }
    }),
    params: {},
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  },
  loadConfig: (state) => {
    const body = JSON.parse(state.body);
    return {
      name: state.name,
      firstName: body.client.portfolio.firstName,
      lastName: body.client.portfolio.lastName
    };
  },
  saveConfig: (state, configValues) => {
    const { name, firstName, lastName } = configValues;
    const newValues: Partial<IAPIActionState> = {
      name,
      body: JSON.stringify({
        name: `${firstName} ${lastName}`,
        billing: { statusType: 'Pending Review' },
        client: { portfolio: { firstName, lastName } }
      })
    };

    return {
      ...state,
      ...newValues
    };
  }
};
