import { memo } from 'react';

import useFeatheryRedux from '../../redux';
import Dialog from '../Dialog';
import { Negative, Neutral } from '../Core/Button';

function DeleteExtractionModal({ show, close, extractionId, name }: any) {
  const {
    deleteAIExtraction,
    toasts: { addErrorToast }
  } = useFeatheryRedux();

  const handleDelete = async (event: any) => {
    event.preventDefault();
    if (!extractionId) return;
    try {
      await deleteAIExtraction({ extractionId: extractionId });
      close();
    } catch (error) {
      addErrorToast('Could not delete extraction. Please try again.');
    }
  };

  return (
    <Dialog
      isOpen={show}
      onClose={() => close()}
      shouldShowCloseIcon={false}
      title={`Are you sure you want to delete ${name}?`}
      alignCenter
      style={{ padding: '24px 40px' }}
    >
      <div className='dialog-form-action'>
        <Neutral onClick={close}>Cancel</Neutral>
        <Negative onClick={handleDelete}>Yes, Delete Extraction</Negative>
      </div>
    </Dialog>
  );
}

export default memo(DeleteExtractionModal);
