import { ContextOnSubmit } from '@feathery/react/dist/types/Form';
import { Amplify, Auth } from 'aws-amplify';

export const cognitoPoolConfig = {
  userPoolWebClientId: '26b9r5mm19b14aaccedl351o8f',
  appRedirectUrl: `https://${location.host}/sso`,
  region: 'us-west-1',
  userPoolId: 'us-west-1_kxCz8lJDH',
  domain: 'feathery-sso.auth.us-west-1.amazoncognito.com',
  responseType: 'token', // token for implicit, code for code grant
  getRedirectUrl: (idp: string, redirectDomain = location.host) => {
    const prefix = redirectDomain.includes('localhost')
      ? 'http://'
      : 'https://';
    const params = new URLSearchParams({
      client_id: cognitoPoolConfig.userPoolWebClientId,
      response_type: cognitoPoolConfig.responseType,
      redirect_uri: `${prefix}${redirectDomain}/sso`,
      identity_provider: idp
    });
    // Scope had issues when putting into URLSearchParams
    return `https://${
      cognitoPoolConfig.domain
    }/oauth2/authorize?scope=email+openid&${params.toString()}`;
  }
};

let configured = false;
export const configureSSO = () => {
  const idP = {
    userPoolWebClientId: cognitoPoolConfig.userPoolWebClientId,
    redirectSignIn: cognitoPoolConfig.appRedirectUrl,
    redirectSignOut: cognitoPoolConfig.appRedirectUrl,
    scopes: ['email', 'openid']
  };

  if (configured) return;
  Amplify.configure({
    Auth: {
      region: cognitoPoolConfig.region,
      userPoolId: cognitoPoolConfig.userPoolId,
      userPoolWebClientId: idP.userPoolWebClientId,
      authenticationFlowType: 'CUSTOM_AUTH', // Use 'CUSTOM_AUTH' for SAML-based SSO
      oauth: {
        domain: cognitoPoolConfig.domain,
        scope: idP.scopes,
        redirectSignIn: idP.redirectSignIn,
        redirectSignOut: idP.redirectSignOut,
        responseType: cognitoPoolConfig.responseType
      }
    }
  });
  configured = true;
};

export const checkUser = async (setSsoUser: any, fetchResources: any) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    fetchResources();
    setSsoUser(user);
  } catch {
    setSsoUser(null);
  }
};

export const ssoRedirect = (context: ContextOnSubmit) => {
  const userEmail = context.submitFields['feathery-sso-login'].value ?? '';

  const setError = () => {
    context.setFieldErrors({ 'feathery-sso-login': 'Invalid email' });
  };

  if (typeof userEmail !== 'string') {
    setError();
  } else if (userEmail.endsWith('+oktatest@feathery.io')) {
    location.href = cognitoPoolConfig.getRedirectUrl(
      'okta-test',
      'localhost:3000'
    );
  } else if (userEmail.endsWith('+onelogin@feathery.io')) {
    if (location.href.includes('localhost')) {
      location.href = cognitoPoolConfig.getRedirectUrl(
        'onelogin-localhost',
        'localhost:3000'
      );
    } else if (location.href.includes('netlify')) {
      location.href = cognitoPoolConfig.getRedirectUrl(
        'onelogin-test',
        location.host
      );
    } else location.href = cognitoPoolConfig.getRedirectUrl('onelogin-test');
  } else if (userEmail.endsWith('+googletest@feathery.io')) {
    location.href = cognitoPoolConfig.getRedirectUrl('google-test');
  } else if (userEmail.endsWith('feathery.io')) {
    location.href = cognitoPoolConfig.getRedirectUrl(
      'feathery-active-directory'
    );
  } else if (userEmail.endsWith('@wvuf.org')) {
    location.href = cognitoPoolConfig.getRedirectUrl('wvuf-onelogin');
  } else if (userEmail.endsWith('@sharp.com')) {
    location.href = cognitoPoolConfig.getRedirectUrl('sharp-secureauth');
  } else if (userEmail.endsWith('@paycor.com')) {
    location.href = cognitoPoolConfig.getRedirectUrl('paycor-active-directory');
  } else if (userEmail.endsWith('@intellum.com')) {
    location.href = cognitoPoolConfig.getRedirectUrl('intellum-google');
  } else if (userEmail.endsWith('@persefoni.com')) {
    location.href = cognitoPoolConfig.getRedirectUrl('persefoni-okta');
  } else if (userEmail.endsWith('@guidepointsecurity.com')) {
    location.href = cognitoPoolConfig.getRedirectUrl('guidepoint-okta');
  } else if (userEmail.endsWith('@darlingconsulting.com')) {
    location.href = cognitoPoolConfig.getRedirectUrl('darling-okta');
  } else if (userEmail.endsWith('@shift4.com')) {
    location.href = cognitoPoolConfig.getRedirectUrl('shift4-okta');
  } else if (
    userEmail.endsWith('@cashcofinancial.com') ||
    userEmail.endsWith('@venuefinancial.com')
  ) {
    location.href = cognitoPoolConfig.getRedirectUrl('cashco-active-directory');
  } else {
    setError();
  }
};

export const isAmplifyConfigured = () => {
  try {
    // Attempt to retrieve a configuration value
    const config = Amplify.configure();
    // @ts-expect-error configure is typed to return an empty object
    return config.Auth !== undefined; // Check if the Auth configuration is set
  } catch (error) {
    return false;
  }
};
