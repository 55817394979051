import { memo, useEffect, useState } from 'react';

import { ElementPanel, FlowPanel } from '../Panels';
import elementEntries from './elementEntries';
import { getStepPropFromElementType } from '../../utils/step';
import { useParams } from 'react-router-dom';
import { AssetActionPrompt } from './components';
import useIntegrations from '../FormIntegrations/useIntegrations';
import { INTEGRATIONS } from '../FormIntegrations';
import { RightArrowIcon } from '../Icons';
import { Viewport } from '../RenderingEngine/GridInGrid/engine';
import useViewport from '../../hooks/useViewport';
import { SELECTION_PANEL_TABS } from '../../types/formBuilder';
import styles from './styles.module.scss';
import { useAppSelector } from '../../hooks';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../Core/Tabs';
import { cn } from '../../utils/cn';

const INTEGRATION_CATEGORIES = [
  {
    // Stripe
    getMessage: (integrations: any) => {
      const integ = integrations[INTEGRATIONS.STRIPE];
      return integ && integ.data.metadata.checkout_type === 'custom'
        ? ''
        : 'To use this field, set up your Stripe integration to collect payments via your form.';
    },
    elements: ['payment_method']
  }
];

function filterIntegrationFields(elements: any, types: any) {
  const filtered: any = [];
  const entries = Object.entries(elements);

  types.forEach((type: any) => {
    entries.forEach(([key, element]: [string, any]) => {
      if (key === type || element.dragPayload?.type === type) {
        filtered.push(element);
      }
    });
  });

  return filtered;
}

export function updateAllowedFields(elements: any, integrations: any) {
  INTEGRATION_CATEGORIES.forEach((c) => {
    filterIntegrationFields(elements, c.elements).forEach(
      (e: any) => (e.message = c.getMessage(integrations))
    );
  });
  return elements;
}

interface SelectionPanelProps {
  disabled: boolean;
  handleUpdates: (op: any) => void;
  setShowConnectionsPanel: (show: boolean) => void;
}
function SelectionPanel({
  disabled = false,
  handleUpdates,
  setShowConnectionsPanel
}: SelectionPanelProps) {
  const { formId } = useParams<{ formId: string }>();
  const { setViewport } = useViewport();
  const integrations = useIntegrations({
    types: [INTEGRATIONS.FIREBASE, INTEGRATIONS.STYTCH, INTEGRATIONS.STRIPE],
    panelId: formId
  });
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const { elementType: focusElementType, elementId } = useAppSelector(
    (state) => state.formBuilder.focusedElement
  );

  const account = useAppSelector((state) => state.accounts.account);
  const themeEditAllowed =
    account.role === 'admin' || account.permission_edit_theme;

  // Initially load all elements, then filter if corresponding integrations aren't enabled
  const [elements, setElements] = useState(elementEntries);

  const elementStepProp = getStepPropFromElementType(focusElementType);
  const focusedElement = workingSteps[activeStepId][elementStepProp].find(
    (el: any) => el.id === elementId
  );

  useEffect(() => {
    const newElements = { ...elements };
    setElements(updateAllowedFields(newElements, integrations));
  }, [integrations]);

  return (
    <div
      className={cn(styles.selectionPanel, 'tw-h-[calc(100vh-55px)] tw-flex')}
    >
      <Tabs className='tw-w-full' defaultValue={SELECTION_PANEL_TABS.FLOW}>
        <TabsList unstyled className='legacyTabsList !tw-h-[60px]'>
          <TabsTrigger
            unstyled
            className='legacyTabsTriggerFlex'
            value={SELECTION_PANEL_TABS.FLOW}
          >
            Flow
          </TabsTrigger>
          <TabsTrigger
            unstyled
            className='legacyTabsTriggerFlex'
            value={SELECTION_PANEL_TABS.ELEMENTS}
          >
            Elements
          </TabsTrigger>
        </TabsList>
        <div className='tw-flex tw-h-[calc(100vh-115px)] tw-flex-col'>
          <TabsContent
            value={SELECTION_PANEL_TABS.FLOW}
            unstyled
            className='legacyTabsContent tw-flex-1 tw-overflow-y-auto'
          >
            <FlowPanel setShowConnectionsPanel={setShowConnectionsPanel} />
          </TabsContent>
          <TabsContent
            value={SELECTION_PANEL_TABS.ELEMENTS}
            unstyled
            className='legacyTabsContent tw-w-[210px] tw-flex-1 tw-overflow-y-auto'
          >
            {disabled && (
              <div className={styles.disabledMask}>
                Elements cannot be added in Mobile view
                <span
                  className={styles.disabledCta}
                  onClick={() => setViewport(Viewport.Desktop)}
                >
                  Desktop View <RightArrowIcon />
                </span>
              </div>
            )}
            <ElementPanel elements={elements} handleUpdates={handleUpdates} />
          </TabsContent>
          {themeEditAllowed && (
            <AssetActionPrompt
              element={focusedElement}
              handleUpdates={handleUpdates}
            />
          )}
        </div>
      </Tabs>
    </div>
  );
}

export default memo(SelectionPanel);
