import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import {
  CheckboxField,
  CollapsibleSection,
  ContextMenu,
  DropdownField,
  DropdownMultiField,
  InlineTooltip,
  NumberInput,
  PropertyLabel,
  TextField
} from '../../../Core';
import {
  ACTION_OPTIONS,
  ActionOption,
  AUTH_TARGET_ACTIONS,
  COLLABORATOR_ACTIONS,
  ONLY_ONE_ACTION,
  SOCIAL_LOGIN_OPTIONS
} from '../../utils';

import FieldSelectorWithModal from '../../../Modals/FieldSelectorWithModal';
import { useParams } from 'react-router-dom';
import useFeatheryRedux from '../../../../redux';
import { useAppSelector } from '../../../../hooks';
import { useNextStepDropdownOptions } from '../../../../hooks/useNextStepDropdownOptions';
import { produce } from 'immer';
import { OpenOverflowIcon, RightArrowIcon, TrashIcon } from '../../../Icons';
import { useGlobalMouseDownToggle } from '../../../Core/util';
import styles from './styles.module.scss';
import controlStyles from '../../../../components/Modals/UserCollaborationModal/styles.module.scss';
import { useStripeActions } from './useStripeActions';
import { useActiveAuthIntegration } from '../../../FormIntegrations/useIntegrations';

export interface ClickActionSectionProps {
  title: string;
  elementId?: string;
  elementType: 'button' | 'text' | 'container';
  properties: Record<string, any>;
  actionOptions: ActionOption[];
  labelData?: any;
  handlePropChange: (p: { properties: { [k: string]: any } }) => void;
  isTheme: boolean;
  inRepeatContainer?: boolean;
  repeatingContainer?: boolean;
}

function ClickActionSection({
  title,
  actionOptions,
  elementType,
  elementId = '',
  properties = {},
  labelData = () => ({}),
  inRepeatContainer = false,
  repeatingContainer = false,
  handlePropChange,
  isTheme
}: ClickActionSectionProps) {
  const { formId } = useParams<{ formId: string }>();
  const activeAuthInteg = useActiveAuthIntegration(formId);
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const {
    formBuilder: { updateNavRulesByElement }
  } = useFeatheryRedux();

  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const activeStep = workingSteps[activeStepId];

  const nextStepSelected = useMemo(
    () =>
      activeStep?.next_conditions.find(
        (cond: any) => cond.element_id === elementId
      )?.next_step ?? '',
    [activeStep, elementId]
  );
  const nextStepOptions = useNextStepDropdownOptions(activeStepId);

  const [actionData, setActionData] = useState<any[]>([]);

  const { StripeActionSection, PaymentProductContextMenu } = useStripeActions();

  const extractions = useAppSelector((state) => state.ai.extractions);
  const documents = useAppSelector((state) => state.documents.documents);

  const repeatingContainerOptions = useMemo(() => {
    const elementOptions: { value: string; label: string }[] = [];
    if (!activeStep) return []; // If in theme
    // add all subgrids with repeated true
    elementOptions.push(
      ...activeStep.subgrids
        .filter((subgrid: any) => subgrid.repeated)
        .map((subgrid: any) => ({
          value: subgrid.id,
          display: subgrid.key
        }))
        .sort((a: any, b: any) => {
          a = a.display.toLowerCase();
          b = b.display.toLowerCase();
          return a > b ? 1 : b > a ? -1 : 0;
        })
    );
    return elementOptions;
  }, [activeStep]);

  useLayoutEffect(() => {
    const actions = properties.actions ?? [];
    setActionData(actions.length > 0 ? initAllActionProperties(actions) : [{}]);
  }, [properties.actions]);

  useEffect(() => {
    let changed = false;
    const newActionData = produce(actionData, (draft) => {
      draft.map((data) => {
        if (data.type === ACTION_OPTIONS.REMOVE_REPEATED_ROW) {
          const showContainer = !inRepeatContainer;
          if (data.repeat_container && !showContainer) {
            data.repeat_container = '';
            changed = true;
          } else if (!data.repeat_container && showContainer) {
            data.repeat_container = repeatingContainerOptions[0].value;
            changed = true;
          }
        }
      });
    });
    if (changed) handleAllActionsChange(newActionData);
  }, [inRepeatContainer]);

  function handleAllActionsChange(
    newActions: any[],
    submit: boolean | undefined = undefined,
    additionalProps: any = {}
  ) {
    setActionData(newActions.length > 0 ? newActions : [{}]);
    newActions = newActions.filter((action) => action.type);
    const newProps: Record<string, any> = { actions: newActions };
    if (submit !== undefined) newProps.submit = submit;
    handlePropChange({ properties: { ...newProps, ...additionalProps } });

    if (
      !isTheme &&
      !newActions.some((action) => action.type === ACTION_OPTIONS.NEXT)
    ) {
      // If no next action, nav rules for element must be cleared. This operation
      // must run after the properties change above or else nav rules get overridden
      updateNavRulesByElement({
        prevStepId: activeStepId,
        elementId,
        newData: [],
        formId
      });
    }
  }

  function handleActionChange(
    index: number,
    newData: Record<string, any>,
    submit: boolean | undefined = undefined
  ) {
    const additionalProps: any = {};

    if (
      newData.type === ACTION_OPTIONS.AI_DOCUMENT_EXTRACT &&
      !properties.show_loading_icon
    ) {
      additionalProps.show_loading_icon = 'on_button';
    }

    handleAllActionsChange(
      produce(actionData, (draft) => {
        draft[index] = Object.assign(draft[index], newData);
      }),
      submit,
      additionalProps
    );
  }

  function handleActionAdd() {
    // add an action locally only, it will be saved later on changes
    setActionData((actionData) =>
      produce(actionData, (draft) => {
        draft.push({});
      })
    );
  }

  function handleActionRemove() {
    handleAllActionsChange(
      produce(actionData, (draft) => {
        draft.splice(activeActionIndex, 1);
      })
    );
  }

  // Make sure action-specific properties exist and are initialized properly
  function initAllActionProperties(actions: Record<string, any>[]) {
    return produce(actions, (draft) =>
      draft.map((action) => initActionProperties(action))
    );
  }

  // Make sure action-specific properties exist and are initialized properly
  function initActionProperties(action: Record<string, any>) {
    if (
      action.type === ACTION_OPTIONS.STORE_FIELD_VALUE &&
      !action.custom_store_value_type
    )
      return {
        ...action,
        custom_store_field: '',
        custom_store_value: '',
        custom_store_field_type: '',
        custom_store_value_type: 'static',
        custom_store_value_field: '',
        custom_store_value_field_type: ''
      };
    else if (
      action.type === ACTION_OPTIONS.GENERATE_QUIK_DOCUMENTS &&
      !action.form_fill_type
    )
      return {
        ...action,
        form_fill_type: 'pdf',
        sign_callback_url: ''
      };
    else return action;
    // add action specific init calls here!
  }

  // Action menu (delete)
  const [menuPosition, setMenuPosition] = useState<{
    x?: number;
    y?: number;
  }>({});
  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useGlobalMouseDownToggle([menuRef]);
  const [activeActionIndex, setActiveActionIndex] = useState(0);

  // Custom value menu
  const [customValueMenuPosition, setCustomValueMenuPosition] = useState<{
    x?: number;
    y?: number;
  }>({});
  const customValueMenuRef = useRef<HTMLDivElement>(null);
  const [showCustomValueMenu, setShowCustomValueMenu] =
    useGlobalMouseDownToggle([customValueMenuRef]);

  const moveAction = (up: boolean) => () => {
    const newActionData = produce(actionData, (draft: any) => {
      const val = draft.splice(activeActionIndex, 1)[0];
      const shift = up ? -1 : 1;
      draft.splice(activeActionIndex + shift, 0, val);
    });
    handleAllActionsChange(newActionData);
  };

  const customValueType =
    actionData[activeActionIndex]?.custom_store_value_type;

  return (
    <CollapsibleSection title={title} collapsible>
      <ContextMenu
        ref={menuRef}
        show={showMenu}
        close={() => setMenuPosition({})}
        position={menuPosition as any}
        actions={[
          {
            onMouseDown: () => handleActionRemove(),
            title: actionData.length > 1 ? 'Delete' : 'Clear',
            Icon: ({ color }: any) => (
              <TrashIcon width={16} height={16} color={color} />
            )
          },
          activeActionIndex !== 0 && {
            onMouseDown: moveAction(true),
            title: 'Move Up',
            Icon: ({ color }: any) => (
              <RightArrowIcon width={16} height={16} color={color} />
            )
          },
          activeActionIndex < actionData.length - 1 && {
            onMouseDown: moveAction(false),
            title: 'Move Down',
            Icon: ({ color }: any) => (
              <RightArrowIcon width={16} height={16} color={color} />
            )
          }
        ]}
      />
      <ContextMenu
        ref={customValueMenuRef}
        show={showCustomValueMenu}
        close={() => setCustomValueMenuPosition({})}
        position={customValueMenuPosition as any}
        actions={[
          {
            onMouseDown: () =>
              handleActionChange(activeActionIndex, {
                custom_store_value_type:
                  customValueType === 'field' ? 'static' : 'field',
                custom_store_value: '',
                custom_store_value_field: '',
                custom_store_value_field_type: ''
              }),
            title:
              customValueType === 'field'
                ? 'Switch to Static Value'
                : 'Switch to Field Value',
            Icon: ({ color }: any) => (
              <RightArrowIcon width={16} height={16} color={color} />
            )
          }
        ]}
      />
      <PaymentProductContextMenu
        actionData={actionData}
        handleAllActionsChange={handleAllActionsChange}
      />

      {elementType === 'button' && (
        <div className={styles.validateSubmitRow}>
          <CheckboxField
            checked={properties.submit}
            onChange={(value) =>
              handlePropChange({ properties: { submit: value } })
            }
            text={
              <PropertyLabel
                label='Validate & Submit Step'
                {...labelData('submit')}
              />
            }
            className={styles.checkbox}
          />
        </div>
      )}

      {actionData.map((action: any, index: number) => (
        <div key={index} className={styles.actionContainer}>
          <Row className={styles.actionType}>
            <Col sm='3'>
              <PropertyLabel
                label={`Action ${index + 1}`}
                {...labelData('actions')}
                className={styles.actionHeader}
              />
            </Col>
            <Col>
              <DropdownField
                placeholder='None'
                title={`Choose an action to run when the ${elementType.toLowerCase()} is clicked`}
                selected={action.type ?? ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const newData: any = { type: e.target.value };
                  let newSubmit;
                  // Default to Google provider
                  if (newData.type === ACTION_OPTIONS.OAUTH)
                    newData.oauth_type = 'google';
                  else if (newData.type === ACTION_OPTIONS.AI_DOCUMENT_EXTRACT)
                    newData.extraction_id = extractions[0]?.id ?? '';
                  else if (newData.type === ACTION_OPTIONS.BACK)
                    newSubmit = false;
                  else if (newData.type === ACTION_OPTIONS.INVITE_COLLABORATOR)
                    newData.template_id = panel.collaborator_template[0].id;
                  else if (newData.type === ACTION_OPTIONS.ADD_REPEATED_ROW) {
                    newSubmit = false;
                    // If only one repeated container, default to it
                    if (repeatingContainerOptions.length === 1)
                      newData.repeat_container =
                        repeatingContainerOptions[0].value;
                  } else if (
                    newData.type === ACTION_OPTIONS.REMOVE_REPEATED_ROW
                  ) {
                    newSubmit = false;
                  }

                  handleActionChange(index, newData, newSubmit);
                }}
                options={[
                  { display: 'Select...', value: '', disabled: true },
                  ...actionOptions.filter(
                    ({ value }) =>
                      action.type === value ||
                      !(
                        ONLY_ONE_ACTION.includes(value) &&
                        actionData.some(({ type }) => type === value)
                      )
                  )
                ]}
              />
              <OpenOverflowIcon
                width={30}
                height={30}
                className={styles.overflowIcon}
                onClick={(e: any) => {
                  e.preventDefault();
                  setMenuPosition({ x: e.pageX, y: e.pageY });
                  setShowMenu(true);
                  setActiveActionIndex(index);
                }}
              />
            </Col>
          </Row>
          {action.type === ACTION_OPTIONS.ALLOY_VERIFY_ID && (
            <>
              <Row>
                <Col sm='4'>
                  <PropertyLabel label='Journey Token' />
                </Col>
                <Col>
                  <TextField
                    title='Journey Token'
                    value={action.journey_token ?? ''}
                    onComplete={(newToken: string) => {
                      handleActionChange(index, {
                        journey_token: newToken
                      });
                    }}
                    triggerCleanUp
                  />
                </Col>
              </Row>
              <Row>
                <Col sm='4'>
                  <PropertyLabel
                    label={
                      <>
                        Journey Application Token{' '}
                        <InlineTooltip
                          text='The field where the journey application token to use for verification is stored'
                          inline
                        />
                      </>
                    }
                  />
                </Col>
                <Col>
                  <FieldSelectorWithModal
                    selectId={action.journey_application_field}
                    selectType={action.journey_application_field_type}
                    placeholder='Select'
                    title='Journey Application Token Field'
                    onSelect={(data: any) => {
                      handleActionChange(index, {
                        journey_application_field: data.selectId,
                        journey_application_field_type: data.selectType
                      });
                    }}
                    currentStepId={activeStepId}
                    includeServarTypes={['text_field', 'text_area'] as any}
                    inFormBuilder
                  />
                </Col>
              </Row>
            </>
          )}
          {action.type === ACTION_OPTIONS.GENERATE_QUIK_DOCUMENTS && (
            <>
              <Row>
                <Col sm='6'>
                  <PropertyLabel
                    label={
                      <>
                        PDF Sign Method
                        <InlineTooltip
                          placement='bottom'
                          text='Quik! provides 2 methods of form generation. The PDF method
                            generates a PDF version of the form. HTML method returns
                            an HTML version suitable for web use'
                        />
                      </>
                    }
                  />
                </Col>
                <Col>
                  <DropdownField
                    onChange={(e: any) => {
                      handleActionChange(index, {
                        form_fill_type: e.target.value
                      });
                    }}
                    selected={action.form_fill_type}
                    options={[
                      { value: 'pdf', display: 'Feathery' },
                      { value: 'html', display: 'Quik! Viewer' }
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxField
                    checked={
                      action.form_fill_type === 'pdf'
                        ? false
                        : action.use_docusign
                    }
                    disabled={action.form_fill_type === 'pdf'}
                    onChange={(isChecked) => {
                      handleActionChange(index, {
                        use_docusign: isChecked
                      });
                    }}
                    text='DocuSign'
                    className={styles.checkbox}
                  />
                </Col>
              </Row>
              {action.form_fill_type === 'html' && action.use_docusign && (
                <>
                  <Row>
                    <Col sm='5'>
                      <PropertyLabel
                        label={
                          <>
                            Connection Name
                            <InlineTooltip
                              text='Found in the Quik! App under DocuSign Properties'
                              inline
                            />
                          </>
                        }
                      />
                    </Col>
                    <Col>
                      <TextField
                        required
                        title='Auth User ID'
                        value={action.auth_user_id ?? ''}
                        onComplete={(new_auth_user_id: string) => {
                          handleActionChange(index, {
                            auth_user_id: new_auth_user_id
                          });
                        }}
                        triggerCleanUp
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='5'>
                      <PropertyLabel label='Callback URL' />
                    </Col>
                    <Col>
                      <TextField
                        title='DocuSign Callback URL'
                        placeholder='https://feathery.io'
                        value={action.sign_callback_url ?? ''}
                        onComplete={(new_sign_callback_url: string) => {
                          handleActionChange(index, {
                            sign_callback_url: new_sign_callback_url
                          });
                        }}
                        triggerCleanUp
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CheckboxField
                        checked={
                          action.use_docusign ? action.joint_agreements : false
                        }
                        disabled={action.form_fill_type === 'pdf'}
                        onChange={(isChecked) => {
                          handleActionChange(index, {
                            joint_agreements: isChecked
                          });
                        }}
                        text='DocuSign Joint Agreements'
                        className={styles.checkbox}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col sm='5'>
                  <PropertyLabel label='Tags (optional)' />
                </Col>
                <Col>
                  <FieldSelectorWithModal
                    selectId={action.quik_tags_field}
                    selectType={action.quik_tags_field_type}
                    placeholder='Select'
                    title='Quik Tags Field'
                    onSelect={(data: any) => {
                      handleActionChange(index, {
                        quik_tags_field: data.selectId,
                        quik_tags_field_type: data.selectType
                      });
                    }}
                    currentStepId={activeStepId}
                    inFormBuilder
                  />
                </Col>
              </Row>
            </>
          )}
          {action.type === ACTION_OPTIONS.OPEN_FUSER_ENVELOPES && (
            <>
              <Row>
                <Col sm='4'>
                  <PropertyLabel label='Signer Email' />
                </Col>
                <Col>
                  <FieldSelectorWithModal
                    selectId={action.envelope_signer_field}
                    selectType={action.envelope_signer_field_type}
                    placeholder='Select'
                    title='Envelope Signer Field'
                    onSelect={(data: any) => {
                      handleActionChange(index, {
                        envelope_signer_field: data.selectId,
                        envelope_signer_field_type: data.selectType
                      });
                    }}
                    currentStepId={activeStepId}
                    includeServarTypes={
                      ['email', 'text_field', 'text_area'] as any
                    }
                    inFormBuilder
                  />
                </Col>
              </Row>
              <Row>
                <Col sm='4'>
                  <PropertyLabel label='Documents to Sign' />
                </Col>
                <Col>
                  <DropdownMultiField
                    selected={action.documents ?? []}
                    options={Object.values(documents)
                      .filter(
                        (document: any) =>
                          document.type === 'pdf' || document.type === 'docx'
                      )
                      .map((document: any) => ({
                        value: document.id,
                        label: document.key
                      }))}
                    onChange={(items: { value: string }[]) =>
                      handleActionChange(index, {
                        documents: items.map((item) => item.value)
                      })
                    }
                    className={controlStyles.controlField}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxField
                    checked={action.redirect}
                    text={
                      <>
                        Redirect to Sign
                        <InlineTooltip
                          text='The signature flow will open in the same window as the form and redirect back after completion.'
                          inline
                        />
                      </>
                    }
                    onChange={(isChecked) => {
                      handleActionChange(index, {
                        redirect: isChecked
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          {action.type === ACTION_OPTIONS.AI_DOCUMENT_EXTRACT && (
            <>
              <Row>
                <Col sm='5'>
                  <PropertyLabel label='Run Extraction' />
                </Col>
                <Col>
                  <DropdownField
                    title='Extraction to Run'
                    selected={action.extraction_id ?? ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleActionChange(index, {
                        extraction_id: e.target.value
                      })
                    }
                    options={extractions.map((extraction) => ({
                      value: extraction.id,
                      display: extraction.key
                    }))}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxField
                    checked={action.run_async ?? false}
                    onChange={(isChecked) => {
                      handleActionChange(index, {
                        run_async: isChecked
                      });
                    }}
                    text='Run async'
                    className={styles.checkbox}
                  />
                  <InlineTooltip text='If async, the extraction will run in the background and not prevent the form filler from progressing. This is useful for larger documents that take too long or time out for the form filler.' />
                </Col>
              </Row>
            </>
          )}
          {COLLABORATOR_ACTIONS.includes(action.type) && (
            <>
              {action.type !== ACTION_OPTIONS.VERIFY_COLLABORATOR && (
                <Row>
                  <Col sm='3'>
                    <PropertyLabel label='Role' />
                  </Col>
                  <Col>
                    <DropdownField
                      title='Collaborator Role'
                      selected={action.template_id ?? ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleActionChange(index, {
                          template_id: e.target.value
                        })
                      }
                      options={panel.collaborator_template
                        .slice(ACTION_OPTIONS.REWIND_COLLABORATION ? 0 : 1)
                        .map((templ: any) => ({
                          value: templ.id,
                          display: templ.label
                        }))}
                    />
                  </Col>
                </Row>
              )}
              {panel.anonymous_collaboration &&
                action.type === ACTION_OPTIONS.REWIND_COLLABORATION &&
                panel.collaborator_template[0].id === action.template_id && (
                  <Row>
                    <Col sm='6'>
                      <PropertyLabel
                        label={
                          <>
                            Assign Email
                            <InlineTooltip
                              text={`Optional. ${panel.collaborator_template[0].label}'s email if it has not yet been set. This is needed for submissions that start anonymously.`}
                              inline
                            />
                          </>
                        }
                      />
                    </Col>
                    <Col>
                      <FieldSelectorWithModal
                        selectId={action.email_field}
                        selectType={action.email_field_type}
                        placeholder='Select'
                        title='Collaborator Email'
                        onSelect={(data: any) => {
                          handleActionChange(index, {
                            email_field: data.selectId,
                            email_field_type: data.selectType
                          });
                        }}
                        currentStepId={activeStepId}
                        inFormBuilder
                      />
                    </Col>
                  </Row>
                )}
              {action.type !== ACTION_OPTIONS.REWIND_COLLABORATION && (
                <Row>
                  <Col sm='6'>
                    <PropertyLabel label='Collaborator Email' />
                  </Col>
                  <Col>
                    <FieldSelectorWithModal
                      selectId={action.email_field}
                      selectType={action.email_field_type}
                      placeholder='Select'
                      title='Collaborator Email'
                      onSelect={(data: any) => {
                        handleActionChange(index, {
                          email_field: data.selectId,
                          email_field_type: data.selectType
                        });
                      }}
                      currentStepId={activeStepId}
                      inFormBuilder
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
          {action.type === ACTION_OPTIONS.OAUTH && (
            <Row>
              <Col sm='3'>
                <PropertyLabel label='Login Provider' />
              </Col>
              <Col>
                <DropdownField
                  title='Login Provider'
                  selected={action.oauth_type ?? ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleActionChange(index, { oauth_type: e.target.value })
                  }
                  options={SOCIAL_LOGIN_OPTIONS[activeAuthInteg.data.type]}
                />
              </Col>
            </Row>
          )}
          {AUTH_TARGET_ACTIONS.includes(action.type) && (
            <Row>
              <Col sm={action.type === ACTION_OPTIONS.SMS_CODE ? '5' : '3'}>
                <PropertyLabel label={getAuthTargetText(action.type)} />
              </Col>
              <Col>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    action.auth_target_field ? (
                      <></>
                    ) : (
                      <Tooltip>{getAuthTooltipText(action.type)}</Tooltip>
                    )
                  }
                >
                  <div>
                    <FieldSelectorWithModal
                      selectId={action.auth_target_field}
                      selectType={action.auth_target_field_type}
                      onSelect={(data: any) => {
                        handleActionChange(index, {
                          auth_target_field: data.selectId,
                          auth_target_field_type: data.selectType
                        });
                      }}
                      currentStepId={activeStepId}
                      inFormBuilder
                    />
                  </div>
                </OverlayTrigger>
              </Col>
            </Row>
          )}
          {action.type === ACTION_OPTIONS.EMAIL_CODE && (
            <Row>
              <Col sm='5'>
                <PropertyLabel label='Email Address' />
              </Col>
              <Col>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    action.email_field ? (
                      <></>
                    ) : (
                      <Tooltip>Select an Email field</Tooltip>
                    )
                  }
                >
                  <div>
                    <FieldSelectorWithModal
                      selectId={action.email_field}
                      selectType={action.email_field_type}
                      onSelect={(data: any) => {
                        handleActionChange(index, {
                          email_field: data.selectId,
                          email_field_type: data.selectType
                        });
                      }}
                      currentStepId={activeStepId}
                      inFormBuilder
                    />
                  </div>
                </OverlayTrigger>
              </Col>
            </Row>
          )}
          {action.type === ACTION_OPTIONS.SMS_MESSAGE && (
            <>
              <Row>
                <Col sm='3'>
                  <PropertyLabel label='Phone Number' />
                </Col>
                <Col>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      action.phone_target_field ? (
                        <></>
                      ) : (
                        <Tooltip>Select a Phone field</Tooltip>
                      )
                    }
                  >
                    <div>
                      <FieldSelectorWithModal
                        selectId={action.phone_target_field}
                        selectType={action.phone_target_field_type}
                        onSelect={(data: any) => {
                          handleActionChange(index, {
                            phone_target_field: data.selectId,
                            phone_target_field_type: data.selectType
                          });
                        }}
                        currentStepId={activeStepId}
                        inFormBuilder
                      />
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>
              <Row>
                <Col sm='3'>
                  <PropertyLabel label='Message' centered={false} />
                </Col>
                <Col>
                  <TextField
                    title='SMS Message'
                    type='textarea'
                    placeholder='Thanks for submitting!'
                    value={action.sms_message ?? ''}
                    onComplete={(newMessage: string) => {
                      handleActionChange(index, {
                        sms_message: newMessage
                      });
                    }}
                    triggerCleanUp
                    rows={5}
                  />
                </Col>
              </Row>
            </>
          )}
          {action.type === ACTION_OPTIONS.TELESIGN_SILENT_VERIFICATION && (
            <>
              <Row>
                <Col sm='3'>
                  <PropertyLabel label='Phone Number' />
                </Col>
                <Col>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      action.telesign_target_field ? (
                        <></>
                      ) : (
                        <Tooltip>Select a phone field</Tooltip>
                      )
                    }
                  >
                    <div>
                      <FieldSelectorWithModal
                        selectId={action.telesign_target_field}
                        selectType={action.telesign_target_field_type}
                        onSelect={(data: any) => {
                          handleActionChange(index, {
                            telesign_target_field: data.selectId,
                            telesign_target_field_type: data.selectType
                          });
                        }}
                        currentStepId={activeStepId}
                        inFormBuilder
                      />
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>
              <Row>
                <Col sm='3'>
                  <PropertyLabel label='Verification Status' />
                </Col>
                <Col>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      action.telesign_status_field ? (
                        <></>
                      ) : (
                        <Tooltip>
                          Choose a field to store the verification result, which
                          will be a boolean
                        </Tooltip>
                      )
                    }
                  >
                    <div>
                      <FieldSelectorWithModal
                        selectId={action.telesign_status_field}
                        selectType={action.telesign_status_field_type}
                        onSelect={(data: any) => {
                          handleActionChange(index, {
                            telesign_status_field: data.selectId,
                            telesign_status_field_type: data.selectType
                          });
                        }}
                        currentStepId={activeStepId}
                        inFormBuilder
                        onlyHiddenFields
                      />
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>
            </>
          )}
          {action.type === ACTION_OPTIONS.TELESIGN_PHONE_TYPE && (
            <>
              <Row>
                <Col sm='3'>
                  <PropertyLabel label='Phone Number' />
                </Col>
                <Col>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      action.telesign_target_field ? (
                        <></>
                      ) : (
                        <Tooltip>Select a phone field</Tooltip>
                      )
                    }
                  >
                    <div>
                      <FieldSelectorWithModal
                        selectId={action.telesign_target_field}
                        selectType={action.telesign_target_field_type}
                        onSelect={(data: any) => {
                          handleActionChange(index, {
                            telesign_target_field: data.selectId,
                            telesign_target_field_type: data.selectType
                          });
                        }}
                        currentStepId={activeStepId}
                        inFormBuilder
                      />
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>
              <Row>
                <Col sm='3'>
                  <PropertyLabel label='Phone Type' />
                </Col>
                <Col>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      action.telesign_status_field ? (
                        <></>
                      ) : (
                        <Tooltip>
                          Choose a field to store the phone type returned from
                          Telesign
                        </Tooltip>
                      )
                    }
                  >
                    <div>
                      <FieldSelectorWithModal
                        selectId={action.telesign_status_field}
                        selectType={action.telesign_status_field_type}
                        onSelect={(data: any) => {
                          handleActionChange(index, {
                            telesign_status_field: data.selectId,
                            telesign_status_field_type: data.selectType
                          });
                        }}
                        currentStepId={activeStepId}
                        inFormBuilder
                        onlyHiddenFields
                      />
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>
            </>
          )}
          {[
            ACTION_OPTIONS.TELESIGN_VOICE_OTP,
            ACTION_OPTIONS.TELESIGN_SMS_OTP
          ].includes(action.type) && (
            <>
              <Row>
                <Col sm='3'>
                  <PropertyLabel label='Phone Number' />
                </Col>
                <Col>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      action.telesign_target_field ? (
                        <></>
                      ) : (
                        <Tooltip>Select a phone field</Tooltip>
                      )
                    }
                  >
                    <div>
                      <FieldSelectorWithModal
                        selectId={action.telesign_target_field}
                        selectType={action.telesign_target_field_type}
                        onSelect={(data: any) => {
                          handleActionChange(index, {
                            telesign_target_field: data.selectId,
                            telesign_target_field_type: data.selectType
                          });
                        }}
                        currentStepId={activeStepId}
                        inFormBuilder
                      />
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>
            </>
          )}
          {action.type === ACTION_OPTIONS.TELESIGN_VERIFY_OTP && (
            <>
              <Row>
                <Col sm='3'>
                  <PropertyLabel label='Pin Code' />
                </Col>
                <Col>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      action.telesign_target_field ? (
                        <></>
                      ) : (
                        <Tooltip>Select a pin input field</Tooltip>
                      )
                    }
                  >
                    <div>
                      <FieldSelectorWithModal
                        selectId={action.telesign_target_field}
                        selectType={action.telesign_target_field_type}
                        onSelect={(data: any) => {
                          handleActionChange(index, {
                            telesign_target_field: data.selectId,
                            telesign_target_field_type: data.selectType
                          });
                        }}
                        currentStepId={activeStepId}
                        inFormBuilder
                      />
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>
            </>
          )}
          {!isTheme && action.type === ACTION_OPTIONS.NEXT && (
            <Row>
              <Col sm='3'>
                <PropertyLabel label='Next Step' />
              </Col>
              <Col>
                <DropdownField
                  title='Choose the step this element navigates to'
                  selected={nextStepSelected}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const newStepId = e.target.value;
                    const newData = [];
                    if (newStepId) {
                      newData.push({
                        previous_step: activeStepId,
                        next_step: newStepId,
                        element_id: elementId,
                        element_type: elementType,
                        created_at: new Date().toISOString(),
                        rules: [],
                        metadata: {}
                      });
                    }

                    updateNavRulesByElement({
                      prevStepId: activeStepId,
                      nextStepId: newStepId,
                      elementId,
                      newData,
                      formId
                    });
                  }}
                  options={[
                    { display: 'None - Finish Form', value: '' },
                    ...nextStepOptions
                  ]}
                />
              </Col>
            </Row>
          )}
          {!isTheme &&
            (action.type === ACTION_OPTIONS.ADD_REPEATED_ROW ||
              (action.type === ACTION_OPTIONS.REMOVE_REPEATED_ROW &&
                !inRepeatContainer)) && (
              <Row>
                <Col sm='4'>
                  <PropertyLabel label='Repeating Container' />
                </Col>
                <Col>
                  <DropdownField
                    title='Choose the repeating container this action affects'
                    selected={action.repeat_container ?? ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleActionChange(index, {
                        repeat_container: e.target.value
                      });
                    }}
                    options={repeatingContainerOptions}
                  />
                </Col>
              </Row>
            )}
          {!isTheme && action.type === ACTION_OPTIONS.ADD_REPEATED_ROW && (
            <Row>
              <Col sm='4'>
                <PropertyLabel label='Max Repeats' />
              </Col>
              <Col>
                <NumberInput
                  min={1}
                  placeholder='Unlimited'
                  value={action.max_repeats ?? ''}
                  onComplete={({ value }: any) =>
                    handleActionChange(index, {
                      max_repeats: value
                    })
                  }
                  triggerCleanUp
                />
              </Col>
            </Row>
          )}
          {action.type === ACTION_OPTIONS.CUSTOM &&
            elementType === 'container' && (
              <Row>
                <Col sm='5'>
                  <PropertyLabel
                    label={
                      <>
                        Field Indicator
                        <InlineTooltip
                          text='Show the container as selected if this field has a submission'
                          inline
                        />
                      </>
                    }
                  />
                </Col>
                <Col>
                  <FieldSelectorWithModal
                    selectId={action.select_field_indicator}
                    selectType='hidden'
                    onlyHiddenFields
                    placeholder='Select'
                    title='Indicator Field'
                    onSelect={(data: any) => {
                      handleActionChange(index, {
                        select_field_indicator: data.selectId
                      });
                    }}
                    currentStepId={activeStepId}
                    inFormBuilder
                  />
                </Col>
              </Row>
            )}
          {action.type === ACTION_OPTIONS.URL && (
            <>
              <Row>
                <Col sm='3'>
                  <PropertyLabel label='URL' />
                </Col>
                <Col>
                  <TextField
                    title='Target Url'
                    placeholder='https://feathery.io'
                    value={action.url || ''}
                    onComplete={(newUrl: string) =>
                      handleActionChange(index, { url: newUrl })
                    }
                    triggerCleanUp
                  />
                </Col>
              </Row>
              <Row>
                <Col sm='3' />
                <Col>
                  <CheckboxField
                    checked={action.open_tab}
                    onChange={(value) =>
                      handleActionChange(index, { open_tab: value })
                    }
                    text={<PropertyLabel label='Open in new tab' />}
                  />
                </Col>
              </Row>
            </>
          )}
          {action.type === ACTION_OPTIONS.PLAID && (
            <>
              <Row>
                <Col sm='3' />
                <Col>
                  <CheckboxField
                    checked={action.wait_for_completion ?? true}
                    onChange={(value) =>
                      handleActionChange(index, { wait_for_completion: value })
                    }
                    text={
                      <>
                        Wait for Plaid completion
                        <InlineTooltip text="After connecting Plaid, it takes a few seconds to fetch user data. If the form doesn't need the data immediately, don't make your user wait." />
                      </>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm='3' />
                <Col>
                  <CheckboxField
                    checked={action.include_liabilities}
                    onChange={(value) =>
                      handleActionChange(index, { include_liabilities: value })
                    }
                    text={
                      <>
                        Include Liabilities product
                        <InlineTooltip text='Allows access to credit cards' />
                      </>
                    }
                  />
                </Col>
              </Row>
            </>
          )}
          {[
            ACTION_OPTIONS.PURCHASE_PRODUCTS,
            ACTION_OPTIONS.SELECT_PRODUCT_TO_PURCHASE,
            ACTION_OPTIONS.REMOVE_PRODUCT_FROM_PURCHASE
          ].includes(action.type) && (
            <StripeActionSection
              index={index}
              action={action}
              handleActionChange={handleActionChange}
            />
          )}

          {action.type === ACTION_OPTIONS.STORE_FIELD_VALUE && (
            <div className={styles.actionPropertiesGroup}>
              <Row>
                <Col sm='3'>
                  <PropertyLabel label='Field' />
                </Col>
                <Col>
                  <FieldSelectorWithModal
                    placeholder='Select'
                    title='Field ID'
                    selectId={action.custom_store_field}
                    selectType={action.custom_store_field_type}
                    onSelect={(data: any) => {
                      const propsToChange: any = {
                        custom_store_field: data.selectId,
                        custom_store_field_type: data.selectType
                      };
                      if (data.selectServarType === 'Checkbox')
                        propsToChange.custom_store_value = true;
                      // If selectServarType is no longer a checkbox, but still has
                      // the value set to true, we must clear it so the
                      // custom_store_value text field will appear
                      else if (action.custom_store_value === true)
                        propsToChange.custom_store_value = '';
                      handleActionChange(index, propsToChange);
                    }}
                    currentStepId={activeStepId}
                    inFormBuilder
                    error={!action.custom_store_field}
                  />
                </Col>
              </Row>
              {typeof action.custom_store_value === 'boolean' && (
                <Row>
                  <Col sm='3'>
                    <PropertyLabel label='Selected' />
                  </Col>
                  <Col>
                    <DropdownField
                      selected={action.custom_store_value.toString()}
                      onChange={(e: any) =>
                        handleActionChange(index, {
                          custom_store_value: e.target.value === 'true'
                        })
                      }
                      options={[
                        { value: 'true', display: 'Yes' },
                        { value: 'false', display: 'No' }
                      ]}
                    />
                  </Col>
                </Row>
              )}
              {typeof action.custom_store_value !== 'boolean' && (
                <Row>
                  <Col sm='3'>
                    <PropertyLabel label='Value' />
                  </Col>
                  <Col>
                    {action.custom_store_value_type === 'field' ? (
                      <FieldSelectorWithModal
                        placeholder='Select'
                        title='Field Value'
                        selectId={action.custom_store_value_field}
                        selectType={action.custom_store_value_field_type}
                        onSelect={(data: any) => {
                          handleActionChange(index, {
                            custom_store_value_field: data.selectId,
                            custom_store_value_field_type: data.selectType
                          });
                        }}
                        currentStepId={activeStepId}
                        inFormBuilder
                        error={!action.custom_store_value_field}
                      />
                    ) : (
                      <TextField
                        title='Field Value'
                        placeholder='Enter Value to Store'
                        value={action.custom_store_value || ''}
                        onComplete={(value: string) =>
                          handleActionChange(index, {
                            custom_store_value: value
                          })
                        }
                        triggerCleanUp
                      />
                    )}
                    <OpenOverflowIcon
                      width={30}
                      height={30}
                      className={styles.overflowIcon}
                      onClick={(e: any) => {
                        e.preventDefault();
                        setCustomValueMenuPosition({ x: e.pageX, y: e.pageY });
                        setShowCustomValueMenu(true);
                        setActiveActionIndex(index);
                      }}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <CheckboxField
                    checked={action.toggle || false}
                    text={
                      <>
                        Allow Toggle
                        <InlineTooltip text='Allow clicking again to clear the value.' />
                      </>
                    }
                    onChange={(value) =>
                      handleActionChange(index, { toggle: value })
                    }
                    className={styles.checkbox}
                  />
                </Col>
              </Row>
              {(repeatingContainer || inRepeatContainer) && (
                <Row>
                  <Col>
                    <CheckboxField
                      checked={action.repeat_single || false}
                      text={
                        <>
                          Single Select
                          <InlineTooltip text='Only one of the repeat fields can be selected at once' />
                        </>
                      }
                      onChange={(value) =>
                        handleActionChange(index, { repeat_single: value })
                      }
                      className={styles.checkbox}
                    />
                  </Col>
                </Row>
              )}
            </div>
          )}
        </div>
      ))}
      <div className={styles.addAction} onClick={() => handleActionAdd()}>
        + Add Action
      </div>
    </CollapsibleSection>
  );
}

export default ClickActionSection;

const getAuthTargetText = (action: any) => {
  switch (action) {
    case ACTION_OPTIONS.SMS_CODE:
      return 'Phone Number';
    case ACTION_OPTIONS.MAGIC_LINK:
      return 'Email';
    case ACTION_OPTIONS.VERIFY_SMS:
      return 'Pin Code';
    case ACTION_OPTIONS.VERIFY_EMAIL:
      return 'Pin Code';
    default:
      return '';
  }
};
const getAuthTooltipText = (action: any) => {
  switch (action) {
    case ACTION_OPTIONS.SMS_CODE:
      return 'Select a Phone field.';
    case ACTION_OPTIONS.MAGIC_LINK:
      return 'Select an Email field';
    case ACTION_OPTIONS.VERIFY_SMS:
      return 'Select a PIN Input field';
    case ACTION_OPTIONS.VERIFY_EMAIL:
      return 'Select a PIN Input field';
  }
};
