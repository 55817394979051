import { memo } from 'react';
import { Neutral, Positive } from '../Core/Button';

import Dialog from '../Dialog';

const FieldLinkWarningModal = ({ show, setShow, onConfirm }: any) => {
  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      shouldShowCloseIcon={false}
      title='Linking this field will clear its user data'
    >
      <div>Are you sure you want to continue?</div>
      <div className='dialog-form-action text-center'>
        <Neutral onClick={() => setShow(false)}>Cancel</Neutral>
        <Positive
          onClick={(e: any) => {
            e.preventDefault();
            onConfirm();
            setShow(false);
          }}
        >
          Confirm
        </Positive>
      </div>
    </Dialog>
  );
};

export default memo(FieldLinkWarningModal);
