function NitrogenWealth({ width = 70, height = 100 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 116.3635 134.356'
    >
      <g id='logoMark'>
        <polygon
          id='logoMark_PathItem_'
          data-name='logoMark &amp;lt;PathItem&amp;gt;'
          points='72.7153 25.1765 72.7153 8.3922 58.1797 0 43.644 8.3922 43.644 25.1765 58.1797 16.7843 72.7153 25.1765'
          fill='#6941c7'
        />
        <polygon
          id='logoMark_PathItem_-2'
          data-name='logoMark &amp;lt;PathItem&amp;gt;'
          points='43.644 109.1796 43.644 125.9639 58.1797 134.356 72.7153 125.9639 72.7153 109.1796 58.1797 117.5717 43.644 109.1796'
          fill='#6941c7'
        />
        <polygon
          id='logoMark_PathItem_-3'
          data-name='logoMark &amp;lt;PathItem&amp;gt;'
          points='14.5356 41.9911 29.0713 33.599 14.5356 25.2068 0 33.599 0 50.3833 14.5356 58.7755 14.5356 41.9911'
          fill='#6941c7'
        />
        <polygon
          id='logoMark_PathItem_-4'
          data-name='logoMark &amp;lt;PathItem&amp;gt;'
          points='101.8201 92.3849 87.2845 100.777 101.8201 109.1692 116.3557 100.777 116.3558 83.9927 101.8201 75.6005 101.8201 92.3849'
          fill='#6941c7'
        />
        <polygon
          id='logoMark_PathItem_-5'
          data-name='logoMark &amp;lt;PathItem&amp;gt;'
          points='14.5434 75.6071 .0078 83.9993 .0078 100.7836 14.5434 109.1757 29.079 100.7836 14.5434 92.3914 14.5434 75.6071'
          fill='#6941c7'
        />
        <polygon
          id='logoMark_PathItem_-6'
          data-name='logoMark &amp;lt;PathItem&amp;gt;'
          points='101.8278 25.2134 87.2922 33.6056 101.8278 41.9977 101.8279 58.782 116.3635 50.3899 116.3635 33.6056 101.8278 25.2134'
          fill='#6941c7'
        />
        <polygon
          id='logoMark_PathItem_-7'
          data-name='logoMark &amp;lt;PathItem&amp;gt;'
          points='43.644 75.5838 43.644 92.3681 58.1797 100.7603 72.7153 92.3681 72.7153 75.5838 58.1797 83.976 43.644 75.5838'
          fill='#6941c7'
        />
        <polygon
          id='logoMark_PathItem_-8'
          data-name='logoMark &amp;lt;PathItem&amp;gt;'
          points='58.1797 50.4073 72.7153 58.7994 72.7153 75.5838 87.251 67.1917 87.251 50.4073 72.7153 42.0151 58.1797 50.4073'
          fill='#6941c7'
        />
        <polygon
          id='logoMark_PathItem_-9'
          data-name='logoMark &amp;lt;PathItem&amp;gt;'
          points='58.1797 50.4073 43.6441 42.0151 29.1085 50.4073 29.1084 67.1916 43.644 75.5838 43.644 58.7994 58.1797 50.4073'
          fill='#6941c7'
        />
      </g>
    </svg>
  );
}

export default NitrogenWealth;
