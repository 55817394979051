import Switch from '..';
import styles from './styles.module.scss';
import { ComponentProps } from 'react';

interface YesNoSwitchProps extends ComponentProps<typeof Switch> {
  switchStyle?: React.CSSProperties;
}
function YesNoSwitch({ checked, switchStyle, ...props }: YesNoSwitchProps) {
  const noClass = checked ? styles.disabled : styles.enabled;
  const yesClass = checked ? styles.enabled : styles.disabled;
  return (
    <label className={styles.switchContainer} style={switchStyle}>
      <span className={noClass}>No</span>
      <Switch checked={checked} className={styles.switch} {...props} />
      <span className={yesClass}>Yes</span>
    </label>
  );
}

export default YesNoSwitch;
