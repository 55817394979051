import { InlineTooltip, YesNoSwitch } from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { Form } from 'react-bootstrap';
import { PUBLISH_STATUS } from '../../../redux/utils';

const ReportingSection = ({
  title,
  curWeeklyReport,
  setCurWeeklyReport,
  setPublishStatus
}: any) => {
  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <Form.Label>
          Receive Weekly Submission Report
          <InlineTooltip
            text="Once a week on Monday, you'll receive an emailed report summarizing your submissions over the past week"
            inline
          />
        </Form.Label>
        <YesNoSwitch
          id='submissions-report'
          checked={curWeeklyReport}
          onCheckedChange={(value) => {
            setCurWeeklyReport(value);
            setPublishStatus(PUBLISH_STATUS.ACTIVE);
          }}
        />
      </div>
    </SettingsContainer>
  );
};

export default ReportingSection;
