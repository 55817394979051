import Dialog from '../../../../components/Dialog';
import { Positive, Neutral } from '../../../../components/Core/Button';

const PHIConfirmationModal = ({ show, close, acknowledge }: any) => {
  return (
    <Dialog isOpen={show} onClose={() => close()} title='Do not send PHI'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            textAlign: 'center',
            fontSize: 18,
            padding: 36
          }}
        >
          You are in a HIPAA-compliant organization. To use this integration,
          you will need to ensure you are not sending PHI through it. Only
          proceed if this is the case.
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Neutral onClick={close}>No</Neutral>
          <Positive
            onClick={() => {
              acknowledge();
              close();
            }}
          >
            I will not send PHI
          </Positive>
        </div>
      </div>
    </Dialog>
  );
};

export default PHIConfirmationModal;
