import { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import HeaderFilterResultsTable from '../../components/HeaderFilterResultsTable';
import { formatDate, uniqifyKey } from '../../utils/format';
import Switch from '../../components/Core/Switch';
import {
  CheckIcon,
  LogicRulesIcon,
  UpArrowIcon,
  UserCtaIcon,
  WarningIcon
} from '../../components/Icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  DeleteRuleConfirmModal,
  RuleCreateModal
} from '../../components/Modals';
import useFeatheryRedux from '../../redux';
import { UNDO_TITLES, UNDO_TYPES } from '../../utils/constants';
import { v4 as uuidv4 } from 'uuid';
import { objectPick, objectRemove } from '../../utils/core';
import classNames from 'classnames';
import styles from './styles.module.scss';
import formStyles from '../forms.module.scss';
import { LogoLoader } from '../../components/Core';
import { CtaPanel } from '../../components/PricingPlans';
import ctaStyles from '../../components/PricingPlans/styles.module.scss';
import {
  elementEvents,
  TriggerEvents,
  triggerEvents
} from '../FormLogicRulePage/components/RuleFieldsPanel';
import { Positive } from '../../components/Core/Button';
import { validateRule } from '../FormLogicRulePage/utils';

export type LogicRuleMode = 'rule_builder' | 'code_editor';

export interface LogicRule {
  id: string;
  name: string;
  description: string;
  index: number;
  trigger_event: string;
  steps: string[];
  elements: string[];
  dsl?: IRuleDSL;
  code: string;
  enabled: boolean;
  valid: boolean;
  mode: LogicRuleMode;
  metadata: Record<string, any>;
  updated_at: string;
  created_at: string;
}

export default function LogicPageComponent() {
  const history = useHistory();
  const { formId } = useParams<{ formId: string }>();
  const logicAllFields = useAppSelector(
    (state) => state.panels.panels[formId].logic_all_fields
  );
  const [curLogicAllFields, setCurLogicAllFields] =
    useState<boolean>(logicAllFields);
  const workingSteps = useAppSelector((s) => s.formBuilder.workingSteps);
  const workingLogicRules = useAppSelector(
    (s) => s.formBuilder.workingLogicRules
  );
  const workingLogicRulesInitialized = useAppSelector(
    (s) => s.formBuilder.workingLogicRulesInitialized
  );
  const [showCreateRuleModal, setShowCreateRuleModal] = useState(false);
  const [ruleToDelete, setRuleToDelete] = useState<null | LogicRule>(null);
  const [showDeleteRuleModal, setShowDeleteRuleModal] = useState(false);
  const [showCTA, setShowCTA] = useState(true);

  const org = useAppSelector((state) => state.accounts.organization);

  const {
    editPanel,
    formBuilder: { setPanelDataWithUndoRedo },
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  const servars = useAppSelector((state) => state.formBuilder.servars);
  const hiddenFields = useAppSelector((state) => state.fields.hiddenFields);

  const updateRule = (
    rule: LogicRule,
    undoTitle = UNDO_TITLES.NEW_LOGIC_RULE
  ) => {
    setPanelDataWithUndoRedo({
      id: rule.id,
      oldValue: {},
      newValue: {},
      workingSteps: { ...workingSteps },
      oldRulesValue: objectPick(workingLogicRules, [rule.id]),
      newRulesValue: { [rule.id]: rule },
      workingLogicRules: { ...workingLogicRules, [rule.id]: rule },
      title: undoTitle,
      type: UNDO_TYPES.LOGIC_RULES
    });
  };
  const deleteRule = (rule: LogicRule) => {
    setPanelDataWithUndoRedo({
      id: rule.id,
      oldValue: {},
      newValue: {},
      workingSteps: { ...workingSteps },
      oldRulesValue: objectPick(workingLogicRules, [rule.id]),
      newRulesValue: {},
      workingLogicRules: objectRemove(workingLogicRules, [rule.id]),
      title: UNDO_TITLES.DELETE_LOGIC_RULE,
      type: UNDO_TYPES.LOGIC_RULES
    });
  };
  const moveRuleRuntimeOrder = (event: any, ruleId: string, up: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    const rule = workingLogicRules[ruleId];
    const rulesWithSameTriggerSorted: any[] = Object.values(workingLogicRules)
      .filter((r: any) => r.trigger_event === rule.trigger_event)
      .sort((a: any, b: any) => a.index - b.index);

    const ruleIndex = rulesWithSameTriggerSorted.findIndex(
      (r: any) => r.id === ruleId
    );
    if (
      (up && ruleIndex > 0) ||
      (!up && ruleIndex < rulesWithSameTriggerSorted.length - 1)
    ) {
      rulesWithSameTriggerSorted.splice(ruleIndex, 1);
      const moveToPos = up ? ruleIndex - 1 : ruleIndex + 1;
      rulesWithSameTriggerSorted.splice(moveToPos, 0, rule);
      // now re-index the rules
      const newRules = rulesWithSameTriggerSorted.map((r: any, i: number) => {
        return { ...r, index: i + 1 };
      });
      const newRulesObj = newRules.reduce((acc: any, r: any) => {
        acc[r.id] = r;
        return acc;
      }, {});

      setPanelDataWithUndoRedo({
        id: rule.id,
        oldValue: {},
        newValue: {},
        workingSteps: { ...workingSteps },
        oldRulesValue: { ...workingLogicRules },
        newRulesValue: newRulesObj,
        workingLogicRules: { ...workingLogicRules, ...newRulesObj },
        title: UNDO_TITLES.LOGIC_RULES,
        type: UNDO_TYPES.LOGIC_RULES
      });
    }
  };

  function RuleEnableSwitch({ active, ruleId }: any) {
    return (
      <label
        className={styles.ruleEnable}
        onClick={(e) => {
          // This is needed to allow the switch to get the click inside the forms table which has onClick
          e.stopPropagation();
        }}
      >
        <Switch
          id={'sw_enabled_' + ruleId}
          checked={active}
          onCheckedChange={(enabled) => {
            updateRule(
              {
                ...workingLogicRules[ruleId],
                enabled,
                updated_at: new Date().toISOString()
              },
              UNDO_TITLES.LOGIC_RULES
            );
          }}
        />
        {active ? 'YES' : 'NO'}
      </label>
    );
  }
  function RuleValidStatus({ valid }: any) {
    const props = { height: 18, width: 18 };
    return (
      <div className={styles.ruleValidCell}>
        {valid ? (
          <CheckIcon {...props} />
        ) : (
          <WarningIcon {...props} color='#E84343' />
        )}
        {valid ? 'Valid' : 'Has Errors'}
      </div>
    );
  }

  function RuleRuntimeOrder({ id, name, trigger_event, index }: any) {
    const props = { height: 18, width: 18 };
    const rulesWithSameTrigger: any[] = Object.values(workingLogicRules)
      .filter((r: any) => r.trigger_event === trigger_event)
      .sort((a: any, b: any) => a.index - b.index);
    const needsOrdering = rulesWithSameTrigger.length > 1;
    const canMoveUp = rulesWithSameTrigger[0].index < index;
    const canMoveDown =
      rulesWithSameTrigger[rulesWithSameTrigger.length - 1].index >= index;

    return (
      <div className={styles.ruleMoveUpDownCell}>
        {needsOrdering && (
          <>
            <span>{index}</span>
            {canMoveUp && (
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip>Run the rule earlier.</Tooltip>}
              >
                {/* needed for the overlay to work properly */}
                <div>
                  <UpArrowIcon
                    className={classNames(styles.filled, styles.ruleAction)}
                    {...props}
                    onClick={(e: any) => moveRuleRuntimeOrder(e, id, true)}
                  />
                </div>
              </OverlayTrigger>
            )}
            {canMoveDown && (
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip>Run the rule later.</Tooltip>}
              >
                {/* needed for the overlay to work properly */}
                <div>
                  <UpArrowIcon
                    {...props}
                    className={classNames(
                      'flip',
                      styles.filled,
                      styles.ruleAction
                    )}
                    onClick={(e: any) => moveRuleRuntimeOrder(e, id, false)}
                  />
                </div>
              </OverlayTrigger>
            )}
          </>
        )}
      </div>
    );
  }

  // subgrid id to key map
  const subgridKeyMap = useMemo(
    () =>
      Object.values(workingSteps).reduce((keyMap: any, step: any) => {
        step.subgrids.forEach((subgrid: any) => {
          keyMap[subgrid.id] = subgrid.key;
        });
        return keyMap;
      }, {}),
    [workingSteps]
  ) as { [id: string]: string };

  const dataToRender = useMemo(() => {
    return Object.values(workingLogicRules).map((rule: any) => {
      const isRuleValid = validateRule(rule, { ...servars, ...hiddenFields });

      const data = {
        ...rule,
        trigger_pretty: triggerEvents[rule.trigger_event as TriggerEvents],
        updated_at_formatted: formatDate(rule.updated_at),
        stepsPretty: '',
        enable_switch: (
          <RuleEnableSwitch active={rule.enabled} ruleId={rule.id} />
        ),
        validPretty: <RuleValidStatus valid={isRuleValid} />,
        runtimeOrder: <RuleRuntimeOrder {...rule} />,
        triggerAndIndex: `${rule.trigger_event} - ${rule.index}`
      };

      // Trigger Conditions
      // This can be either a list of steps or a list of elements/field id (view event only)
      // If rule has steps, then get the step names from workingSteps and join them
      // Otherwise joind the elements/field ids
      if (elementEvents.includes(rule.trigger_event) && rule.elements?.length) {
        data.triggerConditions = (
          <div className={styles.items}>
            On {rule.trigger_event === 'change' ? 'Fields' : 'Elements'}:
            {rule.elements.map((s: string, i: number) => (
              <span className={styles.item} key={i}>
                {servars[s]?.key ?? subgridKeyMap[s] ?? s}
              </span>
            ))}
          </div>
        );
      } else if (rule.steps?.length) {
        const stepNames = rule.steps.map((stepId: string) => {
          if (workingSteps[stepId]) {
            return workingSteps[stepId].key;
          }
          return '';
        });
        // sort the step names alphabetically
        stepNames.sort();
        data.triggerConditions = (
          <div className={styles.items}>
            On Steps:
            {stepNames.map((s: string, i: number) => (
              <span className={styles.item} key={i}>
                {s}
              </span>
            ))}
          </div>
        );
      }

      return data;
    });
  }, [workingLogicRules, workingSteps, servars]);

  const ruleColumns = [
    { key: 'name', name: 'Name' },
    { key: 'trigger_pretty', name: 'Trigger', sortBy: 'triggerAndIndex' },
    { key: 'triggerConditions', name: 'Trigger Conditions' },
    { key: 'updated_at_formatted', name: 'Last Edited' },
    {
      key: 'enable_switch',
      name: 'Enabled',
      sortBy: 'enabled',
      headerCellStyle: { width: '140px' }
    },
    {
      key: 'validPretty',
      name: 'Valid',
      sortBy: 'valid',
      headerCellStyle: { width: '180px' }
    },
    {
      key: 'runtimeOrder',
      name: 'Runtime Order',
      noSort: true,
      headerCellStyle: { width: '110px' }
    }
  ];

  const toggleOtherFormFields = (checked: boolean) => {
    setCurLogicAllFields(checked);
    const message = checked
      ? 'Fields across all forms are now usable in your rules'
      : 'Only fields in your current form can now be used in your rules';
    editPanel({ panelId: formId, logic_all_fields: checked }).then(() =>
      addInfoToast(message)
    );
  };

  return (
    <>
      {workingLogicRulesInitialized ? (
        <div className={styles.logicPage}>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>
                Allow your rules to access fields from other forms. This may
                cause a performance impact if you have a lot of additional forms
                & fields{' '}
              </Tooltip>
            }
          >
            <div
              className={classNames(
                styles.switchContainer,
                dataToRender.length === 0 && styles.emptyState
              )}
            >
              <label className={styles.otherFieldsSwitch}>
                <Switch
                  id='other-form-fields'
                  checked={curLogicAllFields}
                  onCheckedChange={toggleOtherFormFields}
                />
                Enable all form fields
              </label>
            </div>
          </OverlayTrigger>
          {org?.tier < 2 && showCTA && (
            <div className={styles.ctaHeader}>
              <CtaPanel
                closePanel={() => setShowCTA(false)}
                width='lg'
                style={{}}
              >
                <div className={ctaStyles.cta}>
                  <div className={ctaStyles.ctaLeft}>
                    <UserCtaIcon className={ctaStyles.ctaIcon} />
                    <div className={ctaStyles.ctaBody}>
                      <div>
                        Your logic can only be run on your test form, not your
                        live form.
                      </div>
                      <div>Please reach out to sales to access live logic.</div>
                    </div>
                  </div>
                  <Positive
                    className={ctaStyles.ctaButton}
                    onClick={() => {
                      setShowCTA(false);
                      history.push('/settings/billing');
                    }}
                  >
                    Upgrade
                  </Positive>
                </div>
              </CtaPanel>
            </div>
          )}
          {!dataToRender.length && (
            <div className={formStyles.blankStateContainer}>
              <LogicRulesIcon onClick={() => {}} />
              <span className={formStyles.blankStateHeader}>
                You have no rules yet!
              </span>
              <button
                className={classNames(
                  'btn btn-custom',
                  formStyles.blankStateButton
                )}
                onClick={() => {
                  setShowCreateRuleModal(true);
                }}
              >
                Create Rule
              </button>
            </div>
          )}
          {dataToRender.length > 0 && (
            <HeaderFilterResultsTable
              data={dataToRender}
              columns={ruleColumns}
              name='Rule'
              defaultSort={{ order: 1, key: 'name' }}
              useSearch
              onCreate={() => {
                setShowCreateRuleModal(true);
              }}
              onCopy={(rule: any) => {
                const allNames = Object.values(workingLogicRules).map(
                  (r) => (r as any).name
                );
                const newName = uniqifyKey(rule.name, allNames);

                // The index is used for determining the run order for the rules that run off the same event
                const newIndex =
                  Object.values(workingLogicRules)
                    .filter((r: any) => r.trigger_event === rule.trigger_event)
                    .reduce(
                      (acc: number, r: any) => Math.max(acc, r.index),
                      0
                    ) + 1;

                updateRule({
                  ...workingLogicRules[rule.id],
                  id: uuidv4(),
                  index: newIndex,
                  name: newName,
                  updated_at: new Date().toISOString(),
                  created_at: new Date().toISOString()
                });
              }}
              onDelete={(rule: any) => {
                setRuleToDelete(rule);
                setShowDeleteRuleModal(true);
              }}
              onSelect={(rule: any) =>
                history.push(`/forms/${formId}/logic/${rule.id}`)
              }
            ></HeaderFilterResultsTable>
          )}
          <RuleCreateModal
            show={showCreateRuleModal}
            setShow={setShowCreateRuleModal}
          />
          <DeleteRuleConfirmModal
            show={showDeleteRuleModal}
            setShow={setShowDeleteRuleModal}
            rule={ruleToDelete}
            deleteRule={deleteRule}
          />
        </div>
      ) : (
        <LogoLoader />
      )}
    </>
  );
}
