import '../../../style/dialog-form.css';

import IntegrationsSidebar from '../IntegrationsSidebar';
import { useParams } from 'react-router-dom';
import {
  ALL_INTEGRATIONS_MAP,
  INTEGRATIONS,
  PREMIUM_INTEGRATION_KEYS
} from '../types';
import {
  AutomationCreator,
  AutomationEditor,
  defaultTheme,
  RolloutConnectProvider
} from '@rollout/connect-react';
import useIntegrations from '../useIntegrations';
import './rollout-overrides.scss';
import useFeatheryRedux from '../../../redux';
import { Fragment, useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { API_BASE } from '../../../api/utils';
import PHIConfirmationModal from './components/PHIConfirmationModal';
import { ROLLOUT_BASE_URL } from './utils';
import { useRenderFields } from './components/useRenderFields';

defaultTheme();

const allowedActionsConfig: Record<any, any> = {
  salesforce: {
    'create-or-update-record': true,
    'create-or-update-contact-and-account': true,
    'create-or-update-contact-and-add-to-campaign': true,
    'add-new-task': true,
    'upload-file': true
  }
};

function RolloutSidebar({ integrationType, rolloutApp }: any) {
  const { formId } = useParams<{ formId: string }>();
  const [err, setErr] = useState('');
  const [showCreate, setShowCreate] = useState(false);
  const [curAutomations, setCurAutomations] = useState<any[]>([]);
  const { createIntegration, editIntegration, fetchRolloutToken } =
    useFeatheryRedux();
  const rolloutToken = useAppSelector(
    (state) => state.integrations.rolloutToken
  );
  const org = useAppSelector((state) => state.accounts.organization);
  const [showPHIConfirmation, setShowPHIConfirmation] = useState(false);
  const [needPHIConfirmation, setNeedPHIConfirmation] = useState(
    org?.enterprise_features.hipaa
  );
  const [fetchRollout, setFetchRollout] = useState(false);

  useEffect(() => {
    fetchRolloutToken().then(async ({ token }: { token: string }) => {
      const headers = { Authorization: `Bearer ${token}` };
      const results: Record<string, any>[] = await fetch(
        `${ROLLOUT_BASE_URL}/automations`,
        {
          headers
        }
      ).then((res) => res.json());
      if (!results) return;

      const validAutomations = results.filter(
        (res) =>
          res.action.appKey === rolloutApp &&
          res.trigger.inputParams.formId === formId
      );

      if (validAutomations.length) setNeedPHIConfirmation(false);
      setCurAutomations(validAutomations);
      setRolloutIds(validAutomations.map((automation) => automation.id));
    });
  }, [fetchRollout]);

  const integration = useIntegrations({
    type: integrationType,
    panelId: formId,
    includeInactive: true
  });
  const [rolloutIds, setRolloutIds] = useState<string[] | null>(null);

  const isEnterprise = org?.tier >= 4;
  const premiumEnabled = org?.enterprise_features[integrationType];
  const needUpgrade =
    PREMIUM_INTEGRATION_KEYS.includes(integrationType) && !premiumEnabled;

  const [triggerSteps, exportPDFs, renderFields]: any[] = useRenderFields(
    curAutomations,
    integrationType,
    () => setFetchRollout(!fetchRollout)
  );

  const defaultInputParams: Record<string, string> = {
    apiSubdomain: API_BASE,
    formId,
    appKey: rolloutApp
  };

  const prefilled: Record<string, any> = {
    trigger: {
      appKey: 'feathery',
      triggerKey: 'form-completion'
    },
    action: { appKey: rolloutApp },
    name: `Connect Feathery with ${rolloutApp}`
  };
  if (rolloutApp === 'pdf') {
    prefilled.action.actionKey = 'fillPdf';
    prefilled.action.inputParams = {
      logoUrl: 'https://feathery.s3.us-west-1.amazonaws.com/full-logo-1.png'
    };
  }

  const onSaveCallback = (newRolloutIds: string[]) => {
    const payload: Record<string, any> = {
      type: integrationType,
      active: newRolloutIds.length > 0,
      panelId: formId
    };
    payload.secret_metadata = { rollout_ids: newRolloutIds };
    if (integration?.data) editIntegration(payload);
    else createIntegration(payload);
  };

  const beforeChangeHandler =
    (index: number) =>
    ({ automationData }: any) => {
      setErr('');
      if (needUpgrade) {
        setErr('You must upgrade your plan to activate this integration');
        return false;
      }

      const inputParams = { ...defaultInputParams };
      const trigger = automationData.trigger.triggerKey;
      if (trigger === 'step-loaded') {
        const triggerStep = triggerSteps[index];
        if (!triggerStep) {
          setErr(
            'To trigger this integration when a step is loaded, you must specify the step.'
          );
          return false;
        }

        inputParams.stepId = triggerStep;
      } else if (trigger === 'custom-logic' && !isEnterprise) {
        setErr(
          'You must be on the Business plan to trigger integrations via Logic Rules'
        );
        return false;
      } else if (trigger === 'file-submission') {
        inputParams.exportSubmissionPDF = exportPDFs[index];
      }

      if (needPHIConfirmation) {
        setShowPHIConfirmation(true);
        return false;
      }

      return {
        automationData: {
          ...automationData,
          trigger: {
            ...automationData.trigger,
            inputParams
          }
        }
      };
    };

  const createIndex = rolloutIds?.length ?? 0;

  // limit actions on certain rollout apps
  let allowedActions: any = undefined;
  if (allowedActionsConfig[rolloutApp] != null) {
    allowedActions = allowedActionsConfig;
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[integrationType]}
      customError={err}
      className={integrationType === INTEGRATIONS.PDF_LEGACY ? 'hide-save' : ''}
    >
      <PHIConfirmationModal
        show={showPHIConfirmation}
        close={() => setShowPHIConfirmation(false)}
        acknowledge={() => setNeedPHIConfirmation(false)}
      />
      {rolloutToken && rolloutIds ? (
        <>
          {rolloutIds.map((rolloutId: string, index: number) => (
            <Fragment key={rolloutId}>
              <RolloutConnectProvider
                token={rolloutToken}
                apiBaseUrl={ROLLOUT_BASE_URL}
              >
                <AutomationEditor
                  automationId={rolloutId}
                  renderFields={renderFields(index, rolloutId)}
                  onAutomationDeleted={() => {
                    // Can trigger data discrepancy between Rollout and Feathery if Rollout automation deleted,
                    // then user exits page before our updated integration saves below.
                    const newRolloutIds = [...rolloutIds];
                    newRolloutIds.splice(index, 1);
                    setRolloutIds(newRolloutIds);
                    onSaveCallback(newRolloutIds);
                  }}
                  onBeforeAutomationUpdate={beforeChangeHandler(index)}
                  disableButtonText='Delete'
                  updateButtonText='Update'
                  variableMenuActivator='{{'
                  allowedActions={allowedActions}
                />
              </RolloutConnectProvider>
              <div className='rollout-separator' />
            </Fragment>
          ))}
          {(showCreate || rolloutIds.length === 0) && (
            <RolloutConnectProvider
              token={rolloutToken}
              apiBaseUrl={ROLLOUT_BASE_URL}
            >
              <AutomationCreator
                prefilled={prefilled}
                renderFields={renderFields(createIndex)}
                enableButtonText='Add'
                variableMenuActivator='{{'
                onAutomationCreated={({ automation }: any) => {
                  const newRolloutIds = [...rolloutIds, automation.id];
                  setRolloutIds(newRolloutIds);
                  onSaveCallback(newRolloutIds);
                  setShowCreate(false);
                }}
                onBeforeAutomationCreate={beforeChangeHandler(createIndex)}
                getDefaultTriggerInputParams={() => defaultInputParams}
                allowedActions={allowedActions}
              />
            </RolloutConnectProvider>
          )}
          {rolloutIds.length > 0 && !showCreate && (
            <button
              className={classNames(
                'btn-custom',
                styles.connectButton,
                styles.bottomPadded
              )}
              style={{ width: '150px' }}
              onClick={() => setShowCreate(true)}
            >
              Add another action
            </button>
          )}
        </>
      ) : (
        <Spinner
          animation='border'
          role='status'
          style={{ width: '16px', height: '16px' }}
        />
      )}
    </IntegrationsSidebar>
  );
}

export default RolloutSidebar;
