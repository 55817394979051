import { IAPIActionState } from '../../pages/types';
import type { Template } from '../types';

export const SALESFORCE_PREFILL: Template = {
  id: 'salesforce_prefill',
  display: 'Prefill Form',
  configuration: {
    name: { type: 'textfield', label: 'Connector Name' },
    credential: {
      type: 'rollout',
      label: 'Salesforce Account',
      rollout_app: 'salesforce'
    },
    query: { type: 'textarea', label: 'Salesforce Query' }
  },
  defaultState: {
    name: 'Salesforce Prefill',
    template: 'salesforce_prefill',
    url: '',
    method: 'GET',
    headers: {
      Authorization: 'Bearer {{salesforce_token}}'
    },
    body: '',
    tokens: { salesforce: '' },
    params: {
      q: "SELECT Id, Name, AccountId FROM Contact WHERE Email='{{SalesforceEmail}}' LIMIT 1"
    },
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  },
  loadConfig: (state) => {
    return {
      name: state.name,
      query: state.params.q
    };
  },
  saveConfig: (state, configValues) => {
    const { name, credential, query } = configValues;
    const newValues: Partial<IAPIActionState> = {
      name,
      params: {
        q: query
      },
      tokens: { salesforce: credential.credentialKey },
      url: `${credential.data.userCustomDomainUrl}/services/data/v50.0/query`
    };

    return {
      ...state,
      ...newValues
    };
  }
};
