import { useMemo } from 'react';

// importing from .../types below to avoid INTEGRATIONS undefined issue (probably circular dependency)
import { INTEGRATIONS } from '../components/FormIntegrations/types';
import useIntegrations from '../components/FormIntegrations/useIntegrations';
import { formatDecimal, formatMoney, toTitleCase } from '../utils/format';
import { Product } from '../components/Panels/Sections/ClickActionSection/useStripeActions';
import { getDefaultPrice } from '../components/Modals/ProductSelectorWithModal/useProductCatalog';
import { useAppSelector } from './';

/**
 * The feathery context is an object that contains data usable in the form at both design time
 * and run time.  In the dashboard, the feathery context object's products sub-property
 * is used for dynamic labels on texts and buttons.  The same data is available at form
 * runtime.
 * It is anticipated that more data will be added to the feathery context in the future.
 *
 */
export const useFeatheryContext = () => {
  const formId = useAppSelector((state: any) => state.formBuilder.formId);

  const integrations = useIntegrations({
    types: [INTEGRATIONS.STRIPE],
    panelId: formId
  });
  const allTestProducts: Record<string, Product> =
    integrations[INTEGRATIONS.STRIPE]?.data.metadata.test?.product_price_cache;
  const allLiveProducts: Record<string, Product> =
    integrations[INTEGRATIONS.STRIPE]?.data.metadata.live?.product_price_cache;

  const getProducts = (
    productPriceCache: Record<string, Product>,
    mode: 'live' | 'test'
  ): Record<string, any> => {
    const products: Record<string, any> = {};
    Object.entries(productPriceCache ?? {}).map(([key, productPriceItem]) => {
      // get the default price if there is one, otherwise just get the first price
      const priceObj = getDefaultPrice(productPriceItem);

      const intervals: any = {
        day: 'Daily',
        week: 'Weekly',
        month: 'Monthly',
        year: 'Yearly'
      };
      let subscriptionInterval = 'One-Time';
      if (priceObj?.type === 'recurring') {
        subscriptionInterval =
          intervals[priceObj?.recurring_interval ?? ''] ?? '';
      }

      products[key] = {
        id: key,
        name: productPriceItem.name,
        price: formatDecimal((priceObj?.unit_amount ?? 0) / 100, 2),
        price_formatted: formatMoney(
          (priceObj?.unit_amount ?? 0) / 100,
          priceObj?.currency
        ),
        currency: priceObj?.currency ?? '',
        subscription_interval: subscriptionInterval,
        mode: mode,
        cart_quantity: '0.00', // No cart in design mode
        cart_subtotal: '0.00',
        cart_subtotal_formatted: formatMoney(0, priceObj?.currency)
      };
    });
    return products;
  };

  // For now in the dashboard, the feathery context only needs the products
  // from the Stripe integration.
  const featheryContextProducts = useMemo(
    () => ({
      ...getProducts(allTestProducts, 'test'),
      ...getProducts(allLiveProducts, 'live')
    }),
    [allTestProducts, allLiveProducts]
  );

  const excludedKeys = ['id'];
  const labelOverrides: Record<string, string> = {
    type: 'Purchase Type (one time or subscription)',
    mode: 'Mode (Live / Test)',
    price_formatted: 'Formatted Price',
    cart_subtotal_formatted: 'Formatted Cart Subtotal',
    recurring_interval: 'Subscription Interval (month, year, etc.)'
  };

  const selectableFeatheryDataItems = useMemo(() => {
    // An object with data item json paths as keys and a text label as values
    const selectableFeatheryDataItems: Record<string, string> = {};
    // also supporting feathery.cart.total and total formatted
    if (Object.keys(featheryContextProducts).length > 0) {
      selectableFeatheryDataItems['feathery.cart.total'] = 'Cart Grand Total';
      selectableFeatheryDataItems['feathery.cart.total_formatted'] =
        'Formatted Cart Grand Total';
    }

    // If there are duplicate product names (test/live) then we need to differentiate in the
    // list.
    const duplicateProductNames = new Set<string>();
    const products = Object.values(featheryContextProducts);
    products.forEach((product) => {
      if (products.filter((p) => p.name === product.name).length > 1)
        duplicateProductNames.add(product.name);
    });

    const selectableProductDataItems: Record<string, string> = {};
    // for now only supporting the products
    Object.entries(featheryContextProducts).map(([key, product]) => {
      const id = product.id;
      // now add all properties of the product
      Object.entries(product).map(([key, value]) => {
        if (excludedKeys.includes(key)) return;
        const jsonPathKey = `feathery.products.${id}.${key}`;
        const productNameSuffix = duplicateProductNames.has(product.name)
          ? ` (${product.mode.toUpperCase()})`
          : '';
        const productName = `${product.name}${productNameSuffix}`;
        selectableProductDataItems[jsonPathKey] = `${productName} - ${
          labelOverrides[key] ?? toTitleCase(key.replace(/_/g, ' '))
        }`;
      });
    });

    return {
      ...selectableFeatheryDataItems,
      ...selectableProductDataItems
    };
  }, [featheryContextProducts]);

  return {
    featheryContext: {
      products: featheryContextProducts,
      cart: {
        total: '0.00',
        total_formatted: '0.00'
      }
    },
    selectableFeatheryDataItems
  };
};
