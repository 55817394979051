import * as Logos from '../Logos';
import { mapArrayToObject } from '../../utils/core';
import { SettingsIcon } from '../Icons';

const INTEGRATIONS: { [key: string]: string } = {
  FIREBASE: 'firebase',
  STYTCH: 'stytch',
  SMS_OTP: 'sms-otp',
  SMS_MESSAGE: 'sms-message',
  GOOGLE_TAG_MANAGER: 'google-tag-manager',
  ZAPIER: 'zapier',
  ZOHO_V2: 'zoho-v2',
  GOOGLE_ANALYTICS: 'google-analytics',
  HEAP: 'heap',
  MIXPANEL: 'mixpanel',
  AMPLITUDE: 'amplitude',
  RUDDERSTACK: 'rudderstack',
  CALENDLY: 'calendly',
  EQUIFAX: 'equifax',
  EXPERIAN: 'experian',
  RECAPTCHA: 'recaptcha',
  // Native Feathery implementation
  SLACK_LEGACY: 'slack',
  SLACK_V2: 'slack-v2',
  MAILCHIMP: 'mailchimp',
  SENDGRID: 'sendgrid',
  MAILERLITE: 'mailerlite',
  MAILGUN: 'mailgun',
  OMNISEND: 'omnisend',
  // Native Feathery implementation
  HUBSPOT_LEGACY: 'hubspot',
  // Rollout-powered
  HUBSPOT_V2: 'hubspot-v2',
  SALESFORCE: 'salesforce',
  NOTION: 'notion',
  AIRTABLE: 'airtable',
  ID_VERIFICATION: 'id-verification',
  GOOGLE_DRIVE: 'google-drive',
  GOOGLE_DOCS: 'google-docs',
  GOOGLE_SHEETS: 'google-sheets',
  DROPBOX: 'dropbox',
  BOX: 'box',
  EMAIL: 'email',
  EMAIL_OTP: 'email-otp',
  PLAID: 'plaid',
  LOANPRO: 'loanpro',
  TEMENOS: 'temenos',
  FISERV: 'fiserv',
  OSCILAR: 'oscilar',
  FLINKS: 'flinks',
  INVERITE: 'inverite',
  PERSONA: 'persona',
  ARGYLE: 'argyle',
  WEBHOOK: 'webhook',
  POSTGRES: 'postgres',
  STRIPE: 'stripe',
  SEGMENT: 'segment',
  KLAVIYO: 'klaviyo',
  SHOPIFY: 'shopify',
  WOOCOMMERCE: 'woocommerce',
  PIPEDRIVE: 'pipedrive',
  ZENDESK: 'zendesk',
  ACTIVE_CAMPAIGN: 'active-campaign',
  ASANA: 'asana',
  LINEAR: 'linear',
  BONZO: 'bonzo',
  CLICKUP: 'clickup',
  CLOSE: 'close',
  DISCORD: 'discord',
  FACEBOOK: 'facebook',
  FLOURISH: 'flourish',
  FOLLOW_UP_BOSS: 'follow-up-boss',
  FRESHMARKETER: 'freshmarketer',
  FRESHSALES: 'freshsales',
  FRESHSERVICE: 'freshservice',
  GMAIL: 'gmail',
  GOOGLE_CALENDAR: 'google-calendar',
  INSTAGRAM: 'instagram',
  INTERCOM: 'intercom',
  INTERCOM_EMBEDDED: 'intercom-embedded',
  JIRA: 'jira',
  EXCEL: 'excel',
  MONDAY: 'monday',
  MICROSOFT_DYNAMICS_BUSINESS_CENTRAL: 'microsoft-dynamics-business-central',
  MICROSOFT_DYNAMICS_CRM: 'microsoft-dynamics-crm',
  MICROSOFT_TEAMS: 'microsoft-teams',
  ALGOLIA: 'algolia',
  ANTHROPIC: 'anthropic',
  OPENAI: 'openai',
  PERPLEXITY: 'perplexity',
  CODA: 'coda',
  SUPABASE: 'supabase',
  TODOIST: 'todoist',
  WEBFLOW: 'webflow',
  OUTLOOK: 'outlook',
  EGNYTE: 'egnyte',
  SFTP: 'sftp',
  // Not an actual integration, just used to boost discoverability
  DOCUMENTS: 'documents',
  PDF_LEGACY: 'pdf',
  QUICKBOOKS: 'quickbooks',
  SAP_CONCUR: 'sap-concur',
  SENDINBLUE: 'sendinblue',
  TRELLO: 'trello',
  PRODUCTBOARD: 'productboard',
  TWILIO: 'twilio',
  XERO: 'xero',
  ZOOM: 'zoom',
  MORNINGSTAR: 'morningstar',
  EMONEY: 'emoney',
  MONEYGUIDE: 'moneyguide',
  FIDELITY: 'fidelity',
  SCHWAB: 'charles-schwab',
  PERSHING: 'pershing',
  VANGUARD: 'vanguard',
  QUIK: 'quik',
  REDTAIL: 'redtail',
  ORION: 'orion',
  WEALTHBOX: 'wealthbox',
  ADDEPAR: 'addepar',
  RIGHTCAPITAL: 'rightcapital',
  WEALTH_COM: 'wealth-com',
  PONTERA: 'pontera',
  NITROGEN_WEALTH: 'nitrogen-wealth',
  MULESOFT: 'mulesoft',
  POWER_AUTOMATE: 'power-automate',
  LEXISNEXIS: 'lexisnexis',
  ALLOY: 'alloy',
  TRUSTEDFORM: 'trustedform',
  TELESIGN: 'telesign',
  APPLIED_EPIC: 'applied-epic',
  ZYWAVE: 'zywave',
  SAGITTA: 'sagitta',
  AMS360: 'ams360',
  EZLYNX: 'ezlynx',
  HAWKSOFT: 'hawksoft',
  QQ_CATALYST: 'qq-catalyst',
  NOVIDEA: 'novidea',
  PL_RATING: 'pl-rating',
  QUOTE_RUSH: 'quote-rush',
  TURBORATER: 'turborater',
  IBQ_RATER: 'ibq-rater',
  TARMIKA: 'tarmika',
  AGENCY_ZOOM: 'agency-zoom',
  GUIDEWIRE: 'guidewire',
  S3: 's3',
  GOOGLE_CLOUD_STORAGE: 'google-cloud-storage',
  APOLLO: 'apollo',
  CINC: 'cinc',
  WORKIZ: 'workiz'
};

const INTEGRATION_TAGS = {
  FINANCIAL_SERVICES: 'Financial Services',
  WEALTH_MANAGEMENT: 'Wealth Management',
  INSURANCE: 'Insurance',
  AUTOMATION: 'Automation',
  ANALYTICS_AND_REPORTING: 'Analytics & Reporting',
  COMMUNICATION: 'Communication',
  AUTHENTICATION: 'Authentication',
  PAYMENTS: 'Payments',
  MARKETING_TOOLS: 'Marketing Tools',
  STORAGE: 'Storage'
};

const DOCS_BASE = 'https://feathery.io/integrations/';

const STANDARD_INTEGRATIONS = [
  {
    key: INTEGRATIONS.EMAIL,
    title: 'Email',
    description: (
      <>
        Send personalized, automated emails based on user form triggers.{' '}
        <a href={`${DOCS_BASE}email`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.AUTOMATION],
    logo: <Logos.SimpleEmailService />
  },
  {
    key: INTEGRATIONS.WEBHOOK,
    title: 'Webhook',
    description: (
      <>
        Send every form response or submission straight to a compatible web app
        or URL.{' '}
        <a href={`${DOCS_BASE}webhooks`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    logo: <SettingsIcon fill='black' width={80} height={80} />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.HUBSPOT_V2,
    title: 'Hubspot v2',
    logo: <Logos.Hubspot />,
    description: (
      <>
        Create and update Hubspot contacts, deals, and more.{' '}
        <a href={`${DOCS_BASE}hubspot`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.HUBSPOT_LEGACY,
    title: 'Hubspot',
    logo: <Logos.Hubspot />,
    description: (
      <>
        Create and update Hubspot contacts from your form data.{' '}
        <a href={`${DOCS_BASE}hubspot`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.ZOHO_V2,
    title: 'Zoho v2',
    logo: <Logos.Zoho width={80} height={80} />,
    description: (
      <>
        Create and update Zoho leads and notes.{' '}
        <a href={`${DOCS_BASE}zoho`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.ZAPIER,
    title: 'Zapier',
    description: (
      <>
        Integrate your Feathery forms with 5,000+ software apps using Zapier.{' '}
        <a href={`${DOCS_BASE}zapier`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    logo: <Logos.Zapier />,
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.PDF_LEGACY,
    title: 'PDF Generator',
    logo: <Logos.PDF />,
    description: (
      <>
        Automatically generate PDFs from your form submissions.{' '}
        <a href={`${DOCS_BASE}pdf`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.CALENDLY,
    title: 'Calendly',
    logo: <Logos.Calendly />,
    description: (
      <>
        Embed a Calendly scheduling calendar into your form.{' '}
        <a href={`${DOCS_BASE}calendly`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.STRIPE,
    title: 'Stripe',
    description: (
      <>
        Integrate Stripe with your form to handle payments.{' '}
        <a href={`${DOCS_BASE}stripe`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.PAYMENTS],
    logo: <Logos.Stripe />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.SEGMENT,
    title: 'Segment',
    description: (
      <>
        Track user form data with Segment.{' '}
        <a href={`${DOCS_BASE}segment`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.ANALYTICS_AND_REPORTING],
    logo: <Logos.Segment />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.MIXPANEL,
    title: 'Mixpanel',
    description: (
      <>
        Track user behavior by sending form data directly to your Mixpanel
        dashboard.{' '}
        <a href={`${DOCS_BASE}mixpanel`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.ANALYTICS_AND_REPORTING],
    logo: <Logos.MixPanel />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.AMPLITUDE,
    title: 'Amplitude',
    description: (
      <>
        Track user behavior by sending form data directly to your Amplitude
        dashboard.{' '}
        <a href={`${DOCS_BASE}amplitude`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.ANALYTICS_AND_REPORTING],
    logo: <Logos.Amplitude />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.HEAP,
    title: 'Heap',
    description: (
      <>
        Track user behavior by sending form data directly to your Heap
        dashboard.{' '}
        <a href={`${DOCS_BASE}heap`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.ANALYTICS_AND_REPORTING],
    logo: <Logos.Heap />,
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.SUPABASE,
    title: 'Supabase',
    description: (
      <>
        Automatically create rows in your Supabase database from form
        submissions.{' '}
        <a href={`${DOCS_BASE}supabase`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE, INTEGRATION_TAGS.AUTOMATION],
    logo: <Logos.Supabase />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.POSTGRES,
    title: 'PostgreSQL',
    description: (
      <>
        Send your field data to a table in your PostgreSQL database. The schema
        is automatically created and updated.{' '}
        <a href={`${DOCS_BASE}postgresql`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE, INTEGRATION_TAGS.AUTOMATION],
    logo: <Logos.PostgreSQL width={80} height={80} />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.RUDDERSTACK,
    title: 'RudderStack',
    description: (
      <>
        Track user behavior by sending form data directly to your RudderStack
        platform.{' '}
        <a href={`${DOCS_BASE}rudderstack`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.ANALYTICS_AND_REPORTING],
    logo: <Logos.RudderStack />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.ACTIVE_CAMPAIGN,
    title: 'Active Campaign',
    logo: <Logos.ActiveCampaign />,
    description: (
      <>
        Create Active Campaign contacts and accounts from your Feathery form
        submissions.{' '}
        <a
          href={`${DOCS_BASE}active-campaign`}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.LINEAR,
    title: 'Linear',
    logo: <Logos.Linear />,
    description: (
      <>
        Update linear tasks via your Feathery form submissions.{' '}
        <a href={`${DOCS_BASE}linear`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.ASANA,
    title: 'Asana',
    logo: <Logos.Asana />,
    description: (
      <>
        Update Asana tasks via your Feathery form submissions.{' '}
        <a href={`${DOCS_BASE}asana`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.BONZO,
    title: 'Bonzo',
    logo: <Logos.Bonzo />,
    description: (
      <>
        Update Bonzo prospect info or trigger communication via your Feathery
        form submissions.{' '}
        <a href={`${DOCS_BASE}bonzo`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS, INTEGRATION_TAGS.COMMUNICATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.CLICKUP,
    title: 'Clickup',
    logo: <Logos.Clickup />,
    description: (
      <>
        Automatically create Clickup tasks with collected Feathery form data.{' '}
        <a href={`${DOCS_BASE}clickup`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.CLOSE,
    title: 'Close',
    logo: <Logos.Close />,
    description: (
      <>
        Automatically create and/or update Close leads with collected Feathery
        form data.{' '}
        <a href={`${DOCS_BASE}close`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.DISCORD,
    title: 'Discord',
    logo: <Logos.Discord />,
    description: (
      <>
        Send Discord channel messages when your forms are submitted.{' '}
        <a href={`${DOCS_BASE}discord`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.DROPBOX,
    title: 'Dropbox',
    logo: <Logos.Dropbox />,
    description: (
      <>
        Send uploaded files from Feathery forms to your Dropbox cloud storage.{' '}
        <a href={`${DOCS_BASE}dropbox`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.EXCEL,
    title: 'Excel',
    logo: <Logos.Excel />,
    description: (
      <>
        Add rows to a Microsoft Excel spreadsheet when your forms are submitted.{' '}
        <a href={`${DOCS_BASE}excel`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.FACEBOOK,
    title: 'Facebook',
    logo: <Logos.Facebook />,
    description: (
      <>
        Add users to your Facebook customer list Custom Audience from collected
        form data.{' '}
        <a href={`${DOCS_BASE}facebook`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.INSTAGRAM,
    title: 'Instagram',
    logo: <Logos.Instagram />,
    description: (
      <>
        Create posts and send messages via Instagram from collected form data.{' '}
        <a href={`${DOCS_BASE}instagram`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.FOLLOW_UP_BOSS,
    title: 'Follow Up Boss',
    logo: <Logos.FollowUpBoss />,
    description: (
      <>
        Create or update Follow Up Boss contacts from your collected form data.{' '}
        <a href={`${DOCS_BASE}follow-up-boss`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.APOLLO,
    title: 'Apollo.io',
    logo: <Logos.Apollo />,
    description: (
      <>
        Create Apollo.io records from your collected form data.{' '}
        <a href={`${DOCS_BASE}apollo-io`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.FRESHSALES,
    title: 'Freshsales',
    logo: <Logos.Freshsales />,
    description: (
      <>
        Create Freshsales contacts from your collected form data.{' '}
        <a href={`${DOCS_BASE}freshsales`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.FRESHMARKETER,
    title: 'Freshmarketer',
    logo: <Logos.Freshmarketer />,
    description: (
      <>
        Create Freshmarketer records from your collected form data.{' '}
        <a href={`${DOCS_BASE}freshmarketer`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.FRESHSERVICE,
    title: 'Freshservice',
    logo: <Logos.Freshservice />,
    description: (
      <>
        Create Freshservice tickets and requesters from your collected form
        data.{' '}
        <a href={`${DOCS_BASE}freshservice`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.GMAIL,
    title: 'Gmail',
    logo: <Logos.Gmail />,
    description: (
      <>
        Send automatic emails upon form submissions.{' '}
        <a href={`${DOCS_BASE}gmail`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.GOOGLE_ANALYTICS,
    title: 'Google Analytics',
    description: (
      <>
        Add Google Analytics to track user behavior in your forms.{' '}
        <a
          href={`${DOCS_BASE}google-analytics`}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.ANALYTICS_AND_REPORTING],
    logo: <Logos.GoogleAnalytics />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.GOOGLE_CALENDAR,
    title: 'Google Calendar',
    logo: <Logos.GoogleCalendar />,
    description: (
      <>
        Automatically create a Google calendar event upon form submissions.{' '}
        <a
          href={`${DOCS_BASE}google-calendar`}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.GOOGLE_DOCS,
    title: 'Google Docs',
    logo: <Logos.GoogleDocs />,
    description: (
      <>
        Sync form submissions to your Google Docs document.{' '}
        <a href={`${DOCS_BASE}google-docs`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.GOOGLE_SHEETS,
    title: 'Google Sheets',
    description: (
      <>
        Automatically populate a Google Sheet with your form data.{' '}
        <a href={`${DOCS_BASE}google-sheets`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    logo: <Logos.GoogleSheets />,
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.GOOGLE_TAG_MANAGER,
    title: 'Google Tag Manager',
    description: (
      <>
        Quickly add your own tracking snippets to Feathery forms.{' '}
        <a
          href={`${DOCS_BASE}google-tag-manager`}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.ANALYTICS_AND_REPORTING],
    logo: <Logos.GoogleTagManager />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.AIRTABLE,
    title: 'Airtable',
    description: (
      <>
        Automatically create or update Airtable records from your form data{' '}
        <a href={`${DOCS_BASE}airtable`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION, INTEGRATION_TAGS.STORAGE],
    logo: <Logos.Airtable />,
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.NOTION,
    title: 'Notion',
    description: (
      <>
        Send form data to your Notion documents.{' '}
        <a href={`${DOCS_BASE}notion`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    logo: <Logos.Notion />,
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.CODA,
    title: 'Coda',
    description: (
      <>
        Send form data to your Coda documents.{' '}
        <a href={`${DOCS_BASE}coda`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    logo: <Logos.Coda />,
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.INTERCOM,
    title: 'Intercom',
    logo: <Logos.Intercom />,
    description: (
      <>
        Automatically create or update Intercom leads and users upon form
        submissions.{' '}
        <a href={`${DOCS_BASE}intercom`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.INTERCOM_EMBEDDED,
    title: 'Intercom Embedded',
    logo: <Logos.Intercom />,
    description: (
      <>
        Embed your Intercom chatbot into your Feathery form{' '}
        <a href={`${DOCS_BASE}intercom`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.ALGOLIA,
    title: 'Algolia',
    logo: <Logos.Algolia />,
    description: (
      <>
        Create Algolia Search objects from your collected form data{' '}
        <a href={`${DOCS_BASE}algolia`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.OPENAI,
    title: 'OpenAI',
    logo: <Logos.OpenAI />,
    description: (
      <>
        Send messages to your OpenAI chatbot{' '}
        <a href={`${DOCS_BASE}openai`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.ANTHROPIC,
    title: 'Anthropic',
    logo: <Logos.Anthropic />,
    description: (
      <>
        Send messages to your Anthropic AI chatbot{' '}
        <a href={`${DOCS_BASE}anthropic`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.PERPLEXITY,
    title: 'Perplexity',
    logo: <Logos.Perplexity />,
    description: (
      <>
        Trigger Perplexity chat completions with collected form data{' '}
        <a href={`${DOCS_BASE}perplexity`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.JIRA,
    title: 'Jira',
    logo: <Logos.Jira />,
    description: (
      <>
        Create Jira issues and comments with your collected form data.{' '}
        <a href={`${DOCS_BASE}jira`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION, INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.KLAVIYO,
    title: 'Klaviyo',
    logo: <Logos.Klaviyo />,
    description: (
      <>
        Create Klaviyo records via your Feathery form submissions.{' '}
        <a href={`${DOCS_BASE}klaviyo`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.MAILCHIMP,
    title: 'Mailchimp',
    logo: <Logos.MailChimp />,
    description: (
      <>
        Update your Mailchimp subscribers or create events from Feathery form
        submissions.{' '}
        <a href={`${DOCS_BASE}mailchimp`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.SENDGRID,
    title: 'SendGrid',
    logo: <Logos.Sendgrid />,
    description: (
      <>
        Send an email through SendGrid or create & update your Sendgrid contact
        from Feathery form submissions.{' '}
        <a href={`${DOCS_BASE}sendgrid`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.MAILERLITE,
    title: 'MailerLite',
    logo: <Logos.MailerLite />,
    description: (
      <>
        Create MailerLite subscribers from Feathery form submissions.{' '}
        <a href={`${DOCS_BASE}mailerlite`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.MAILGUN,
    title: 'Mailgun',
    logo: <Logos.Mailgun />,
    description: (
      <>
        Send emails through Mailgun populated with collected form data.{' '}
        <a href={`${DOCS_BASE}mailgun`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.OMNISEND,
    title: 'Omnisend',
    logo: <Logos.Omnisend />,
    description: (
      <>
        Update Omnisend contacts with collected form data.{' '}
        <a href={`${DOCS_BASE}omnisend`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.MICROSOFT_TEAMS,
    title: 'Microsoft Teams',
    logo: <Logos.MicrosoftTeams />,
    description: (
      <>
        Create Microsoft Team channels and messages when your forms are
        submitted.{' '}
        <a
          href={`${DOCS_BASE}microsoft-teams`}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.OUTLOOK,
    title: 'Outlook Mail',
    logo: <Logos.OutlookMail />,
    description: (
      <>
        Send emails from Outlook when your users submit your forms.{' '}
        <a href={`${DOCS_BASE}outlook`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.PIPEDRIVE,
    title: 'Pipedrive',
    logo: <Logos.Pipedrive />,
    description: (
      <>
        Sync form submissions to your Pipedrive account.{' '}
        <a href={`${DOCS_BASE}pipedrive`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.QUICKBOOKS,
    title: 'Quickbooks',
    logo: <Logos.Quickbooks />,
    description: (
      <>
        Create Quickbooks customers and invoices from collected form data.{' '}
        <a href={`${DOCS_BASE}quickbooks`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.SHOPIFY,
    title: 'Shopify',
    logo: <Logos.Shopify />,
    description: (
      <>
        Create Shopify records via your Feathery form submissions.{' '}
        <a href={`${DOCS_BASE}shopify`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.WOOCOMMERCE,
    title: 'WooCommerce',
    logo: <Logos.WooCommerce />,
    description: (
      <>
        Create WooCommerce records via your Feathery form submissions.{' '}
        <a href={`${DOCS_BASE}woocommerce`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.WEBFLOW,
    title: 'Webflow',
    logo: <Logos.Webflow />,
    description: (
      <>
        Add custom code to a Webflow page via your Feathery form submissions.{' '}
        <a href={`${DOCS_BASE}webflow`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.SLACK_LEGACY,
    title: 'Slack',
    description: (
      <>
        Automatically notify your team in Slack when a user completes a Feathery
        form.{' '}
        <a href={`${DOCS_BASE}slack`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.AUTOMATION],
    logo: <Logos.Slack />,
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.SLACK_V2,
    title: 'Slack v2',
    description: (
      <>
        Automatically notify your team in Slack when a user completes a Feathery
        form.{' '}
        <a href={`${DOCS_BASE}slack`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.AUTOMATION],
    logo: <Logos.Slack />,
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.TRELLO,
    title: 'Trello',
    logo: <Logos.Trello />,
    description: (
      <>
        Create Trello boards and cards from form submissions.{' '}
        <a href={`${DOCS_BASE}trello`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.TODOIST,
    title: 'Todoist',
    logo: <Logos.Todoist />,
    description: (
      <>
        Create Todoist tasks from form submissions.{' '}
        <a href={`${DOCS_BASE}todoist`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.PRODUCTBOARD,
    title: 'Productboard',
    logo: <Logos.Productboard />,
    description: (
      <>
        Create Productboard features from form submission data.{' '}
        <a href={`${DOCS_BASE}productboard`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE, INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.XERO,
    title: 'Xero',
    logo: <Logos.Xero />,
    description: (
      <>
        Create contacts and invoices in Xero from collected form data.{' '}
        <a href={`${DOCS_BASE}xero`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.ANALYTICS_AND_REPORTING],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.ZENDESK,
    title: 'Zendesk',
    logo: <Logos.Zendesk />,
    description: (
      <>
        Create Zendesk records via your Feathery form submissions.{' '}
        <a href={`${DOCS_BASE}zendesk`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION, INTEGRATION_TAGS.COMMUNICATION],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.ZOOM,
    title: 'Zoom',
    logo: <Logos.Zoom />,
    description: (
      <>
        Create Zoom meetings from collected form data.{' '}
        <a href={`${DOCS_BASE}zoom`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION],
    hideForHIPAA: true
  }
];
const STANDARD_INTEGRATION_ORDER = STANDARD_INTEGRATIONS.reduce(
  (orderMap, integ, index) => {
    orderMap[integ.key] = index;
    return orderMap;
  },
  {} as Record<string, any>
);

const PREMIUM_INTEGRATIONS = [
  {
    key: INTEGRATIONS.SALESFORCE,
    title: 'Salesforce',
    description: (
      <>
        Create and update Salesforce standard and custom objects from your form
        data.{' '}
        <a href={`${DOCS_BASE}salesforce`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [
      INTEGRATION_TAGS.MARKETING_TOOLS,
      INTEGRATION_TAGS.FINANCIAL_SERVICES,
      INTEGRATION_TAGS.WEALTH_MANAGEMENT,
      INTEGRATION_TAGS.INSURANCE
    ],
    logo: <Logos.Salesforce />,
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.GOOGLE_DRIVE,
    title: 'Google Drive',
    logo: <Logos.GoogleDrive />,
    description: (
      <>
        Automatically populate form files to a custom Google Drive destination.{' '}
        <a href={`${DOCS_BASE}google-drive`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.BOX,
    title: 'Box',
    logo: <Logos.Box />,
    description: (
      <>
        Automatically populate form files to a custom Box destination.{' '}
        <a href={`${DOCS_BASE}box`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.S3,
    title: 'AWS S3',
    logo: <Logos.S3 />,
    description: (
      <>
        Automatically populate form files to a custom S3 bucket and path.{' '}
        <a href={`${DOCS_BASE}aws-s3`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.GOOGLE_CLOUD_STORAGE,
    title: 'Google Cloud Storage',
    logo: <Logos.GoogleCloudStorage />,
    description: (
      <>
        Automatically populate form files to a custom Google Cloud Storage
        location.{' '}
        <a
          href={`${DOCS_BASE}google-cloud-storage`}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.SFTP,
    title: 'SFTP',
    logo: <Logos.SFTP />,
    description: (
      <>
        Automatically send your form file uploads to remote servers via SFTP.{' '}
        <a href={`${DOCS_BASE}sftp`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.MONDAY,
    title: 'Monday.com',
    logo: <Logos.Monday />,
    description: (
      <>
        Add Monday.com groups and items from your collected form data.{' '}
        <a href={`${DOCS_BASE}monday`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.SENDINBLUE,
    title: 'Sendinblue',
    logo: <Logos.Sendinblue />,
    description: (
      <>
        Create or update Sendinblue contacts from form submissions.{' '}
        <a href={`${DOCS_BASE}sendinblue`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.TRUSTEDFORM,
    title: 'TrustedForm',
    logo: <Logos.TrustedForm />,
    description: (
      <>
        Document lead generation consent in your form to protect against
        litigation{' '}
        <a href={`${DOCS_BASE}trustedform`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTHENTICATION],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.SMS_MESSAGE,
    title: 'Custom SMS Messages',
    description: (
      <>
        Send custom SMS messages to your users based on form triggers and data.{' '}
        <a href={`${DOCS_BASE}sms-otp`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION],
    logo: <Logos.SMSIcon />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.SMS_OTP,
    title: 'SMS One-Time Passcodes',
    description: (
      <>
        Send one-time passcodes via Feathery SMS to validate submitted phone
        numbers.{' '}
        <a href={`${DOCS_BASE}sms-otp`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.AUTHENTICATION],
    logo: <Logos.SMSIcon />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.EMAIL_OTP,
    title: 'Email One-Time Passcodes',
    description: (
      <>
        Send one-time passcodes via email to validate a submitted email address.{' '}
        <a
          href={`${DOCS_BASE}email-one-time-passcodes`}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.AUTHENTICATION],
    logo: <Logos.SimpleEmailService />
  },
  {
    key: INTEGRATIONS.FIREBASE,
    title: 'Firebase',
    description: (
      <>
        Verify users via SMS OTP, email magic links, one-click social logins
        (Google, Microsoft, Facebook, etc.), and more. Build login and signup
        directly into your form.{' '}
        <a href={`${DOCS_BASE}firebase`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTHENTICATION],
    logo: <Logos.Firebase />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.STYTCH,
    title: 'Stytch',
    description: (
      <>
        Verify users via SMS OTP, email magic links, one-click social logins
        (Google, Microsoft, Facebook, etc.), and more. Build login and signup
        directly into your form.{' '}
        <a href={`${DOCS_BASE}stytch`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTHENTICATION],
    logo: <Logos.Stytch />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.TWILIO,
    title: 'Twilio SMS',
    description: (
      <>
        Send custom SMS messages via Twilio{' '}
        <a href={`${DOCS_BASE}twilio`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION],
    logo: <Logos.Twilio />,
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.ID_VERIFICATION,
    title: 'ID Verification',
    logo: <Logos.IDVerification />,
    description: (
      <>
        Verify government IDs in your Feathery form flow.{' '}
        <a
          href={`${DOCS_BASE}id-verification`}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </>
    ),
    tags: [
      INTEGRATION_TAGS.AUTHENTICATION,
      INTEGRATION_TAGS.FINANCIAL_SERVICES,
      INTEGRATION_TAGS.WEALTH_MANAGEMENT
    ],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.PLAID,
    title: 'Plaid',
    description: (
      <>
        Embed Plaid in your forms to collect financial information automatically
        from your users.{' '}
        <a href={`${DOCS_BASE}plaid`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [
      INTEGRATION_TAGS.AUTHENTICATION,
      INTEGRATION_TAGS.FINANCIAL_SERVICES,
      INTEGRATION_TAGS.WEALTH_MANAGEMENT
    ],
    logo: <Logos.Plaid />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.LOANPRO,
    title: 'LoanPro',
    description: (
      <>
        Integrate Feathery with LoanPro for an end-to-end loan origination and
        management experience.{' '}
        <a href={`${DOCS_BASE}loanpro`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.FINANCIAL_SERVICES],
    logo: <Logos.LoanPro />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.FISERV,
    title: 'Fiserv',
    description: (
      <>
        Integrate Feathery with Fiserv for an end-to-end loan origination
        experience.{' '}
        <a href={`${DOCS_BASE}fiserv`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.FINANCIAL_SERVICES],
    logo: <Logos.Fiserv />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.TEMENOS,
    title: 'Temenos',
    description: (
      <>
        Integrate Feathery with Temenos for an end-to-end loan origination
        experience.{' '}
        <a href={`${DOCS_BASE}temenos`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.FINANCIAL_SERVICES],
    logo: <Logos.Temenos />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.OSCILAR,
    title: 'Oscilar',
    description: (
      <>
        Integrate Feathery with Oscilar to handle risk decisioning for financial
        services.{' '}
        <a href={`${DOCS_BASE}oscilar`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.FINANCIAL_SERVICES],
    logo: <Logos.Oscilar />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.FLINKS,
    title: 'Flinks',
    description: (
      <>
        Embed Flinks in your forms to collect financial information
        automatically from your users.{' '}
        <a href={`${DOCS_BASE}flinks`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [
      INTEGRATION_TAGS.AUTHENTICATION,
      INTEGRATION_TAGS.FINANCIAL_SERVICES,
      INTEGRATION_TAGS.WEALTH_MANAGEMENT
    ],
    logo: <Logos.Flinks />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.INVERITE,
    title: 'Inverite',
    description: (
      <>
        Embed Inverite in your forms to verify and collect financial information
        automatically from your users.{' '}
        <a href={`${DOCS_BASE}inverite`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [
      INTEGRATION_TAGS.AUTHENTICATION,
      INTEGRATION_TAGS.FINANCIAL_SERVICES
    ],
    logo: <Logos.Inverite />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.LEXISNEXIS,
    title: 'LexisNexis',
    logo: <Logos.LexisNexis />,
    description: (
      <>
        Run KYC and KYB directly in your form, powered by LexisNexis.{' '}
        <a href={`${DOCS_BASE}lexisnexis`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [
      INTEGRATION_TAGS.AUTHENTICATION,
      INTEGRATION_TAGS.FINANCIAL_SERVICES
    ],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.ALLOY,
    title: 'Alloy',
    logo: <Logos.Alloy />,
    description: (
      <>
        Integrate Alloy for user and business identity verification directly in
        your form.{' '}
        <a href={`${DOCS_BASE}alloy`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [
      INTEGRATION_TAGS.AUTHENTICATION,
      INTEGRATION_TAGS.FINANCIAL_SERVICES
    ],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.PERSONA,
    title: 'Persona',
    logo: <Logos.Persona />,
    description: (
      <>
        Verify user identities in your Feathery form.{' '}
        <a href={`${DOCS_BASE}persona`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [
      INTEGRATION_TAGS.AUTHENTICATION,
      INTEGRATION_TAGS.FINANCIAL_SERVICES
    ],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.POWER_AUTOMATE,
    title: 'Microsoft Power Automate',
    description: (
      <>
        Integrate your form data with Microsoft Power Automate to transform and
        route your data to a variety of Microsoft platforms.{' '}
        <a href={`${DOCS_BASE}power-automate`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    logo: <Logos.PowerAutomate />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.MULESOFT,
    title: 'Mulesoft',
    description: (
      <>
        Integrate your form data with Mulesoft to route to a variety of other
        platforms.{' '}
        <a href={`${DOCS_BASE}mulesoft`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    logo: <Logos.Mulesoft />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.FIDELITY,
    title: 'Fidelity',
    logo: <Logos.Fidelity />,
    description: (
      <>
        Automatically open Fidelity custodial accounts and sync client
        information{' '}
        <a href={`${DOCS_BASE}fidelity`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT, INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.SCHWAB,
    title: 'Charles Schwab',
    logo: <Logos.Schwab />,
    description: (
      <>
        Automatically open Charles Schwab custodial accounts and sync client
        information{' '}
        <a href={`${DOCS_BASE}charles-schwab`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT, INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.PERSHING,
    title: 'Pershing',
    logo: <Logos.Pershing />,
    description: (
      <>
        Automatically open Pershing custodial accounts and sync client
        information{' '}
        <a href={`${DOCS_BASE}pershing`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT, INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.VANGUARD,
    title: 'Vanguard',
    logo: <Logos.Vanguard />,
    description: (
      <>
        Automatically open Vanguard custodial accounts and sync client
        information{' '}
        <a href={`${DOCS_BASE}vanguard`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT, INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.WEALTHBOX,
    title: 'Wealthbox CRM',
    logo: <Logos.Wealthbox />,
    description: (
      <>
        Sync your form data with your Wealthbox account, both ways.{' '}
        <a href={`${DOCS_BASE}wealthbox`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT, INTEGRATION_TAGS.STORAGE],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.REDTAIL,
    title: 'Redtail CRM',
    logo: <Logos.Redtail />,
    description: (
      <>
        Sync your form data with your Redtail account, both ways.{' '}
        <a href={`${DOCS_BASE}redtail`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.ORION,
    title: 'Orion',
    logo: <Logos.Orion />,
    description: (
      <>
        Automatically sync your Feathery form data with Orion.{' '}
        <a href={`${DOCS_BASE}orion`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.EMONEY,
    title: 'eMoney',
    logo: <Logos.EMoney />,
    description: (
      <>
        Automatically send your Feathery form data to and from eMoney{' '}
        <a href={`${DOCS_BASE}emoney`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.MONEYGUIDE,
    title: 'MoneyGuide',
    logo: <Logos.MoneyGuide />,
    description: (
      <>
        Automatically send your Feathery form data to and from MoneyGuide{' '}
        <a href={`${DOCS_BASE}moneyguide`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.RIGHTCAPITAL,
    title: 'RightCapital',
    logo: <Logos.RightCapital />,
    description: (
      <>
        Automatically pull and send client financial planning info to
        RightCapital{' '}
        <a href={`${DOCS_BASE}rightcapital`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.QUIK,
    title: 'Quik Forms',
    logo: <Logos.Quik />,
    description: (
      <>
        Automatically send your Feathery form data and documents to and from
        your Quik! account{' '}
        <a href={`${DOCS_BASE}quik`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [
      INTEGRATION_TAGS.FINANCIAL_SERVICES,
      INTEGRATION_TAGS.WEALTH_MANAGEMENT
    ],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.MORNINGSTAR,
    title: 'Morningstar',
    logo: <Logos.Morningstar />,
    description: (
      <>
        Automatically send your Feathery form data to Morningstar for analysis{' '}
        <a href={`${DOCS_BASE}morningstar`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [
      INTEGRATION_TAGS.FINANCIAL_SERVICES,
      INTEGRATION_TAGS.WEALTH_MANAGEMENT
    ],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.ADDEPAR,
    title: 'Addepar',
    logo: <Logos.Addepar />,
    description: (
      <>
        Automatically pull and send client data to Addepar{' '}
        <a href={`${DOCS_BASE}addepar`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.WEALTH_COM,
    title: 'Wealth.com',
    logo: <Logos.WealthCom />,
    description: (
      <>
        Automatically pull and send client data to Wealth.com{' '}
        <a href={`${DOCS_BASE}wealth-com`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.PONTERA,
    title: 'Pontera',
    logo: <Logos.Pontera />,
    description: (
      <>
        Automatically pull and send client data to Pontera{' '}
        <a href={`${DOCS_BASE}pontera`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.NITROGEN_WEALTH,
    title: 'Nitrogen Wealth',
    logo: <Logos.NitrogenWealth />,
    description: (
      <>
        Automatically pull and send client data to Nitrogen Wealth{' '}
        <a
          href={`${DOCS_BASE}nitrogen-wealth`}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.WEALTH_MANAGEMENT],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.EGNYTE,
    title: 'Egnyte',
    logo: <Logos.Egnyte />,
    description: (
      <>
        Automatically send your form file uploads to and from Egnyte.{' '}
        <a href={`${DOCS_BASE}egnyte`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [
      INTEGRATION_TAGS.FINANCIAL_SERVICES,
      INTEGRATION_TAGS.WEALTH_MANAGEMENT,
      INTEGRATION_TAGS.STORAGE
    ],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.APPLIED_EPIC,
    title: 'Applied Epic',
    description: (
      <>
        Prefill your forms from Applied Epic data and update your Applied Epic
        records with form data.{' '}
        <a href={`${DOCS_BASE}applied-epic`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE, INTEGRATION_TAGS.STORAGE],
    logo: <Logos.AppliedEpic />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.GUIDEWIRE,
    title: 'Guidewire',
    description: (
      <>
        Two-way sync of your Guidewire policy, billing, and claims records with
        form data.{' '}
        <a href={`${DOCS_BASE}guidewire`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE],
    logo: <Logos.Guidewire />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.ZYWAVE,
    title: 'Zywave',
    description: (
      <>
        Prefill your forms from Zywave data and update your Zywave records with
        form data.{' '}
        <a href={`${DOCS_BASE}zywave`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE, INTEGRATION_TAGS.STORAGE],
    logo: <Logos.Zywave />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.SAGITTA,
    title: 'Sagitta',
    description: (
      <>
        Prefill your forms from Sagitta data and update your Sagitta records
        with form data.{' '}
        <a href={`${DOCS_BASE}sagitta`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE, INTEGRATION_TAGS.STORAGE],
    logo: <Logos.Sagitta />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.AMS360,
    title: 'AMS360',
    description: (
      <>
        Prefill your forms from AMS360 data and update your AMS360 records with
        form data.{' '}
        <a href={`${DOCS_BASE}ams360`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE, INTEGRATION_TAGS.STORAGE],
    logo: <Logos.AMS360 />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.EZLYNX,
    title: 'EZLynx',
    description: (
      <>
        Prefill your forms from EZLynx AMS or Sales Center data. Update your
        EZLynx records with form data.{' '}
        <a href={`${DOCS_BASE}ezlynx`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE, INTEGRATION_TAGS.STORAGE],
    logo: <Logos.EZLynx />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.HAWKSOFT,
    title: 'HawkSoft',
    description: (
      <>
        Prefill your forms from HawkSoft AMS data. Update your HawkSoft records
        with form data.{' '}
        <a href={`${DOCS_BASE}hawksoft`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE, INTEGRATION_TAGS.STORAGE],
    logo: <Logos.HawkSoft />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.QQ_CATALYST,
    title: 'QQ Catalyst',
    description: (
      <>
        Prefill your forms from QQ Catalyst AMS data. Update your QQ Catalyst
        records with form data.{' '}
        <a href={`${DOCS_BASE}qq-catalyst`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE, INTEGRATION_TAGS.STORAGE],
    logo: <Logos.QQCatalyst />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.NOVIDEA,
    title: 'Novidea',
    description: (
      <>
        Prefill your forms from Novidea AMS data. Update your Novidea records
        with form data.{' '}
        <a href={`${DOCS_BASE}novidea`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE, INTEGRATION_TAGS.STORAGE],
    logo: <Logos.Novidea />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.PL_RATING,
    title: 'PL Rating',
    description: (
      <>
        Seamlessly connects quoting data from Feathery forms to Vertafore&apos;s
        PL Rating.{' '}
        <a href={`${DOCS_BASE}pl-rating`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE],
    logo: <Logos.PLRating />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.QUOTE_RUSH,
    title: 'QuoteRUSH',
    description: (
      <>
        Seamlessly connects quoting data from Feathery forms to QuoteRUSH.{' '}
        <a href={`${DOCS_BASE}quote-rush`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE],
    logo: <Logos.QuoteRUSH />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.TURBORATER,
    title: 'TurboRater',
    description: (
      <>
        Seamlessly connects quoting data from Feathery forms to TurboRater.{' '}
        <a href={`${DOCS_BASE}turborater`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE],
    logo: <Logos.TurboRater />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.IBQ_RATER,
    title: 'IBQ Rater',
    description: (
      <>
        Seamlessly connects quoting data from Feathery forms to Zywave&apos;s
        IBQ Rater.{' '}
        <a href={`${DOCS_BASE}ibq-rater`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE],
    logo: <Logos.IBQRater />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.TARMIKA,
    title: 'Tarmika',
    description: (
      <>
        Seamlessly connects quoting data from Feathery forms to Tarmika.{' '}
        <a href={`${DOCS_BASE}tarmika`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE],
    logo: <Logos.Tarmika />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.AGENCY_ZOOM,
    title: 'Agency Zoom',
    description: (
      <>
        Prefill your forms from Agency Zoom data. Update your Agency Zoom
        records with form data.{' '}
        <a href={`${DOCS_BASE}agency-zoom`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.INSURANCE],
    logo: <Logos.AgencyZoom />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.TELESIGN,
    title: 'Telesign',
    logo: <Logos.Telesign />,
    description: (
      <>
        Validate phone numbers through Telesign via silent verification, voice
        OTP, or SMS OTP.
        <a href={`${DOCS_BASE}telesign`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.COMMUNICATION, INTEGRATION_TAGS.AUTHENTICATION],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.ARGYLE,
    title: 'Argyle',
    description: (
      <>
        Embed Argyle in your forms to collect income and employment information
        automatically from your users.{' '}
        <a href={`${DOCS_BASE}argyle`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.FINANCIAL_SERVICES],
    logo: <Logos.Argyle />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.SAP_CONCUR,
    title: 'SAP Concur',
    logo: <Logos.SAPConcur />,
    description: (
      <>
        Send and receive employee data to and from a Feathery form.{' '}
        <a href={`${DOCS_BASE}sap-concur`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.MICROSOFT_DYNAMICS_CRM,
    title: 'Microsoft Dynamics 365 CRM',
    logo: <Logos.MicrosoftDynamicsCRM />,
    description: (
      <>
        Update your Dynamics 365 CRM contacts, accounts, and opportunities from
        collected form data.{' '}
        <a
          href={`${DOCS_BASE}microsoft-dynamics-365`}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE, INTEGRATION_TAGS.WEALTH_MANAGEMENT],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.MICROSOFT_DYNAMICS_BUSINESS_CENTRAL,
    title: 'Microsoft Dynamics Business Central',
    logo: <Logos.MicrosoftDynamicsBusinessCentral />,
    description: (
      <>
        Update your Business Central contacts and sales orders from form
        submissions.{' '}
        <a
          href={`${DOCS_BASE}microsoft-dynamics-365`}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.STORAGE, INTEGRATION_TAGS.WEALTH_MANAGEMENT],
    hideForHIPAA: true
  },
  {
    key: INTEGRATIONS.EXPERIAN,
    title: 'Experian',
    description: (
      <>
        Connect Experian to your forms to automatically collect credit report
        data from your users and apply it to form logic.{' '}
        <a href={`${DOCS_BASE}experian`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.FINANCIAL_SERVICES],
    logo: <Logos.Experian />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.EQUIFAX,
    title: 'Equifax',
    description: (
      <>
        Connect Equifax to your forms to automatically collect credit report
        data from your users and apply it to form logic.{' '}
        <a href={`${DOCS_BASE}equifax`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.FINANCIAL_SERVICES],
    logo: <Logos.Equifax />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.CINC,
    title: 'CINC',
    description: (
      <>
        Automatically create CINC leads and records from collected form data.{' '}
        <a href={`${DOCS_BASE}cinc`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.MARKETING_TOOLS],
    logo: <Logos.CINC />,
    hideForHIPAA: false
  },
  {
    key: INTEGRATIONS.WORKIZ,
    title: 'Workiz',
    description: (
      <>
        Automatically create Workiz records from collected form data.{' '}
        <a href={`${DOCS_BASE}workiz`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    ),
    tags: [INTEGRATION_TAGS.AUTOMATION],
    logo: <Logos.Workiz />,
    hideForHIPAA: false
  }
];
const PREMIUM_INTEGRATIONS_ORDER = PREMIUM_INTEGRATIONS.reduce(
  (orderMap, integ, index) => {
    orderMap[integ.key] = index;
    return orderMap;
  },
  {} as Record<string, any>
);
const PREMIUM_INTEGRATION_KEYS = PREMIUM_INTEGRATIONS.map((integ) => integ.key);

const ALL_INTEGRATIONS_MAP = mapArrayToObject(
  [...STANDARD_INTEGRATIONS, ...PREMIUM_INTEGRATIONS],
  'key'
);

export {
  INTEGRATIONS,
  INTEGRATION_TAGS,
  ALL_INTEGRATIONS_MAP,
  STANDARD_INTEGRATIONS,
  STANDARD_INTEGRATION_ORDER,
  PREMIUM_INTEGRATION_KEYS,
  PREMIUM_INTEGRATIONS,
  PREMIUM_INTEGRATIONS_ORDER
};
