import {
  ChangeEvent,
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import Dialog from '../../Dialog';
import { PlusIcon } from '../../Icons';
import { OPERATOR_CODE } from '@feathery/react';
import {
  FIELD_OPERATORS,
  getComparisonOperators
} from '../../../utils/validation';
import DecisionLogicSection from './DecisionLogicSection';
import FieldSelector, {
  SelectedFieldData
} from '../../Modals/FieldSelectorWithModal/FieldSelector';
import { Positive } from '../../Core/Button';
import ConfirmationOverlay from '../components/ConfirmationOverlay';
import useFeatheryRedux from '../../../redux';
import { deepEquals } from '../../../utils/core';
import { DropdownField } from '../../Core';
import { useAppSelector } from '../../../hooks';
import styles from './styles.module.scss';
import ruleStyles from './../../NavigationRules/styles.module.scss';
import classNames from 'classnames';

export type FieldValueType = {
  field_type: 'servar' | 'hidden' | '';
  field_id: string | null;
};
export type ValueType = string | FieldValueType;
export interface UnresolvedComparisonRule {
  field_type?: '' | 'servar' | 'hidden';
  hidden_field?: string | null;
  servar?: string | null;
  comparison?: OPERATOR_CODE; // always present after the initial state
  values: ValueType[];
  field_id: string | null; // always non-null after the initial state
  field_key?: string;
}
export interface UnresolvedDecisionLogic {
  message?: string;
  rules: UnresolvedComparisonRule[];
}

export type HideShowBehaviors = 'hide' | 'show';
export type ValidationBehaviors = 'validate' | 'invalidate';
type AllBehaviors = HideShowBehaviors | ValidationBehaviors | '';

const DecisionLogicModal = ({
  show,
  setShow,
  element,
  logicRulesType,
  decisionLogicItems,
  behaviorToPerform,
  handleUpdates
}: {
  show: boolean;
  setShow: (_: boolean) => void;
  logicRulesType: 'validation' | 'visibility';
  element: any;
  decisionLogicItems: UnresolvedDecisionLogic[] | null;
  behaviorToPerform: AllBehaviors; // what behavior to to take if the logic evaluates to true
  handleUpdates: any;
}) => {
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const focusedElement = useAppSelector(
    (state) => state.formBuilder.focusedElement
  );
  const servars = useAppSelector((state) => state.formBuilder.servars);

  const targetFieldKey = useMemo(() => {
    if (element.servar) return element.servar.key;
    if (focusedElement.elementType) return focusedElement.elementType;
    // container
    if (element.key) return element.key;
    return element.id ?? 'Container';
  }, [element]);

  const newRule = (element?: any): UnresolvedComparisonRule => {
    if (element && element.servar && logicRulesType === 'validation') {
      // default the comparison operator
      const operators = getComparisonOperators(element.servar);
      let comparison: OPERATOR_CODE | undefined;
      if (operators.find((o) => o.code === 'equal')) comparison = 'equal';
      else if (operators.length) comparison = operators[0].code;

      if (comparison)
        return {
          field_type: 'servar',
          field_id: element.servar.id,
          comparison,
          values: ['']
        };
      // If no comparison then this field is not supported in a rule (e.g. hex_color)
    }
    return { field_id: null, comparison: 'equal', values: [''] };
  };
  const newLogicItem = (element: any): UnresolvedDecisionLogic => ({
    message: '',
    rules: [newRule(element)]
  });

  // Behavior state data
  const [behavior, setBehavior] = useState<AllBehaviors>('');
  // LogicItems state data
  const [logicItems, setLogicItems] = useState<UnresolvedDecisionLogic[]>([]);
  const [startingLogicItems, setStartingLogicItems] = useState<
    UnresolvedDecisionLogic[]
  >([]);
  useEffect(() => {
    if (show) {
      setBehavior(!behaviorToPerform ? 'show' : behaviorToPerform);
      // set the initial decisionLogicItems state
      const initialLogicItems =
        decisionLogicItems && decisionLogicItems.length
          ? [...decisionLogicItems]
          : [newLogicItem(element)];
      setLogicItems(initialLogicItems);
      setStartingLogicItems(initialLogicItems); // detect unsaved data with this
      // reset auto-validation of fields
      setShowErrors(false);
    }
  }, [show]);

  // Data validation stuff
  const {
    toasts: { addErrorToast }
  } = useFeatheryRedux();

  const valueTypeIsField = (v: ValueType): v is FieldValueType =>
    typeof v === 'object' && 'field_id' in v;

  const [showErrors, setShowErrors] = useState(false);
  // Data Validity Checks for every logic rule comparison clause:
  // 1. The LHS side field must be filled
  // 2. The field type is servar or hidden
  // 3. The comparison operator must be selected
  // 4. If the comparison is in-fix, then there must a values array of at least one element
  // 5. Any field type values must be filled
  const dataValid = useMemo(
    () =>
      logicItems.every((logicItem) =>
        logicItem.rules.every((rule) => {
          const infix =
            rule.comparison && FIELD_OPERATORS[rule.comparison]?.infix;
          const hasValues = rule.values.length;
          const fieldTypeValid =
            rule.field_type === 'servar' || rule.field_type === 'hidden';
          const allFieldTypeValuesFilled = rule.values
            .filter(valueTypeIsField)
            .every((v) => v.field_id);
          return (
            rule.field_id &&
            fieldTypeValid &&
            rule.comparison &&
            (!infix || (infix && hasValues && allFieldTypeValuesFilled))
          );
        })
      ),
    [logicItems]
  );

  const [showCloseConfirmOverlay, setShowCloseConfirmOverlay] = useState(false);

  const [showFieldSelector, setShowFieldSelector] = useState(false);

  // remember and restore the rule scroll position
  const ruleScrollPositionRef = useRef<number>(0);
  const scrollerRef = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    if (!showFieldSelector && scrollerRef.current)
      scrollerRef.current.scrollTop = ruleScrollPositionRef.current;
  }, [showFieldSelector]);

  // Track which item is being acted upon
  const [
    [
      selectedFieldId,
      selectedFieldType,
      selectedLogicItemIndex,
      selectedRuleItemIndex,
      selectedRuleValueIndex /* Fields also supported on the right side for values */
    ],
    setSelectedField
  ] = useState<
    [string | null, '' | 'servar' | 'hidden', number, number, number | null]
  >([null, 'servar', 0, 0, null]);

  const updateLogicItem = (
    newLogicItem: UnresolvedDecisionLogic,
    index: number
  ) => {
    const newLogicItems = [...logicItems];
    newLogicItems.splice(index, 1, newLogicItem);
    setLogicItems(newLogicItems);
  };

  const updateLogicItemRule = (
    rule: UnresolvedComparisonRule | null,
    index: number,
    logicItemIndex: number
  ) => {
    const logicItem = logicItems[logicItemIndex];
    const rules = [...logicItem.rules];
    // must always have at least one rule for good UX
    if (rules.length <= 1 && !rule) rule = { field_id: null, values: [] };
    if (rule) rules.splice(index, 1, rule);
    else {
      rules.splice(index, 1);
    }
    updateLogicItem({ ...logicItem, rules }, logicItemIndex);
  };

  const onSelectField = ({ selectId, selectType }: SelectedFieldData) => {
    const rule =
      logicItems[selectedLogicItemIndex].rules[selectedRuleItemIndex];
    // Supporting field changes on either the left side of the comparison
    //  or the right side (i.e. existing field to field comparisons)
    const leftSideFieldChanged =
      selectedRuleValueIndex === null && selectId !== rule.field_id;
    const fieldValueIsChanged = (selectId: string, value: ValueType) =>
      valueTypeIsField(value) && selectId !== value.field_id;
    const rightSideFieldChanged =
      selectedRuleValueIndex !== null &&
      fieldValueIsChanged(selectId, rule.values[selectedRuleValueIndex]);

    if (leftSideFieldChanged || rightSideFieldChanged) {
      // Figure out the comparison operators for the new field.  If the old comparison
      // is in the new list, then preserve it.  Otherwise, if the 'equal' comparison op is present, choose
      // it.  Otherwise, choose the first one.
      const servar =
        selectId && selectType === 'servar' ? servars[selectId] : null;
      const operators = getComparisonOperators(servar);
      let newComparison: OPERATOR_CODE | undefined;
      if (rule.comparison && operators.find((o) => o.code === rule.comparison))
        newComparison = rule.comparison;
      else if (operators.find((o) => o.code === 'equal'))
        newComparison = 'equal';
      else if (operators.length) newComparison = operators[0].code;

      const values = [...(rule.values.length ? rule.values : [''])]; // make sure there is at least one value field is infix
      if (rightSideFieldChanged)
        values[selectedRuleValueIndex] = {
          field_id: selectId,
          field_type: selectType
        };
      if (
        !newComparison ||
        !FIELD_OPERATORS[newComparison as OPERATOR_CODE].multiValue
      )
        // if new opertor is single value, make sure only one value
        values.splice(1);

      // If simply unselecting a previously selected field, interpret this as a remove condition
      // (the context menu is not obvious and users are likely to use this to remove one)
      const changedRule =
        selectId || rightSideFieldChanged
          ? {
              field_type: leftSideFieldChanged ? selectType : rule.field_type,
              comparison: newComparison,
              values: values,
              field_id: leftSideFieldChanged ? selectId : rule.field_id
            }
          : null;
      // Also, forcing a reset of the selected comparison operator if changing fields
      updateLogicItemRule(
        changedRule,
        selectedRuleItemIndex,
        selectedLogicItemIndex
      );
    }
  };
  const showHide = behavior ?? 'show';
  const title =
    logicRulesType === 'validation'
      ? 'Custom Validation Errors'
      : `Create ${'hide' === showHide ? 'Hide' : 'Show'} Logic`;
  const validationTitle =
    logicRulesType === 'validation'
      ? 'The validation rules are incomplete or invalid.'
      : 'The hide rules are incomplete or invalid.';

  return (
    <Dialog
      isOpen={show}
      onClose={() => {
        if (!deepEquals(logicItems, startingLogicItems))
          setShowCloseConfirmOverlay(true);
        else {
          setShowFieldSelector(false);
          setShow(false);
        }
      }}
      shouldShowCloseIcon
      size='xlg'
      title={showFieldSelector ? 'Select Field' : title}
    >
      {show /* Only mount when showing */ && (
        <>
          {/* Either showing the logic item rules builder or the field selector. */}
          {showFieldSelector ? (
            <FieldSelector
              selectId={selectedFieldId}
              selectType={selectedFieldType}
              currentStepId={activeStepId}
              show={showFieldSelector}
              setShow={setShowFieldSelector}
              onSelect={onSelectField}
              inFormBuilder
              selectTitle='Confirm'
              showOtherButton
              excludeServarTypes={
                selectedRuleValueIndex !== null
                  ? ['file_upload', 'signature', 'payment_method', 'hex_color'] // exclude these types from the right side
                  : ['hex_color'] // not ever usable in a rule on either side (no operators)
              }
              includeReserved // include reserved feathery. hidden fields
            />
          ) : (
            /* Now showing the validation or show/hide rules builder. */
            <>
              <div className={styles.logicSubtitle}>
                {logicRulesType === 'validation' && 'For'}
                {logicRulesType === 'visibility' && (
                  <DropdownField
                    className={styles.behaviorSelector}
                    selected={behavior}
                    options={[
                      { value: 'show', display: 'Show' },
                      { value: 'hide', display: 'Hide' }
                    ]}
                    onChange={(event: ChangeEvent) => {
                      const newBehavior = (event.target as any).value;
                      setBehavior(newBehavior);
                    }}
                  />
                )}
                <span className={styles.fieldKey}> {targetFieldKey}</span>
                {logicRulesType === 'visibility' &&
                  'if the conditions below are met.'}
              </div>

              <div ref={scrollerRef} className={styles.logicScroller}>
                <div className={styles.logicContainer}>
                  {logicItems.map(
                    (
                      validation: UnresolvedDecisionLogic,
                      validationIndex: number
                    ) => (
                      <DecisionLogicSection
                        key={validationIndex}
                        element={element}
                        logicRulesType={logicRulesType}
                        decisionLogic={validation}
                        name={`Rule ${validationIndex + 1}`}
                        showErrors={showErrors}
                        handleDelete={() => {
                          const newLogicItems = [...logicItems];
                          newLogicItems.splice(validationIndex, 1);
                          setLogicItems(newLogicItems);
                        }}
                        handleUpdates={(newLogicItem) =>
                          updateLogicItem(newLogicItem, validationIndex)
                        }
                        showFieldSelector={(
                          selectedFieldId,
                          selectedFieldType,
                          selectedRuleItemIndex,
                          selectedRuleValueIndex = null
                        ) => {
                          setSelectedField([
                            selectedFieldId,
                            selectedFieldType,
                            validationIndex,
                            selectedRuleItemIndex,
                            selectedRuleValueIndex
                          ]);
                          setShowFieldSelector(true);
                          ruleScrollPositionRef.current =
                            scrollerRef.current?.scrollTop || 0;
                        }}
                        newRule={newRule}
                      />
                    )
                  )}

                  <div
                    className={classNames(
                      ruleStyles.addNavigationRule,
                      ruleStyles.ruleTarget,
                      styles.logicButton
                    )}
                    onClick={() =>
                      setLogicItems([...logicItems, newLogicItem(element)])
                    }
                  >
                    <>
                      <div className={styles.icon}>
                        <PlusIcon
                          width={15}
                          height={15}
                          color='#414859'
                          className={ruleStyles.addNewRuleIcon}
                        />
                      </div>
                      New Rule
                    </>
                  </div>
                </div>
              </div>

              <div className='dialog-form-action text-right'>
                <Positive
                  onClick={() => {
                    setShowErrors(true);
                    if (!dataValid)
                      addErrorToast(
                        {
                          title: validationTitle,
                          body: 'Please correct the indicated fields and try again.'
                        },
                        { decay: 6000, dismissible: false }
                      );
                    else {
                      handleUpdates(logicItems, behavior);
                      setShow(false);
                    }
                  }}
                >
                  Save Rules
                </Positive>
              </div>
            </>
          )}
          {/* Confirm the dialog close */}
          <ConfirmationOverlay
            show={showCloseConfirmOverlay}
            hideIt={() => setShowCloseConfirmOverlay(false)}
            onConfirm={() => {
              setShowFieldSelector(false);
              setShow(false);
            }}
          />
        </>
      )}
    </Dialog>
  );
};

export default memo(DecisionLogicModal);
