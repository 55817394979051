import type { ComponentProps } from 'react';
import { UndoIcon } from '../../Icons';

function Undo({
  disabled = false,
  ...rest
}: Omit<ComponentProps<'button'>, 'children' | 'className'>) {
  return (
    <button {...rest} disabled={disabled} className='undo-button'>
      <UndoIcon className={disabled ? '' : 'undo-redo-enabled'} />
    </button>
  );
}

export default Undo;
