import * as Icons from '../../Icons';

import {
  assetUpdateOperations,
  BORDER_PROPS,
  CORNER_PROPS,
  DISABLED_BORDER_PROPS,
  elementOperation,
  getAsset,
  HOVER_BORDER_PROPS,
  level2UpdateOperations,
  SELECTED_BORDER_PROPS
} from '../../../utils/themes';
import {
  BorderStyleInput,
  CheckboxField,
  CollapsibleSection,
  CornerStyleInput,
  DropdownField,
  FColorPicker,
  NumberInput,
  PropertyLabel,
  RadioButtonGroup
} from '../../Core';
import { Col, Row } from 'react-bootstrap';
import { memo } from 'react';

import { RichTextEditor, ShadowStyleSection } from './components';
import { calculateOverrideObjects } from '../utils';
import { objectPick } from '../../../utils/core';
import styles from './styles.module.scss';
import { TYPE_BUTTON } from '../../../utils/elements';
import ImageStyleSection from './components/ImageStyleSection';
import { Viewport } from '../../RenderingEngine/GridInGrid/engine';
import { AXIS } from '../../../utils/constants';
import BoxSpacingInput from '../../Core/BoxSpacingInput';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../Core/Tabs';

const COLOR_TABS = [
  {
    eventKey: 'HoverColors',
    title: 'Hover',
    pre: (prop: string) => `hover_${prop}`,
    prefix: 'hover_',
    borderProps: HOVER_BORDER_PROPS
  },
  {
    eventKey: 'SelectedColors',
    title: 'Selected',
    pre: (prop: string) => `selected_${prop}`,
    prefix: 'selected_',
    borderProps: SELECTED_BORDER_PROPS
  },
  {
    eventKey: 'DisabledColors',
    title: 'Disabled',
    pre: (prop: string) => `disabled_${prop}`,
    prefix: 'disabled_',
    borderProps: DISABLED_BORDER_PROPS
  }
];
function ButtonStylePanel({
  mode,
  viewport,
  theme,
  baseProps,
  baseStyle,
  overrideProps,
  overrideStyle,
  handleUpdates,
  isAsset,
  cellNode
}: any) {
  const { result: element } = calculateOverrideObjects(
    baseProps,
    overrideProps,
    true
  );
  const { result: elementStyle, isOverride: isOverrideStyle } =
    calculateOverrideObjects(baseStyle, overrideStyle);
  const asset = getAsset(theme, TYPE_BUTTON, element.source_asset);

  function handleStyleChange(styleUpdate: any) {
    handleUpdates([elementOperation({ viewport, styleUpdate })]);
  }

  const { isOverride: isOverrideProp } = calculateOverrideObjects(
    baseProps,
    overrideProps,
    true
  );
  function labelPropData(...propList: string[]) {
    return {
      mode,
      highlighted: isOverrideProp({ properties: propList }),
      themeOperations: {
        instance: {
          label: 'Reset to asset value',
          operation: () => {
            handleUpdates([
              elementOperation({ viewport, propReset: propList })
            ]);
          }
        }
      }
    };
  }

  function labelData(...props: string[]) {
    const newStyle = objectPick(elementStyle, props);
    const key = viewport === Viewport.Mobile ? 'Desktop' : 'Theme';
    return {
      mode,
      highlighted: isOverrideStyle(props),
      themeOperations: {
        instance: {
          label: `Reset to ${key}`,
          operation: () => {
            handleUpdates([elementOperation({ viewport, styleReset: props })]);
          }
        },
        ...(mode === 'builder' && {
          level2: {
            label: 'Buttons',
            operation: () => {
              handleUpdates(
                level2UpdateOperations({
                  viewport,
                  elementType: TYPE_BUTTON,
                  asset,
                  newStyle,
                  level2: 'button'
                })
              );
            }
          },
          ...(asset && {
            asset: {
              label: asset.key,
              operation: () => {
                handleUpdates(
                  assetUpdateOperations({
                    viewport,
                    elementType: TYPE_BUTTON,
                    asset,
                    newStyle
                  })
                );
              }
            }
          })
        })
      }
    };
  }

  return (
    <>
      <CollapsibleSection title='Layout' collapsible>
        <Row>
          <Col sm='3'>
            <PropertyLabel
              label='Width'
              {...labelData('width', 'width_unit')}
            />
          </Col>
          <Col>
            <NumberInput
              required
              min={0}
              value={elementStyle.width}
              unit={elementStyle.width_unit}
              units={['px', '%']}
              onComplete={({ value, unit }: any) =>
                handleStyleChange({ width: value, width_unit: unit })
              }
              triggerCleanUp
              dimension='width'
            />
          </Col>
          <Col sm='3'>
            <PropertyLabel
              label='Height'
              indented
              {...labelData('height', 'height_unit')}
            />
          </Col>
          <Col>
            <NumberInput
              required
              min={0}
              value={elementStyle.height}
              unit={elementStyle.height_unit}
              units={['px', '%']}
              onComplete={({ value, unit }: any) =>
                handleStyleChange({ height: value, height_unit: unit })
              }
              triggerCleanUp
              dimension='height'
            />
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <CheckboxField
              checked={elementStyle.content_responsive}
              onChange={(value) =>
                handleStyleChange({
                  content_responsive: value
                })
              }
              text={
                <PropertyLabel
                  label='Responsive - Expand to Content'
                  {...labelData('content_responsive')}
                />
              }
              style={{ marginLeft: '5px' }}
            />
          </Col>
        </Row>
        <BoxSpacingInput
          labelData={labelData}
          handleStyleChange={handleStyleChange}
          elementStyle={elementStyle}
          elementType={TYPE_BUTTON}
        />
        {cellNode && (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Align Self'
                {...labelData('layout', 'vertical_layout')}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.layout}
                name='button-editor-horizontal-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.COL}
                onChange={(value: any) => handleStyleChange({ layout: value })}
                options={[
                  {
                    value: 'flex-start',
                    display: <Icons.HorizontalLeftIcon />
                  },
                  { value: 'center', display: <Icons.HorizontalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.HorizontalRightIcon /> }
                ]}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.vertical_layout}
                name='button-editor-vertical-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.ROW}
                onChange={(value: any) =>
                  handleStyleChange({ vertical_layout: value })
                }
                options={[
                  { value: 'flex-start', display: <Icons.VerticalTopIcon /> },
                  { value: 'center', display: <Icons.VerticalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.VerticalBottomIcon /> }
                ]}
              />
            </Col>
          </Row>
        )}
        {mode !== 'theme' && (
          <Row>
            <Col sm='3'>
              <PropertyLabel label='Visibility' />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.visibility}
                name='button-editor-visibility'
                onChange={(value: any) =>
                  handleStyleChange({ visibility: value })
                }
                options={[
                  {
                    value: 'visible',
                    display: <Icons.ShowIcon width={18} height={18} />
                  },
                  {
                    value: 'hidden',
                    display: <Icons.HideIcon width={18} height={18} />
                  }
                ]}
              />
            </Col>
          </Row>
        )}
      </CollapsibleSection>

      <CollapsibleSection title='Typography' collapsible>
        <RichTextEditor
          mode={mode}
          level2={{ label: 'Buttons', type: 'button' }}
          viewport={viewport}
          baseProps={baseProps}
          overrideProps={overrideProps}
          elementType={TYPE_BUTTON}
          baseStyle={baseStyle}
          overrideStyle={overrideStyle}
          handleUpdates={handleUpdates}
          theme={theme}
          isAsset={isAsset}
          labelCustomData={labelData}
          handleCustomStyleChange={handleStyleChange}
          customValues={{
            line_height: elementStyle.line_height,
            text_align: elementStyle.text_align
          }}
        />
      </CollapsibleSection>

      <CollapsibleSection title='Image' collapsible>
        <ImageStyleSection
          element={element}
          elementStyle={elementStyle}
          fieldType={TYPE_BUTTON}
          labelData={labelData}
          labelPropData={labelPropData}
          handleStyleChange={handleStyleChange}
          level2={{ type: 'field', label: 'Fields' }}
          viewport={viewport}
          handleUpdates={handleUpdates}
          isAsset={asset}
          hideImageSelection={!(mode === 'builder' || isAsset)}
        />
      </CollapsibleSection>

      <CollapsibleSection title='Display' collapsible>
        <Row>
          <Col>
            <PropertyLabel label='Corners' {...labelData(...CORNER_PROPS)} />
          </Col>
        </Row>
        <Row>
          <Col>
            <CornerStyleInput
              topLeft={elementStyle.corner_top_left_radius}
              topRight={elementStyle.corner_top_right_radius}
              bottomLeft={elementStyle.corner_bottom_left_radius}
              bottomRight={elementStyle.corner_bottom_right_radius}
              onComplete={(newCorners: any) =>
                handleStyleChange({
                  corner_top_left_radius: newCorners.topLeft,
                  corner_top_right_radius: newCorners.topRight,
                  corner_bottom_left_radius: newCorners.bottomLeft,
                  corner_bottom_right_radius: newCorners.bottomRight
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <PropertyLabel
              label='Transition'
              {...labelData('entry_transition')}
            />
          </Col>
          <Col>
            <DropdownField
              selected={elementStyle.entry_transition ?? ''}
              onChange={(e: any) =>
                handleStyleChange({
                  entry_transition: e.target.value
                })
              }
              options={[
                { value: '', display: 'None' },
                { value: 'fade_in', display: 'Fade In' }
              ]}
            />
          </Col>
        </Row>
      </CollapsibleSection>

      <ShadowStyleSection
        labelData={labelData}
        styles={elementStyle}
        handleStyleChange={handleStyleChange}
      />
      <Tabs defaultValue='DefaultColors'>
        <TabsList unstyled className='legacyTabsList'>
          <TabsTrigger
            unstyled
            className='legacyTabsTriggerFlex'
            value='DefaultColors'
          >
            Default
          </TabsTrigger>
          {COLOR_TABS.map((tab) => (
            <TabsTrigger
              unstyled
              className='legacyTabsTriggerFlex'
              key={tab.eventKey}
              value={tab.eventKey}
            >
              {tab.title}
            </TabsTrigger>
          ))}
        </TabsList>
        <TabsContent
          unstyled
          className='legacyTabsContent'
          style={{ padding: '0 1em' }}
          value='DefaultColors'
        >
          <Row>
            <Col sm='4'>
              <PropertyLabel
                label='Background'
                {...labelData('background_color')}
              />
            </Col>
            <Col>
              <FColorPicker
                value={elementStyle.background_color}
                onChange={(color: any) =>
                  handleStyleChange({ background_color: color })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm='5'>
              <CheckboxField
                style={{ marginLeft: '5px' }}
                checked={!!elementStyle.gradient_color}
                onChange={(value) =>
                  handleStyleChange({
                    gradient_color: value ? '000000FF' : ''
                  })
                }
                text={
                  <PropertyLabel
                    label='Gradient'
                    {...labelData('gradient_color')}
                  />
                }
              />
            </Col>
            {elementStyle.gradient_color && (
              <Col>
                <FColorPicker
                  value={elementStyle.gradient_color}
                  onChange={(color: any) =>
                    handleStyleChange({ gradient_color: color })
                  }
                  colorPickerStyle={{ left: '-30px' }}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <PropertyLabel label='Border' {...labelData(...BORDER_PROPS)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <BorderStyleInput
                elementStyles={elementStyle}
                onComplete={handleStyleChange}
              />
            </Col>
          </Row>
        </TabsContent>
        {COLOR_TABS.map(({ eventKey, title, pre, prefix, borderProps }) => (
          <TabsContent
            key={eventKey}
            value={eventKey}
            className={styles.stylesTab}
            style={{ padding: '0 1em' }}
          >
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle[pre('font_color')]}
                  onChange={(value) =>
                    handleStyleChange({
                      [pre('font_color')]: value ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Font'
                      {...labelData(pre('font_color'))}
                    />
                  }
                />
              </Col>
              {elementStyle[pre('font_color')] && (
                <Col>
                  <FColorPicker
                    value={elementStyle[pre('font_color')]}
                    onChange={(color: any) =>
                      handleStyleChange({ [pre('font_color')]: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle[pre('background_color')]}
                  onChange={(value) =>
                    handleStyleChange({
                      [pre('background_color')]: value ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Background'
                      {...labelData(pre('background_color'))}
                    />
                  }
                />
              </Col>
              {elementStyle[pre('background_color')] && (
                <Col>
                  <FColorPicker
                    value={elementStyle[pre('background_color')]}
                    onChange={(color: any) =>
                      handleStyleChange({ [pre('background_color')]: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle[pre('border_top_color')]}
                  onChange={(value) =>
                    handleStyleChange({
                      [pre('border_top_color')]: value ? '000000FF' : '',
                      [pre('border_right_color')]: value ? '000000FF' : '',
                      [pre('border_bottom_color')]: value ? '000000FF' : '',
                      [pre('border_left_color')]: value ? '000000FF' : '',
                      [pre('border_top_width')]: 0,
                      [pre('border_right_width')]: 0,
                      [pre('border_bottom_width')]: 0,
                      [pre('border_left_width')]: 0,
                      [pre('border_top_pattern')]: value ? 'solid' : '',
                      [pre('border_right_pattern')]: value ? 'solid' : '',
                      [pre('border_bottom_pattern')]: value ? 'solid' : '',
                      [pre('border_left_pattern')]: value ? 'solid' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Border'
                      {...labelData(...borderProps)}
                    />
                  }
                />
              </Col>
            </Row>
            {!!elementStyle[pre('border_top_color')] && (
              <Row>
                <Col>
                  <BorderStyleInput
                    elementStyles={elementStyle}
                    onComplete={handleStyleChange}
                    prefix={prefix}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle[pre('image_color')]}
                  onChange={(value) =>
                    handleStyleChange({
                      [pre('image_color')]: value ? 'black' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Image'
                      {...labelData(pre('image_color'))}
                    />
                  }
                />
              </Col>
              {elementStyle[pre('image_color')] && (
                <Col>
                  <DropdownField
                    selected={elementStyle[pre('image_color')]}
                    onChange={(e: any) =>
                      handleStyleChange({
                        [pre('image_color')]: e.target.value
                      })
                    }
                    options={[
                      { value: 'black', display: 'Black' },
                      { value: 'white', display: 'White' }
                    ]}
                  />
                </Col>
              )}
            </Row>
          </TabsContent>
        ))}
      </Tabs>
    </>
  );
}

export default memo(ButtonStylePanel);
