import useIntegrations from '../../components/FormIntegrations/useIntegrations';
import { INTEGRATIONS } from '../../components/FormIntegrations';
import { useParams } from 'react-router-dom';
import LockIcon from '../../components/Icons/system/LockIcon';
import styles from './styles.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function useIsStepProtected(stepId: string) {
  const { formId } = useParams<{ formId: string }>();
  const { firebase, stytch } = useIntegrations({
    types: [INTEGRATIONS.STYTCH, INTEGRATIONS.FIREBASE],
    panelId: formId
  });
  const activeInteg = stytch ? 'Stytch' : firebase ? 'Firebase' : null;
  const authGateSteps =
    stytch?.data.metadata?.auth_gate_steps ??
    firebase?.data.metadata?.auth_gate_steps ??
    [];

  return authGateSteps.includes(stepId) ? (
    <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip id='step-protected'>
          Only logged-in users can visit this step. Edit via your {activeInteg}{' '}
          settings.
        </Tooltip>
      }
    >
      <div className={styles.lockIconContainer}>
        <LockIcon className={styles.lockIcon} />
      </div>
    </OverlayTrigger>
  ) : null;
}
