import {
  DropdownMultiField,
  InlineTooltip,
  TextField
} from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { Form } from 'react-bootstrap';
import { useAppSelector } from '../../../hooks';

const NotificationsSection = ({ title, formData, updateData }: any) => {
  const accounts = useAppSelector(
    (state) => state.accounts.organization?.team_accounts ?? []
  );

  const optionEmails = accounts.map((account: any) => account.email);
  formData.signature_alertees.forEach((alertee: string) => {
    if (!optionEmails.includes(alertee)) optionEmails.push(alertee);
  });

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <Form.Label>
          Signature Notification Emails{' '}
          <InlineTooltip text='Emails to notify when documents to sign have been sent, viewed, or signed. This may also be a field wrapped in double curly braces (e.g. {{FieldID}}), which will automatically populate an email if one exists.' />
        </Form.Label>
        <DropdownMultiField
          placeholder='notify1@company.com'
          selected={formData.signature_alertees}
          options={optionEmails}
          creatable
          onChange={(items: { value: string }[]) =>
            updateData(
              'signature_alertees',
              items.map((item) => item.value)
            )
          }
        />
      </div>
      <div className='field-group'>
        <Form.Label>
          Slack Integration
          <InlineTooltip
            text='Add a Slack incoming webhook to get notifications for signed documents and daily reports on outstanding signatures'
            inline
          />
        </Form.Label>
        <TextField
          type='url'
          placeholder='https://hooks.slack.com/services/...'
          value={formData.slack_alert_hook}
          onComplete={(val: string) => updateData('slack_alert_hook', val)}
        />
      </div>
    </SettingsContainer>
  );
};

export default NotificationsSection;
