import { memo } from 'react';

import Dialog from '../Dialog';
import { TrashIcon } from '../Icons';
import useFeatheryRedux from '../../redux';
import { Negative, Neutral } from '../Core/Button';
import { useFormBuilderChangeStep } from '../../hooks/useFormBuilderChangeStep';
import {
  objectApply,
  objectMap,
  objectPick,
  objectRemove
} from '../../utils/core';
import { produce } from 'immer';
import { UNDO_TITLES, UNDO_TYPES } from '../../utils/constants';
import useElementRefCleanup from '../../utils/useElementRefCleanup';
import { useAppSelector } from '../../hooks';

const DeleteStepModal = ({ show, setShow }: any) => {
  const {
    toasts: { addToast, addInfoToast },
    formBuilder: { setPanelDataWithUndoRedo }
  } = useFeatheryRedux();
  const changeStep = useFormBuilderChangeStep();
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const { deleteElements } = useElementRefCleanup();
  const workingLogicRules = useAppSelector(
    (s) => s.formBuilder.workingLogicRules
  );

  const stepOnDelete = () => {
    setShow(false);
    const steps = Object.values(workingSteps);
    if (steps.length <= 1) {
      addInfoToast('Your form must have at least one step');
      return;
    }

    // Need to clean all other steps (and logic rules elements) of references to fields on the to be deleted step
    const changedStepKeys = new Set<string>();
    const changedLogicRuleKeys = new Set<string>();
    const { steps: cleanedWorkingSteps, logicRules: cleanedLogicRules } =
      deleteElements(
        workingSteps,
        activeStepId,
        objectPick(workingSteps[activeStepId], [
          'servar_fields',
          'progress_bars', // The rest of these could be referenced by logic rules elements
          'buttons',
          'texts',
          'images',
          'videos',
          'subgrids'
        ]),
        changedStepKeys,
        workingLogicRules,
        changedLogicRuleKeys
      );

    // Figure out which steps need to be overwritten because they contain the step id being removed and
    // only save those.
    const noChange: any = [];
    steps.forEach((step: any) => {
      if (
        !step.next_conditions.some(
          (cond: any) => cond.next_step === activeStepId
        ) &&
        !step.previous_conditions.some(
          (cond: any) => cond.previous_step === activeStepId
        ) &&
        step.id !== activeStepId &&
        !changedStepKeys.has(step.id)
      ) {
        noChange.push(step.id);
      }
    });

    const oldVal = objectRemove(workingSteps, noChange);
    noChange.push(activeStepId);
    const newVal = objectMap(
      objectRemove(cleanedWorkingSteps, noChange),
      ([key, step]) => {
        const newNextConditions = step.next_conditions.filter(
          (cond: any) => cond.next_step !== activeStepId
        );
        const newPrevConditions = step.previous_conditions.filter(
          (cond: any) => cond.previous_step !== activeStepId
        );
        return [
          key,
          objectApply(step, {
            next_conditions: newNextConditions,
            previous_conditions: newPrevConditions
          })
        ];
      }
    );

    let needNewOrigin = cleanedWorkingSteps[activeStepId].origin;
    let newOriginStepId = '';
    const newWorkingSteps: { [key: string]: any } = produce(
      cleanedWorkingSteps,
      (draft: any) => {
        delete draft[activeStepId];

        const stepList = Object.values(draft);

        // If a step is being deleted, clear out any navigation rules in the form that depend on it
        stepList.forEach((step: any) => {
          step.next_conditions = step.next_conditions.filter(
            (cond: any) => cond.next_step !== activeStepId
          );
          step.previous_conditions = step.previous_conditions.filter(
            (cond: any) => cond.previous_step !== activeStepId
          );
          // Find a step that no other steps lead to
          if (step.previous_conditions.length === 0 && needNewOrigin) {
            step.origin = true;
            needNewOrigin = false;
            newOriginStepId = step.id;
          }
        });

        // If the remaining steps are in a recursive loop, pick a step at random to set to origin
        if (needNewOrigin) {
          (stepList as any)[0].origin = true;
          newOriginStepId = (stepList as any)[0].id;
        }
      }
    );
    // Include the changed origin step in the changes
    if (newOriginStepId) {
      oldVal[newOriginStepId] = workingSteps[newOriginStepId];
      (newVal as any)[newOriginStepId] = newWorkingSteps[newOriginStepId];
    }

    // also need to clean logic rules steps property of references to the step being deleted
    const newLogicRules: { [id: string]: any } = {};
    Object.values(cleanedLogicRules).forEach((logicRule: any) => {
      if (logicRule.steps.includes(activeStepId)) {
        logicRule = { ...logicRule, steps: [...logicRule.steps] };
        newLogicRules[logicRule.id] = logicRule;
        logicRule.steps = logicRule.steps.filter(
          (stepId: string) => stepId !== activeStepId
        );
        changedLogicRuleKeys.add(logicRule.id);
      } else {
        newLogicRules[logicRule.id] = logicRule;
      }
    });

    const changedLogicRulesKeysArr = Array.from(changedLogicRuleKeys);
    setPanelDataWithUndoRedo({
      id: activeStepId,
      oldValue: oldVal,
      newValue: newVal,
      title: UNDO_TITLES.DELETE,
      type: UNDO_TYPES.CONDITION,
      workingSteps: newWorkingSteps,
      oldRulesValue: objectPick(workingLogicRules, changedLogicRulesKeysArr),
      newRulesValue: objectPick(newLogicRules, changedLogicRulesKeysArr),
      workingLogicRules: newLogicRules
    });

    addToast({
      text: (
        <>
          <strong>Step</strong> was deleted.
        </>
      ),
      icon: <TrashIcon />,
      decay: 6000
    });

    const newStepId = Object.values(newWorkingSteps).find(
      (step: any) => step.origin
    ).id;
    changeStep(newStepId);
  };

  return (
    <Dialog
      alignCenter
      isOpen={show}
      shouldShowCloseIcon={false}
      onClose={() => setShow(false)}
      title='Delete step'
    >
      <p style={{ padding: '0 40px', fontSize: '16px' }}>
        Are you sure you want to delete this step?
      </p>
      <div className='dialog-form-action'>
        <Neutral onClick={() => setShow(false)}>Cancel</Neutral>
        <Negative onClick={stepOnDelete}>Delete</Negative>
      </div>
    </Dialog>
  );
};

export default memo(DeleteStepModal);
