import { memo } from 'react';
import Dialog from '../Dialog';

import { Positive, Neutral } from '../Core/Button';

function FormActivationModal({
  activate = false,
  activateErrorMessage = '',
  formId,
  changeFormStatus,
  show = false,
  setShow = () => {},
  setShowChangePlan = () => {}
}: any) {
  const title = activate ? 'Cannot turn on form' : 'Are you sure?';

  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      shouldShowCloseIcon
      title={title}
      size='sm'
    >
      <p style={{ padding: '40px 0', fontSize: '16px' }}>
        {activate
          ? activateErrorMessage
          : 'If you turn this form off, users will not be able to fill it out.'}
      </p>
      <div
        className='dialog-form-action'
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Neutral onClick={() => setShow(false)}>Cancel</Neutral>
        <Positive
          onClick={(event: any) => {
            event.preventDefault();
            setShow(false);
            if (activate) setShowChangePlan(true);
            else changeFormStatus(formId, activate);
          }}
        >
          {activate ? 'Upgrade Now' : 'Turn Form Off'}
        </Positive>
      </div>
    </Dialog>
  );
}

export default memo(FormActivationModal);
