import { Link } from 'react-router-dom';

type ConfirmStepProps = {
  isRollback: boolean;
  formId: string;
  formKey: string;
};

export function ConfirmStep(props: ConfirmStepProps) {
  const { isRollback, formId, formKey } = props;
  return (
    <div>
      {isRollback
        ? 'Are you sure you want to rollback '
        : 'Are you sure you want to promote this form to '}
      <Link to={`/forms/${formId}`}>{formKey}</Link>?
    </div>
  );
}
