import { Fragment } from 'react';
import { DropdownField } from '../../../Core';
import Label from '../../../Dialog/Label';

type MapFieldsStepProps = {
  thisFormServarIds: string[];
  promoteToServarIds: string[];
  servarKeysById: Record<string, string>;
  setMapFieldData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  mapFieldData: Record<string, string>;
  promoteKey: string;
};

export function MapFieldsStep(props: MapFieldsStepProps) {
  const {
    thisFormServarIds,
    promoteToServarIds,
    servarKeysById,
    setMapFieldData,
    mapFieldData,
    promoteKey
  } = props;
  const selectedFields = Object.values(mapFieldData);
  return (
    <div>
      <div className='tw-grid tw-grid-cols-2 tw-gap-x-4 tw-gap-y-2 tw-items-center'>
        <Label className='-tw-mb-1.5'>This Form</Label>
        <Label className='-tw-mb-1.5'>{promoteKey}</Label>
        {thisFormServarIds.map((thisServar) => {
          const options = [
            { value: '', display: 'New field / no mapping' },
            ...promoteToServarIds.map((s) => ({
              value: s,
              display: servarKeysById[s],
              disabled: selectedFields.includes(s)
            }))
          ];
          return (
            <Fragment key={thisServar}>
              <div>{servarKeysById[thisServar]}</div>
              <DropdownField
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  setMapFieldData((curr) => ({
                    ...curr,
                    [thisServar]: event.target.value
                  }))
                }
                selected={mapFieldData[thisServar] || ''}
                options={options}
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
