function GoogleDocs({ width = 96, height = 96 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      x='0px'
      y='0px'
      viewBox='0 0 4422 3074.2'
      xmlSpace='preserve'
    >
      <g>
        <path
          style={{ fill: '#4285F4' }}
          d='M2438.3,287.1h-966c-90.8,0-170.4,79.5-170.4,170.5v2159.1c0,90.9,79.5,170.5,170.5,170.5h1477.3   c90.9,0,170.5-79.5,170.5-170.5V968.9l-397.7-284.1L2438.3,287.1z'
        />
        <path
          style={{ fill: '#F1F1F1' }}
          d='M1756.4,2105.3h909.1v-113.6h-909L1756.4,2105.3L1756.4,2105.3z M1756.4,2332.6h681.8V2219h-681.8V2332.6z    M1756.4,1537.1v113.6h909.1v-113.6H1756.4z M1756.4,1878h909.1v-113.6h-909L1756.4,1878L1756.4,1878z'
        />
        <path
          style={{ fill: '#A1C2FA' }}
          d='M2438.3,287.1v511.4c0,90.9,79.5,170.4,170.4,170.4h511.4L2438.3,287.1z'
        />
      </g>
    </svg>
  );
}

export default GoogleDocs;
