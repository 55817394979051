export default function PromoteIcon({ width = 17, height = 18, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 18'
      fill='#414859'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_promote_icon)'>
        <path d='M17 15.4286V2.07143C17 1.06547 16.1845 0.25 15.1786 0.25H1.82143C0.815469 0.25 0 1.06547 0 2.07143V15.4286C0 16.4345 0.815469 17.25 1.82143 17.25H15.1786C16.1845 17.25 17 16.4345 17 15.4286Z' />
        <path d='M8.5 3.5L13 8H10V14.5H7V8H4L8.5 3.5Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_promote_icon'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
